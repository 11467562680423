import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleInsulationSpecsPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData } from '../Dashboard/selectors';

import { selectCurrentEditedDraftInsulationSpecsCount } from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectCurrentEditedDraftInsulationSpecsCount(),
  (isLoadingInitialData, isFetching, count) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: PipingModuleInsulationSpecsPath.defaultTitle,
    subtitle: `Showing ${count} ${pluralize('Insulation Spec', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
