import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectDrafts from '../../modules/drafts/selectors';

import { selectNormalizedDraftLayers } from '../../entities/Layers/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

export const selectCurrentDraftLayersPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) =>
      drafts.getIn([EK.LAYERS.state, 'saveable']).size > 0
  );

export const selectCurrentDraftLayerIds = () =>
  createSelector(selectNormalizedDraftLayers(), (drafts) =>
    drafts.reduce((ids, draft) => ids.add(draft.id), Set())
  );

export const selectCurrentEditedDraftLayersCount = () =>
  createSelector(
    selectNormalizedDraftLayers(),
    (drafts) =>
      drafts.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
  );

const layerFilterForm = formValueSelector(
  `filters.${EK.LAYERS.state}`
);

export const selectCurrentFilteredNormalizedDraftLayers = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftLayers(),
    (state) => layerFilterForm(state, 'name', 'description'),
    (isFiltering, layers, query) =>
      isFiltering
        ? layers.filter((layer) => layer.doesMatchQuery(query))
        : layers
  );
