import { createAction } from 'redux-actions';

import {
  generateCreateDraftsAction,
  generateEditDraftsAction,
} from '../../modules/drafts/actions';

import {
  CREATE_ORIENTATION_DRAFTS,
  EDIT_ORIENTATION_DRAFTS,
  UPDATE_ORIENTATIONS_COLLECTION,
} from './constants';

export const createOrientationDrafts = generateCreateDraftsAction(
  CREATE_ORIENTATION_DRAFTS
);

export const editOrientationDrafts = generateEditDraftsAction(
  EDIT_ORIENTATION_DRAFTS
);

export const updateOrientationsCollection = createAction(
  UPDATE_ORIENTATIONS_COLLECTION
);
