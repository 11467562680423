import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentBendMachine } from './selectors';

import { PipingModulePipesBendMachinesEditPath } from '../../paths';

const mapStateToProps = createSelector(
  selectCurrentBendMachine(),
  (bendMachine) => ({
    title:
      (bendMachine && bendMachine.name) ||
      PipingModulePipesBendMachinesEditPath.defaultTitle,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
