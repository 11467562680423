import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  CREATE_PIPE_INSTANCE_DRAFTS,
  EDIT_PIPE_INSTANCE_DRAFTS,
  UPDATE_PIPE_INSTANCES_COLLECTION,
} from './constants';

export const createPipeInstanceDrafts = generateCreateDraftsAction(CREATE_PIPE_INSTANCE_DRAFTS);

export const editPipeInstanceDrafts = generateEditDraftsAction(EDIT_PIPE_INSTANCE_DRAFTS);

export const updatePipeInstancesCollection = createAction(UPDATE_PIPE_INSTANCES_COLLECTION, pipeFamilyId => ({ pipeFamilyId }));
