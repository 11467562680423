import { createAction } from 'redux-actions';

import {
  generateCreateDraftsAction,
  generateEditDraftsAction,
} from '../../modules/drafts/actions';

import {
  CREATE_LAYER_DRAFTS,
  EDIT_LAYER_DRAFTS,
  UPDATE_LAYERS_COLLECTION,
} from './constants';

export const createLayerDrafts = generateCreateDraftsAction(
  CREATE_LAYER_DRAFTS
);

export const editLayerDrafts = generateEditDraftsAction(
  EDIT_LAYER_DRAFTS
);

export const updateLayersCollection = createAction(
  UPDATE_LAYERS_COLLECTION
);
