import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import PipeInstanceSchema from './schema';

export const selectNormalizedPipeInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('pipeInstances')
);
export const selectNormalizedDraftPipeInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('pipeInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectPipeInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('pipeInstances'), [PipeInstanceSchema], entities.delete('pipeFamilies'))
);
export const selectDraftPipeInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftPipeInstances(),
  (entities, drafts) => denormalize(drafts, [PipeInstanceSchema], entities.delete('pipeFamilies'))
);