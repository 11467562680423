import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';
import FittingFamilySchema from '../../entities/FittingFamilies/schema';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const fittingFilterForm = formValueSelector(`filters.${EK.FITTINGS.state}`);

export const selectCurrentFilteredDraftFittings = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectEntities(),
  selectNormalizedFittingFamilies(),
  state => fittingFilterForm(state, 'name', 'selectionName', 'fittingCode', 'fittingCategory', 'material', 'boltNutFamily'),
  (isFiltering, isShowingArchived, entities, fittings, query) => denormalize(
    isFiltering ? 
      fittings.filter(fitting => (isShowingArchived || !fitting.archivedFlag) && fitting.doesMatchQuery(query)) : 
      fittings.filter(fitting => !fitting.archivedFlag),
    [FittingFamilySchema],
    entities.delete(EK.FITTING_INSTANCES.state)
  )
);