import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import SizeModel from '../../entities/Sizes/model';
import SizeSchema from '../../entities/Sizes/schema';
import { selectNormalizedSizes, selectNormalizedDraftSizes } from '../../entities/Sizes/selectors';

import {
  selectCurrentDraftSizeIds,
} from './selectors';

import { processSaveSizes } from '../../entities/Sizes/actions';
import { saveSizesTypes } from '../../entities/Sizes/constants';

import {
  CREATE_SIZE_DRAFTS,
  EDIT_SIZE_DRAFTS,
  UPDATE_SIZES_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.SIZES,
  schema: SizeSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedSizes,
});

const moveSaga = generateMoveSaga({
  keys: EK.SIZES,
  model: SizeModel,
  originalNormalizedDataSelector: selectNormalizedSizes, 
  currentIdsSetSelector: selectCurrentDraftSizeIds, 
});

const createSaga = generateWatchCreateSaga({
  keys: EK.SIZES,
  model: SizeModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftSizeIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.SIZES, 
  model: SizeModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftSizes,
  currentIdsSetSelector: selectCurrentDraftSizeIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectNormalizedDraftSizes,
  processUpdateAction: processSaveSizes,
  processUpdateTypes: saveSizesTypes,
  moveSaga,
});

export function* createDraftSizesSaga() {
  yield takeEvery(CREATE_SIZE_DRAFTS, createSaga);
}

export function* editDraftSizesSaga() {
  yield takeEvery(EDIT_SIZE_DRAFTS, editSaga);
}

export function* saveDraftSizesSaga() {
  yield takeLatest(UPDATE_SIZES_COLLECTION, updateSaga);
}

export function* watchSizesDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftSizesSaga),
      fork(editDraftSizesSaga),
      fork(saveDraftSizesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.SIZES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSizesDraftsSaga);
}
