import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import BendInstanceModel from '../../entities/BendInstances/model';
import BendInstanceSchema from '../../entities/BendInstances/schema';

import { selectCurrentPipeInstanceAndChildrenSerializationSelector } from '../PipesEditSwitch/selectors';

import {
  selectBaseParams,
  selectDraftDenormalizeSelector,
  selectOriginalNormalizedBendInstancesForFamily,
  selectOriginalDenormalizedBendInstancesForFamily,
  selectCurrentDraftBendInstances,
  selectCurrentDraftBendInstanceIds,
} from './selectors';

import { processFetchIndividualBendFamilyForPipeFamily } from '../../entities/BendFamilies/actions';
import { fetchIndividualBendFamilyForPipeFamilyTypes } from '../../entities/BendFamilies/constants';

import { 
  selectNormalizedDraftBendInstances,
} from '../../entities/BendInstances/selectors';
import { processSaveBendInstances } from '../../entities/BendInstances/actions';
import { saveBendInstancesTypes } from '../../entities/BendInstances/constants';

import {
  CREATE_BEND_INSTANCE_DRAFTS,
  EDIT_BEND_INSTANCE_DRAFTS,
  UPDATE_BEND_INSTANCES_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectBaseParams,
});

const reviewSaga = generateReviewSaga({
  keys: EK.BEND_INSTANCES,
  schema: BendInstanceSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalDenormalizedBendInstancesForFamily,
});

const generateCreationProperties = ({ familyId }) => ({
  bendFamilyId: familyId, 
  bendFamily: familyId
});

const moveSaga = generateMoveSaga({
  keys: EK.BEND_INSTANCES,
  model: BendInstanceModel,
  baseParamsSelector: selectBaseParams, 
  originalNormalizedDataSelector: selectOriginalNormalizedBendInstancesForFamily, 
  currentIdsSetSelector: selectCurrentDraftBendInstanceIds, 
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.BEND_INSTANCES,
  model: BendInstanceModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftBendInstanceIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.BEND_INSTANCES, 
  model: BendInstanceModel,
  reviewSaga,
  baseParamsSelector: selectBaseParams, 
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftBendInstances,
  currentIdsSetSelector: selectCurrentDraftBendInstanceIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectBaseParams,
  currentDraftDataToSerializeSelector: selectCurrentDraftBendInstances,
  serializationDataSelector: selectCurrentPipeInstanceAndChildrenSerializationSelector,
  processUpdateAction: processSaveBendInstances,
  processUpdateTypes: saveBendInstancesTypes,
  moveSaga,
});

export function* createDraftBendInstancesSaga() {
  yield takeEvery(CREATE_BEND_INSTANCE_DRAFTS, createSaga);
}

export function* editDraftBendInstancesSaga() {
  yield takeEvery(EDIT_BEND_INSTANCE_DRAFTS, editSaga);
}

export function* saveDraftBendInstancesSaga() {
  yield takeLatest(UPDATE_BEND_INSTANCES_COLLECTION, updateSaga);
}

export function* watchBendInstancesDraftsSaga() {
  try {
    const baseParams = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualBendFamilyForPipeFamily(baseParams));

    // wait for the individual request to be successful
    yield take(fetchIndividualBendFamilyForPipeFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftBendInstancesSaga),
      fork(editDraftBendInstancesSaga),
      fork(saveDraftBendInstancesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.BEND_INSTANCES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchBendInstancesDraftsSaga);
}
