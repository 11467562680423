import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import { CREATE_MATERIAL_DRAFTS, EDIT_MATERIAL_DRAFTS, UPDATE_MATERIALS_COLLECTION } from './constants';

export const createMaterialDrafts = generateCreateDraftsAction(CREATE_MATERIAL_DRAFTS);

export const editMaterialDrafts = generateEditDraftsAction(EDIT_MATERIAL_DRAFTS);

export const updateMaterialsCollection = createAction(UPDATE_MATERIALS_COLLECTION);
