import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData, selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectCurrentSpec, selectCurrentPipe } from '../SpecsEditSwitch/selectors';

import {
  selectCurrentSpecPipeInstancesForFamily,
  selectCurrentFilteredSpecPipeInstancesForFamily,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentSpec(),
  selectCurrentPipe(),
  selectCurrentSpecPipeInstancesForFamily(),
  selectCurrentFilteredSpecPipeInstancesForFamily(),
  (isLoadingInitialData, isFetching, isFiltering, spec, pipe, data, filtered) => ({
    isLoading: isLoadingInitialData || (isFetching && (!data || data.size === 0)),
    title: spec && spec.specName && pipe && pipe.name && `${spec.specName} // ${pipe.name}` || 'Loading...',
    subtitle: isFiltering ? `Showing ${filtered.size}/${data.size} ${pluralize('filtered Pipe Instance', filtered.size)}` : `Showing ${data.size} ${pluralize('Pipe Instance', data.size)} Total`,
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(Header);