import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import selectEntities from '../../../modules/entities/selectors';

import EK from '../../../entities/keys';

import SizeTooltip from '../templates/SizeTooltip';
import ScheduleTooltip from '../templates/ScheduleTooltip';

const ENTITY_TOOLTIPS = {
  [EK.SIZES.state]: SizeTooltip,
  [EK.SCHEDULES.state]: ScheduleTooltip,
};

const createSelectSpecificEntityFromEntityKey = () => createSelector(
  selectEntities(),
  (_, ek) => ek.state,
  (_, ek, id) => id,
  (entities, eks, id) => entities.getIn([eks, id])
);

const BaseEntityTooltip = ({ forwardRef, ek, id, ...props }) => {
  const selectEntityFromEntityKey = useMemo(
    createSelectSpecificEntityFromEntityKey,
    []
  );

  const entity = useSelector(state => selectEntityFromEntityKey(state, ek, id));

  const TooltipComponent = ENTITY_TOOLTIPS[ek.state];

  return TooltipComponent && <TooltipComponent entity={entity} { ...props } /> || null;
};

const EntityTooltip = React.forwardRef((props, ref) => <BaseEntityTooltip forwardRef={ref} { ...props } />);

export default EntityTooltip;