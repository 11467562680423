import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import BendSpringbackInstanceModel from '../../entities/BendSpringbackInstances/model';
import BendSpringbackInstanceSchema from '../../entities/BendSpringbackInstances/schema';

import { selectCurrentPipeInstanceAndChildrenSerializationSelector } from '../PipesEditSwitch/selectors';

import {
  selectBaseParams,
  selectDraftDenormalizeSelector,
  selectOriginalNormalizedBendSpringbackInstancesForFamily,
  selectOriginalDenormalizedBendSpringbackInstancesForFamily,
  selectCurrentDraftBendSpringbackInstances,
  selectCurrentDraftBendSpringbackInstanceIds,
} from './selectors';

import { processFetchIndividualBendSpringbackFamilyForPipeFamily } from '../../entities/BendSpringbackFamilies/actions';
import { fetchIndividualBendSpringbackFamilyForPipeFamilyTypes } from '../../entities/BendSpringbackFamilies/constants';

import { 
  selectNormalizedDraftBendSpringbackInstances,
} from '../../entities/BendSpringbackInstances/selectors';
import { processSaveBendSpringbackInstances } from '../../entities/BendSpringbackInstances/actions';
import { saveBendSpringbackInstancesTypes } from '../../entities/BendSpringbackInstances/constants';

import {
  CREATE_BEND_SPRINGBACK_INSTANCE_DRAFTS,
  EDIT_BEND_SPRINGBACK_INSTANCE_DRAFTS,
  UPDATE_BEND_SPRINGBACK_INSTANCES_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectBaseParams,
});

const reviewSaga = generateReviewSaga({
  keys: EK.BEND_SPRINGBACK_INSTANCES,
  schema: BendSpringbackInstanceSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalDenormalizedBendSpringbackInstancesForFamily,
});

const generateCreationProperties = ({ familyId }) => ({
  bendSpringbackFamilyId: familyId, 
  bendSpringbackFamily: familyId
});

const moveSaga = generateMoveSaga({
  keys: EK.BEND_SPRINGBACK_INSTANCES,
  model: BendSpringbackInstanceModel,
  baseParamsSelector: selectBaseParams, 
  originalNormalizedDataSelector: selectOriginalNormalizedBendSpringbackInstancesForFamily, 
  currentIdsSetSelector: selectCurrentDraftBendSpringbackInstanceIds, 
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.BEND_SPRINGBACK_INSTANCES,
  model: BendSpringbackInstanceModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftBendSpringbackInstanceIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.BEND_SPRINGBACK_INSTANCES, 
  model: BendSpringbackInstanceModel,
  reviewSaga,
  baseParamsSelector: selectBaseParams, 
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftBendSpringbackInstances,
  currentIdsSetSelector: selectCurrentDraftBendSpringbackInstanceIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectBaseParams,
  currentDraftDataToSerializeSelector: selectCurrentDraftBendSpringbackInstances,
  serializationDataSelector: selectCurrentPipeInstanceAndChildrenSerializationSelector,
  processUpdateAction: processSaveBendSpringbackInstances,
  processUpdateTypes: saveBendSpringbackInstancesTypes,
  moveSaga,
});

export function* createDraftBendSpringbackInstancesSaga() {
  yield takeEvery(CREATE_BEND_SPRINGBACK_INSTANCE_DRAFTS, createSaga);
}

export function* editDraftBendSpringbackInstancesSaga() {
  yield takeEvery(EDIT_BEND_SPRINGBACK_INSTANCE_DRAFTS, editSaga);
}

export function* saveDraftBendSpringbackInstancesSaga() {
  yield takeLatest(UPDATE_BEND_SPRINGBACK_INSTANCES_COLLECTION, updateSaga);
}

export function* watchBendSpringbackInstancesDraftsSaga() {
  try {
    const baseParams = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualBendSpringbackFamilyForPipeFamily(baseParams));

    // wait for the individual request to be successful
    yield take(fetchIndividualBendSpringbackFamilyForPipeFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftBendSpringbackInstancesSaga),
      fork(editDraftBendSpringbackInstancesSaga),
      fork(saveDraftBendSpringbackInstancesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.BEND_SPRINGBACK_INSTANCES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchBendSpringbackInstancesDraftsSaga);
}
