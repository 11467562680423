import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import BoltNutInstanceModel from '../../entities/BoltNutInstances/model';
import BoltNutInstanceSchema from '../../entities/BoltNutInstances/schema';

import {
  selectDraftDenormalizeSelector,
  selectCurrentBoltNutId,
  selectOriginalNormalizedBoltNutInstancesForFamily,
  selectOriginalDenormalizedBoltNutInstancesForFamily,
  selectCurrentDraftBoltNutInstances,
  selectCurrentDraftBoltNutInstanceIds,
} from './selectors';

import { processFetchIndividualBoltNutFamily } from '../../entities/BoltNutFamilies/actions';
import { fetchIndividualBoltNutFamilyTypes } from '../../entities/BoltNutFamilies/constants';

import { 
  selectNormalizedDraftBoltNutInstances,
} from '../../entities/BoltNutInstances/selectors';
import { processSaveBoltNutInstances } from '../../entities/BoltNutInstances/actions';
import { saveBoltNutInstancesTypes } from '../../entities/BoltNutInstances/constants';

import {
  CREATE_BOLT_NUT_INSTANCE_DRAFTS,
  EDIT_BOLT_NUT_INSTANCE_DRAFTS,
  UPDATE_BOLT_NUT_INSTANCES_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentBoltNutId,
});

const reviewSaga = generateReviewSaga({
  keys: EK.BOLT_NUT_INSTANCES,
  schema: BoltNutInstanceSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalDenormalizedBoltNutInstancesForFamily,
});

const generateCreationProperties = boltNutId => ({
  boltNutFamilyId: boltNutId, 
  boltNutFamily: boltNutId
});

const moveSaga = generateMoveSaga({
  keys: EK.BOLT_NUT_INSTANCES,
  model: BoltNutInstanceModel,
  baseParamsSelector: selectCurrentBoltNutId, 
  originalNormalizedDataSelector: selectOriginalNormalizedBoltNutInstancesForFamily, 
  currentIdsSetSelector: selectCurrentDraftBoltNutInstanceIds, 
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.BOLT_NUT_INSTANCES,
  model: BoltNutInstanceModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftBoltNutInstanceIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.BOLT_NUT_INSTANCES, 
  model: BoltNutInstanceModel,
  reviewSaga,
  baseParamsSelector: selectCurrentBoltNutId, 
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftBoltNutInstances,
  currentIdsSetSelector: selectCurrentDraftBoltNutInstanceIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectCurrentBoltNutId,
  currentDraftDataToSerializeSelector: selectCurrentDraftBoltNutInstances,
  processUpdateAction: processSaveBoltNutInstances,
  processUpdateTypes: saveBoltNutInstancesTypes,
  moveSaga,
});

export function* createDraftBoltNutInstancesSaga() {
  yield takeEvery(CREATE_BOLT_NUT_INSTANCE_DRAFTS, createSaga);
}

export function* editDraftBoltNutInstancesSaga() {
  yield takeEvery(EDIT_BOLT_NUT_INSTANCE_DRAFTS, editSaga);
}

export function* saveDraftBoltNutInstancesSaga() {
  yield takeLatest(UPDATE_BOLT_NUT_INSTANCES_COLLECTION, updateSaga);
}

export function* watchBoltNutInstancesDraftsSaga() {
  try {
    const boltNutId = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualBoltNutFamily(boltNutId));

    // wait for the individual request to be successful
    yield take(fetchIndividualBoltNutFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftBoltNutInstancesSaga),
      fork(editDraftBoltNutInstancesSaga),
      fork(saveDraftBoltNutInstancesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.BOLT_NUT_INSTANCES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchBoltNutInstancesDraftsSaga);
}
