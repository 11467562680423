import React from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { editProjectSpecs } from './actions';

import {
  selectCurrentFilteredDraftSpecs,
  selectCurrentProject,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentProject(),
  selectCurrentFilteredDraftSpecs(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    project,
    data,
  ) => {
    const isLoading = isLoadingInitialData || isFetching;
    return {
      ...rest,
      isLoading,
      isList: true,
      data: !isLoading && data.toList().toArray() || [],
      editable: canCollaborate && hasValidLicense && project &&
        !project.archivedFlag,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.PROJECTS.state}Edit`, saga })
);

function ProjectsEditGrid (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    ...(reduxProps.editable && [
      { type: ['entitySelectionForIdCell'] },
    ] || []),
    {
      headerName: 'Name + Mnemonic // Instances',
      field: 'name',
      type: ['complexCell'],
      valueGetter: ({ data }) => data.summary,
    },
  ];

  return (
    <Grid
      {...props}
      {...reduxProps}
      editInstances={(field, value) => dispatch(editProjectSpecs(field, value))}

      columnDefs={columnDefs}
    />
  );
}

export default enhance(ProjectsEditGrid);
