import { takeLatest, take, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import { PipingModuleBoltNutsPath, PipingBoltNutsEditPath } from '../../../../paths';

import {
  createBoltNutFamilyTypes,
  copyBoltNutFamilyTypes,
  editBoltNutFamilyTypes,
  deleteBoltNutFamilyTypes,
} from '../../../../entities/BoltNutFamilies/constants';
import {
  selectNormalizedBoltNutFamilies
} from '../../../../entities/BoltNutFamilies/selectors';

export function* handleCreateBoltNutFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.BOLT_NUTS.plural}[0].id`)}`;
  if (id) {
    let boltNuts = yield select(selectNormalizedBoltNutFamilies());
    let copiedBoltNut = boltNuts.get(id);

    while (!copiedBoltNut) {
      yield take();
      boltNuts = yield select(selectNormalizedBoltNutFamilies());
      copiedBoltNut = boltNuts.get(id);
    }

    yield put(push(PipingBoltNutsEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeleteBoltNutFamilySuccess() {
  yield put(push(PipingModuleBoltNutsPath.url));
}

export function* watchCreateBoltNutSaga() {
  yield takeLatest([
    createBoltNutFamilyTypes.SUCCESS,
    copyBoltNutFamilyTypes.SUCCESS,
  ], handleCreateBoltNutFamilySuccess
  );
}

export function* watchDeleteBoltNutSaga() {
  yield takeLatest(
    deleteBoltNutFamilyTypes.SUCCESS,
    handleDeleteBoltNutFamilySuccess
  );
}

export function* handleCloseBoltNutFamilyModal() {
  yield put(hideModal());
}

export function* watchCloseBoltNutModalSaga() {
  yield takeLatest(
    [
      createBoltNutFamilyTypes.SUCCESS,
      editBoltNutFamilyTypes.SUCCESS,
      deleteBoltNutFamilyTypes.SUCCESS,
    ],
    handleCloseBoltNutFamilyModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateBoltNutSaga),
    fork(watchDeleteBoltNutSaga),
    fork(watchCloseBoltNutModalSaga),
  ]);
}
