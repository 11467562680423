import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import MiterInstanceSchema from './schema';

export const selectNormalizedMiterInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('miterInstances')
);
export const selectNormalizedDraftMiterInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('miterInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectMiterInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('miterInstances'), [MiterInstanceSchema], entities.delete('miterFamilies'))
);
export const selectDraftMiterInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftMiterInstances(),
  (entities, drafts) => denormalize(drafts, [MiterInstanceSchema], entities.delete('miterFamilies'))
);