import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import { CREATE_SCHEDULE_DRAFTS, EDIT_SCHEDULE_DRAFTS, UPDATE_SCHEDULES_COLLECTION } from './constants';

export const createScheduleDrafts = generateCreateDraftsAction(CREATE_SCHEDULE_DRAFTS);

export const editScheduleDrafts = generateEditDraftsAction(EDIT_SCHEDULE_DRAFTS);

export const updateSchedulesCollection = createAction(UPDATE_SCHEDULES_COLLECTION);
