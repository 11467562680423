import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectMasterCatalogUnitSystem } from '../../entities/PipingCatalogs/selectors';
import UnitSystemSchema from '../../entities/UnitSystems/schema';

import { selectNormalizedInsulationFamilies } from '../../entities/InsulationFamilies/selectors';

import InsulationInstanceSchema from '../../entities/InsulationInstances/schema';
import {
  selectNormalizedInsulationInstances,
  selectNormalizedDraftInsulationInstances,
} from '../../entities/InsulationInstances/selectors';

import { PipingModuleInsulationsEditPath } from '../../paths';

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.INSULATIONS.state)
  );

export const selectCurrentInsulationId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleInsulationsEditPath.url,
    });
    return (match && match.params.id) || null;
  });

export const selectCurrentInsulation = () =>
  createSelector(
    selectCurrentInsulationId(),
    selectNormalizedInsulationFamilies(),
    (insulationId, insulations) => insulations.get(insulationId)
  );

export const selectOriginalNormalizedInsulationInstancesForFamily = () =>
  createSelector(
    selectNormalizedInsulationInstances(),
    selectCurrentInsulationId(),
    (instances, insulationFamilyId) =>
      instances.filter(
        (instance) => instance.insulationFamilyId === insulationFamilyId
      )
  );

export const selectOriginalDenormalizedInsulationInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedInsulationInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [InsulationInstanceSchema],
        entities.delete(EK.PIPES.state)
      )
  );

export const selectCurrentInsulationUnitSystem = () =>
  createSelector(
    selectCurrentInsulation(),
    selectEntities(),
    selectMasterCatalogUnitSystem(),
    (insulation, entities, defaultMasterCatalogUnitSystem) =>
      insulation &&
        insulation.unitSystem &&
        entities.hasIn([EK.UNIT_SYSTEMS.state, insulation.unitSystem])
        ? denormalize(
          entities.getIn([EK.UNIT_SYSTEMS.state, insulation.unitSystem]),
          UnitSystemSchema,
          entities
        )
        : defaultMasterCatalogUnitSystem
  );

export const selectCurrentInsulationInstanceSerializationSelector = () =>
  createSelector(selectCurrentInsulationUnitSystem(), (unitSystem) => ({
    unitSystem,
  }));

const insulationInstanceFilterForm = formValueSelector(
  `filters.${EK.INSULATION_INSTANCES.state}`
);

export const selectCurrentDraftInsulationInstances = () =>
  createSelector(
    selectNormalizedDraftInsulationInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [InsulationInstanceSchema],
        entities.delete(EK.INSULATIONS.state)
      )
  );

export const selectCurrentFilteredDraftInsulationInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftInsulationInstances(),
    selectEntities(),
    (state) =>
      insulationInstanceFilterForm(
        state,
        'sizeProperty',
        'insulationType',
        'fittingCategory',
        'material'
      ),
    (isFiltering, instances, entities, query) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) => instance.doesMatchQuery(query))
          : instances,
        [InsulationInstanceSchema],
        entities.delete(EK.INSULATIONS.state)
      )
  );

export const selectCurrentDraftInsulationInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) =>
      drafts.getIn([EK.INSULATION_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftInsulationInstanceIds = () =>
  createSelector(selectNormalizedDraftInsulationInstances(), (drafts) =>
    drafts.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftInsulationInstancesCount = () =>
  createSelector(
    selectNormalizedDraftInsulationInstances(),
    (drafts) =>
      drafts.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
  );
