import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import FittingForm from '../../../form/templates/FittingForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import FittingModel from '../../../../entities/FittingFamilies/model';
import {
  processCreateFittingFamily,
  processCopyFittingFamily,
  processEditFittingFamily,
  processDeleteFittingFamily,
} from '../../../../entities/FittingFamilies/actions';

import { selectCurrentFittingFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyFitting = new FittingModel();

const FittingModal = ({ fitting, isPerforming, copyEntity, onCreate, onCopy, onUpdate, onDelete }) => {
  const onSave = useCallback(data => {
    if (fitting) {
      if (copyEntity) {
        onCopy({ ...data, id: fitting.id });
      } else {
        onUpdate(fitting.id, data);
      }
    } else {
      onCreate(data);
    }
  }, [fitting, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(fitting.id), [fitting, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={fitting && fitting.name} copy={copyEntity} create='Fitting' />

      <FittingForm
        initialValues={fitting || emptyFitting}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        fitting && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={fitting.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  fitting: selectCurrentFittingFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.FITTINGS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreateFittingFamily,
      onCopy: processCopyFittingFamily,
      onUpdate: processEditFittingFamily,
      onDelete: processDeleteFittingFamily,
    }
  ),
);

export default enhance(FittingModal);
