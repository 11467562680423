import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import { selectNormalizedMiterFamiliesList } from '../../entities/MiterFamilies/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const miterFilterForm = formValueSelector(`filters.${EK.MITERS.state}`);

export const selectCurrentPipesMitersCount = () => createSelector(
  selectCurrentPipeId(),
  selectNormalizedMiterFamiliesList(),
  (pipeId, miters) => miters
    .filter(miter => miter.pipeFamilyId === pipeId)
    .reduce(({ unarchived, total }, miter) => ({
      unarchived: !miter.archivedFlag ? unarchived + 1 : unarchived,
      total: total + 1,
    }), {
      unarchived: 0,
      total: 0,
    })
);

export const selectCurrentFilteredPipesMiters = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipeId(),
  selectNormalizedMiterFamiliesList(),
  state => miterFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, pipeId, miters, name) => isFiltering ? 
    miters.filter(miter => (isShowingArchived || !miter.archivedFlag) && miter.pipeFamilyId === pipeId && miter.doesMatchQuery({ name })) : 
    miters.filter(miter => !miter.archivedFlag && miter.pipeFamilyId === pipeId)
);