import { toast } from 'react-toastify';

import EK from '../keys';

import insulationClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import insulationFamilyKeys from './keys';
import insulationInstanceKeys from '../InsulationInstances/keys';

import {
  fetchAllInsulationFamiliesTypes,
  fetchIndividualInsulationFamilyTypes,
  createInsulationFamilyTypes,
  copyInsulationFamilyTypes,
  editInsulationFamilyTypes,
  deleteInsulationFamilyTypes,
  archiveInsulationFamiliesTypes,
  unarchiveInsulationFamiliesTypes,
} from './constants';

export const processFetchAllInsulationFamilies = () => insulationClient(REQUEST_TYPES.GET, 'plural', fetchAllInsulationFamiliesTypes, '/piping/insulation_families?all=true');

export const processFetchIndividualInsulationFamily = insulationId => insulationClient(REQUEST_TYPES.GET, 'single', fetchIndividualInsulationFamilyTypes, `/piping/insulation_families/${insulationId}?with_full_detail=true`, {
  mutateResponse: ({ insulationFamily: { id, insulationInstances = [], ...other } = {} }) => ({
    insulationFamily: {
      ...other,
      id,
      insulationInstances: insulationInstances.map(instance => ({
        ...instance,
        insulationFamily: `${id}`,
        insulationFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateInsulationFamily = data => insulationClient(REQUEST_TYPES.POST, 'plural', createInsulationFamilyTypes, '/piping/insulation_families', {
  data: {
    insulationFamilies: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Insulation successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create insulation',
  },
});

export const processCopyInsulationFamily = (data) =>
  insulationClient(
    REQUEST_TYPES.POST,
    'plural',
    copyInsulationFamilyTypes,
    '/piping/insulation_families/copy',
    {
      data: {
        [EK.INSULATIONS.plural]: [data],
      },
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Insulation successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy insulation',
      },
    }
  );

export const processEditInsulationFamily = (insulationId, data) => insulationClient(REQUEST_TYPES.PATCH, 'single', editInsulationFamilyTypes, `/piping/insulation_families/${insulationId}`, {
  data,
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteInsulationFamily = insulationId => insulationClient(REQUEST_TYPES.DELETE, 'single', deleteInsulationFamilyTypes, `/piping/insulation_families/${insulationId}?with_full_detail=true`, {
  deleteKey: insulationFamilyKeys.state,
  dependentDeletionKeys: [insulationInstanceKeys.state],
  mutateResponse: ({ insulationFamily: { id, ...other } = {}, insulationInstances = [] }) => ({
    insulationFamily: {
      ...other,
      id,
      insulationInstances: insulationInstances.map(instance => ({
        ...instance,
        insulationFamily: `${id}`,
        insulationFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Insulation deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete insulation',
  },
});

export const processArchiveInsulationFamilies = insulationIds => insulationClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveInsulationFamiliesTypes, '/piping/insulation_families/archive', {
  data: { ids: insulationIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Insulation archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive insulation',
  },
});

export const processUnarchiveInsulationFamilies = insulationIds => insulationClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveInsulationFamiliesTypes, '/piping/insulation_families/unarchive', {
  data: { ids: insulationIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Insulation unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive insulation',
  },
});