import { select } from 'redux-saga/effects';

import { selectEndTypeCompatibilities } from './selectors';

import endTypeCompatibilityKeys from './keys';

export default function* review(drafts) {
  const originalData = yield select(selectEndTypeCompatibilities());

  return {
    [endTypeCompatibilityKeys.state]: drafts.map((entity) => {
      return entity.withMutations((model) =>
        model.comparison(originalData.get(model.id)).validate.renormalized
          .analyze
      );
    }),
  };
}
