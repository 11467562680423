import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectNormalizedMiterFamilies } from '../../entities/MiterFamilies/selectors';

import MiterInstanceSchema from '../../entities/MiterInstances/schema';
import {
  selectNormalizedMiterInstances,
  selectNormalizedDraftMiterInstances,
} from '../../entities/MiterInstances/selectors';

import { PipingModulePipesMitersEditPath } from '../../paths';

export const selectBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModulePipesMitersEditPath.url,
    });
    // returns { id, familyId }
    return (match && match.params) || null;
  });

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.MITERS.state)
  );

export const selectCurrentMiterId = () =>
  createSelector(
    selectBaseParams(),
    (params) => (params && params.familyId) || null
  );

export const selectCurrentMiter = () =>
  createSelector(
    selectCurrentMiterId(),
    selectNormalizedMiterFamilies(),
    (miterId, miters) => miters.get(miterId)
  );

export const selectOriginalNormalizedMiterInstancesForFamily = () =>
  createSelector(
    selectNormalizedMiterInstances(),
    selectCurrentMiterId(),
    (instances, miterFamilyId) =>
      instances.filter((instance) => instance.miterFamilyId === miterFamilyId)
  );

export const selectOriginalDenormalizedMiterInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedMiterInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [MiterInstanceSchema],
        entities.delete(EK.MITERS.state)
      )
  );

const miterInstanceFilterForm = formValueSelector(
  `filters.${EK.MITER_INSTANCES.state}`
);

export const selectCurrentDraftMiterInstances = () =>
  createSelector(
    selectNormalizedDraftMiterInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [MiterInstanceSchema],
        entities.delete(EK.MITERS.state)
      )
  );

export const selectCurrentFilteredDraftMiterInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftMiterInstances(),
    selectEntities(),
    (state) => miterInstanceFilterForm(state, 'sizeProperty'),
    (isFiltering, instances, entities, sizeProperty) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) =>
            instance.doesMatchQuery({ sizeProperty })
          )
          : instances,
        [MiterInstanceSchema],
        entities.delete(EK.MITERS.state)
      )
  );

export const selectCurrentDraftMiterInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) => drafts.getIn([EK.MITER_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftMiterInstanceIds = () =>
  createSelector(selectNormalizedDraftMiterInstances(), (instances) =>
    instances.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftMiterInstancesCount = () =>
  createSelector(
    selectNormalizedDraftMiterInstances(),
    (instances) =>
      instances.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT)
        .size
  );
