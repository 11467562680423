import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModulePipesEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentPipe } from '../PipesEditSwitch/selectors';

import {
  selectCurrentEditedDraftPipeInstancesCount,
  selectCurrentFilteredDraftPipeInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentPipe(),
  selectCurrentEditedDraftPipeInstancesCount(),
  selectCurrentFilteredDraftPipeInstances(),
  (isLoadingInitialData, isFetching, isFiltering, pipe, count, filtered) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: (pipe && pipe.name) || PipingModulePipesEditPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Pipe Instance',
        count
      )}`
      : `Showing ${count} ${pluralize('Pipe Instance', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
