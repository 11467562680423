import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedParameterProfilesList } from '../../entities/ParameterProfiles/selectors';
import {
  processArchiveParameterProfiles,
  processUnarchiveParameterProfiles,
} from '../../entities/ParameterProfiles/actions';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../Dashboard/selectors';

import { ParameterProfilesEditPath } from '../../paths';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedParameterProfilesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const mapDispatchToProps = (dispatch) => ({
  createEntity() {
    dispatch(showModal('PARAMETER_PROFILE_MODAL'));
  },

  editParameterProfile(parameterProfile) {
    dispatch(
      showModal('PARAMETER_PROFILE_MODAL', {
        parameterProfileId: parameterProfile.id,
      }),
    );
  },
  copyParameterProfile(parameterProfile) {
    dispatch(
      showModal('PARAMETER_PROFILE_MODAL', {
        parameterProfileId: parameterProfile.id,
        copyEntity: true,
      }),
    );
  },
  manageParametersForParameterProfile(parameterProfile) {
    dispatch(
      push(ParameterProfilesEditPath.generate({ id: parameterProfile.id })),
    );
  },
  archiveParameterProfile(profile) {
    dispatch(processArchiveParameterProfiles([profile.id]));
  },
  unarchiveParameterProfile(profile) {
    dispatch(processUnarchiveParameterProfiles([profile.id]));
  },
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    (
      { data, showArchived, editable, isList },
      dispatchProps,
    ) => ({
      data,
      isList,
      columnDefs: [
        {
          type: ['archivedStatusCell'],
          hide: !showArchived,
        },
        {
          headerName: 'Name // Parameters',
          field: 'name',
          type: ['complexCell', 'linkCell'],
          valueGetter: ({ data }) => ({
            ...data.summary,
            to:
              data.id && ParameterProfilesEditPath.generate({ id: data.id }) ||
              undefined,
          }),
        },
        ...(editable && [{
          type: ['dataActionIconCell'],
          cellRendererParams: ({ value }) => ({
            actions: value && List([
              {
                icon: 'list',
                onClick:
                          dispatchProps.manageParametersForParameterProfile,
              },
              {
                icon: 'copy',
                primary: true,
                onClick: dispatchProps.copyParameterProfile,
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: dispatchProps.unarchiveParameterProfile,
              },
            ]) || List([
              {
                icon: 'edit',
                primary: true,
                onClick: dispatchProps.editParameterProfile,
              },
              {
                icon: 'list',
                onClick:
                        dispatchProps.manageParametersForParameterProfile,
              },
              {
                icon: 'copy',
                primary: true,
                onClick: dispatchProps.copyParameterProfile,
              },
              {
                icon: 'archive',
                error: true,
                onClick: dispatchProps.archiveParameterProfile,
              },
            ]),
          }),
        }] || []),
      ],
    }),
  ),
);

export default enhance(Grid);
