import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import SpecForm from '../../../form/templates/SpecForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import SpecModel from '../../../../entities/Specs/model';
import {
  processCreateSpec,
  processCopySpec,
  processEditSpec,
  processDeleteSpec,
} from '../../../../entities/Specs/actions';

import { selectCurrentSpec } from '../../../../routes/SpecsEditSwitch/selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptySpec = new SpecModel();

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal()
});

const SpecModal = ({ copyEntity, isPerforming, specId = null, ...rest }) => {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const spec = useSelector(selectCurrentSpec(specId));

  const onSave = ( data ) => {
    if (spec) {
      if (copyEntity) {
        dispatch(processCopySpec({ ...data, id: spec.id }));
      } else {
        dispatch(processEditSpec(spec.id, data));
      }
    } else {
      dispatch(processCreateSpec(data));
    }
  };

  const onConfirmDelete = () => dispatch(processDeleteSpec(spec.id));

  return (
    <Box>
      <CreateOrEditEntityTitle edit={spec && spec.specName} copy={copyEntity} create='Piping Spec' />

      <SpecForm
        initialValues={spec || emptySpec}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        spec && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={spec.specName} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const enhance = compose(
  injectSaga({ key: `${EK.SPECS.state}Modal`, saga }),
);

export default enhance(SpecModal);
