import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import { MATERIAL_FILTERS } from '../../../../entities/Materials/model';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXSearchSelect from '../../RDXSearchSelect';

import { ALLOWANCES } from '../../../../entities/FittingFamilies/model';
import { validations } from '../../../../entities/FittingFamilies/formValidation';

const FittingForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          validate={validations['name']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="selectionName"
          label="Selection Name"
          required
          allow={ALLOWANCES.SELECTION_NAME}
          validate={validations['selectionName']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="fittingCategory"
          label="Fitting Category"
          ek={EK.FITTING_CATEGORIES}
          required
          validate={validations['fittingCategory']}
          width="50%"
        />
        <Field
          component={RDXSearchSelect}
          name="fittingCode"
          label="Fitting Code"
          ek={EK.FITTING_CODES}
          required
          validate={validations['fittingCode']}
          width="50%"
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="boltNutFamily"
          label="Bolt Nut"
          ek={EK.BOLT_NUTS}
          width="50%"
        />
        <Field
          component={RDXSearchSelect}
          name="material"
          label="Material"
          ek={EK.MATERIALS}
          eFilter={MATERIAL_FILTERS.FITTING}
          width="50%"
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.FITTINGS.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(FittingForm);
