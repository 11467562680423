import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  CREATE_BEND_SPRINGBACK_INSTANCE_DRAFTS,
  EDIT_BEND_SPRINGBACK_INSTANCE_DRAFTS,
  UPDATE_BEND_SPRINGBACK_INSTANCES_COLLECTION,
} from './constants';

export const createBendSpringbackInstanceDrafts = generateCreateDraftsAction(CREATE_BEND_SPRINGBACK_INSTANCE_DRAFTS);

export const editBendSpringbackInstanceDrafts = generateEditDraftsAction(EDIT_BEND_SPRINGBACK_INSTANCE_DRAFTS);

export const updateBendSpringbackInstancesCollection = createAction(UPDATE_BEND_SPRINGBACK_INSTANCES_COLLECTION, bendSpringbackFamilyId => ({ bendSpringbackFamilyId }));
