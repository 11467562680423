import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectNormalizedBendSpringbackFamilies } from '../../entities/BendSpringbackFamilies/selectors';

import BendSpringbackInstanceSchema from '../../entities/BendSpringbackInstances/schema';
import {
  selectNormalizedBendSpringbackInstances,
  selectNormalizedDraftBendSpringbackInstances,
} from '../../entities/BendSpringbackInstances/selectors';

import { PipingModulePipesBendSpringbacksEditPath } from '../../paths';

export const selectBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModulePipesBendSpringbacksEditPath.url,
    });
    // returns { id, familyId }
    return (match && match.params) || null;
  });

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.BEND_SPRINGBACKS.state)
  );

export const selectCurrentBendSpringbackId = () =>
  createSelector(
    selectBaseParams(),
    (params) => (params && params.familyId) || null
  );

export const selectCurrentBendSpringback = () =>
  createSelector(
    selectCurrentBendSpringbackId(),
    selectNormalizedBendSpringbackFamilies(),
    (bendSpringbackId, bendSpringbacks) => bendSpringbacks.get(bendSpringbackId)
  );

export const selectOriginalNormalizedBendSpringbackInstancesForFamily = () =>
  createSelector(
    selectNormalizedBendSpringbackInstances(),
    selectCurrentBendSpringbackId(),
    (instances, bendSpringbackFamilyId) =>
      instances.filter(
        (instance) => instance.bendSpringbackFamilyId === bendSpringbackFamilyId
      )
  );

export const selectOriginalDenormalizedBendSpringbackInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedBendSpringbackInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BendSpringbackInstanceSchema],
        entities.delete(EK.BEND_SPRINGBACKS.state)
      )
  );

const bendSpringbackInstanceFilterForm = formValueSelector(
  `filters.${EK.BEND_SPRINGBACK_INSTANCES.state}`
);

export const selectCurrentDraftBendSpringbackInstances = () =>
  createSelector(
    selectNormalizedDraftBendSpringbackInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BendSpringbackInstanceSchema],
        entities.delete(EK.BEND_SPRINGBACKS.state)
      )
  );

export const selectCurrentFilteredDraftBendSpringbackInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftBendSpringbackInstances(),
    selectEntities(),
    (state) => bendSpringbackInstanceFilterForm(state, 'sizeProperty'),
    (isFiltering, instances, entities, sizeProperty) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) =>
            instance.doesMatchQuery({ sizeProperty })
          )
          : instances,
        [BendSpringbackInstanceSchema],
        entities.delete(EK.BEND_SPRINGBACKS.state)
      )
  );

export const selectCurrentDraftBendSpringbackInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) =>
      drafts.getIn([EK.BEND_SPRINGBACK_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftBendSpringbackInstanceIds = () =>
  createSelector(selectNormalizedDraftBendSpringbackInstances(), (instances) =>
    instances.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftBendSpringbackInstancesCount = () =>
  createSelector(
    selectNormalizedDraftBendSpringbackInstances(),
    (instances) =>
      instances.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT)
        .size
  );
