import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveParameterProfiles,
  processUnarchiveParameterProfiles,
} from '../../entities/ParameterProfiles/actions';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import {
  selectCurrentDraftParameterProfileParametersPendingValidChanges,
  selectCurrentParameterProfile,
} from './selectors';

import { updateParameterProfileParametersCollection } from './actions';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentParameterProfile(),
  selectCurrentDraftParameterProfileParametersPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    parameterProfile,
    pendingValidChanges,
  ) => ({
    ...rest,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    parameterProfileId: (parameterProfile && parameterProfile.id) || null,
    isArchived: parameterProfile && parameterProfile.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  
  return (
    <Options 
      {...props}
      {...reduxProps}
      updateEntities = {() => dispatch(updateParameterProfileParametersCollection(reduxProps.parameterProfileId))}
      editEntity = {() => dispatch(showModal('PARAMETER_PROFILE_MODAL', reduxProps.parameterProfileId))}
      archiveEntity = {() => dispatch(processArchiveParameterProfiles([reduxProps.parameterProfileId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveParameterProfiles([reduxProps.parameterProfileId]))}
    />
  );
}
