import { List } from 'immutable';
import pluralize from 'pluralize';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_\- ]{0,255})$/,
};

export default class InsulationFamily extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  },
  insulationInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleDeepCompare: true,
    handleSerializeCollection: true,
    serializeOutputField: 'insulationInstancesAttributes',
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return '--';
  }

  get instancesCount() {
    const count =
      (this.insulationInstances && this.insulationInstances.size) || 0;
    return `${count} ${pluralize('instance', count)}`;
  }

  get summary() {
    return {
      primary: this.name,
      secondary: this.description,
      tertiary: this.instancesCount,
    };
  }
}
