import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedPipeFamilies } from '../../entities/PipeFamilies/selectors';
import PipeFamilySchema from '../../entities/PipeFamilies/schema';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const pipeFilterForm = formValueSelector(`filters.${EK.PIPES.state}`);

export const selectCurrentFilteredDraftPipes = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectEntities(),
  selectNormalizedPipeFamilies(),
  state => pipeFilterForm(state, 'name', 'material'),
  (isFiltering, isShowingArchived, entities, pipes, query) => denormalize(
    isFiltering ? 
      pipes.filter(pipe => (isShowingArchived || !pipe.archivedFlag) && pipe.doesMatchQuery(query)) : 
      pipes.filter(pipe => !pipe.archivedFlag),
    [PipeFamilySchema],
    entities.delete(EK.PIPE_INSTANCES.state)
  )
);