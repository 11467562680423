import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import { hideModal } from '../../../../modules/modal/actions';

import { PipingModuleProjectsPath, PipingModuleProjectsEditPath } from '../../../../paths';

import projectKeys from '../../../../entities/Projects/keys';
import {
  createProjectTypes,
  copyProjectTypes,
  editProjectTypes,
  deleteProjectTypes,
} from '../../../../entities/Projects/constants';
import {
  selectNormalizedProjects
} from '../../../../entities/Projects/selectors';

export function* handleCreateProjectSuccess({ payload = {} }) {
  const id = `${get(payload, `${projectKeys.plural}[0].id`)}`;
  if (id) {
    let projects = yield select(selectNormalizedProjects());
    let copiedProject = projects.get(id);

    while (!copiedProject) {
      yield take();
      projects = yield select(selectNormalizedProjects());
      copiedProject = projects.get(id);
    }

    yield put(push(PipingModuleProjectsEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeleteProjectSuccess() {
  yield put(push(PipingModuleProjectsPath.url));
}

export function* watchCreateProjectSaga() {
  yield takeLatest([
    createProjectTypes.SUCCESS,
    copyProjectTypes.SUCCESS,
  ], handleCreateProjectSuccess);
}

export function* watchDeleteProjectSaga() {
  yield takeLatest(deleteProjectTypes.SUCCESS, handleDeleteProjectSuccess);
}

export function* handleCloseProjectModal() {
  yield put(hideModal());
}

export function* watchCloseProjectModalSaga() {
  yield takeLatest(
    [
      createProjectTypes.SUCCESS,
      editProjectTypes.SUCCESS,
      deleteProjectTypes.SUCCESS,
    ],
    handleCloseProjectModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateProjectSaga),
    fork(watchDeleteProjectSaga),
    fork(watchCloseProjectModalSaga),
  ]);
}
