import React, { useRef } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled, { css } from 'styled-components';
import radii from '../../../../assets/themes/base/radii';
import colors from '../../../../assets/themes/base/colors';
import { useDrag, useDrop } from 'react-dnd';

import EK from '../../../../entities/keys';

import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Text from '../../../../components/common/Text';

import { processDeleteNoteBlockNote, processEditNoteBlockNote } from '../../../../entities/NoteBlockNotes/actions';

import { selectCurrentNoteBlockNote } from '../../selectors';
import Popup from 'reactjs-popup';

const NoteBlockNoteHandle = styled(Flex)`
  cursor: move;
`;

const NoteBlockNoteItem = styled(Flex)`
  border-bottom: 1px solid ${colors.gray[4]};

  ${({ isHovering }) =>
    isHovering &&
    css`
      background-color: ${colors.primary[0]};
    `}

  &:first-child {
    border-top-left-radius: ${radii[1]};
    border-top-right-radius: ${radii[1]};
  }

  &:last-child {
    border-bottom-left-radius: ${radii[1]};
    border-bottom-right-radius: ${radii[1]};
    border-bottom: none;
  }
`;

const NoteBlockNote = ({
  noteBlockId,
  noteBlock,
  noteBlockNote,
  index,
  currentNote,
  editable,
  isLocked,
  onMoveComplete,
  onDelete,
  onOptional,
  onFlag
}) => {
  const ref = useRef();

  const [{ isHovering }, drop] = useDrop({
    accept: `${EK.NOTE_BLOCK_NOTES.state}-${noteBlockId}`,
    collect: (monitor) => ({ isHovering: !isLocked && monitor.isOver() }),
    drop: (_) => ({ id: noteBlockNote.id, index }),
  });

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: `${EK.NOTE_BLOCK_NOTES.state}-${noteBlockId}`,
      id: noteBlockNote.id,
      index,
    },
    collect: (monitor) => ({ isDragging: !isLocked && monitor.isDragging() }),
    end: (item, monitor) => {
      if (!isLocked && monitor.didDrop()) {
        const drop = monitor.getDropResult();
        onMoveComplete(item.id, drop.index);
      }
    },
  });

  drag(drop(ref));

  const contentStyle = { background: '#fff', color: `${colors.gray[7]}`, padding: '10px 10px 10px 10px ', border: 'solid darkgrey 2px', borderRadius: '3px' };
  const overlayStyle = { background: 'rgba(255,255,255,0.75)' };
  const arrowStyle = { color: 'rgb(255,255,255)', stroke: 'darkgrey', strokeWidth: '2px' };

  return (
    (currentNote && (
      <NoteBlockNoteItem
        ref={ref}
        isHovering={!isDragging && isHovering}
        flexDirection="row"
        alignItems="center"
        bg="white"
        p={3}
        width="100%"
        style={{ opacity: isDragging ? 0.5 : 1 }}
      >
        {(!isLocked && editable && (
          <NoteBlockNoteHandle flexDirection="column" pl={2} pr={2} mr={2}>
            <Icon name="menu" />
          </NoteBlockNoteHandle>
        )) ||
          null}
        <Flex flexDirection="column" justifyContent="center" width="8%">
          <Text fontSize={2} color="gray.6" textAlign="center" $ellipsis>
            {(noteBlockNote.prefix && `${noteBlockNote.prefix}.`) || ''}
          </Text>
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          pl={2}
          pr={2}
          ml={2}
          mr={2}
          style={{ flexGrow: 1 }}
        >
          <Text fontSize={1} color="gray.7" mb={1}>
            {currentNote.name}
          </Text>
          <Text fontSize={0} color="gray.6">
            {currentNote.noteBody.length < 25 ? currentNote.noteBody : currentNote.noteBody.substring(0,17) + '...' }
          </Text>
        </Flex>
        {(!isLocked && editable && (
          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <Popup trigger={() => <Button transparent subtle error icon="toggleFlag" onClick={onFlag} value={noteBlockNote.isFlagNote} />} position='bottom center' on={['hover']} {...{contentStyle, overlayStyle, arrowStyle}} >click to make the <br/>note a {noteBlockNote.isFlagNote ? (<strong>non-flag</strong>) : <strong>flag</strong>}</Popup>
            <Popup trigger={() => <Button transparent subtle error icon="toggleSwitch" onClick={onOptional} value={noteBlockNote.isOptional} />} position='bottom center' on={['hover']} {...{contentStyle, overlayStyle, arrowStyle}} >click to make the<br/>note {noteBlockNote.isOptional ? (<strong>non-optional</strong>) : <strong>optional</strong>}</Popup>
            <Button transparent subtle error icon="delete" onClick={onDelete} />
          </Flex>
        )) ||
          null}
      </NoteBlockNoteItem>
    )) ||
    null
  );
};

const mapStateToProps = createStructuredSelector({
  currentNote: selectCurrentNoteBlockNote(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onDelete() {
    dispatch(
      processDeleteNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlockId,
        ownProps.noteBlockNote.id
      )
    );
  },
  onOptional() {
    dispatch(
      processEditNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlockId,
        ownProps.noteBlockNote.id,
        { note_block_note_id: ownProps.noteBlockNote.id, is_optional: ownProps.noteBlockNote.isOptional ? false : true }
      )
    );
  },
  onFlag() {
    dispatch(
      processEditNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlockId,
        ownProps.noteBlockNote.id,
        { note_block_note_id: ownProps.noteBlockNote.id, is_flag_note: ownProps.noteBlockNote.isFlagNote ? false : true }
      )
    );
  }
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(NoteBlockNote);
