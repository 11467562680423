import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import PipeFamilySchema from './schema';

export const selectNormalizedPipeFamilies = () => createSelector(
  selectEntities(),
  entities => entities.get('pipeFamilies')
);
export const selectPipeFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('pipeFamilies'), [PipeFamilySchema], entities.delete('pipeInstances'))
);
export const selectPipeFamiliesList = () => createSelector(
  selectPipeFamilies(),
  pipeFamilies => pipeFamilies.toList()
);

export const selectPipeFamiliesCounts = () => createSelector(
  selectNormalizedPipeFamilies(),
  pipes => pipes.reduce(({ unarchived, total }, pipe) => ({
    unarchived: !pipe.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectNormalizedDraftPipeFamilies = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('pipeFamilies');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectNormalizedUnarchivedDraftPipeFamilies = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('pipeFamilies');
    const records = state.get('records');
    return state.get('ids').reduce((output, id) => !records.getIn([id, 'archivedFlag']) ? output.push(records.get(id)) : output, List());
  }
);