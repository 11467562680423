import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import useFloatingState from '../../../hooks/useFloatingState';
import useIsHovering from '../../../hooks/useIsHovering';

import Icon from '../../common/Icon';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';

import BaseCell from '../BaseCell';

import ArchivedEntityTooltip from '../../tooltip/templates/ArchivedEntityTooltip';
import UnarchivedEntityTooltip from '../../tooltip/templates/UnarchivedEntityTooltip';

const ArchivedStatusCellRenderer = ({ value }) => {
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'left' });
  const isHovering = useIsHovering(reference, { delayEnter: 250 });

  return (
    <BaseCell ref={reference} flexDirection='column' justifyContent='center' alignItems='center'>
      { value && <Icon name='archive' color='error.4' /> || <Icon name='unarchive' color='gray.4' /> }
      {
        isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='large'>
            { value ? <ArchivedEntityTooltip /> : <UnarchivedEntityTooltip /> }
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseCell>
  );
};

export default ArchivedStatusCellRenderer;