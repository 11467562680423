import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Notice from '../../containers/common/Notice';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectNormalizedUnarchivedDraftFittingFamilies } from '../../entities/FittingFamilies/selectors';

const mapStateToProps = createStructuredSelector({
  isFetching: selectIsFetching(),
  data: selectNormalizedUnarchivedDraftFittingFamilies(),
});

const enhance = compose(
  connect(mapStateToProps)
);

const SpecsFittingsNotices = ({ isFetching, data }) => isFetching && data && data.size > 0 && <Notice id='specsFittings' message='Loading data in the background...' /> || null;

export default enhance(SpecsFittingsNotices);
