import React from 'react';

import Text from '../../common/Text';

import BaseCell from '../BaseCell';

const style = {
  minHeight: 38,
};

const BasicGroupHeader = ({
  displayName,
  column: { actualWidth } = {},
}) => {
  return (
    <BaseCell
      flexDirection='column'
      justifyContent='center'
      width={actualWidth || undefined}
      style={style}
    >
      <Text $ellipsis color='gray.7' fontSize={1} fontWeight={600} pt={1} pb={1}>{ displayName }</Text>
    </BaseCell>
  );
};

export default BasicGroupHeader;
