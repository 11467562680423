import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import InsulationSpecSchema from './schema';

export const selectNormalizedInsulationSpecs = () => createSelector(
  selectEntities(),
  entities => entities.get('insulationSpecs')
);
export const selectInsulationSpecs = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('insulationSpecs'), [InsulationSpecSchema], entities)
);
export const selectInsulationSpecsOptions = () => createSelector(
  selectInsulationSpecs(),
  insulationSpecs => insulationSpecs.map(insulationSpec => insulationSpec.toOption()).toList()
);

export const selectNormalizedDraftInsulationSpecs = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('insulationSpecs');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);
export const selectDraftInsulationSpecs = () => createSelector(
  selectEntities(),
  selectNormalizedDraftInsulationSpecs(),
  (entities, drafts) => denormalize(drafts, [InsulationSpecSchema], entities)
);
