import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectDrafts from '../../modules/drafts/selectors';

import { selectNormalizedDraftSizes } from '../../entities/Sizes/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

export const selectCurrentDraftSizesPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.SIZES.state, 'saveable']).size > 0
);

export const selectCurrentDraftSizeIds = () => createSelector(
  selectNormalizedDraftSizes(),
  drafts => drafts.reduce((ids, draft) => ids.add(draft.id), Set())
);

export const selectCurrentEditedDraftSizesCount = () => createSelector(
  selectNormalizedDraftSizes(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);

const sizeFilterForm = formValueSelector(`filters.${EK.SIZES.state}`);

export const selectCurrentFilteredNormalizedDraftSizes = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftSizes(),
  state => sizeFilterForm(state, 'name', 'code', 'description'),
  (isFiltering, sizes, query) => isFiltering ? sizes.filter(size => size.doesMatchQuery(query)) : sizes
);