import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import space from '../../../assets/themes/base/space';

const Status = styled.div`
height: ${space[3]};
width: ${space[3]};
background-color: ${colors.gray[4]};

${
  ({ primary }) => primary && css`
    background-color: ${colors.primary[4]};
  `
}

${
  ({ error }) => error && css`
    background-color: ${colors.error[4]};
  `
}

${
  ({ success }) => success && css`
    background-color: ${colors.success[4]};
  `
}

border-radius: 100%;
`;

export default Status;