import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';

import { ALLOWANCES } from '../../../../entities/BoltNutFamilies/model';
import { validations } from '../../../../entities/BoltNutFamilies/formValidation';

const BoltNutForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          validate={validations['name']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="boltNutCode"
          label="Bolt Nut Code"
          required
          allow={ALLOWANCES.BOLT_NUT_CODE}
          validate={validations['boltNutCode']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="description"
          label="Description"
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: EK.BOLT_NUTS.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(BoltNutForm);
