import React from 'react';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import borders from '../../../assets/themes/base/borders';
import space from '../../../assets/themes/base/space';
import fontSizes from '../../../assets/themes/base/fontSizes';

import theme from '../../../assets/themes/theme';

import Flex from '../../../components/common/Flex';
import Icon from '../../../components/common/Icon';

import { hideModal, afterHideModal } from '../../../modules/modal/actions';
//import selectModal from '../../../modules/modal/selectors';

import CurrentUserModal from '../templates/CurrentUserModal';

import BendModal from '../templates/BendModal';
import BendMachineModal from '../templates/BendMachineModal';
import BendSpringbackModal from '../templates/BendSpringbackModal';
import BoltNutModal from '../templates/BoltNutModal';
import EndTypeCompatibilityModal from '../templates/EndTypeCompatibilityModal';
import FittingModal from '../templates/FittingModal';
import InsulationModal from '../templates/InsulationModal';
import MasterCatalogUnitSystemModal from '../templates/MasterCatalogUnitSystemModal';
import MiterModal from '../templates/MiterModal';
import PipeModal from '../templates/PipeModal';
import SpecModal from '../templates/SpecModal';
import ProjectModal from '../templates/ProjectModal';

import OrganizationModal from '../templates/OrganizationModal';
import OrganizationSettingsModal from '../templates/OrganizationSettingsModal';
import OrganizationUserModal from '../templates/OrganizationUserModal';
import OrganizationUserInvitesModal from '../templates/OrganizationUserInvitesModal';
import CreateOrganizationUserModal from '../templates/CreateOrganizationUserModal';

import NoteProfileModal from '../templates/NoteProfileModal';
import NoteBlockModal from '../templates/NoteBlockModal';
import ParameterProfileModal from '../templates/ParameterProfileModal';
import SimplifiedRepModal from '../templates/SimplifiedRepModal';
import SimplifiedRepRulesetModal from '../templates/SimplifiedRepRulesetModal';

import ModelTemplateModal from '../templates/ModelTemplateModal';

import LegalTermsUpdatedModal from '../templates/LegalTermsUpdatedModal';
import PrivacyPolicyModal from '../templates/PrivacyPolicyModal';

import ConfigProfileEditorModal from '../templates/ConfigProfileEditorModal';
import ConfigProfileMapkeysModal from '../templates/ConfigProfileMapkeysModal';

const modalContentClass = 'cadactive-modal-content';
const modalOverlayClass = 'cadactive-modal-overlay';

const ModalStyle = createGlobalStyle`
  .${modalOverlayClass} {
    background-color: rgba(73,80,87, 0.9);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 500;

    opacity: 0;
    transition: opacity 100ms ease-in-out;

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      transition-delay: 100ms;
      opacity: 0;
    }
  }

  .${modalContentClass} {
    position: absolute;
    left: 5rem;
    right: 5rem;
    top: 5rem;
    bottom: 5rem;
    padding: 0;
    border: none;
    background: none;

    /* TODO: come clean this up later */
    max-height: 100%;
    overflow-y: auto;

    &:focus,
    &:active {
      outline: none;
    }

    opacity: 0;
    transform: translate3d(0, -2rem, 0);
    transition: opacity 100ms ease-in-out, transform 100ms ease-in-out;

    &.ReactModal__Content--after-open {
      transition-delay: 100ms;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }

    &.ReactModal__Content--before-close {
      opacity: 0;
      transform: translate3d(0, 2rem, 0);
    }

    & > * {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    & > *:nth-last-child(n+2) {
      display: none;
    }
  }
`;

const ModalNavigationContainer = styled(Flex)`
  position: fixed;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 2rem;
  background: ${colors.gray[0]};
  border: ${borders[1]};
  border-radius: ${radii[2]};
  padding: ${space[3]} ${space[4]};
  cursor: pointer;
  z-index: 9000;
`;

const CloseIcon = styled(Icon)`
  position: fixed;
  top: 2rem;
  right: 2rem;
  font-size: ${fontSizes[7]};
  cursor: pointer;
  color: ${colors.gray[5]};
`;

const MODAL_COMPONENTS = {
  CURRENT_USER_MODAL: CurrentUserModal,

  BEND_MODAL: BendModal,
  BEND_MACHINE_MODAL: BendMachineModal,
  BEND_SPRINGBACK_MODAL: BendSpringbackModal,
  BOLT_NUT_MODAL: BoltNutModal,
  END_TYPE_COMPATIBILITY_MODAL: EndTypeCompatibilityModal,
  FITTING_MODAL: FittingModal,
  INSULATION_MODAL: InsulationModal,
  MASTER_CATALOG_UNIT_SYSTEM_MODAL: MasterCatalogUnitSystemModal,
  MITER_MODAL: MiterModal,
  PIPE_MODAL: PipeModal,
  PROJECT_MODAL: ProjectModal,
  SPEC_MODAL: SpecModal,

  ORGANIZATION_MODAL: OrganizationModal,
  ORGANIZATION_USER_MODAL: OrganizationUserModal,
  ORGANIZATION_USER_INVITES_MODAL: OrganizationUserInvitesModal,
  CREATE_ORGANIZATION_USER_MODAL: CreateOrganizationUserModal,
  ORGANIZATION_SETTINGS_MODAL: OrganizationSettingsModal,

  NOTE_PROFILE_MODAL: NoteProfileModal,
  NOTE_BLOCK_MODAL: NoteBlockModal,
  PARAMETER_PROFILE_MODAL: ParameterProfileModal,
  SIMPLIFIED_REP_MODAL: SimplifiedRepModal,
  SIMPLIFIED_REP_RULESET_MODAL: SimplifiedRepRulesetModal,
  MODEL_TEMPLATE_MODAL: ModelTemplateModal,

  LEGAL_TERMS_UPDATED_MODAL: LegalTermsUpdatedModal,
  PRIVACY_POLICY_MODAL: PrivacyPolicyModal,

  CONFIG_PROFILE_EDITOR_MODAL: ConfigProfileEditorModal,
  CONFIG_PROFILE_MAPKEYS_MODAL: ConfigProfileMapkeysModal,
};

export const MODAL_SIZES = {
  XSMALL: '20rem',
  SMALL: '30rem',
  NORMAL: '40rem',
  LARGE: '50rem',
  XLARGE: '60rem',
  FLUID: '100%',
};

class ModalContent extends React.Component {
  componentWillUnmount = () => {
    this.props.afterCloseModal();
  };

  render() {
    const {
      modalComponent: SpecificModal,
      afterCloseModal,
      ...props
    } = this.props;

    return (SpecificModal && <SpecificModal {...props} />) || null;
  }
}

// const mapStateToProps = createSelector(
//   (state) => state.modal
// );

// used for screenreaders, adding this here to get rid of the error from the library
ReactModal.setAppElement('#app');

export default function ModalRoot({ shouldCloseOnOverlayClick }) {

  const modals = useSelector((state) => state.get('modal'));
  const dispatch = useDispatch();

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <ModalStyle />
        <ReactModal
          isOpen={modals.size > 0}
          onRequestClose={() => dispatch(hideModal())}
          className={modalContentClass}
          overlayClassName={modalOverlayClass}
          closeTimeoutMS={200}
          shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        >
          {modals.map((modal) => (
            <ModalContent
              key={modal.modalType}
              modalComponent={
                (!!modal.modalType && MODAL_COMPONENTS[modal.modalType]) || null
              }
              {...modal.modalProps}
              closeModal={() => dispatch(hideModal())}
              afterCloseModal={() => dispatch(afterHideModal())}
            />
          ))}
        </ReactModal>
        {
          modals.size > 1 && (
            <ModalNavigationContainer onClick={() => dispatch(hideModal())} left='2rem'>
              <Icon name='chevron-left' color='gray.7' fontSize={6} />
            </ModalNavigationContainer>
          )
        }

        {
          modals.size > 0 && (
            <ModalNavigationContainer onClick={() => dispatch(hideModal(true))} right='2rem'>
              <Icon name='close' color='gray.7' fontSize={6} />
            </ModalNavigationContainer>
          )
        }
      </React.Fragment>
    </ThemeProvider>
  );
}

// export default ModalRoot;
