import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedSimplifiedReps = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get('simplifiedReps')
  );
export const selectNormalizedSimplifiedRepsList = () =>
  createSelector(
    selectNormalizedSimplifiedReps(),
    (simplifiedReps) => simplifiedReps.toList()
  );
