import { toast } from 'react-toastify';

/**
 * Creates a Redux middleware that looks for the `notification` property in an
 * action's `meta` property, and then displays and appropriate toast notification
 *
 * @returns {Function} A Redux middleware.
 */
export default function toastifyNotificationMiddleware() {
  return (store) =>
    (next) =>
      (action) => {
        const { notification } = (action || {}).meta || {};

        if (notification && notification.message) {
          const {
            message,
            type = toast.TYPE.DEFAULT,
            position = toast.POSITION.BOTTOM_CENTER,
            ...rest
          } = notification;

          toast(message, {
            type,
            position,
            ...rest,
          });
        }

        next(action);
      };
}
