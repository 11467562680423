import React from 'react';

import Flex from '../../common/Flex';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

import BaseAppend from '../../form/BaseAppend';

const OptionalUnitCellRenderer = ({ 
  value = {}, 
  placeholder,
}) => {
  const isValueEmpty = value.value === null || value.value === '' || value.value === undefined;
  
  return (
    <BaseCell flexDirection='row' alignContent='center'>
      <Flex flexDirection='column' justifyContent='center' minWidth={0} width='100%'>
        <BaseCellDataItem primary={!isValueEmpty} disabled={isValueEmpty}>
          { isValueEmpty && placeholder || value.value }
        </BaseCellDataItem>
      </Flex>
      <BaseAppend>
        { value.unit && value.unit.abbreviation || value.unit }
      </BaseAppend>
    </BaseCell>
  );
};

export default OptionalUnitCellRenderer;
