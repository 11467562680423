import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import BendSpringbackInstanceSchema from './schema';

export const selectNormalizedBendSpringbackInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('bendSpringbackInstances')
);
export const selectNormalizedDraftBendSpringbackInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('bendSpringbackInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectBendSpringbackInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('bendSpringbackInstances'), [BendSpringbackInstanceSchema], entities.delete('bendSpringbackFamilies'))
);
export const selectDraftBendSpringbackInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftBendSpringbackInstances(),
  (entities, drafts) => denormalize(drafts, [BendSpringbackInstanceSchema], entities.delete('bendSpringbackFamilies'))
);