import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

export const selectNormalizedSizes = () => createSelector(
  selectEntities(),
  entities => entities.get('sizes')
);
export const selectNormalizedDraftSizes = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('sizes');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectAllSizesOptions = () => createSelector(
  selectNormalizedSizes(),
  sizes => sizes.map(size => size.toOption()).toList()
);