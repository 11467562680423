import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { selectCurrentPipeUnitSystem } from '../PipesEditSwitch/selectors';

import {
  createBendSpringbackInstanceDrafts,
  editBendSpringbackInstanceDrafts,
} from './actions';

import {
  selectCurrentBendSpringback,
  selectCurrentFilteredDraftBendSpringbackInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBendSpringback(),
  selectCurrentFilteredDraftBendSpringbackInstances(),
  selectCurrentPipeUnitSystem(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    bendSpringback,
    data,
    defaultUnitSystem,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && bendSpringback &&
        !bendSpringback.archivedFlag,
      bendSpringbackId: bendSpringback && bendSpringback.id || null,
      defaultUnitSystem,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.BEND_SPRINGBACK_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editBendSpringbackInstanceDrafts,
      createInstances: createBendSpringbackInstanceDrafts,
      copyInstances: createBendSpringbackInstanceDrafts,
    },
    (
      { data, defaultUnitSystem, editable, bendSpringbackId },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      defaultUnitSystem,
      columnDefs: [
        ...(editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ] || []),
        {
          headerName: 'Size',
          field: 'sizeProperty',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Size' },
            ek: EK.SIZES,
          },
          cellEditorParams: { ek: EK.SIZES },
        },
        {
          headerName: 'Angle',
          field: 'angle',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Angle',
          },
        },
        {
          headerName: 'Radius',
          field: 'radius',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Spring Back Length',
          field: 'springBackLength',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Elongation Length',
          field: 'elongationLength',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
      ],
      ...(editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              bendSpringbackFamily: bendSpringbackId,
              bendSpringbackFamilyId: bendSpringbackId,
            },
          });
        },
      } || {}),
    }),
  ),
);

export default enhance(Grid);
