import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../../../modules/drafts/actions';

import {
  INITIAL_END_TYPE_COMPATIBILITY_LOAD,
  CREATE_END_TYPE_COMPATIBILITY_DRAFTS,
  EDIT_END_TYPE_COMPATIBILITY_DRAFTS,
} from './constants';

export const initialEndTypeCompatibilityLoad = createAction(INITIAL_END_TYPE_COMPATIBILITY_LOAD, endTypeId => ({ endTypeId }));

export const createEndTypeCompatibilityDrafts = generateCreateDraftsAction(CREATE_END_TYPE_COMPATIBILITY_DRAFTS);

export const editEndTypeCompatibilityDrafts = generateEditDraftsAction(EDIT_END_TYPE_COMPATIBILITY_DRAFTS);
