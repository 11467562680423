import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import BoltNutInstanceSchema from './schema';

export const selectNormalizedBoltNutInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('boltNutInstances')
);
export const selectNormalizedDraftBoltNutInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('boltNutInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectBoltNutInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('boltNutInstances'), [BoltNutInstanceSchema], entities.delete('boltNutFamilies'))
);
export const selectDraftBoltNutInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftBoltNutInstances(),
  (entities, drafts) => denormalize(drafts, [BoltNutInstanceSchema], entities.delete('boltNutFamilies'))
);
