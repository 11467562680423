import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import { PipingModulePipesMitersEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchiveMiterFamiliesForPipeFamily,
  processFetchAllMiterFamiliesForPipeFamily,
  processUnarchiveMiterFamiliesForPipeFamily,
} from '../../entities/MiterFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import { selectCurrentFilteredPipesMiters } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredPipesMiters(),
  selectCurrentPipeId(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    pipeId,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      pipeId,
    };
  },
);

function PipesMitersGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name // Instances',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModulePipesMitersEditPath.generate({
            id: reduxProps.pipeId,
            familyId: data.id,
          })) ||
          undefined,
      }),
    },
    reduxProps.editable &&
      {
        type: ['dataActionIconCell'],
        cellRendererParams: ({ value }) => ({
          actions: (value &&
            List([
              {
                icon: 'list',
                onClick: (miter) => dispatch(push(PipingModulePipesMitersEditPath.generate({id: props.match.params.id,familyId: miter.id,}),)),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (miter) => dispatch(showModal('MITER_MODAL', {pipeFamilyId: props.match.params.id,miterId: miter.id,copyEntity: true,})),
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: (miter) => dispatch(processUnarchiveMiterFamiliesForPipeFamily(props.match.params.id, [miter.id,])),
              },
            ])) ||
            List([
              {
                icon: 'edit',
                primary: true,
                onClick: (miter) => dispatch(showModal('MITER_MODAL', {pipeFamilyId: props.match.params.id,miterId: miter.id,})),
              },
              {
                icon: 'list',
                onClick: (miter) => dispatch(push(PipingModulePipesMitersEditPath.generate({id: props.match.params.id,familyId: miter.id,}),)),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (miter) => dispatch(showModal('MITER_MODAL', {pipeFamilyId: props.match.params.id,miterId: miter.id,copyEntity: true,})),
              },
              {
                icon: 'archive',
                error: true,
                onClick: (miter) => dispatch(processArchiveMiterFamiliesForPipeFamily(props.match.params.id, [miter.id,])),
              },
            ]),
        }),
      }
  ];

  useEffect( () => {
    dispatch(processFetchAllMiterFamiliesForPipeFamily(props.match.params.id));
  }, []);

  return (
    <Grid
      {...props}

      isList = {reduxProps.isList}
      data = {reduxProps.data}
      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(PipesMitersGrid);
