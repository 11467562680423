import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/InsulationInstances/model';

import { INSULATION_TYPE_OPTIONS } from '../../entities/InsulationInstances/model';

import { MATERIAL_FILTERS } from '../../entities/Materials/model';

import Grid from '../../containers/grid/Grid';

import {
  selectPipingModulePermissionsAndState,
  selectShowHiddenGridColumns,
} from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import {
  createInsulationInstanceDrafts,
  editInsulationInstanceDrafts,
} from './actions';

import {
  selectCurrentFilteredDraftInsulationInstances,
  selectCurrentInsulation,
  selectCurrentInsulationUnitSystem,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentInsulation(),
  selectCurrentFilteredDraftInsulationInstances(),
  selectCurrentInsulationUnitSystem(),
  selectShowHiddenGridColumns(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    insulation,
    data,
    defaultUnitSystem,
    showHiddenGridColumns,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate &&
        hasValidLicense &&
        insulation &&
        !insulation.archivedFlag,
      insulationId: (insulation && insulation.id) || null,
      defaultUnitSystem,
      showHiddenGridColumns,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.INSULATION_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editInsulationInstanceDrafts,
      createInstances: createInsulationInstanceDrafts,
      copyInstances: createInsulationInstanceDrafts,
    },
    (
      {
        data,
        defaultUnitSystem,
        editable,
        insulationId,
        showHiddenGridColumns,
      },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      defaultUnitSystem,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Size',
          field: 'sizeProperty',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Size' },
            ek: EK.SIZES,
          },
          cellEditorParams: { ek: EK.SIZES },
        },
        {
          headerName: 'Insulation Type',
          field: 'insulationType',
          width: 200,
          type: ['basicCell', 'radioEditor'],
          editable,
          cellRendererParams: {
            placeholder: 'Select a type',
            isCapitalized: true,
          },
          cellEditorParams: {
            isCapitalized: true,
            options: INSULATION_TYPE_OPTIONS,
          },
        },
        {
          headerName: 'Fitting Category',
          field: 'fittingCategory',
          editable: ({ data }) =>
            editable &&
            (data.insulationType === 'fitting' ||
              (data.insulationType !== 'fitting' && !!data.fittingCategory)),
          suppressNavigable: ({ data }) => data.insulationType !== 'fitting',
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Fitting Category' },
            ek: EK.FITTING_CATEGORIES,
          },
          cellEditorParams: {
            ek: EK.FITTING_CATEGORIES,
            clearable: true,
          },
        },
        {
          headerName: 'Material',
          field: 'material',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Material' },
            ek: EK.MATERIALS,
          },
          cellEditorParams: {
            ek: EK.MATERIALS,
            eFilter: MATERIAL_FILTERS.INSULATION,
          },
        },
        {
          headerName: 'Thickness',
          field: 'matlThickness',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Stock No',
          field: 'stockno',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Stockno',
          },
          cellEditorParams: {
            allow: ALLOWANCES.STOCKNO,
          },
        },
      ],
      ...((editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              insulationFamily: insulationId,
              insulationFamilyId: insulationId,
            },
          });
        },
      }) ||
        {}),
    }),
  ),
);

export default enhance(Grid);
