import React, { useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ChromePicker } from 'react-color';

import useFloatingState from '../../../hooks/useFloatingState';
import useMergeRefs from '../../../hooks/useMergeRefs';
import useStateWithCallback from '../../../hooks/useStateWithCallback';

import Button from '../../common/Button';
import Flex from '../../common/Flex';
import ColorSwatch from '../../common/ColorSwatch';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';


const defaultWhite = { r: 255, g: 255, b: 255 };
const ColorDropdownEditor = React.forwardRef(({
  value,
  stopEditing,
  defaultValue = null
}, ref) => {
  const [isReady, setIsReady] = useState(false);

  /*TODO: Here and MultiLineTextEditor have issues with being in the grid and being
  called to render by the wrong thing at the wrong time. Only occasionally works.
  When properly working, the color picker should appear above/below the reference, and
  the close button to the right.*/
  const [reference, floating, floatingStyle] = useFloatingState( { placement: 'bottom' });
  const [closeReference, closeFloating, closeFloatingStyle] = useFloatingState({ placement: 'right' });
  const parentRef = useMergeRefs(reference, closeReference);

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    getValue: () => currentValue === '' || currentValue === undefined ? defaultValue : currentValue,
  }));

  const setCurrentValueCallback = useCallback(
    newValue => {
      // only accept values here once the dropdown is ready
      if (isReady) {
        if (newValue === null || newValue === undefined) {
          stopEditing();
        }
      }
    },
    [stopEditing, isReady]
  );

  const [currentValue, setCurrentValue] = useStateWithCallback(value || defaultWhite, setCurrentValueCallback);

  const onChange = useCallback(
    ({ rgb = {} } = {}) => {
      const { a, ...value } = rgb;
      setCurrentValue(value);
    }, []
  );

  const clearColor = useCallback(_ => setCurrentValue(null), [setCurrentValue]);

  // prevent initial render from ruining life
  useEffect(() => {
    if (!isReady) { setIsReady(true); }
  }, []);

  return (
    <Flex
      ref={isReady ? parentRef : undefined}
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='100%'
      cursor='pointer'
      pl={3}
      pr={3}
    >
      { currentValue && <ColorSwatch color={currentValue} height='100%' width='100%' /> }
      {
        currentValue && isReady && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='fluid'>
            <ChromePicker color={currentValue} onChangeComplete={onChange} disableAlpha />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
      {
        currentValue && isReady && ReactDOM.createPortal(
          <Tooltip ref={closeFloating} style={closeFloatingStyle} size='fluid'>
            <Button icon='close' small error onClick={clearColor} />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </Flex>
  );
});

export default ColorDropdownEditor;
