import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import { hideModal } from '../../../../modules/modal/actions';

import {
  PipingModuleSpecsPath,
  PipingModuleSpecsEditPath,
} from '../../../../paths';

import EK from '../../../../entities/keys';

import {
  createSpecTypes,
  copySpecTypes,
  editSpecTypes,
  deleteSpecTypes,
} from '../../../../entities/Specs/constants';
import {
  selectNormalizedSpecs
} from '../../../../entities/Specs/selectors';

export function* handleCreateSpecSuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.SPECS.plural}[0].id`)}`;
  if (id) {
    let specs = yield select(selectNormalizedSpecs());
    let copiedSpec = specs.get(id);

    while (!copiedSpec) {
      yield take();
      specs = yield select(selectNormalizedSpecs());
      copiedSpec = specs.get(id);
    }

    yield put(push(PipingModuleSpecsEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeleteSpecSuccess() {
  yield put(push(PipingModuleSpecsPath.url));
}

export function* watchCreateSpecSaga() {
  yield takeLatest([
    createSpecTypes.SUCCESS,
    copySpecTypes.SUCCESS,
  ], handleCreateSpecSuccess
  );
}

export function* watchDeleteSpecSaga() {
  yield takeLatest(
    deleteSpecTypes.SUCCESS,
    handleDeleteSpecSuccess
  );
}

export function* handleCloseSpecModal() {
  yield put(hideModal());
}

export function* watchCloseSpecModalSaga() {
  yield takeLatest(
    [
      createSpecTypes.SUCCESS,
      editSpecTypes.SUCCESS,
      deleteSpecTypes.SUCCESS,
    ],
    handleCloseSpecModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateSpecSaga),
    fork(watchDeleteSpecSaga),
    fork(watchCloseSpecModalSaga),
  ]);
}
