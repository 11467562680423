import {
  take,
  takeLatest,
  takeEvery,
  all,
  call,
  put,
  fork,
  cancelled,
} from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import LayerModel from '../../entities/Layers/model';
import LayerSchema from '../../entities/Layers/schema';
import {
  selectNormalizedLayers,
  selectDraftLayers,
} from '../../entities/Layers/selectors';

import { selectCurrentDraftLayerIds } from './selectors';

import { processSaveLayers } from '../../entities/Layers/actions';
import { saveLayersTypes } from '../../entities/Layers/constants';

import {
  CREATE_LAYER_DRAFTS,
  EDIT_LAYER_DRAFTS,
  UPDATE_LAYERS_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.LAYERS,
  schema: LayerSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedLayers,
});

const moveSaga = generateMoveSaga({
  keys: EK.LAYERS,
  model: LayerModel,
  originalNormalizedDataSelector: selectNormalizedLayers,
  currentIdsSetSelector: selectCurrentDraftLayerIds,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.LAYERS,
  model: LayerModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftLayerIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.LAYERS,
  model: LayerModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectDraftLayers,
  currentIdsSetSelector: selectCurrentDraftLayerIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectDraftLayers,
  processUpdateAction: processSaveLayers,
  processUpdateTypes: saveLayersTypes,
  moveSaga,
});

export function* createDraftLayerssSaga() {
  yield takeEvery(CREATE_LAYER_DRAFTS, createSaga);
}

export function* editDraftLayerssSaga() {
  yield takeEvery(EDIT_LAYER_DRAFTS, editSaga);
}

export function* saveDraftLayerssSaga() {
  yield takeLatest(UPDATE_LAYERS_COLLECTION, updateSaga);
}

export function* watchLayerssDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftLayerssSaga),
      fork(editDraftLayerssSaga),
      fork(saveDraftLayerssSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.LAYERS.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchLayerssDraftsSaga);
}
