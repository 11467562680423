import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

import useFloatingState from '../../../hooks/useFloatingState';
import useIsHovering from '../../../hooks/useIsHovering';

import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';
import TooltipBody from '../../tooltip/TooltipBody';
import TooltipSection from '../../tooltip/TooltipSection';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

import BaseAppend from '../../form/BaseAppend';

const TextareaDisplay = styled(Box)`
  white-space: pre-line;
`;

const MultiLineCellRenderer = ({
  value = null,
  valueFormatted = null,
  placeholder,
  column,
}) => {
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom' });
  const isHovering = useIsHovering(reference, { delayEnter: 500 });

  const isValueEmpty = (value === null || value === '') && (valueFormatted === null || valueFormatted === '');

  const display = isValueEmpty ? [] : ((valueFormatted && valueFormatted.split('\n')) || (value && value.split('\n')));

  return (
    <BaseCell
      ref={reference}
      flexDirection='row'
      alignContent='center'
    >
      <Flex flexDirection='column' justifyContent='center' minWidth={0} width='100%'>
        <BaseCellDataItem primary={!isValueEmpty} disabled={isValueEmpty}>
          { isValueEmpty && placeholder || display[0] }
        </BaseCellDataItem>
      </Flex>
      {
        display.length > 1 && (
          <BaseAppend>
            <Icon name='more-vert' />
          </BaseAppend>
        )
      }
      {
        isHovering && display.length > 1 && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} width={column.actualWidth}>
            <TooltipBody>
              <TooltipSection>
                <TextareaDisplay>{ valueFormatted || value }</TextareaDisplay>
              </TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseCell>
  );
};

export default MultiLineCellRenderer;
