import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import BoltNutForm from '../../../form/templates/BoltNutForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import BoltNutModel from '../../../../entities/BoltNutFamilies/model';
import {
  processCreateBoltNutFamily,
  processCopyBoltNutFamily,
  processEditBoltNutFamily,
  processDeleteBoltNutFamily,
} from '../../../../entities/BoltNutFamilies/actions';

import { selectCurrentBoltNutFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyBoltNut = new BoltNutModel();

const BoltNutModal = ({ boltNut, isPerforming, copyEntity, onCreate, onCopy, onUpdate, onDelete }) => {
  const onSave = useCallback(data => {
    if (boltNut) {
      if (copyEntity) {
        onCopy({ ...data, id: boltNut.id });
      } else {
        onUpdate(boltNut.id, data);
      }
    } else {
      onCreate(data);
    }
  }, [boltNut, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(boltNut.id), [boltNut, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={boltNut && boltNut.name} copy={copyEntity} create='Bolt Nut' />

      <BoltNutForm
        initialValues={boltNut || emptyBoltNut}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        boltNut && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={boltNut.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  boltNut: selectCurrentBoltNutFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.BOLT_NUTS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreateBoltNutFamily,
      onCopy: processCopyBoltNutFamily,
      onUpdate: processEditBoltNutFamily,
      onDelete: processDeleteBoltNutFamily,
    }
  ),
);

export default enhance(BoltNutModal);
