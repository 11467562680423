import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { List } from 'immutable';
import Downshift from 'downshift';

import Flex from '../../common/Flex';
import FixedItemSizeList from '../../common/FixedItemSizeList';

import useFloatingState from '../../../hooks/useFloatingState';

import Tooltip, { LAYER_PRIORITY, CONTAINERS } from '../../tooltip/Tooltip';

import BaseOption from '../BaseOption';
import BaseOptions from '../BaseOptions';
import BaseMultiOptionValue from '../BaseMultiOptionValue';
import BaseOptionsFilterCount from '../BaseOptionsFilterCount';

import CurrentEditableMultiOptionValue from './CurrentEditableMultiOptionValue';


const findSelectedItems = (value, options) => value && value.map(v => options.find(option => option.value === v)) || [];
const itemToString = option => (!!option && (option.primary || option.value)) || '';
const filterItems = (inputValue, items) => (
  (inputValue === '' || inputValue === null || inputValue === undefined) ?
    items :
    items.filter(item => (
      (item.primary && item.primary.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) ||
      (item.secondary && item.secondary.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    ))
);
const findIndex = (inputValue, items) => {
  return (inputValue === '' || inputValue === null || inputValue === undefined) ?
    0 :
    items.findIndex(item => (
      (item.primary && item.primary.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) ||
      (item.secondary && item.secondary.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    ));
};

const BaseSelect = ({ forwardRef, isLoading, name, placeholder, value = List(), options : unfilteredOptions, defaultIsOpen, preventFiltering, onOuterClick : onOuterClickHandler, onChange : onChangeHandler, currentItemHeight, itemHeight = 56, width, renderValue, renderOption, isCapitalized }) => {
  const currentSelectedItems = useMemo(() => findSelectedItems(value, unfilteredOptions), [value, unfilteredOptions]);

  const [selectState, setSelectState] = useState({ isOpen: !!defaultIsOpen, inputValue: '', highlightedIndex: 0, previousSelectedItem: null });

  const options = useMemo(() => preventFiltering ? unfilteredOptions : filterItems(selectState.inputValue, unfilteredOptions), [selectState.inputValue, unfilteredOptions, preventFiltering]);

  const onChange = useCallback(
    item => {
      if (onChangeHandler && item && item.value) {
        if (value.includes(item.value)) {
          onChangeHandler(value.filter(v => v !== item.value));
        } else {
          onChangeHandler(value.push(item.value));
        }
      }
    },
    [value, onChangeHandler],
  );

  const onOuterClick = useCallback(
    _ => {
      setSelectState(prev => ({ ...prev, isOpen: false }));
      onOuterClickHandler && onOuterClickHandler();
    },
    [onOuterClickHandler, setSelectState],
  );

  const onStateChange = useCallback(
    ({ type, inputValue, selectedItem, highlightedIndex }) => {
      if (
        selectedItem ||
        type === Downshift.stateChangeTypes.clickItem ||
        type === Downshift.stateChangeTypes.keyDownEnter ||
        type === Downshift.stateChangeTypes.unknown
      ) {
        setSelectState(prev => {
          if (!selectedItem) {
            // we need to trigger this manually because if we press enter on the same item twice,
            // doownshift ignores the previous selection
            onChange(prev.previousSelectedItem);
          }

          return {
            ...prev,
            isOpen: true,
            inputValue: '',
            highlightedIndex: prev.inputValue === '' && prev.highlightedIndex !== undefined ? prev.highlightedIndex : 0,
            previousSelectedItem: selectedItem || prev.previousSelectedItem,
          };
        });
        return;
      }

      // when the input is just being typed in so the value is changing as normal
      if (preventFiltering) {
        // there is no filtering, so the higlighted index must change as the user types to keep
        // in line with the user's selection. this ALSO means that arrow keys still need to work
        // thus, we must take complete control of the highlightedIndex, which we've previously
        // allowed more control to Downshift to handle

        // so, the logic here is IF the arrow keys are used and the incoming highlightedIndex
        // isn't undefined, then we set the incoming highglightedIndex because the user is
        // navigating with arrow keys
        // OTHERWISE, we define the highlightedIndex based on whatever the user has entered
        // into the text field
        setSelectState(prev => ({
          ...prev,
          isOpen: type !== Downshift.stateChangeTypes.blurInput,
          highlightedIndex: (
            (
              (
                type === Downshift.stateChangeTypes.keyDownArrowUp ||
                type === Downshift.stateChangeTypes.keyDownArrowDown
              ) &&
              highlightedIndex !== undefined
            ) ?
              highlightedIndex :
              findIndex(
                inputValue === undefined ? prev.inputValue : inputValue,
                options
              )
          ),
          inputValue: inputValue === undefined ? prev.inputValue : inputValue,
        }));
      } else {
        setSelectState(prev => ({
          ...prev,
          isOpen: type !== Downshift.stateChangeTypes.blurInput,
          highlightedIndex,
          inputValue: inputValue === undefined ? prev.inputValue : inputValue,
        }));
      }
    },
    [options, onChange, preventFiltering],
  );

  const listHeight = options.size >= 5 ? (itemHeight * 5) : (itemHeight * options.size);
  const menuWidth = width <= 240 ? 240 : width;

  const [reference, floating, floatingStyle] = useFloatingState({placement:'bottom'});

  return (
    <Flex flexDirection='column' justifyContent='center' height='100%'>
      <Downshift
        isOpen={selectState.isOpen}
        inputValue={selectState.inputValue}
        highlightedIndex={selectState.highlightedIndex || 0}
        defaultIsOpen={defaultIsOpen}
        itemToString={itemToString}
        onChange={onChange}
        onOuterClick={onOuterClick}
        defaultHighlightedIndex={0}
        itemCount={options.size}
        onStateChange={onStateChange}
      >
        {
          ({ isOpen, highlightedIndex, inputValue, selectItem, clearSelection, getRootProps, getInputProps, getMenuProps, getItemProps }) => (
            <Flex flexDirection='column' justifyContent='center' height='100%' { ...getRootProps({ ref: reference }) }>
              <CurrentEditableMultiOptionValue
                ref={forwardRef}
                getInputProps={getInputProps}
                isLoading={isLoading}
                inputValue={inputValue}
                selectedItems={currentSelectedItems}
                name={name}
                placeholder={placeholder}
                currentHighlightedItem={!isOpen && highlightedIndex > -1 && options.get(highlightedIndex) || null}
                renderValue={renderValue}
                isOpen={isOpen}
                setSelectState={setSelectState}
                clearSelection={clearSelection}
                isCapitalized={isCapitalized}
                itemHeight={currentItemHeight || itemHeight}
              />
              {
                !isLoading && isOpen && ReactDOM.createPortal(
                  <Tooltip { ...getMenuProps({ ref: floating, style: floatingStyle, size: 'fluid', priority: LAYER_PRIORITY.MODAL_DROPDOWN }) }>
                    <BaseOptions pt={3} pr={3} pb={3} pl={3} mb={2} style={{ width: menuWidth }}>
                      <BaseMultiOptionValue selectedItems={currentSelectedItems} selectItem={selectItem} isCapitalized={isCapitalized} />
                    </BaseOptions>
                    <BaseOptions style={{ width: menuWidth, height: listHeight + 32 }}>
                      <FixedItemSizeList
                        width={menuWidth}
                        height={listHeight}
                        itemCount={options.size}
                        itemSize={itemHeight}
                        scrollToIndex={highlightedIndex || 0}
                      >
                        {
                          ({ index, style }) => (
                            <BaseOption
                              key={index}
                              item={options.get(index)}
                              index={index}
                              style={style}
                              searchWords={[inputValue]}
                              isHighlighted={index === highlightedIndex}
                              isSelected={value.includes(options.get(index).value)}
                              getItemProps={getItemProps}
                              renderItem={renderOption}
                            />
                          )
                        }
                      </FixedItemSizeList>
                      <BaseOptionsFilterCount width={menuWidth} filteredCount={options.size} totalCount={unfilteredOptions.size} />
                    </BaseOptions>
                  </Tooltip>,
                  document.querySelector(CONTAINERS.DROPDOWN)
                )
              }
            </Flex>
          )
        }
      </Downshift>
    </Flex>
  );
};

const Select = React.forwardRef((props, ref) => <BaseSelect forwardRef={ref} { ...props } />);

export default Select;
