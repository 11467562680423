import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import SpecSchema from './schema';

export const selectNormalizedSpecs = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.SPECS.state)
);
export const selectNormalizedDraftSpecs = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get(EK.SPECS.state);
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);
export const selectNormalizedUnarchivedDraftSpecs = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get(EK.SPECS.state);
    const records = state.get('records');
    return state.get('ids').reduce((output, id) => !records.getIn([id, 'archivedFlag']) ? output.push(records.get(id)) : output, List());
  }
);
export const selectSpecsCounts = () => createSelector(
  selectNormalizedSpecs(),
  specs => specs.reduce(({ unarchived, total }, spec) => ({
    unarchived: !spec.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectSpecs = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.SPECS.state), [SpecSchema], entities.delete(EK.SPEC_PIPE_INSTANCES.state).delete(EK.SPEC_FITTING_INSTANCES.state))
);
export const selectSpecsList = () => createSelector(
  selectSpecs(),
  specs => specs.toList()
);
