import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/PipeInstances/model';

import { PIPE_SHAPES } from '../../entities/PipeFamilies/model';

import Grid from '../../containers/grid/Grid';

import {
  selectPipingModulePermissionsAndState,
  selectShowHiddenGridColumns,
} from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import {
  selectCurrentPipe,
  selectCurrentPipeUnitSystem,
} from '../PipesEditSwitch/selectors';

import { createPipeInstanceDrafts, editPipeInstanceDrafts } from './actions';

import { selectCurrentFilteredDraftPipeInstances } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentPipe(),
  selectCurrentFilteredDraftPipeInstances(),
  selectCurrentPipeUnitSystem(),
  selectShowHiddenGridColumns(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    pipe,
    data,
    defaultUnitSystem,
    showHiddenGridColumns,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && pipe && !pipe.archivedFlag,
      pipeId: (pipe && pipe.id) || null,
      pipeShape: (pipe && pipe.crossSectionShape) || undefined,
      defaultUnitSystem,
      showHiddenGridColumns,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.PIPE_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editPipeInstanceDrafts,
      createInstances: createPipeInstanceDrafts,
      copyInstances: createPipeInstanceDrafts,
    },
    (
      {
        data,
        defaultUnitSystem,
        editable,
        pipeId,
        pipeShape,
        showHiddenGridColumns,
      },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      defaultUnitSystem,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Size',
          field: 'sizeProperty',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Size' },
            ek: EK.SIZES,
          },
          cellEditorParams: { ek: EK.SIZES },
        },
        {
          headerName: 'Schedule',
          field: 'schedule',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Schedule' },
            ek: EK.SCHEDULES,
          },
          cellEditorParams: { ek: EK.SCHEDULES },
        },
        ...(pipeShape === PIPE_SHAPES.CIRCULAR
          ? [
            {
              headerName: 'Diameter',
              field: 'diameter',
              editable,
              type: ['unitCell'],
              unitType: 'unitLength',
            },
          ]
          : []),
        ...(pipeShape === PIPE_SHAPES.RECTANGULAR
          ? [
            {
              headerName: 'Pipe Height',
              field: 'pipeHeight',
              editable,
              type: ['unitCell'],
              unitType: 'unitLength',
            },
            {
              headerName: 'Pipe Width',
              field: 'pipeWidth',
              editable,
              type: ['unitCell'],
              unitType: 'unitLength',
            },
          ]
          : []),
        {
          headerName: 'Thickness',
          field: 'thickness',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'End Types',
          field: 'endTypes',
          editable,
          type: ['listCell', 'multiDropdownEditor'],
          cellRendererParams: {
            isCapitalized: true,
            isDropdown: true,
          },
          cellEditorParams: { ek: EK.END_TYPES },
        },
        {
          headerName: 'Stock No',
          field: 'stockno',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Stockno',
          },
          cellEditorParams: {
            allow: ALLOWANCES.STOCKNO,
          },
        },
        {
          headerName: 'MCCS Code',
          field: 'mccsCode',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'MCCS Code',
          },
          cellEditorParams: {
            allow: ALLOWANCES.MCCS_CODE,
          },
        },
      ],
      ...((editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: { pipeFamily: pipeId, pipeFamilyId: pipeId },
          });
        },
      }) ||
        {}),
    }),
  ),
);

export default enhance(Grid);
