import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedParameterProfiles = () =>  createSelector(
  selectEntities(),
  entities => entities.get('parameterProfiles')
);
export const selectNormalizedParameterProfilesList = () => createSelector(
  selectNormalizedParameterProfiles(),
  parameterProfiles => parameterProfiles.toList()
);