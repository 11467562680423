import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingBoltNutsEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentBoltNut,
  selectCurrentEditedDraftBoltNutInstancesCount,
  selectCurrentFilteredDraftBoltNutInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentBoltNut(),
  selectCurrentEditedDraftBoltNutInstancesCount(),
  selectCurrentFilteredDraftBoltNutInstances(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    boltNut,
    count,
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: (boltNut && boltNut.name) || PipingBoltNutsEditPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Bolt Nut Instance',
        count
      )}`
      : `Showing ${count} ${pluralize('Bolt Nut Instance', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
