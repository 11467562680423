import React from 'react';
import styled from 'styled-components';

import Box from '../../../../components/common/Box';
import Text from '../../../../components/common/Text';

import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

import { ENTITY_STATUS } from '../../../../modules/entities/constants';

const ErrorLabel = styled(Text)`
  text-transform: uppercase;
  font-weight: 500;
`;

const EntityStatusTooltip = React.memo(({ entity }) => {
  let title;
  let text;
  let showErrors = false;

  switch(entity.status) {
  case ENTITY_STATUS.UNSELECTED: {
    // a newly-created draft that is currently unselected
    title = 'Unselected';
    text = 'This record is not selected to be saved so it WILL NOT be included when you are saving changes';
    break;
  }
  case ENTITY_STATUS.LOCAL_DRAFT: {
    // a newly-created draft that is currently only present locally and not saved
    title = 'Local Change';
    text = 'This record has not been saved and is currently unedited, so it WILL NOT be included when you are saving changes';
    break;
  }
  case ENTITY_STATUS.SAVED: {
    // an existing record that is currently selected but unedited
    title = 'Saved Record';
    text = 'This record is saved but currently unedited so it WILL NOT be included when you are saving changes';
    break;
  }
  case ENTITY_STATUS.PENDING_VALID_SAVE: {
    // a newly-created draft that is valid and ready to be saved
    title = 'Valid New Record';
    text = 'This record has not been saved and is currently valid, so it WILL be included when you are saving changes';
    break;
  }
  case ENTITY_STATUS.PENDING_VALID_CHANGES: {
    // an existing record that is currently selected and valid
    title = 'Valid Updated Record';
    text = 'This record has valid changes and is ready to be updated, so it WILL be included when you are saving changes';
    break;
  }
  case ENTITY_STATUS.PENDING_INVALID_SAVE: {
    // a newly-created draft that is not valid or ready to be saved
    title = 'Invalid New Record';
    text = 'This record has not been saved and is currently invalid, so it WILL NOT be included when you are saving changes';
    showErrors = true;
    break;
  }
  case ENTITY_STATUS.PENDING_INVALID_CHANGES: {
    // a newly-created draft that is currently selected but invalid
    title = 'Invalid Updated Record';
    text = 'This record has invalid changes so it WILL NOT be updated when you try to save changes';
    showErrors = true;
    break;
  }
  case ENTITY_STATUS.PENDING_DELETE: {
    // an existing record that is unselected, so it will be deleted
    title = 'Pending Delete';
    text = 'This existing record has been unselected, so it WILL BE DELETED when changes are saved';
    break;
  }
  }

  return (
    <TooltipBody>
      <TooltipSection main large>
        <Text fontSize={3} fontWeight='500'>{ title }</Text>
      </TooltipSection>
      <TooltipSection large><Text fontSize={1}>{ text }</Text></TooltipSection>
      {
        showErrors &&
        <TooltipSection large error>
          {
            entity.errors.reduce((output, error, key) => {
              return [
                ...output,
                <Box key={key} mb={4}>
                  <ErrorLabel color='gray.1' fontSize={1} mb={0}>{ key.replace(/([A-Z])/g, ' $1').toLowerCase() }</ErrorLabel>
                  <Text fontSize={1}>{ error }</Text>
                </Box>
              ];
            }, []).map(o => o)
          }
        </TooltipSection>
      }
    </TooltipBody>
  );
});

export default EntityStatusTooltip;
