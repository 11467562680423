import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import ParameterModel from '../../entities/Parameters/model';
import ParameterSchema from '../../entities/Parameters/schema';
import { selectNormalizedParameters, selectDraftParameters } from '../../entities/Parameters/selectors';

import {
  selectCurrentDraftParameterIds,
} from './selectors';

import { processSaveParameters } from '../../entities/Parameters/actions';
import { saveParametersTypes } from '../../entities/Parameters/constants';

import {
  CREATE_PARAMETER_DRAFTS,
  EDIT_PARAMETER_DRAFTS,
  UPDATE_PARAMETERS_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.PARAMETERS,
  schema: ParameterSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedParameters,
});

const moveSaga = generateMoveSaga({
  keys: EK.PARAMETERS,
  model: ParameterModel,
  originalNormalizedDataSelector: selectNormalizedParameters,
  currentIdsSetSelector: selectCurrentDraftParameterIds,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.PARAMETERS,
  model: ParameterModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftParameterIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.PARAMETERS,
  model: ParameterModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectDraftParameters,
  currentIdsSetSelector: selectCurrentDraftParameterIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectDraftParameters,
  processUpdateAction: processSaveParameters,
  processUpdateTypes: saveParametersTypes,
  moveSaga,
});

export function* createDraftParameterssSaga() {
  yield takeEvery(CREATE_PARAMETER_DRAFTS, createSaga);
}

export function* editDraftParameterssSaga() {
  yield takeEvery(EDIT_PARAMETER_DRAFTS, editSaga);
}

export function* saveDraftParameterssSaga() {
  yield takeLatest(UPDATE_PARAMETERS_COLLECTION, updateSaga);
}

export function* watchParameterssDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftParameterssSaga),
      fork(editDraftParameterssSaga),
      fork(saveDraftParameterssSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.PARAMETERS.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchParameterssDraftsSaga);
}
