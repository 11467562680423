import React, { useState, useMemo } from 'react';
import { compose } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import pluralize from 'pluralize';

import EK from '../../entities/keys';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import {
  selectCurrentEditableSpecFamilySorting,
  selectCurrentSpec,
} from '../SpecsEditSwitch/selectors';
import { updateFamilySorting } from '../SpecsEditSwitch/actions';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { PipingModuleSpecsPipeInstancesEditPath } from '../../paths';

import {
  selectCurrentSelectedSpecPipeFamiliesIds,
  selectOrderedFilteredUnarchivedPipeFamilies,
} from './selectors';

import { toggleSpecPipeFamilies } from './actions';

const defaultEmptyList = List();

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(),
  selectCurrentEditableSpecFamilySorting(),
  selectOrderedFilteredUnarchivedPipeFamilies(),
  selectCurrentSelectedSpecPipeFamiliesIds(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    spec,
    currentEditableSpecFamilySorting,
    data,
    currentSelectedFamiliesIds,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    const currentSpecPrioritizedPipeFamilies =
      currentEditableSpecFamilySorting ||
      (spec && spec.prioritizedPipeFamilies) ||
      defaultEmptyList;
    return {
      ...rest,
      isLoading,
      isList: true,
      data: (!isLoading &&
        data.map((fam) =>
          fam
            .set('isSelectedForId', currentSelectedFamiliesIds.has(fam.id))
            .set(
              'selectedPipeInstancesForSpec',
              currentSelectedFamiliesIds.get(fam.id, 0),
            )
            .set(
              'isSelectableForPriority',
              currentSelectedFamiliesIds.get(fam.id, 0) > 0,
            )
            .set(
              'isSelectedForPriority',
              currentSpecPrioritizedPipeFamilies.includes(fam.id),
            )
            .set(
              'prioritizationState',
              `${
                currentSelectedFamiliesIds.get(
                  fam.id,
                  0,
                )
              }${currentSpecPrioritizedPipeFamilies.includes(fam.id)}`,
            )
        )) ||
        [],
      editable: canCollaborate &&
        hasValidLicense &&
        spec &&
        !spec.archivedFlag,
      currentSpecId: (spec && spec.id) || null,
      currentSpecPrioritizedPipeFamilies,
      isPrioritizedFamilySortingEnabled: !!currentEditableSpecFamilySorting,
    };
  },
);

function SpecsPipesEditGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    ...((reduxProps.editable && [
      ...((reduxProps.isPrioritizedFamilySortingEnabled && [
        {
          headerName: '',
          type: ['iconCell'],
          rowDrag: ({ data }) =>
            data.isSelectedForId && data.isSelectedForPriority,
          valueGetter: ({ data }) => data.name,
        },
        {
          headerName: '',
          field: 'prioritizationState',
          type: ['iconCell'],
          cellRendererParams: ({ data }) => ({
            name: data.isSelectableForPriority ? 'star' : null,
            color: data.isSelectedForPriority ? 'primary.4' : 'gray.4',
          }),
          cellStyle: ({ data }) =>
            (reduxProps.editable &&
              data.isSelectableForPriority && { cursor: 'pointer' }) ||
            {},
          onCellClicked: ({ data, context }) => {
            if (reduxProps.editable && data.isSelectableForPriority) {
              if (data.isSelectedForPriority) {
                dispatch(updateFamilySorting(
                  reduxProps.currentSpecPrioritizedPipeFamilies.filterNot(
                    (famId) => data.id === famId,
                  ),
                ));
              } else {
                dispatch(updateFamilySorting(
                  reduxProps.currentSpecPrioritizedPipeFamilies.push(
                    data.id,
                  ),
                ));
              }
            }
          },
        },
      ]) || [
        {
          type: ['entitySelectionForIdCell']
        },
        {
          headerName: '',
          field: 'prioritizationState',
          type: ['iconCell'],
          cellRendererParams: ({ data }) => ({
            name: data.isSelectedForPriority ? 'star' : null,
            color: 'primary.4',
          }),
        },
      ]),
    ]) ||
      []),
    {
      headerName: 'Name // Instances',
      field: '',
      type: ['complexCell'],
      valueGetter: ({ data }) => {
        const count = (data.pipeInstances && data.pipeInstances.size) || 0;
        const current = data.get('selectedPipeInstancesForSpec', 0);
        return {
          primary: data.name,
          secondary: `${
            current > 0 ? `${current} selected / ` : ''
          }${count} ${pluralize('instance', count)}`,
        };
      },
    },
    {
      headerName: 'Material',
      field: 'material',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Material', secondary: '--' },
        ek: EK.MATERIALS,
      },
    },
    {
      headerName: '',
      field: '',
      type: ['iconCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        to: (reduxProps.currentSpecId &&
          data.id &&
          PipingModuleSpecsPipeInstancesEditPath.generate({
            id: reduxProps.currentSpecId,
            familyId: data.id,
          })) ||
          undefined,
      }),
      cellRendererParams: {
        name: 'list',
        color: 'gray.6',
      },
    },
  ];

  const onRowDragEnd = ({ node, overNode }) => {
    const dragData = (node && node.data) || null;
    const data = (overNode && overNode.data) || null;
    if (
      data &&
      dragData &&
      dragData.isSelectableForPriority &&
      dragData.isSelectedForPriority
    ) {
      let cleaned = reduxProps.currentSpecPrioritizedPipeFamilies.filterNot(
        (famId) => famId === dragData.id,
      );

      dispatch(updateFamilySorting(
        cleaned.splice(
          cleaned.findIndex((famId) => famId === data.id),
          0,
          dragData.id,
        ),
      ));
    }
  };

  const dispatchProps = {
    editInstances: (field, value) => dispatch(toggleSpecPipeFamilies(field, value)),
    editPrioritizedFamilies: updateFamilySorting,
  };

  return (
    <Grid
      {...props}
      {...reduxProps}
      {...dispatchProps}

      columnDefs = {columnDefs}
      onRowDragEnd = {onRowDragEnd}
    />
  );
}

const enhance = compose(
  withRouter,
  injectSaga({
    key: `${EK.SPEC_PIPE_INSTANCES.state}FamiliesEdit`,
    saga,
  })
);

export default enhance(SpecsPipesEditGrid);