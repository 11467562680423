import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateWatchEditSaga,
  waitWhileInitialFetchingSaga,
  waitWhilePerformingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import {
  generateMoveOrCreateSaga,
} from '../SpecsEditSwitch/sagaTemplates';

import {
  selectCurrentFittingInstancesBaseParams,
  selectCurrentDraftSpecFittingInstanceIds,
} from '../SpecsEditSwitch/selectors';

import {
  selectCurrentSpecFittingInstanceDraftDenormalizeSelector,
  selectOriginalSpecFittingInstancesForFamily,
} from './selectors';

import SpecFittingInstanceModel from '../../entities/SpecFittingInstances/model';
import SpecFittingInstanceSchema from '../../entities/SpecFittingInstances/schema';

import { selectNormalizedSpecFittingInstances } from '../../entities/SpecFittingInstances/selectors';
import { saveSpecFittingInstancesTypes } from '../../entities/SpecFittingInstances/constants';

import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';
import { fetchIndividualFittingFamilyTypes } from '../../entities/FittingFamilies/constants';
import { processFetchIndividualFittingFamily } from '../../entities/FittingFamilies/actions';

import {
  EDIT_SPEC_FITTING_INSTANCE_DRAFTS,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentFittingInstancesBaseParams,
});

const moveOrCreateSaga = generateMoveOrCreateSaga({
  keys: EK.SPEC_FITTING_INSTANCES,
  model: SpecFittingInstanceModel,
  baseParamsSelector: selectCurrentFittingInstancesBaseParams,
  originalNormalizedDataSelector: selectNormalizedSpecFittingInstances,
  normalizedParentDataSelector: selectNormalizedFittingFamilies,
  childProperty: 'fittingInstances',
  currentIdsSetSelector: selectCurrentDraftSpecFittingInstanceIds,
  findExistingRecordFilterFunction: ({ baseParams, originalRecordId }) => originalRecord => (
    originalRecord.spec === baseParams.id &&
    originalRecord.fittingFamilyId === baseParams.familyId &&
    originalRecord.fittingInstance === originalRecordId
  ),
  generateCreationProperties: ({ baseParams, originalRecordId }) => ({
    spec: baseParams.id,
    fittingFamilyId: baseParams.familyId,
    fittingInstance: originalRecordId,
  }),
});

const reviewSaga = generateReviewSaga({
  keys: EK.SPEC_FITTING_INSTANCES,
  schema: SpecFittingInstanceSchema,
  draftDenormalizeSelector: selectCurrentSpecFittingInstanceDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalSpecFittingInstancesForFamily,
  postValidateProcessing: entity => entity.isSelected && entity.forceSetIsEdited(true).analyze || null,
});

const editSaga = generateWatchEditSaga({
  reviewSaga,
});

export function* updateSpecSuccessForMoveSaga() {
  yield takeLatest(saveSpecFittingInstancesTypes.SUCCESS, function* () {
    yield call(waitWhilePerformingSaga);

    yield call(moveOrCreateSaga);
  });
}

export function* editSpecFittingInstancesSaga() {
  yield takeEvery(EDIT_SPEC_FITTING_INSTANCE_DRAFTS, editSaga);
}

export function* watchSpecsFittingsCollectionSaga() {
  try {
    const baseParams = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualFittingFamily(baseParams.familyId));

    yield take(fetchIndividualFittingFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveOrCreateSaga);

    yield all([
      fork(editSpecFittingInstancesSaga),
      fork(updateSpecSuccessForMoveSaga),
    ]);

    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.SPEC_FITTING_INSTANCES.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSpecsFittingsCollectionSaga);
}
