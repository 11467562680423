import React from 'react';

import ColorSwatch from '../../common/ColorSwatch';

import BaseCell from '../BaseCell';

const ColorCellRenderer = ({ value }) => (
  <BaseCell flexDirection='column' justifyContent='center' alignItems='center'>
    { value && <ColorSwatch color={value} height='100%' width='100%' /> || null }
  </BaseCell>
);

export default ColorCellRenderer;