import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import InsulationInstanceSchema from './schema';

export const selectNormalizedInsulationInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('insulationInstances')
);
export const selectNormalizedDraftInsulationInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('insulationInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectInsulationInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('insulationInstances'), [InsulationInstanceSchema], entities.delete('insulationFamilies'))
);
export const selectDraftInsulationInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftInsulationInstances(),
  (entities, drafts) => denormalize(drafts, [InsulationInstanceSchema], entities.delete('insulationFamilies'))
);
