import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleInsulationsPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectInsulationFamiliesCounts } from '../../entities/InsulationFamilies/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredNormalizedDraftInsulations } from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectInsulationFamiliesCounts(),
  selectCurrentFilteredNormalizedDraftInsulations(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    isShowingArchived,
    { unarchived, total },
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && total === 0),
    title: PipingModuleInsulationsPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${
        isShowingArchived ? total : unarchived
      } ${pluralize(
        'filtered Insulation',
        isShowingArchived ? total : unarchived
      )} ${
        (isShowingArchived && '(including archived)') ||
          '(excluding archived)'
      }`
      : `Showing ${unarchived} ${pluralize('Insulation', unarchived)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
