import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

export const selectNormalizedFittingCategories = () => createSelector(
  selectEntities(),
  entities => entities.get('fittingCategories')
);
export const selectFittingCategoriesList = () => createSelector(
  selectNormalizedFittingCategories(),
  (fittingCategories) => fittingCategories.toList()
);
export const selectAllFittingCategoriesOptions = () => createSelector(
  selectFittingCategoriesList(),
  (fittingCategories) => fittingCategories.map(fittingCategory => fittingCategory.toOption())
);

export const selectNormalizedDraftFittingCategories = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('fittingCategories');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);
