import React from 'react';
import styled from 'styled-components';

import Flex from '../../../common/Flex';
import Icon from '../../../common/Icon';

import { DefaultOptionTemplate } from '../';

const MultiOption = styled(Flex).attrs(props => ({
  $ellipsis: true,
  flexDirection: props.flexDirection || 'column',
  justifyContent: props.justifyContent || 'center',
}))`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const renderMultiOptionForSelect = ({ item, searchWords, isCapitalized, isSelected }) => (
  <Flex flexDirection='row' justifyContent='center' alignItems='center'>
    <Flex flexDirection='column' justifyContent='center' alignItems='center' height='100%' mr={3}>
      <Icon name={isSelected ? 'checkbox' : 'checkbox-outline'} color={item.disabled ? 'gray.4' : (isSelected ? 'primary.4' : 'gray.6')} />
    </Flex>

    <MultiOption>
      <DefaultOptionTemplate searchWords={searchWords} isCapitalized={isCapitalized} item={item} />
    </MultiOption>
  </Flex>
);

export default renderMultiOptionForSelect;
