import React, {useState, useEffect} from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';

import Header from '../../templates/Structures/Header';
import Flex from '../../../components/common/Flex/index';
import Options from '../../templates/Structures/Options';
import { FrameworkModuleProfileTablesPath, FrameworkModuleTablePath } from '../../../paths';
import { ConstructedOptions } from '../Table/components';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

import Button from '../../../components/common/Button/index';

import { createSelector } from 'reselect';

import { selectNormalizedProfileTablesList } from '../../../entities/ProfileTables/selectors';
import {
  selectSidebarIsFiltering,
  selectFrameworkModulePermissionsAndState,
} from '../../Dashboard/selectors';
import { selectCurrentProfile } from '../../../entities/Profiles/selectors';
import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { useDispatch, useSelector } from 'react-redux';

import Text from '../../../components/common/Text';

import { useLocation, matchPath } from 'react-router-dom';

//api request actions
import {
  processFetchTables,
  processCreateTable,
  processEditTable,
  processDeleteTable,
  processCopyTable
} from '../../../entities/ProfileTables/actions';
import { processFetchParameters } from '../../../entities/ProfileParameters/actions';
import { processFetchDimensions } from '../../../entities/ProfileDimensions/actions';
import { push } from 'connected-react-router';
import { processFetchAllUnitSystems } from '../../../entities/UnitSystems/actions';

const mapStateToProps = createSelector(
  selectFrameworkModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentProfile(),
  selectNormalizedProfileTablesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    profile,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toArray()) || [],
      editable: canCollaborate && hasValidLicense,
      profile,
      hasValidLicense,
      canCollaborate,
    };
  },
);

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const location = useLocation();
  const profileId = matchPath(location.pathname, { path: FrameworkModuleProfileTablesPath.url });

  const emptyRow = {
    id: '',
    tableName: '',
    tableLabel: '',
  };

  const [row, setRow] = useState(emptyRow);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const newTable = () => {
    setRow(emptyRow);
    setIsCreateDialogOpen(true);
    setIsDialogOpen(true);
  };

  const editRow = (rowdata) => {
    setRow({ id: rowdata.id, tableName: rowdata.tableName, tableLabel: rowdata.tableLabel });
    setIsDialogOpen(true);
  };

  const cancelDialog = () => {
    setIsDialogOpen(false);
    !isCreateDialogOpen || setIsCreateDialogOpen(false);
  };

  const submitNewTable = () => {
    if (reduxProps.editable) {
      dispatch(processCreateTable(props.match.params.id, [row]));
      cancelDialog();
    }else {
      cancelDialog();
    }
  };

  const submitRowEdit = () => {
    if(reduxProps.editable && row.id) {
      dispatch(processEditTable(props.match.params.id, row));
      cancelDialog();
    }else {
      cancelDialog();
    }
  };

  const deleteItemDialog = (rowdata) => {
    let deleteRow = { id: rowdata.id, tableName: rowdata.tableName, tabelLabel: rowdata.tableLabel };
    setRow(deleteRow);
    setIsDeleteDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const deleteItem = () => {
    dispatch(processDeleteTable(props.match.params.id, row.id));
    setIsDeleteDialogOpen(false);
  };

  const onInputChange = (e, name) => {
    //copies the rowdata state variable, then replaces just the correct field with the changed input value
    const val = (!!e.target && e.target.value) || '';
    let _row = { ...row };
    _row[`${name}`] = val;
    setRow(_row);
  };

  //actions for each row
  const actionBodyTemplate = (rowdata) => {
    const tableURL = FrameworkModuleTablePath.generate({ id: profileId.params.id, tableId: rowdata.id, libraryId: profileId.params.libraryId });
    return (
      <>
        <Button icon="edit" onClick={() => editRow(rowdata)} />
        <Button icon="list" onClick={() => dispatch(push(tableURL))} />
        <Button icon="delete" onClick={() => deleteItemDialog(rowdata)} />
      </>
    );
  };

  //footers call different methods, two dispatches depending on new/update row
  const newRowFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={submitNewTable} >Save</Button>
    </>
  );

  const editRowFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={submitRowEdit} >Save</Button>
    </>
  );

  //uses state to select the correct save action
  let dialogFooter = !isCreateDialogOpen ? editRowFooter : newRowFooter;

  const deleteDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDeleteDialog} >Cancel</Button>
      <Button error={true} onClick={deleteItem} >Delete</Button>
    </>
  );

  const toTableNameBody = (rowdata) => {
    const tableURL = FrameworkModuleTablePath.generate({ id: profileId.params.id, tableId: rowdata.id, libraryId: profileId.params.libraryId});
    const parentTableName = rowdata.isSubtable && rowdata.parentTable && reduxProps.data.find(table => table.id == rowdata.parentTable).tableName;
    return (
      <>
        <Text onClick={ () => dispatch(push(tableURL)) } style={{ cursor: 'pointer' }} >{rowdata.tableName}</Text>
        {rowdata.isSubtable && <Text style={{ fontSize: '.875rem' }} >subtable of {parentTableName}</Text>}
      </>
    );
  };

  useEffect( () => {
    dispatch(processFetchTables(profileId.params.id));
    dispatch(processFetchDimensions(profileId.params.id));
    dispatch(processFetchAllUnitSystems());
    dispatch(processFetchParameters(profileId.params.id));
  }, [location]);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={`${reduxProps.profile && reduxProps.profile.name || ''} Tables`}
          subtitle={`${reduxProps.data.length || 0} ${(!!reduxProps.data.length && reduxProps.data.length > 1) || !reduxProps.data.length ? 'Tables' : 'Table'} Total`}
        />
        <Options
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          isLoading={reduxProps.isLoading}
          ConstructedOptions={() => ConstructedOptions(profileId.params.id, profileId.params.libraryId, dispatch)}
          createEntity={newTable}
        />
      </Flex>
      <div style={{ border: '1px solid #DEE2E6', borderRadius: '5px' }}>
        <DataTable value={reduxProps.data} tableStyle={{ minWidth: '50rem', borderRadius: '5px' }} size='small' stripedRows scrollable >
          <Column field='tableName' header="Name" style={{ width: '25%' }} body={toTableNameBody} ></Column>
          <Column field='tableLabel' header='Label' style={{width: '25%'}} ></Column>
          <Column body={actionBodyTemplate} style={{ width: '20%', textAlign: 'right' }} ></Column>
        </DataTable>
      </div>
      <Dialog visible={reduxProps.editable && isDialogOpen} style={{ width: '32rem' }} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={isCreateDialogOpen ? 'Create Table' : 'Edit Table'} footer={dialogFooter} modal className="p-fluid" onHide={cancelDialog} closable={false} >
        <div className="field">
          <label htmlFor="tableName" className="font-bold">Name</label>
          <InputText id="tableName" value={row.tableName} onChange={(e) => onInputChange(e, 'tableName')} required autoFocus />
        </div>
        <div className="field">
          <label htmlFor="tableLabel" className="font-bold">Label</label>
          <InputText id="tableLabel" value={row.tableLabel} onChange={(e) => onInputChange(e, 'tableLabel')} required rows={3} cols={20} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDialogOpen} style={{ width: '32rem' }} header="Delete Table" footer={deleteDialogFooter} modal className='p-fluid' onHide={cancelDeleteDialog} closable={false} >
        <div>
          <Text>Are you sure you want to delete {row.tableName}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}