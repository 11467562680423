import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { selectCurrentPipeUnitSystem } from '../PipesEditSwitch/selectors';

import { createBendInstanceDrafts, editBendInstanceDrafts } from './actions';

import {
  selectCurrentBend,
  selectCurrentFilteredDraftBendInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBend(),
  selectCurrentFilteredDraftBendInstances(),
  selectCurrentPipeUnitSystem(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    bend,
    data,
    defaultUnitSystem,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && bend && !bend.archivedFlag,
      bendId: bend && bend.id || null,
      defaultUnitSystem,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.BEND_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editBendInstanceDrafts,
      createInstances: createBendInstanceDrafts,
      copyInstances: createBendInstanceDrafts,
    },
    (
      { data, defaultUnitSystem, editable, bendId },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      defaultUnitSystem,
      columnDefs: [
        ...(editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ] || []),
        {
          headerName: 'Size',
          field: 'sizeProperty',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Size' },
            ek: EK.SIZES,
          },
          cellEditorParams: { ek: EK.SIZES },
        },
        {
          headerName: 'Ratio',
          field: 'ratio',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Ratio',
          },
        },
        {
          headerName: 'Radius',
          field: 'radius',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Min Radius',
          field: 'minRadius',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Max Radius',
          field: 'maxRadius',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Min Angle',
          field: 'minAngle',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Min Angle',
          },
        },
        {
          headerName: 'Max Angle',
          field: 'maxAngle',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Max Angle',
          },
        },
      ],
      ...(editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              bendFamily: bendId,
              bendFamilyId: bendId,
            },
          });
        },
      } || {}),
    }),
  ),
);

export default enhance(Grid);
