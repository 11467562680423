import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Map, List } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import FittingFamilySchema from '../../entities/FittingFamilies/schema';
import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';

import {
  selectNormalizedSpecFittingInstances,
  selectNormalizedDraftSpecFittingInstancesRecords,
} from '../../entities/SpecFittingInstances/selectors';

import {
  selectCurrentSpecId,
  selectCurrentSpec,
  selectCurrentEditableSpecFamilySorting,
} from '../SpecsEditSwitch/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const fittingFilterForm = formValueSelector(`filters.${EK.FITTINGS.state}`);

export const selectNormalizedUnarchivedFittingFamilies = () =>
  createSelector(
    selectNormalizedFittingFamilies(),
    // we just keep track of the count and empty the actual ids list to speed up denormalization later
    (fittings) =>
      fittings
        .map((fitting) =>
          fitting
            .set(
              'fittingInstancesCount',
              fitting.get('fittingInstances', List()).size
            )
            .set('fittingInstances', List())
        )
        .filter((fitting) => !fitting.archivedFlag)
  );

export const selectOrderedFilteredUnarchivedFittingFamilies = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectEntities(),
    selectCurrentSpec(),
    selectCurrentEditableSpecFamilySorting(),
    selectNormalizedUnarchivedFittingFamilies(),
    (state) =>
      fittingFilterForm(
        state,
        'name',
        'selectionName',
        'fittingCode',
        'fittingCategory',
        'material',
        'boltNutFamily'
      ),
    (
      isFiltering,
      entities,
      spec,
      editableFamilySorting,
      fittings,
      query
    ) => {
      let ordered = fittings;

      const sortedList =
        editableFamilySorting ||
        (spec && spec.prioritizedFittingFamilies);

      if (sortedList) {
        const final = fittings.reduce(
          ({ prioritized, unprioritized }, fitting) => {
            if (sortedList.includes(fitting.id)) {
              return { prioritized: prioritized.push(fitting), unprioritized };
            } else {
              return {
                prioritized,
                unprioritized: unprioritized.push(fitting),
              };
            }
          },
          { prioritized: List(), unprioritized: List() }
        );
        ordered = final.prioritized
          .sort((a, b) => sortedList.indexOf(a.id) - sortedList.indexOf(b.id))
          .concat(final.unprioritized);
      }

      return denormalize(
        isFiltering
          ? ordered.filter((fitting) => fitting.doesMatchQuery(query))
          : ordered,
        [FittingFamilySchema],
        entities.delete(EK.FITTING_INSTANCES.state)
      );
    }
  );

export const selectCurrentNormalizedSpecFittingInstances = () =>
  createSelector(
    selectNormalizedSpecFittingInstances(),
    selectCurrentSpecId(),
    (specFittingInstances, specId) =>
      specFittingInstances.filter(
        (instance) => instance.spec === specId
      )
  );

export const selectCurrentSelectedSpecFittingFamiliesIds = () =>
  createSelector(
    selectNormalizedDraftSpecFittingInstancesRecords(),
    selectCurrentSpecId(),
    (instances, specId) =>
      Map().withMutations((count) => {
        instances.reduce((ids, instance) => {
          if (instance.isSelected && instance.spec === specId) {
            return ids.set(
              instance.fittingFamilyId,
              ids.get(instance.fittingFamilyId, 0) + 1
            );
          } else {
            return ids;
          }
        }, count);
      })
  );
