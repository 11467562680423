import { createSelector } from 'reselect';
// import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
// import OrganizationSchema from './schema';

export const selectNormalizedOrganizations = () => createSelector(
  selectEntities(),
  entities => entities.get('organizations')
);
