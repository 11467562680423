import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';

import useStateWithCallback from '../../../hooks/useStateWithCallback';

import Flex from '../../common/Flex';

import RadioSelect from '../../form/RadioSelect';

const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;

const RadioEditor = React.forwardRef(({ 
  isNullable, 
  keyPress,  
  value, 
  placeholder, 
  options, 
  stopEditing,
  column, 
  renderOption, 
  renderValue, 
  defaultValue = null,
  isCapitalized,
}, ref) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    },
    getValue: () => currentValue === '' || currentValue === undefined ? defaultValue : currentValue,
  }));

  const initialValue = useMemo(() => {
    let startValue = '';

    if (isNullable && (keyPress === KEY_BACKSPACE || keyPress === KEY_DELETE)) {
      // if backspace or delete pressed, clear the cell
      startValue = '';
    } else {
      startValue = value && value.id || value && value.value || value || '';
    }
  
    return startValue;
  }, []);

  const [isReady, setIsReady] = useState(false);

  const setCurrentValueCallback = useCallback(
    newValue => {
      // only accept values here once the dropdown is ready
      if (isReady) {
        if (isNullable) {
          stopEditing();
        } else if (newValue) {
          stopEditing();
        }
      }
    }, 
    [isNullable, stopEditing, isReady]
  );

  const [currentValue, setCurrentValue] = useStateWithCallback(initialValue, setCurrentValueCallback);

  const onChange = useCallback(
    value => {
      if (value !== initialValue) {
        setCurrentValue(value);
      } else {
        stopEditing();
      }
    }, 
    [setCurrentValue, stopEditing]
  );

  // prevent initial render from ruining life
  useEffect(() => {
    if (!isReady) { setIsReady(true); }
  }, []);

  const onOuterClick = useCallback(_ => stopEditing(), [stopEditing]);

  return (
    <Flex flexDirection='column' justifyContent='center' height='100%' cursor='pointer' pl={3} pr={3}>
      <RadioSelect
        ref={inputRef}
        placeholder={placeholder}
        value={currentValue}
        options={options}
        onChange={onChange}
        width={column.actualWidth}
        itemHeight={56}
        renderOption={renderOption}
        renderValue={renderValue}
        clearable={isNullable}
        onOuterClick={onOuterClick}
        isCapitalized={isCapitalized}
      />
    </Flex>
  );
});

export default RadioEditor;
