import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleSchedulesPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentEditedDraftSchedulesCount,
  selectCurrentFilteredNormalizedDraftSchedules,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentEditedDraftSchedulesCount(),
  selectCurrentFilteredNormalizedDraftSchedules(),
  (isLoadingInitialData, isFetching, isFiltering, count, filtered) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: PipingModuleSchedulesPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Schedule',
        count
      )}`
      : `${count} ${pluralize('Schedule', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
