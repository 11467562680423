import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import SpecPipeInstanceSchema from './schema';

export const selectNormalizedSpecPipeInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('specPipeInstances')
);
export const selectNormalizedDraftSpecPipeInstancesRecords = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn(['specPipeInstances', 'records'])
);
export const selectNormalizedDraftSpecPipeInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('specPipeInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);
