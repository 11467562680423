import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
} from '../../../../paths';

import {
  createBendMachineFamilyForPipeFamilyTypes,
  copyBendMachineFamilyForPipeFamilyTypes,
  editBendMachineFamilyForPipeFamilyTypes,
  deleteBendMachineFamilyForPipeFamilyTypes,
} from '../../../../entities/BendMachineFamilies/constants';
import {
  selectNormalizedBendMachineFamilies
} from '../../../../entities/BendMachineFamilies/selectors';

import { PREPARE_BEND_MACHINE_MODAL } from './constants';

const generateWatchCreateBendMachineSaga = (id) =>
  function* () {
    yield takeLatest([
      createBendMachineFamilyForPipeFamilyTypes.SUCCESS,
      copyBendMachineFamilyForPipeFamilyTypes.SUCCESS,
    ], function* ({ payload = {} }) {
      const familyId = `${get(payload, `${EK.BEND_MACHINES.plural}[0].id`)}`;
      if (!!familyId && !!id) {
        let bendMachines = yield select(selectNormalizedBendMachineFamilies());
        let copiedBendMachine = bendMachines.get(familyId);

        while (!copiedBendMachine) {
          yield take();
          bendMachines = yield select(selectNormalizedBendMachineFamilies());
          copiedBendMachine = bendMachines.get(familyId);
        }

        yield put(
          push(
            PipingModulePipesBendMachinesEditPath.generate({
              id,
              familyId,
            })
          )
        );

        yield put(hideModal());
      }
    }
    );
  };

const generateWatchDeleteBendMachineSaga = (id) =>
  function* () {
    yield takeLatest(
      deleteBendMachineFamilyForPipeFamilyTypes.SUCCESS,
      function* () {
        yield put(push(PipingModulePipesBendMachinesPath.generate({ id })));
      }
    );
  };

export function* handleCloseBendMachineFamilyModal() {
  yield put(hideModal());
}

export function* watchCloseBendMachineModalSaga() {
  yield takeLatest(
    [
      createBendMachineFamilyForPipeFamilyTypes.SUCCESS,
      editBendMachineFamilyForPipeFamilyTypes.SUCCESS,
      deleteBendMachineFamilyForPipeFamilyTypes.SUCCESS,
    ],
    handleCloseBendMachineFamilyModal
  );
}

// final output saga
export default function* main() {
  const {
    payload: { id },
  } = yield take(PREPARE_BEND_MACHINE_MODAL);

  const watchCreateBendMachineSaga = generateWatchCreateBendMachineSaga(
    id
  );
  const watchDeleteBendMachineSaga = generateWatchDeleteBendMachineSaga(
    id
  );

  yield all([
    fork(watchCreateBendMachineSaga),
    fork(watchDeleteBendMachineSaga),
    fork(watchCloseBendMachineModalSaga),
  ]);
}
