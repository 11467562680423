import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import { selectNormalizedBendSpringbackFamiliesList } from '../../entities/BendSpringbackFamilies/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const bendSpringbackFilterForm = formValueSelector(`filters.${EK.BEND_SPRINGBACKS.state}`);

export const selectCurrentPipesBendSpringbacksCount = () => createSelector(
  selectCurrentPipeId(),
  selectNormalizedBendSpringbackFamiliesList(),
  (pipeId, bendSpringbacks) => bendSpringbacks
    .filter(bendSpringback => bendSpringback.pipeFamilyId === pipeId)
    .reduce(({ unarchived, total }, bendSpringback) => ({
      unarchived: !bendSpringback.archivedFlag ? unarchived + 1 : unarchived,
      total: total + 1,
    }), {
      unarchived: 0,
      total: 0,
    })
);

export const selectCurrentFilteredPipesBendSpringbacks = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentPipeId(),
  selectNormalizedBendSpringbackFamiliesList(),
  state => bendSpringbackFilterForm(state, 'name'),
  (isFiltering, isShowingArchived, pipeId, bendSpringbacks, name) => isFiltering ? 
    bendSpringbacks.filter(bendSpringback => (isShowingArchived || !bendSpringback.archivedFlag) && bendSpringback.pipeFamilyId === pipeId && bendSpringback.doesMatchQuery({ name })) : 
    bendSpringbacks.filter(bendSpringback => !bendSpringback.archivedFlag && bendSpringback.pipeFamilyId === pipeId)
);