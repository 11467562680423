import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import { PipingModuleSpecsEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processFetchAllSpecs,
  processArchiveSpecs,
  processUnarchiveSpecs,
} from '../../entities/Specs/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredDraftSpecs } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredDraftSpecs(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size == 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function SpecsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name + Mnemonic // Instances',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModuleSpecsEditPath.generate({ id: data.id })) ||
          undefined,
      }),
    },
    reduxProps.editable &&
      {
        type: ['dataActionIconCell'],
        cellRendererParams: ({ value }) => ({
          actions: (value &&
            List([
              {
                icon: 'list',
                onClick: (spec) => dispatch(push(PipingModuleSpecsEditPath.generate({ id: spec.id }))),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (spec) => dispatch(showModal('SPEC_MODAL', { specId: spec.id, copyEntity: true })),
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: (spec) => dispatch(processUnarchiveSpecs([spec.id])),
              },
            ])) ||
            List([
              {
                icon: 'edit',
                primary: true,
                onClick: (spec) => dispatch(showModal('SPEC_MODAL', { specId: spec.id })),
              },
              {
                icon: 'list',
                onClick: (spec) => dispatch(push(PipingModuleSpecsEditPath.generate({ id: spec.id }))),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (spec) => dispatch(showModal('SPEC_MODAL', { specId: spec.id, copyEntity: true })),
              },
              {
                icon: 'archive',
                error: true,
                onClick: (spec) => dispatch(processArchiveSpecs([spec.id])),
              },
            ]),
        }),
      }
  ];

  useEffect( () => {
    dispatch(processFetchAllSpecs());
  }, []);

  return (
    <Grid
      {...reduxProps}

      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(SpecsGrid);
