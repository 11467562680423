import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentBend } from './selectors';

import { PipingModulePipesBendsEditPath } from '../../paths';

const mapStateToProps = createSelector(selectCurrentBend(), (bend) => ({
  title: (bend && bend.name) || PipingModulePipesBendsEditPath.defaultTitle,
}));

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
