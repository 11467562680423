import React from 'react';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm } from 'redux-form/immutable';
import times from 'lodash/times';

import EK from '../../entities/keys';

import BaseFormRow from '../../components/form/BaseFormRow';

import FilterFormTemplate from '../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../containers/form/RDXSearchSelect';

import {
  selectPortNames,
} from '../../entities/FittingInstances/selectors';

import { 
  selectCurrentFittingFamilyEndPortCountAndChangesSize,
} from './selectors';

const FilterForm = ({ portNames, endPortCount, ...props }) => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='schedule'
        label='Schedule'
        ek={EK.SCHEDULES}
        clearable
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='cadModelName'
        label='CAD Model Name'
        type='text'
      />
    </BaseFormRow>
    {
      times(endPortCount, idx => (
        <React.Fragment>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name={`port${idx}Size`}
              label={`${portNames.get(idx)} Size`}
              ek={EK.SIZES}
              clearable
            />
          </BaseFormRow>
          <BaseFormRow>
            <Field
              component={RDXSearchSelect}
              name={`port${idx}EndType`}
              label={`${portNames.get(idx)} End Type`}
              ek={EK.END_TYPES}
              clearable
            />
          </BaseFormRow>
        </React.Fragment>
      ))
    }
  </FilterFormTemplate>
);

const mapStateToProps = createSelector(
  selectPortNames(),
  selectCurrentFittingFamilyEndPortCountAndChangesSize(),
  (portNames, { endCount }) => {
    return {
      portNames,
      endPortCount: endCount,
    };
  }
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.FITTING_INSTANCES.state}` }),
  connect(mapStateToProps),
);

export default enhance(FilterForm);
