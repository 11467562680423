import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import BendMachineInstanceModel from '../../entities/BendMachineInstances/model';
import BendMachineInstanceSchema from '../../entities/BendMachineInstances/schema';

import { selectCurrentPipeInstanceAndChildrenSerializationSelector } from '../PipesEditSwitch/selectors';

import {
  selectBaseParams,
  selectDraftDenormalizeSelector,
  selectOriginalNormalizedBendMachineInstancesForFamily,
  selectOriginalDenormalizedBendMachineInstancesForFamily,
  selectCurrentDraftBendMachineInstances,
  selectCurrentDraftBendMachineInstanceIds,
} from './selectors';

import { processFetchIndividualBendMachineFamilyForPipeFamily } from '../../entities/BendMachineFamilies/actions';
import { fetchIndividualBendMachineFamilyForPipeFamilyTypes } from '../../entities/BendMachineFamilies/constants';

import { 
  selectNormalizedDraftBendMachineInstances,
} from '../../entities/BendMachineInstances/selectors';
import { processSaveBendMachineInstances } from '../../entities/BendMachineInstances/actions';
import { saveBendMachineInstancesTypes } from '../../entities/BendMachineInstances/constants';

import {
  CREATE_BEND_MACHINE_INSTANCE_DRAFTS,
  EDIT_BEND_MACHINE_INSTANCE_DRAFTS,
  UPDATE_BEND_MACHINE_INSTANCES_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectBaseParams,
});

const reviewSaga = generateReviewSaga({
  keys: EK.BEND_MACHINE_INSTANCES,
  schema: BendMachineInstanceSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalDenormalizedBendMachineInstancesForFamily,
});

const generateCreationProperties = ({ familyId }) => ({
  bendMachineFamilyId: familyId, 
  bendMachineFamily: familyId
});

const moveSaga = generateMoveSaga({
  keys: EK.BEND_MACHINE_INSTANCES,
  model: BendMachineInstanceModel,
  baseParamsSelector: selectBaseParams, 
  originalNormalizedDataSelector: selectOriginalNormalizedBendMachineInstancesForFamily, 
  currentIdsSetSelector: selectCurrentDraftBendMachineInstanceIds, 
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.BEND_MACHINE_INSTANCES,
  model: BendMachineInstanceModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftBendMachineInstanceIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.BEND_MACHINE_INSTANCES, 
  model: BendMachineInstanceModel,
  reviewSaga,
  baseParamsSelector: selectBaseParams, 
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftBendMachineInstances,
  currentIdsSetSelector: selectCurrentDraftBendMachineInstanceIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectBaseParams,
  currentDraftDataToSerializeSelector: selectCurrentDraftBendMachineInstances,
  serializationDataSelector: selectCurrentPipeInstanceAndChildrenSerializationSelector,
  processUpdateAction: processSaveBendMachineInstances,
  processUpdateTypes: saveBendMachineInstancesTypes,
  moveSaga,
});

export function* createDraftBendMachineInstancesSaga() {
  yield takeEvery(CREATE_BEND_MACHINE_INSTANCE_DRAFTS, createSaga);
}

export function* editDraftBendMachineInstancesSaga() {
  yield takeEvery(EDIT_BEND_MACHINE_INSTANCE_DRAFTS, editSaga);
}

export function* saveDraftBendMachineInstancesSaga() {
  yield takeLatest(UPDATE_BEND_MACHINE_INSTANCES_COLLECTION, updateSaga);
}

export function* watchBendMachineInstancesDraftsSaga() {
  try {
    const baseParams = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualBendMachineFamilyForPipeFamily(baseParams));

    // wait for the individual request to be successful
    yield take(fetchIndividualBendMachineFamilyForPipeFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftBendMachineInstancesSaga),
      fork(editDraftBendMachineInstancesSaga),
      fork(saveDraftBendMachineInstancesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.BEND_MACHINE_INSTANCES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchBendMachineInstancesDraftsSaga);
}
