import { take, takeLatest, all, call, put, fork, cancelled } from 'redux-saga/effects';

import {
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import {
  selectCurrentSpecId,
  selectCurrentNormalizedSpecFittingInstances,
  selectCurrentNormalizedSpecPipeInstances,
} from './selectors';

import { fetchIndividualSpecTypes } from '../../entities/Specs/constants';
import { processFetchIndividualSpec } from '../../entities/Specs/actions';

import { processSaveSpecFittingInstances } from '../../entities/SpecFittingInstances/actions';
import { saveSpecFittingInstancesTypes } from '../../entities/SpecFittingInstances/constants';

import { processSaveSpecPipeInstances } from '../../entities/SpecPipeInstances/actions';
import { saveSpecPipeInstancesTypes } from '../../entities/SpecPipeInstances/constants';

import {
  UPDATE_SPEC_PIPE_INSTANCES,
  UPDATE_SPEC_FITTING_INSTANCES,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentSpecId,
});

const updateSpecFittingInstancesSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectCurrentSpecId,
  currentDraftDataToSerializeSelector: selectCurrentNormalizedSpecFittingInstances,
  processUpdateAction: processSaveSpecFittingInstances,
  processUpdateTypes: saveSpecFittingInstancesTypes,
});

const updateSpecPipeInstancesSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectCurrentSpecId,
  currentDraftDataToSerializeSelector: selectCurrentNormalizedSpecPipeInstances,
  processUpdateAction: processSaveSpecPipeInstances,
  processUpdateTypes: saveSpecPipeInstancesTypes,
});

export function* saveSpecFittingInstanceChangesSaga() {
  yield takeLatest(UPDATE_SPEC_FITTING_INSTANCES, updateSpecFittingInstancesSaga);
}

export function* saveSpecPipeInstanceChangesSaga() {
  yield takeLatest(UPDATE_SPEC_PIPE_INSTANCES, updateSpecPipeInstancesSaga);
}

export function* watchSpecSaga() {
  try {
    const specId = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualSpec(specId));

    yield take(fetchIndividualSpecTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield all([
      fork(saveSpecFittingInstanceChangesSaga),
      fork(saveSpecPipeInstanceChangesSaga),
    ]);

    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      // does anything need to go here?
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSpecSaga);
}
