import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { List, Set } from 'immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';

import { selectNormalizedPipeFamilies } from '../../entities/PipeFamilies/selectors';

import { selectNormalizedSpecs } from '../../entities/Specs/selectors';
import { selectNormalizedDraftSpecFittingInstances } from '../../entities/SpecFittingInstances/selectors';
import { selectNormalizedDraftSpecPipeInstances } from '../../entities/SpecPipeInstances/selectors';

import {
  PipingModuleSpecsEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
} from '../../paths';

export const selectCurrentSpecId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsEditPath.url,
    });
    return (match && match.params.id) || null;
  });

export const selectCurrentSpec = (altSpecId = null) =>
  createSelector(
    selectCurrentSpecId(),
    selectNormalizedSpecs(),
    (specId, specs) => {
      const specIdToGet = specId || altSpecId || null;
      return specIdToGet && specs.get(specIdToGet) || undefined;
    }
  );

export const selectCurrentEditableSpecFamilySorting = () => (state) =>
  state.getIn([`${EK.SPECS.state}EditSorting`, 'familySortingOrder']);

export const selectCurrentFittingId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsFittingInstancesEditPath.url,
    });
    return (match && match.params.familyId) || null;
  });

export const selectCurrentFitting = () =>
  createSelector(
    selectCurrentFittingId(),
    selectNormalizedFittingFamilies(),
    (fittingId, fittings) => fittings.get(fittingId)
  );

export const selectCurrentFittingInstancesBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsFittingInstancesEditPath.url,
    });
    // returns  { id, familyId }
    return (match && match.params) || null;
  });

export const selectCurrentPipeId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsPipeInstancesEditPath.url,
    });
    return (match && match.params.familyId) || null;
  });

export const selectCurrentPipe = () =>
  createSelector(
    selectCurrentPipeId(),
    selectNormalizedPipeFamilies(),
    (pipeId, pipes) => pipes.get(pipeId)
  );

export const selectCurrentPipeInstancesBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleSpecsPipeInstancesEditPath.url,
    });
    // returns  { id, familyId }
    return (match && match.params) || null;
  });

export const selectCurrentNormalizedSpecFittingInstances = () =>
  createSelector(
    selectNormalizedDraftSpecFittingInstances(),
    selectCurrentSpecId(),
    (instances, specId) =>
      instances.filter((record) => record.spec === specId)
  );

export const selectCurrentNormalizedSpecPipeInstances = () =>
  createSelector(
    selectNormalizedDraftSpecPipeInstances(),
    selectCurrentSpecId(),
    (instances, specId) =>
      instances.filter((record) => record.spec === specId)
  );

const defaultEmptyList = List();
export const selectSpecFittingInstancesChangesPending = () =>
  createSelector(
    selectNormalizedDraftSpecFittingInstances(),
    selectCurrentSpec(),
    selectCurrentEditableSpecFamilySorting(),
    (draftSpecFittingInstances, spec, editableFamilySorting) => {
      if (editableFamilySorting) {
        const existingFamilySorting =
          (spec && spec.prioritizedFittingFamilies) ||
          defaultEmptyList;
        return (
          editableFamilySorting.size !== existingFamilySorting.size ||
          editableFamilySorting.some(
            (v, i) => v !== existingFamilySorting.get(i)
          )
        );
      } else {
        const specId = (spec && spec.id) || null;
        return draftSpecFittingInstances.some(
          (instance) =>
            instance.spec === specId &&
            (instance.status === ENTITY_STATUS.PENDING_DELETE ||
              instance.status === ENTITY_STATUS.PENDING_VALID_CHANGES ||
              instance.status === ENTITY_STATUS.PENDING_VALID_SAVE)
        );
      }
    }
  );

export const selectSpecPipeInstancesChangesPending = () =>
  createSelector(
    selectNormalizedDraftSpecPipeInstances(),
    selectCurrentSpec(),
    selectCurrentEditableSpecFamilySorting(),
    (draftSpecPipeInstances, spec, editableFamilySorting) => {
      if (editableFamilySorting) {
        const existingFamilySorting =
          (spec && spec.prioritizedPipeFamilies) ||
          defaultEmptyList;
        return (
          editableFamilySorting.size !== existingFamilySorting.size ||
          editableFamilySorting.some(
            (v, i) => v !== existingFamilySorting.get(i)
          )
        );
      } else {
        const specId = (spec && spec.id) || null;
        return draftSpecPipeInstances.some(
          (instance) =>
            instance.spec === specId &&
            (instance.status === ENTITY_STATUS.PENDING_DELETE ||
              instance.status === ENTITY_STATUS.PENDING_VALID_CHANGES ||
              instance.status === ENTITY_STATUS.PENDING_VALID_SAVE)
        );
      }
    }
  );

export const selectSpecConditionalPipeInstancesChangesPending = () =>
  createSelector(
    selectRouteLocationPathname(),
    selectSpecPipeInstancesChangesPending(),
    (pathname, pendingValidChanges) => {
      const match = matchPath(pathname, {
        path: PipingModuleSpecsPipesEditPath.url,
      });
      return (!!match && { pendingValidChanges }) || {};
    }
  );

export const selectSpecConditionalFittingInstancesChangesPending = () =>
  createSelector(
    selectRouteLocationPathname(),
    selectSpecFittingInstancesChangesPending(),
    (pathname, pendingValidChanges) => {
      const match = matchPath(pathname, {
        path: PipingModuleSpecsFittingsEditPath.url,
      });
      return (!!match && { pendingValidChanges }) || {};
    }
  );

export const selectCurrentDraftSpecPipeInstanceIds = () =>
  createSelector(selectNormalizedDraftSpecPipeInstances(), (instances) =>
    instances.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentDraftSpecFittingInstanceIds = () =>
  createSelector(
    selectNormalizedDraftSpecFittingInstances(),
    (instances) =>
      instances.reduce((ids, current) => ids.add(current.id), Set())
  );
