import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
} from '../../../../paths';

import {
  createBendFamilyForPipeFamilyTypes,
  copyBendFamilyForPipeFamilyTypes,
  editBendFamilyForPipeFamilyTypes,
  deleteBendFamilyForPipeFamilyTypes,
} from '../../../../entities/BendFamilies/constants';
import {
  selectNormalizedBendFamilies
} from '../../../../entities/BendFamilies/selectors';

import { PREPARE_BEND_MODAL } from './constants';

const generateWatchCreateBendSaga = (id) =>
  function* () {
    yield takeLatest([
      createBendFamilyForPipeFamilyTypes.SUCCESS,
      copyBendFamilyForPipeFamilyTypes.SUCCESS,
    ], function* ({
      payload = {},
    }) {
      const familyId = `${get(payload, `${EK.BENDS.plural}[0].id`)}`;
      if (!!familyId && !!id) {
        let bends = yield select(selectNormalizedBendFamilies());
        let copiedBend = bends.get(familyId);

        while (!copiedBend) {
          yield take();
          bends = yield select(selectNormalizedBendFamilies());
          copiedBend = bends.get(familyId);
        }

        yield put(
          push(PipingModulePipesBendsEditPath.generate({ id, familyId }))
        );

        yield put(hideModal());
      }
    });
  };

const generateWatchDeleteBendSaga = (id) =>
  function* () {
    yield takeLatest(deleteBendFamilyForPipeFamilyTypes.SUCCESS, function* () {
      yield put(push(PipingModulePipesBendsPath.generate({ id })));
    });
  };

export function* handleCloseBendFamilyModal() {
  yield put(hideModal());
}

export function* watchCloseBendModalSaga() {
  yield takeLatest(
    [
      createBendFamilyForPipeFamilyTypes.SUCCESS,
      editBendFamilyForPipeFamilyTypes.SUCCESS,
      deleteBendFamilyForPipeFamilyTypes.SUCCESS,
    ],
    handleCloseBendFamilyModal
  );
}

// final output saga
export default function* main() {
  const {
    payload: { id },
  } = yield take(PREPARE_BEND_MODAL);

  const watchCreateBendSaga = generateWatchCreateBendSaga(id);
  const watchDeleteBendSaga = generateWatchDeleteBendSaga(id);

  yield all([
    fork(watchCreateBendSaga),
    fork(watchDeleteBendSaga),
    fork(watchCloseBendModalSaga),
  ]);
}
