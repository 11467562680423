import React, { useCallback } from 'react';

import Button from '../../common/Button';

import BaseCell from '../BaseCell';

const DataActionButton = ({ onClick, data, ...rest }) => {
  const onHandleClick = useCallback(
    _ => onClick(data),
    [onClick, data]
  );

  return <Button transparent subtle { ...rest } onClick={onHandleClick} />;
};

const DataActionIconCellRenderer = ({ actions, data }) => (
  <BaseCell flexDirection='row' justifyContent='flex-end' alignItems='center'>
    { actions.map(({ onClick, ...rest }, idx) => <DataActionButton key={idx} onClick={onClick} data={data} mr={idx !== actions.size - 1 ? 2 : undefined} { ...rest } />) }
  </BaseCell>
);

export default DataActionIconCellRenderer;
