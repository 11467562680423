import isString from 'lodash/isString';

import validateRules from '../../utils/validation/validateRules';

import { ALLOWANCES } from './model';

export const rules = {
  specName: {
    minLength: {
      message: 'Must be at least 1 characters long',
      validator: (value) =>
        value && value.length >= 1 && ALLOWANCES.SPEC_NAME.test(value),
    },
  },
  layerName: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: (value) =>
        value && value.length >= 2 && ALLOWANCES.LAYER_NAME.test(value),
    },
  },
  mnemonic: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: (value) =>
        value && value.length >= 2 && ALLOWANCES.MNEMONIC.test(value),
    },
  },
  appearance: {
    required: {
      message: 'Cannot be empty',
      validator: (value) => isString(value),
    },
  },
};

export const validations = {
  specName: validateRules(rules.specName),
  layerName: validateRules(rules.layerName),
  mnemonic: validateRules(rules.mnemonic),
  appearance: validateRules(rules.appearance),
};
