import { take, takeLatest, takeEvery, all, call, put, fork, race, select, cancelled } from 'redux-saga/effects';
import { List, Set } from 'immutable';

import { addDrafts, mergeDrafts, moveDrafts, clearDrafts } from '../../../../modules/drafts/actions';
import { 
  generateUniqueIdForCreations,
} from '../../../../modules/drafts/sagas';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import EndTypeCompatibilityModel from '../../../../entities/EndTypeCompatibilities/model';
import endTypeCompatibilityKeys from '../../../../entities/EndTypeCompatibilities/keys';
import { selectNormalizedEndTypeCompatibilities, selectNormalizedDraftEndTypeCompatibilitiesRecords } from '../../../../entities/EndTypeCompatibilities/selectors';
import review from '../../../../entities/EndTypeCompatibilities/reviewSaga';

import { 
  createEndTypeCompatibilitiesTypes, 
  updateEndTypeCompatibilityTypes,
  removeEndTypeCompatibilityTypes,
} from '../../../../entities/EndTypeCompatibilities/constants';

import {
  INITIAL_END_TYPE_COMPATIBILITY_LOAD,
  CREATE_END_TYPE_COMPATIBILITY_DRAFTS,
  EDIT_END_TYPE_COMPATIBILITY_DRAFTS,
} from './constants';

function* moveEndTypeCompatibilities(endTypeId) {
  const endTypeCompatibilities = yield select(selectNormalizedEndTypeCompatibilities());
  yield put( moveDrafts({ [endTypeCompatibilityKeys.state]: endTypeCompatibilities.filter(comp => comp.firstEndType === endTypeId || comp.secondEndType === endTypeId) }) );
}

export function* watchForCreateEndTypeCompatibilityDrafts({ payload: { creations, insertAt, creationProperties } }) {
  const draftEndTypeCompatibilities = yield select(selectNormalizedDraftEndTypeCompatibilitiesRecords());

  // this turns the incoming creations from a List into a Map
  let drafts = generateUniqueIdForCreations(
    creations,
    draftEndTypeCompatibilities.reduce((ids, current) => ids.add(current.id), Set()),
    { creationProperties }
  );

  const draftEntities = yield call(review, drafts.map(d => new EndTypeCompatibilityModel(d).tally));

  yield put(addDrafts(draftEntities, { insertAt }));
}

export function* watchForEditEndTypeCompatibilityDrafts({ payload: { edits } }) {
  const draftEntities = yield call(review, edits.map(edit => edit.tally));

  yield put(mergeDrafts(draftEntities));
}

export function* createDraftEndTypeCompatibilitiesSaga() {
  yield takeEvery(CREATE_END_TYPE_COMPATIBILITY_DRAFTS, watchForCreateEndTypeCompatibilityDrafts);
}

export function* editDraftEndTypeCompatibilitiesSaga() {
  yield takeEvery(EDIT_END_TYPE_COMPATIBILITY_DRAFTS, watchForEditEndTypeCompatibilityDrafts);
}

const generateWatchForMoveEndTypeCompatibilitiesSaga = endTypeId => function* () {
  yield takeEvery([createEndTypeCompatibilitiesTypes.SUCCESS, updateEndTypeCompatibilityTypes.SUCCESS, removeEndTypeCompatibilityTypes.SUCCESS], function* () {
    let isPerformingEntities = yield select(selectIsPerformingInModal());
    while (isPerformingEntities > 0) {
      yield take();
      isPerformingEntities = yield select(selectIsPerformingInModal());
    }

    yield call(moveEndTypeCompatibilities, endTypeId);
  });
};

export function* watchEndTypeCompatibilitiesDraftsSaga() {
  try {
    const { payload: { endTypeId } } = yield take(INITIAL_END_TYPE_COMPATIBILITY_LOAD);

    yield call(moveEndTypeCompatibilities, endTypeId);

    const watchForMoveEndTypeCompatibilitiesSaga = generateWatchForMoveEndTypeCompatibilitiesSaga(endTypeId);

    // then here, we fork off tasks to watch for updates to the endTypeCompatibilities grid
    yield all([
      fork(watchForMoveEndTypeCompatibilitiesSaga),
      fork(createDraftEndTypeCompatibilitiesSaga),
      fork(editDraftEndTypeCompatibilitiesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [endTypeCompatibilityKeys.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchEndTypeCompatibilitiesDraftsSaga);
}

