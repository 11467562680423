import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectDrafts from '../../modules/drafts/selectors';

import { selectNormalizedDraftFittingCategories } from '../../entities/FittingCategories/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

export const selectCurrentDraftFittingCategoriesPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.FITTING_CATEGORIES.state, 'saveable']).size > 0
);

export const selectCurrentDraftFittingCategoryIds = () => createSelector(
  selectNormalizedDraftFittingCategories(),
  drafts => drafts.reduce((ids, draft) => ids.add(draft.id), Set())
);

export const selectCurrentEditedDraftFittingCategoriesCount = () => createSelector(
  selectNormalizedDraftFittingCategories(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);

const fittingCategoryFilterForm = formValueSelector(`filters.${EK.FITTING_CATEGORIES.state}`);

export const selectCurrentFilteredNormalizedDraftFittingCategories = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftFittingCategories(),
  state => fittingCategoryFilterForm(state, 'categoryName', 'symbolFile'),
  (isFiltering, fittingCategories, query) => isFiltering ? fittingCategories.filter(fittingCategory => fittingCategory.doesMatchQuery(query)) : fittingCategories
);