import { createAction } from 'redux-actions';

import {
  generateCreateDraftsAction,
  generateEditDraftsAction,
} from '../../modules/drafts/actions';

import {
  CREATE_SECTION_DRAFTS,
  EDIT_SECTION_DRAFTS,
  UPDATE_SECTIONS_COLLECTION,
} from './constants';

export const createSectionDrafts = generateCreateDraftsAction(
  CREATE_SECTION_DRAFTS
);

export const editSectionDrafts = generateEditDraftsAction(
  EDIT_SECTION_DRAFTS
);

export const updateSectionsCollection = createAction(
  UPDATE_SECTIONS_COLLECTION
);
