import React from 'react';

import Button from '../../../components/common/Button';
import Popup from 'reactjs-popup';

import { FrameworkModuleProfilePath } from '../../../paths';

import colors from '../../../assets/themes/base/colors';
import { push } from 'connected-react-router';

//popup styling
const contentStyle = { background: '#fff', color: `${colors.gray[7]}`, padding: '10px 10px 10px 10px ', border: 'solid darkgrey 2px', borderRadius: '3px' };
const overlayStyle = { background: 'rgba(255,255,255,0.75)' };
const arrowStyle = { color: 'rgb(255,255,255)', stroke: 'darkgrey', strokeWidth: '2px' };

export const ConstructedOptions = (profileId, libraryId, dispatch) => {
  const profileLink = FrameworkModuleProfilePath.generate({ id: profileId, libraryId: libraryId });
  return (
    <>
      <Popup trigger={() => <Button icon="list" secondary={true} onClick={() => dispatch(push(profileLink))} style={{ marginRight: '10px' }} />} position='bottom center' on={['hover']} {...{ contentStyle, overlayStyle, arrowStyle }} >
        To Parameters/Dimensions
      </Popup>
    </>
  );
};
