import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedBendMachineFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('bendMachineFamilies')
);
export const selectNormalizedBendMachineFamiliesList = () => createSelector(
  selectNormalizedBendMachineFamilies(),
  bendMachineFamilies => bendMachineFamilies.toList()
);