import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectNormalizedSettings } from '../Settings/selectors';

import FittingInstanceSchema from './schema';

export const selectNormalizedFittingInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('fittingInstances')
);
export const selectNormalizedDraftFittingInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('fittingInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectFittingInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('fittingInstances'), [FittingInstanceSchema], entities.delete('fittingFamilies'))
);
export const selectDraftFittingInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftFittingInstances(),
  (entities, drafts) => denormalize(drafts, [FittingInstanceSchema], entities.delete('fittingFamilies'))
);

const empty = { value: '...' };
export const selectPortNames = () => createSelector(
  selectNormalizedSettings(),
  settings => {
    return List([
      (settings.find(setting => setting.name === 'Fitting Inlet Feature Name') || empty).value,
      (settings.find(setting => setting.name === 'Fitting Outlet Feature Name') || empty).value,
      (settings.find(setting => setting.name === 'Fitting Branch Feature Name') || empty).value,
    ]);
  }
);

