import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import ParameterSchema from './schema';

export const selectNormalizedParameters = () =>  createSelector(
  selectEntities(),
  entities => entities.get(EK.PARAMETERS.state)
);
export const selectNormalizedParametersList = () => createSelector(
  selectNormalizedParameters(),
  parameters => parameters.toList()
);

export const selectNormalizedDraftParameters = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get(EK.PARAMETERS.state);
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectParameters = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.PARAMETERS.state), [ParameterSchema], entities)
);
export const selectDraftParameters = () => createSelector(
  selectEntities(),
  selectNormalizedDraftParameters(),
  (entities, drafts) => denormalize(drafts, [ParameterSchema], entities)
);