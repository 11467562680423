import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
} from '../../../../paths';

import {
  createMiterFamilyForPipeFamilyTypes,
  copyMiterFamilyForPipeFamilyTypes,
  editMiterFamilyForPipeFamilyTypes,
  deleteMiterFamilyForPipeFamilyTypes,
} from '../../../../entities/MiterFamilies/constants';
import {
  selectNormalizedMiterFamilies
} from '../../../../entities/MiterFamilies/selectors';

import { PREPARE_MITER_MODAL } from './constants';

const generateWatchCreateMiterSaga = (id) =>
  function* () {
    yield takeLatest([
      createMiterFamilyForPipeFamilyTypes.SUCCESS,
      copyMiterFamilyForPipeFamilyTypes.SUCCESS,
    ], function* ({
      payload = {},
    }) {
      const familyId = `${get(payload, `${EK.MITERS.plural}[0].id`)}`;
      if (!!familyId && !!id) {
        let miters = yield select(selectNormalizedMiterFamilies());
        let copiedMiter = miters.get(familyId);

        while (!copiedMiter) {
          yield take();
          miters = yield select(selectNormalizedMiterFamilies());
          copiedMiter = miters.get(familyId);
        }

        yield put(
          push(PipingModulePipesMitersEditPath.generate({ id, familyId }))
        );

        yield put(hideModal());
      }
    });
  };

const generateWatchDeleteMiterSaga = (id) =>
  function* () {
    yield takeLatest(deleteMiterFamilyForPipeFamilyTypes.SUCCESS, function* () {
      yield put(push(PipingModulePipesMitersPath.generate({ id })));
    });
  };

export function* handleCloseMiterFamilyModal() {
  yield put(hideModal());
}

export function* watchCloseMiterModalSaga() {
  yield takeLatest(
    [
      createMiterFamilyForPipeFamilyTypes.SUCCESS,
      editMiterFamilyForPipeFamilyTypes.SUCCESS,
      deleteMiterFamilyForPipeFamilyTypes.SUCCESS,
    ],
    handleCloseMiterFamilyModal
  );
}

// final output saga
export default function* main() {
  const {
    payload: { id },
  } = yield take(PREPARE_MITER_MODAL);

  const watchCreateMiterSaga = generateWatchCreateMiterSaga(id);
  const watchDeleteMiterSaga = generateWatchDeleteMiterSaga(id);

  yield all([
    fork(watchCreateMiterSaga),
    fork(watchDeleteMiterSaga),
    fork(watchCloseMiterModalSaga),
  ]);
}
