import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleMaterialsPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData } from '../Dashboard/selectors';

import { selectCurrentEditedDraftMaterialsCount } from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectCurrentEditedDraftMaterialsCount(),
  (isLoadingInitialData, isFetching, count) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: PipingModuleMaterialsPath.defaultTitle,
    subtitle: `Showing ${count} ${pluralize('Material', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
