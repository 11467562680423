import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';

import EK from '../../../entities/keys';

import Flex from '../../common/Flex';
import BaseInput from '../../form/BaseInput';
import BaseAppend from '../../form/BaseAppend';
import EntitySearchSelect from '../../../containers/form/EntitySearchSelect';

// const KEY_ENTER = 13;
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

const isLeftOrRight = event => [37, 39].indexOf(event.keyCode) > -1;

const isDeleteOrBackspace = event => [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;

const TextEditor = React.forwardRef(({
  keyPress,
  charPress,
  value = {},
  allow,
  onDisallow,
  placeholder,
  defaultValue = null,
  ek = EK.UNITS,
  eFilter,
}, ref) => {
  const inputRef = useRef();
  const dropdownRef = useRef();

  const initialValue = useMemo(() => {
    let startValue = '';

    if (keyPress === KEY_BACKSPACE || keyPress === KEY_DELETE) {
      // if backspace or delete pressed, clear the cell
      startValue = '';
    } else if (charPress) {
      // if a letter was pressed, we start with the letter if it's allowed
      if (allow) {
        if (allow instanceof RegExp) {
          startValue = allow.test(charPress) ? charPress : (value.value || '');
        } else {
          startValue = allow(charPress) ? charPress : (value.value || '');
        }
      } else {
        startValue = charPress;
      }
    } else {
      // otherwise, we start with the current value
      startValue = value.value || '';
    }

    return { value: startValue, unit: value.unit && value.unit.id || value.unit };
  }, []);

  const [currentValue, setCurrentValue] = useState(initialValue);

  const highlightAllOnFocus = useMemo(() => !charPress && keyPress !== KEY_F2, []);

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      inputRef.current.focus();
      if (highlightAllOnFocus) {
        inputRef.current.select();
      } else {
        // when we started editing, we want the carot at the end, not the start.
        // this comes into play in two scenarios: a) when user hits F2 and b)
        // when user hits a printable character, then on IE (and only IE) the carot
        // was placed after the first character, thus 'apply' would end up as 'pplea'
        const length = inputRef.current.value ? inputRef.current.value.length : 0;
        if (length > 0) {
          inputRef.current.setSelectionRange(length, length);
        }
      }
    },
    getValue: () => ({
      value: currentValue.value === '' || currentValue.value === undefined ? defaultValue : currentValue.value,
      unit: currentValue.unit === '' || currentValue.unit === undefined ? null : currentValue.unit,
    })
  }));

  const onChange = useCallback(
    e => {
      e.persist();
      setCurrentValue(({ value, ...rest }) => ({
        ...rest,
        value: e.target.value,
      }));
    }, [setCurrentValue]
  );

  const onUnitChange = useCallback(
    newUnit => {
      setCurrentValue(({ unit, ...rest }) => ({
        ...rest,
        unit: newUnit,
      }));
    }, [setCurrentValue]
  );

  const onKeyDown = useCallback(
    e => {
      if (isLeftOrRight(e) || isDeleteOrBackspace(e)) {
        e.stopPropagation();
        return;
      }
    }
  );

  return (
    <Flex flexDirection='row' alignItems='center' height='100%' cursor='pointer' pl={3} pr={3}>
      <BaseInput
        ref={inputRef}
        value={currentValue.value}
        allow={allow}
        onDisallow={onDisallow}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
      />
      <BaseAppend width={150}>
        <EntitySearchSelect
          ref={dropdownRef}
          placeholder='--'
          width={150}
          value={currentValue.unit}
          ek={ek}
          eFilter={eFilter}
          onChange={onUnitChange}
          itemHeight={56}
          clearable
        />
      </BaseAppend>
    </Flex>
  );
});

export default TextEditor;
