import React from 'react';

import Flex from '../../common/Flex';
import Icon from '../../common/Icon';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

import BaseAppend from '../../form/BaseAppend';

const BasicCellRenderer = ({ 
  value = null, 
  valueFormatted = null,
  placeholder, 
  isCapitalized, 
  isDropdown, 
  append,
}) => {
  const isValueEmpty = (value === null || value === '') && (valueFormatted === null || valueFormatted === '');
  const isAppend = isDropdown || append;
  
  return (
    <BaseCell 
      flexDirection={isAppend ? 'row' : 'column'} 
      justifyContent={!isAppend && 'center' || undefined} 
      alignContent={isAppend && 'center' || undefined}
    >
      <Flex flexDirection='column' justifyContent='center' minWidth={0} width='100%'>
        <BaseCellDataItem primary={!isValueEmpty} disabled={isValueEmpty} isCapitalized={isCapitalized}>
          { isValueEmpty && placeholder || valueFormatted || value }
        </BaseCellDataItem>
      </Flex>
      {
        isAppend && (
          <BaseAppend isCapitalized={isCapitalized}>
            { isDropdown ? <Icon name='chevron-down' /> : append }
          </BaseAppend>
        )
      }
    </BaseCell>
  );
};

export default BasicCellRenderer;
