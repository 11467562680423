import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedMiterFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('miterFamilies')
);
export const selectNormalizedMiterFamiliesList = () => createSelector(
  selectNormalizedMiterFamilies(),
  miterFamilies => miterFamilies.toList()
);