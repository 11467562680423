import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedInsulationFamiliesList } from '../../entities/InsulationFamilies/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const insulationFilterForm = formValueSelector(`filters.${EK.INSULATIONS.state}`);

export const selectCurrentFilteredNormalizedDraftInsulations = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedInsulationFamiliesList(),
  state => insulationFilterForm(state, 'name', 'description'),
  (isFiltering, isShowingArchived, insulations, query) => isFiltering ? 
    insulations.filter(insulation => (isShowingArchived || !insulation.archivedFlag) && insulation.doesMatchQuery(query)) : 
    insulations.filter(insulation => !insulation.archivedFlag)
);