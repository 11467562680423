import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { updateInsulationSpecsCollection } from './actions';

import { selectCurrentDraftInsulationSpecsPendingValidChanges } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentDraftInsulationSpecsPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    pendingValidChanges,
  ) => ({
    ...rest,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    pendingValidChanges,
  }),
);

const enhance = compose(
  connect(
    mapStateToProps,
    { updateEntities: updateInsulationSpecsCollection },
  ),
);

export default enhance(Options);
