import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import { CREATE_SIZE_DRAFTS, EDIT_SIZE_DRAFTS, UPDATE_SIZES_COLLECTION } from './constants';

export const createSizeDrafts = generateCreateDraftsAction(CREATE_SIZE_DRAFTS);

export const editSizeDrafts = generateEditDraftsAction(EDIT_SIZE_DRAFTS);

export const updateSizesCollection = createAction(UPDATE_SIZES_COLLECTION);
