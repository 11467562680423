import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleSpecsFittingsEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentSpec } from '../SpecsEditSwitch/selectors';

import {
  selectCurrentSelectedSpecFittingFamiliesIds,
  selectNormalizedUnarchivedFittingFamilies,
  selectOrderedFilteredUnarchivedFittingFamilies,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentSpec(),
  selectCurrentSelectedSpecFittingFamiliesIds(),
  selectNormalizedUnarchivedFittingFamilies(),
  selectOrderedFilteredUnarchivedFittingFamilies(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    spec,
    selectedIds,
    data,
    filtered
  ) => ({
    isLoading:
      isLoadingInitialData || (isFetching && (!data || data.size === 0)),
    title:
      (spec &&
        spec.specName &&
        `${spec.specName} // ${PipingModuleSpecsFittingsEditPath.defaultTitle}`) ||
      'Loading...',
    subtitle: `${selectedIds.size} ${pluralize(
      'Selected Fitting',
      selectedIds.size
    )} ${isFiltering
      ? ` // Showing ${filtered.size}/${data.size} ${pluralize(
        'filtered Fitting',
        data.size
      )}`
      : ''
    }`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
