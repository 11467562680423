import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleInsulationsEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentInsulation,
  selectCurrentEditedDraftInsulationInstancesCount,
  selectCurrentFilteredDraftInsulationInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentInsulation(),
  selectCurrentEditedDraftInsulationInstancesCount(),
  selectCurrentFilteredDraftInsulationInstances(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    insulation,
    count,
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title:
      (insulation && insulation.name) ||
      PipingModuleInsulationsEditPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Insulation Instance',
        count
      )}`
      : `Showing ${count} ${pluralize('Insulation Instance', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
