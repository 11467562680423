import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../entities/keys';

import BaseFormRow from '../../components/form/BaseFormRow';

import FilterFormTemplate from '../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../containers/form/RDXTextInput';
import RDXSearchSelect from '../../containers/form/RDXSearchSelect';

const FilterForm = props => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='sizeProperty'
        label='Size'
        ek={EK.SIZES}
        clearable
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='schedule'
        label='Schedule'
        ek={EK.SCHEDULES}
        clearable
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='mccsCode'
        label='MCCS Code'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='bulkItemName'
        label='Bulk Item Name'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='boltType'
        label='Bolt Type'
        type='text'
      />
    </BaseFormRow>

    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='materialDescription'
        label='Material Description'
        type='text'
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.BOLT_NUT_INSTANCES.state}` })
);

export default enhance(FilterForm);
