import React from 'react';

import TooltipBody from '../../../../components/tooltip/TooltipBody';
import TooltipSection from '../../../../components/tooltip/TooltipSection';

import EntityTooltip from '../../../../components/tooltip/templates/EntityTooltip';

const ScheduleTooltip = ({ entity }) => {
  const restrict = entity.printableRestrictDeleteCounts || [];
  const warn = entity.printableWarnDeleteCounts || [];

  return (
    <TooltipBody>
      <TooltipSection main large>
        <EntityTooltip.Header>{ entity.value }</EntityTooltip.Header>
        <EntityTooltip.Description>{ entity.description }</EntityTooltip.Description>
      </TooltipSection>

      <TooltipSection large>
        { warn.length && <EntityTooltip.Counts counts={warn} /> || null }
        { restrict.length && <EntityTooltip.Counts counts={restrict} error /> || null }
      </TooltipSection>
    </TooltipBody>
  );
};
export default ScheduleTooltip;
