import React, { useMemo } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import {
  CROSS_SECTION_SHAPE_OPTIONS,
  LINE_TYPE_OPTIONS,
} from '../../../../entities/PipeFamilies/model';

import { MATERIAL_FILTERS } from '../../../../entities/Materials/model';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput, {
  FORMATS,
  NORMALIZATIONS,
} from '../../RDXTextInput';
import RDXSearchSelect from '../../RDXSearchSelect';
import RDXRadioSelect from '../../RDXRadioSelect';

import UnitSystemSchema from '../../../../entities/UnitSystems/schema';
import { UNIT_SYSTEM_FILTERS } from '../../../../entities/UnitSystems/model';
import { selectMasterCatalogUnitSystemId } from '../../../../entities/PipingCatalogs/selectors';
import { selectUnitSystems } from '../../../../entities/UnitSystems/selectors';

import { ALLOWANCES } from '../../../../entities/PipeFamilies/model';
import { validations } from '../../../../entities/PipeFamilies/formValidation';

const PipeForm = ({ handleSubmit, isCopying, isPerforming, valid, unitLength }) => {
  const formatLength = useMemo(() => FORMATS.DIMENSION(unitLength.id), [
    unitLength.id,
  ]);
  const normalizeLength = useMemo(
    () => NORMALIZATIONS.DIMENSION(unitLength.id),
    [unitLength.id]
  );

  return (
    <Box as="form" onSubmit={handleSubmit}>
      <BaseFormSection>
        <BaseFormRow>
          <Field
            component={RDXTextInput}
            name="name"
            label="Name"
            required
            allow={ALLOWANCES.NAME}
            validate={validations['name']}
          />
        </BaseFormRow>
        <BaseFormRow>
          {!isCopying && <Field
            component={RDXRadioSelect}
            name="crossSectionShape"
            label="Cross Section Shape"
            options={CROSS_SECTION_SHAPE_OPTIONS}
            required
            width="50%"
          />}
          <Field
            component={RDXRadioSelect}
            name="lineType"
            label="Line Type"
            options={LINE_TYPE_OPTIONS}
            required
            width={isCopying ? '100%' : '50%'}
          />
        </BaseFormRow>
        <BaseFormRow>
          <Field
            component={RDXSearchSelect}
            name="material"
            label="Material"
            ek={EK.MATERIALS}
            eFilter={MATERIAL_FILTERS.PIPE}
            required
            validate={validations['material']}
            width={isCopying ? '100%' : '50%'}
          />
          {!isCopying && <Field
            component={RDXSearchSelect}
            name="unitSystem"
            label="Unit System"
            ek={EK.UNIT_SYSTEMS}
            eFilter={UNIT_SYSTEM_FILTERS.DEFAULT}
            schema={UnitSystemSchema}
            width="50%"
          />}
        </BaseFormRow>
        {!isCopying && <BaseFormRow>
          <Field
            component={RDXTextInput}
            name="minLength"
            label="Min Segment Length"
            append={unitLength && unitLength.abbreviation}
            format={formatLength}
            normalize={normalizeLength}
            width="33.3333333333333333333333%"
          />
          <Field
            component={RDXTextInput}
            name="maxLength"
            label="Max Segment Length"
            append={unitLength && unitLength.abbreviation}
            format={formatLength}
            normalize={normalizeLength}
            width="33.3333333333333333333333%"
          />
          <Field
            component={RDXTextInput}
            name="maxOverallLength"
            label="Max Overall Length"
            append={unitLength && unitLength.abbreviation}
            format={formatLength}
            normalize={normalizeLength}
            width="33.3333333333333333333333%"
          />
        </BaseFormRow>}
        <BaseFormRow>
          <Field
            component={RDXTextInput}
            name="minBends"
            label="Minimum Number of Bends"
            width="50%"
          />
          <Field
            component={RDXTextInput}
            name="maxBends"
            label="Maximum Number of Bends"
            width="50%"
          />
        </BaseFormRow>
      </BaseFormSection>

      <Button
        primary
        type="submit"
        large
        full
        disabled={isPerforming || !valid}
        isPerformingAction={isPerforming}
      >
        Save
      </Button>
    </Box>
  );
};

const pipeFormSelector = formValueSelector(EK.PIPES.state);

const mapStateToProps = createSelector(
  (state, props) => props.initialValues,
  (state) => pipeFormSelector(state, 'unitSystem'),
  selectMasterCatalogUnitSystemId(),
  selectUnitSystems(),
  (initialValues, unitSystemId, defaultUnitSystemId, unitSystems) => {
    const currentUnitSystem =
      unitSystems.get(initialValues.get('unitSystem')) || unitSystems.get(defaultUnitSystemId);

    return {
      currentUnitSystem,
      unitLength: (!!currentUnitSystem && currentUnitSystem.unitLength) || null,
      initialValues: initialValues.set('unitSystem', currentUnitSystem.id),
    };
  }
);

const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: EK.PIPES.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(
        model.serialize({ unitSystem: props.currentUnitSystem })
      );
    },
  })
);

export default enhance(PipeForm);
