import React from 'react';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import pluralize from 'pluralize';

import EK from '../../entities/keys';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import {
  selectCurrentEditableSpecFamilySorting,
  selectCurrentSpec,
} from '../SpecsEditSwitch/selectors';
import { updateFamilySorting } from '../SpecsEditSwitch/actions';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { PipingModuleSpecsFittingInstancesEditPath } from '../../paths';

import {
  selectCurrentSelectedSpecFittingFamiliesIds,
  selectOrderedFilteredUnarchivedFittingFamilies,
} from './selectors';

import { toggleSpecFittingFamilies } from './actions';

const defaultEmptyList = List();

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentSpec(),
  selectCurrentEditableSpecFamilySorting(),
  selectOrderedFilteredUnarchivedFittingFamilies(),
  selectCurrentSelectedSpecFittingFamiliesIds(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    spec,
    currentEditableSpecFamilySorting,
    data,
    currentSelectedFamiliesIds,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    const currentSpecPrioritizedFittingFamilies =
      currentEditableSpecFamilySorting ||
      (spec && spec.prioritizedFittingFamilies) ||
      defaultEmptyList;
    return {
      ...rest,
      isLoading,
      isList: true,
      data: isLoading ? [] : data.map((fam) =>
        fam
          .set('isSelectedForId', currentSelectedFamiliesIds.has(fam.id))
          .set(
            'selectedFittingInstancesForSpec',
            currentSelectedFamiliesIds.get(fam.id, 0),
          )
          .set(
            'isSelectableForPriority',
            currentSelectedFamiliesIds.get(fam.id, 0) > 0,
          )
          .set(
            'isSelectedForPriority',
            currentSpecPrioritizedFittingFamilies.includes(fam.id),
          )
          .set(
            'prioritizationState',
            `${
              currentSelectedFamiliesIds.get(
                fam.id,
                0,
              )
            }${
              currentSpecPrioritizedFittingFamilies.includes(
                fam.id,
              )
            }`,
          )
      ),
      editable: canCollaborate &&
        hasValidLicense &&
        spec &&
        !spec.archivedFlag,
      currentSpecId: (spec && spec.id) || null,
      currentSpecPrioritizedFittingFamilies,
      isPrioritizedFamilySortingEnabled: !!currentEditableSpecFamilySorting,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({
    key: `${EK.SPEC_FITTING_INSTANCES.state}FamiliesEdit`,
    saga,
  }),
);

function SpecsFittingsEditGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    ...((reduxProps.editable && [
      ...((reduxProps.isPrioritizedFamilySortingEnabled && [
        {
          headerName: '',
          type: ['iconCell'],
          rowDrag: ({ data }) =>
            data.isSelectedForId && data.isSelectedForPriority,
          valueGetter: ({ data }) => data.name,
        },
        {
          headerName: '',
          field: 'prioritizationState',
          type: ['iconCell'],
          cellRendererParams: ({ data }) => ({
            name: data.isSelectableForPriority ? 'star' : null,
            color: data.isSelectedForPriority ? 'primary.4' : 'gray.4',
          }),
          cellStyle: ({ data }) =>
            (reduxProps.editable &&
              data.isSelectableForPriority && { cursor: 'pointer' }) ||
            {},
          onCellClicked: ({ data, context }) => {
            if (reduxProps.editable && data.isSelectableForPriority) {
              if (data.isSelectedForPriority) {
                dispatch(updateFamilySorting(
                  reduxProps.currentSpecPrioritizedFittingFamilies.filterNot(
                    (famId) => data.id === famId,
                  ),
                ));
              } else {
                dispatch(updateFamilySorting(
                  reduxProps.currentSpecPrioritizedFittingFamilies.push(
                    data.id,
                  ),
                ));
              }
            }
          },
        },
      ]) || [
        { type: ['entitySelectionForIdCell'] },
        {
          headerName: '',
          field: 'prioritizationState',
          type: ['iconCell'],
          cellRendererParams: ({ data }) => ({
            name: data.isSelectedForPriority ? 'star' : null,
            color: 'primary.4',
          }),
        },
      ]),
    ]) ||
      []),
    {
      headerName: 'Name // Instances',
      field: '',
      type: ['complexCell'],
      valueGetter: ({ data }) => {
        const count = (data.fittingInstances && data.fittingInstancesCount) || 0;
        const current = data.get(
          'selectedFittingInstancesForSpec',
          0,
        );
        return {
          primary: data.name,
          secondary: `${
            current > 0 ? `${current} selected / ` : ''
          }${count} ${pluralize('instance', count)}`,
        };
      },
    },
    {
      headerName: 'Fitting Code',
      field: 'fittingCode',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Fitting Code', secondary: '--' },
        ek: EK.FITTING_CODES,
      },
    },
    {
      headerName: 'Fitting Category',
      field: 'fittingCategory',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Fitting Category', secondary: '--' },
        ek: EK.FITTING_CATEGORIES,
      },
    },
    {
      headerName: '',
      field: '',
      type: ['iconCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        to: (reduxProps.currentSpecId &&
          data.id &&
          PipingModuleSpecsFittingInstancesEditPath.generate({
            id: reduxProps.currentSpecId,
            familyId: data.id,
          })) ||
          undefined,
      }),
      cellRendererParams: {
        name: 'list',
        color: 'gray.6',
      },
    },
  ];

  const onRowDragEnd = (event) => {
    const dragData = (event.node && event.node.data) || null;
    const data = (event.overNode && event.overNode.data) || null;
    if (
      data &&
      dragData &&
      dragData.isSelectableForPriority &&
      dragData.isSelectedForPriority
    ) {
      let cleaned = reduxProps.currentSpecPrioritizedFittingFamilies.filterNot(
        (famId) => famId === dragData.id,
      );

      dispatch(updateFamilySorting(
        cleaned.splice(
          cleaned.findIndex((famId) => famId === data.id),
          0,
          dragData.id,
        ),
      ));
    }
  };

  return (
    <Grid
      {...props}
      {...reduxProps}

      columnDefs={columnDefs}
      editInstances={(field, value) => dispatch(toggleSpecFittingFamilies(field, value))}
      onRowDragEnd={onRowDragEnd}
    />
  );
}

export default enhance(SpecsFittingsEditGrid);