import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import FittingCategoryModel from '../../entities/FittingCategories/model';
import FittingCategorySchema from '../../entities/FittingCategories/schema';
import { selectNormalizedFittingCategories, selectNormalizedDraftFittingCategories } from '../../entities/FittingCategories/selectors';

import {
  selectCurrentDraftFittingCategoryIds,
} from './selectors';

import { processSaveFittingCategories } from '../../entities/FittingCategories/actions';
import { saveFittingCategoriesTypes } from '../../entities/FittingCategories/constants';

import {
  CREATE_FITTING_CATEGORY_DRAFTS,
  EDIT_FITTING_CATEGORY_DRAFTS,
  UPDATE_FITTING_CATEGORIES_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.FITTING_CATEGORIES,
  schema: FittingCategorySchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedFittingCategories,
});

const moveSaga = generateMoveSaga({
  keys: EK.FITTING_CATEGORIES,
  model: FittingCategoryModel,
  originalNormalizedDataSelector: selectNormalizedFittingCategories, 
  currentIdsSetSelector: selectCurrentDraftFittingCategoryIds, 
});

const createSaga = generateWatchCreateSaga({
  keys: EK.FITTING_CATEGORIES,
  model: FittingCategoryModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftFittingCategoryIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.FITTING_CATEGORIES, 
  model: FittingCategoryModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftFittingCategories,
  currentIdsSetSelector: selectCurrentDraftFittingCategoryIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectNormalizedDraftFittingCategories,
  processUpdateAction: processSaveFittingCategories,
  processUpdateTypes: saveFittingCategoriesTypes,
  moveSaga,
});

export function* createDraftFittingCategoriessSaga() {
  yield takeEvery(CREATE_FITTING_CATEGORY_DRAFTS, createSaga);
}

export function* editDraftFittingCategoriessSaga() {
  yield takeEvery(EDIT_FITTING_CATEGORY_DRAFTS, editSaga);
}

export function* saveDraftFittingCategoriessSaga() {
  yield takeLatest(UPDATE_FITTING_CATEGORIES_COLLECTION, updateSaga);
}

export function* watchFittingCategoriessDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftFittingCategoriessSaga),
      fork(editDraftFittingCategoriessSaga),
      fork(saveDraftFittingCategoriessSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.FITTING_CATEGORIES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchFittingCategoriessDraftsSaga);
}
