import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  CREATE_BEND_MACHINE_INSTANCE_DRAFTS,
  EDIT_BEND_MACHINE_INSTANCE_DRAFTS,
  UPDATE_BEND_MACHINE_INSTANCES_COLLECTION,
} from './constants';

export const createBendMachineInstanceDrafts = generateCreateDraftsAction(CREATE_BEND_MACHINE_INSTANCE_DRAFTS);

export const editBendMachineInstanceDrafts = generateEditDraftsAction(EDIT_BEND_MACHINE_INSTANCE_DRAFTS);

export const updateBendMachineInstancesCollection = createAction(UPDATE_BEND_MACHINE_INSTANCES_COLLECTION, bendMachineFamilyId => ({ bendMachineFamilyId }));
