import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveBendSpringbackFamiliesForPipeFamily,
  processUnarchiveBendSpringbackFamiliesForPipeFamily,
} from '../../entities/BendSpringbackFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import {
  selectCurrentBendSpringback,
  selectCurrentDraftBendSpringbackInstancesPendingValidChanges,
} from './selectors';

import { updateBendSpringbackInstancesCollection } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentBendSpringback(),
  selectCurrentPipeId(),
  selectCurrentDraftBendSpringbackInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    bendSpringback,
    pipeId,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    bendSpringbackId: bendSpringback && bendSpringback.id || null,
    pipeId,
    isArchived: bendSpringback && bendSpringback.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  
  return (
    <Options 
      {...props}
      {...reduxProps}
      updateEntities = {() => dispatch(updateBendSpringbackInstancesCollection(reduxProps.bendSpringbackId))}
      editEntity = {() => dispatch(showModal('BEND_SPRINGBACK_MODAL', {pipeFamilyId: reduxProps.pipeId, bendSpringbackId: reduxProps.bendSpringbackId}))}
      archiveEntity = {() => dispatch(processArchiveBendSpringbackFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.bendSpringbackId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveBendSpringbackFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.bendSpringbackId]))}
    />
  );
}