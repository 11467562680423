import { toast } from 'react-toastify';

import bendSpringbackInstanceClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import { saveBendSpringbackInstancesTypes } from './constants';

export const processSaveBendSpringbackInstances = ({ familyId }, data) => bendSpringbackInstanceClient(REQUEST_TYPES.PATCH, 'plural', saveBendSpringbackInstancesTypes, `/piping/bend_springback_families/${familyId}?diff_result=true`, {
  data: { bendSpringbackInstancesAttributes: data },
  mutateResponse: ({ bendSpringbackFamily: { id } = {}, bendSpringbackInstances: { created = [], destroyed = [], updated = [] } = {} }) => ({
    bendSpringbackInstances: {
      created: created.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
      destroyed: destroyed.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
      updated: updated.map(instance => ({
        ...instance,
        bendSpringbackFamily: `${id}`,
        bendSpringbackFamilyId: `${id}`,
      })),
    }
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
