import { createSelector } from 'reselect';
import { Set } from 'immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectDrafts from '../../modules/drafts/selectors';

import { selectNormalizedDraftInsulationSpecs } from '../../entities/InsulationSpecs/selectors';

export const selectCurrentDraftInsulationSpecsPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.INSULATION_SPECS.state, 'saveable']).size > 0
);

export const selectCurrentDraftInsulationSpecIds = () => createSelector(
  selectNormalizedDraftInsulationSpecs(),
  drafts => drafts.reduce((ids, draft) => ids.add(draft.id), Set())
);

export const selectCurrentEditedDraftInsulationSpecsCount = () => createSelector(
  selectNormalizedDraftInsulationSpecs(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);