import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import { PipingModuleInsulationsEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchiveInsulationFamilies,
  processFetchAllInsulationFamilies,
  processUnarchiveInsulationFamilies,
} from '../../entities/InsulationFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredNormalizedDraftInsulations } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredNormalizedDraftInsulations(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function InsulationsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name // Description',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModuleInsulationsEditPath.generate({ id: data.id })) ||
          undefined,
      }),
      cellRendererParams: {
        placeholder: {
          primary: 'No Name',
          secondary: 'No Description',
        },
      },
    },
    reduxProps.editable &&
      {
        headerName: '',
        field: 'archivedFlag',
        type: ['dataActionIconCell'],
        cellRendererParams: ({ value }) => ({
          actions: (value &&
            List([
              {
                icon: 'list',
                onClick: (insulation) => dispatch(push(PipingModuleInsulationsEditPath.generate({ id: insulation.id }))),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (insulation) => dispatch(showModal('INSULATION_MODAL', {insulationId: insulation.id, copyEntity: true})),
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: (insulation) => dispatch(processUnarchiveInsulationFamilies([insulation.id])),
              },
            ])) ||
            List([
              {
                icon: 'edit',
                primary: true,
                onClick: (insulation) => dispatch(showModal('INSULATION_MODAL', { insulationId: insulation.id })),
              },
              {
                icon: 'list',
                onClick: (insulation) => dispatch(push(PipingModuleInsulationsEditPath.generate({ id: insulation.id }))),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (insulation) => dispatch(showModal('INSULATION_MODAL', {insulationId: insulation.id, copyEntity: true})),
              },
              {
                icon: 'archive',
                error: true,
                onClick: (insulation) => dispatch(processArchiveInsulationFamilies([insulation.id])),
              },
            ]),
        }),
      }
  ];

  useEffect( () => {
    dispatch(processFetchAllInsulationFamilies());
  }, []);
  return (
    <Grid
      {...props}
      {...reduxProps}

      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(InsulationsGrid);
