import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List, Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectMasterCatalogUnitSystem } from '../../entities/PipingCatalogs/selectors';
import UnitSystemSchema from '../../entities/UnitSystems/schema';

import { selectNormalizedSettings } from '../../entities/Settings/selectors';

import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';

import FittingInstanceSchema from '../../entities/FittingInstances/schema';
import {
  selectNormalizedFittingInstances,
  selectNormalizedDraftFittingInstances,
} from '../../entities/FittingInstances/selectors';

import { PipingModuleFittingsEditPath } from '../../paths';

export const selectCurrentFittingId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModuleFittingsEditPath.url,
    });
    return (match && match.params.id) || null;
  });

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.FITTINGS.state)
  );

export const selectCurrentFitting = () =>
  createSelector(
    selectCurrentFittingId(),
    selectNormalizedFittingFamilies(),
    (fittingId, fittings) => fittings.get(fittingId)
  );

export const selectCurrentBaseCreationParams = () =>
  createSelector(selectCurrentFitting(), (fitting) => ({
    fittingId: fitting.id,
    fittingCodeId: fitting.fittingCode,
  }));

export const selectOriginalNormalizedFittingInstancesForFamily = () =>
  createSelector(
    selectNormalizedFittingInstances(),
    selectCurrentFittingId(),
    (instances, fittingFamilyId) =>
      instances.filter(
        (instance) => instance.fittingFamilyId === fittingFamilyId
      )
  );

export const selectOriginalDenormalizedFittingInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedFittingInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [FittingInstanceSchema],
        entities.delete(EK.FITTINGS.state)
      )
  );

export const selectCurrentFittingUnitSystem = () =>
  createSelector(
    selectCurrentFitting(),
    selectEntities(),
    selectMasterCatalogUnitSystem(),
    (fitting, entities, defaultMasterCatalogUnitSystem) =>
      fitting &&
        fitting.unitSystem &&
        entities.hasIn([EK.UNIT_SYSTEMS.state, fitting.unitSystem])
        ? denormalize(
          entities.getIn([EK.UNIT_SYSTEMS.state, fitting.unitSystem]),
          UnitSystemSchema,
          entities
        )
        : defaultMasterCatalogUnitSystem
  );

export const selectCurrentFittingFamilyEndPortCountAndChangesSize = () =>
  createSelector(
    selectEntities(),
    selectCurrentFittingId(),
    (entities, fittingFamilyId) => {
      const fittingCode = entities.getIn([
        EK.FITTING_CODES.state,
        entities.getIn([EK.FITTINGS.state, fittingFamilyId, 'fittingCode']),
      ]);

      return {
        endCount: (fittingCode && fittingCode.endCount) || 1,
        changesSize: fittingCode && fittingCode.changesSize,
      };
    }
  );

const fittingInstanceFilterForm = formValueSelector(
  `filters.${EK.FITTING_INSTANCES.state}`
);

export const selectCurrentDraftFittingInstances = () =>
  createSelector(
    selectNormalizedDraftFittingInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [FittingInstanceSchema],
        entities.delete(EK.FITTINGS.state)
      )
  );

export const selectCurrentFilteredDraftFittingInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftFittingInstances(),
    selectEntities(),
    (state) =>
      fittingInstanceFilterForm(
        state,
        'schedule',
        'cadModelName',
        'port0Size',
        'port0EndType',
        'port1Size',
        'port1EndType',
        'port2Size',
        'port2EndType'
      ),
    (isFiltering, instances, entities, query) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) => instance.doesMatchQuery(query))
          : instances,
        [FittingInstanceSchema],
        entities.delete(EK.FITTINGS.state)
      )
  );

export const selectCurrentDraftFittingInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) => drafts.getIn([EK.FITTING_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftFittingInstanceIds = () =>
  createSelector(selectNormalizedDraftFittingInstances(), (drafts) =>
    drafts.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftFittingInstancesCount = () =>
  createSelector(
    selectNormalizedDraftFittingInstances(),
    (drafts) =>
      drafts.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
  );
