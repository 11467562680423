import { matchPath } from 'react-router-dom';
import { Set } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectNormalizedBoltNutFamilies } from '../../entities/BoltNutFamilies/selectors';

import BoltNutInstanceSchema from '../../entities/BoltNutInstances/schema';
import {
  selectNormalizedBoltNutInstances,
  selectNormalizedDraftBoltNutInstances,
} from '../../entities/BoltNutInstances/selectors';

import { PipingBoltNutsEditPath } from '../../paths';

export const selectCurrentBoltNutId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, { path: PipingBoltNutsEditPath.url });
    return (match && match.params.id) || null;
  });

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.BOLT_NUTS.state)
  );

export const selectCurrentBoltNut = () =>
  createSelector(
    selectCurrentBoltNutId(),
    selectNormalizedBoltNutFamilies(),
    (boltNutId, boltNuts) => boltNuts.get(boltNutId)
  );

export const selectOriginalNormalizedBoltNutInstancesForFamily = () =>
  createSelector(
    selectNormalizedBoltNutInstances(),
    selectCurrentBoltNutId(),
    (instances, boltNutFamilyId) =>
      instances.filter(
        (instance) => instance.boltNutFamilyId === boltNutFamilyId
      )
  );

export const selectOriginalDenormalizedBoltNutInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedBoltNutInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BoltNutInstanceSchema],
        entities.delete(EK.BOLT_NUTS.state)
      )
  );

const boltNutInstanceFilterForm = formValueSelector(
  `filters.${EK.BOLT_NUT_INSTANCES.state}`
);

export const selectCurrentDraftBoltNutInstances = () =>
  createSelector(
    selectNormalizedDraftBoltNutInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BoltNutInstanceSchema],
        entities.delete(EK.BOLT_NUTS.state)
      )
  );

export const selectCurrentFilteredDraftBoltNutInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftBoltNutInstances(),
    selectEntities(),
    (state) =>
      boltNutInstanceFilterForm(
        state,
        'sizeProperty',
        'schedule',
        'mccsCode',
        'bulkItemName',
        'boltType',
        'materialDescription'
      ),
    (isFiltering, instances, entities, query) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) => instance.doesMatchQuery(query))
          : instances,
        [BoltNutInstanceSchema],
        entities.delete(EK.BOLT_NUTS.state)
      )
  );

export const selectCurrentDraftBoltNutInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) => drafts.getIn([EK.BOLT_NUT_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftBoltNutInstanceIds = () =>
  createSelector(selectNormalizedDraftBoltNutInstances(), (instances) =>
    instances.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftBoltNutInstancesCount = () =>
  createSelector(
    selectNormalizedDraftBoltNutInstances(),
    (instances) =>
      instances.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT)
        .size
  );
