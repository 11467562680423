import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import { PipingModulePipesPath, PipingModulePipesEditPath } from '../../../../paths';

import {
  createPipeFamilyTypes,
  copyPipeFamilyTypes,
  editPipeFamilyTypes,
  deletePipeFamilyTypes,
} from '../../../../entities/PipeFamilies/constants';
import {
  selectNormalizedPipeFamilies
} from '../../../../entities/PipeFamilies/selectors';

export function* handleCreatePipeFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.PIPES.plural}[0].id`)}`;
  if (id) {
    let pipes = yield select(selectNormalizedPipeFamilies());
    let copiedPipe = pipes.get(id);

    while (!copiedPipe) {
      yield take();
      pipes = yield select(selectNormalizedPipeFamilies());
      copiedPipe = pipes.get(id);
    }

    yield put(push(PipingModulePipesEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeletePipeFamilySuccess() {
  yield put(push(PipingModulePipesPath.url));
}

export function* watchCreatePipeSaga() {
  yield takeLatest([
    createPipeFamilyTypes.SUCCESS,
    copyPipeFamilyTypes.SUCCESS,
  ], handleCreatePipeFamilySuccess
  );
}

export function* watchDeletePipeSaga() {
  yield takeLatest(
    deletePipeFamilyTypes.SUCCESS,
    handleDeletePipeFamilySuccess
  );
}

export function* handleClosePipeFamilyModal() {
  yield put(hideModal());
}

export function* watchClosePipeModalSaga() {
  yield takeLatest(
    [
      createPipeFamilyTypes.SUCCESS,
      editPipeFamilyTypes.SUCCESS,
      deletePipeFamilyTypes.SUCCESS,
    ],
    handleClosePipeFamilyModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreatePipeSaga),
    fork(watchDeletePipeSaga),
    fork(watchClosePipeModalSaga),
  ]);
}
