import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedNoteProfilesList } from '../../entities/NoteProfiles/selectors';
import {
  processArchiveNoteProfiles,
  processUnarchiveNoteProfiles,
} from '../../entities/NoteProfiles/actions';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../Dashboard/selectors';

import { NoteProfilesEditPath } from '../../paths';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedNoteProfilesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const mapDispatchToProps = (dispatch) => ({
  createEntity() {
    dispatch(showModal('NOTE_PROFILE_MODAL'));
  },

  editNoteProfile(noteProfile) {
    dispatch(
      showModal('NOTE_PROFILE_MODAL', { noteProfileId: noteProfile.id }),
    );
  },
  copyNoteProfile(noteProfile) {
    dispatch(
      showModal('NOTE_PROFILE_MODAL', {
        noteProfileId: noteProfile.id,
        copyEntity: true,
      }),
    );
  },
  manageNotesForNoteProfile(noteProfile) {
    dispatch(push(NoteProfilesEditPath.generate({ id: noteProfile.id })));
  },
  archiveNoteProfile(profile) {
    dispatch(processArchiveNoteProfiles([profile.id]));
  },
  unarchiveNoteProfile(profile) {
    dispatch(processUnarchiveNoteProfiles([profile.id]));
  },
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    (
      { data, showArchived, editable, isList },
      dispatchProps,
    ) => ({
      data,
      isList,
      columnDefs: [
        {
          type: ['archivedStatusCell'],
          hide: !showArchived,
        },
        {
          headerName: 'Name // Notes',
          field: 'name',
          type: ['complexCell', 'linkCell'],
          valueGetter: ({ data }) => ({
            ...data.summary,
            to: data.id && NoteProfilesEditPath.generate({ id: data.id }) ||
              undefined,
          }),
        },
        ...(editable && [{
          type: ['dataActionIconCell'],
          cellRendererParams: ({ value }) => ({
            actions: value && List([
              {
                icon: 'list',
                onClick: dispatchProps.manageNotesForNoteProfile,
              },
              {
                icon: 'copy',
                primary: true,
                onClick: dispatchProps.copyNoteProfile,
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: dispatchProps.unarchiveNoteProfile,
              },
            ]) || List([
              {
                icon: 'edit',
                primary: true,
                onClick: dispatchProps.editNoteProfile,
              },
              {
                icon: 'list',
                onClick: dispatchProps.manageNotesForNoteProfile,
              },
              {
                icon: 'copy',
                primary: true,
                onClick: dispatchProps.copyNoteProfile,
              },
              {
                icon: 'archive',
                error: true,
                onClick: dispatchProps.archiveNoteProfile,
              },
            ]),
          }),
        }] || []),
      ],
    }),
  ),
);

export default enhance(Grid);
