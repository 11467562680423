import React from 'react';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleFittingsEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentFitting,
  selectCurrentEditedDraftFittingInstancesCount,
  selectCurrentFilteredDraftFittingInstances,
} from './selectors';
import { useSelector } from 'react-redux';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentFitting(),
  selectCurrentEditedDraftFittingInstancesCount(),
  selectCurrentFilteredDraftFittingInstances(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    fitting,
    count,
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title:
      (fitting && fitting.name) || PipingModuleFittingsEditPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Fitting Instance',
        count
      )}`
      : `Showing ${count} ${pluralize('Fitting Instance', count)} Total`,
  })
);

export default function FittingsHeader (props) {

  const {isLoading, title, subtitle } = useSelector(mapStateToProps);

  return (
    <Header
      isLoading={isLoading}
      title={title}
      subtitle={subtitle}
    />
  );
}
