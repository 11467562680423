import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Flex from '../../common/Flex';
import BaseInput from '../../form/BaseInput';

import useFloatingState from '../../../hooks/useFloatingState';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';

// const KEY_ENTER = 13;
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

const isLeftOrRight = event => [37, 39].indexOf(event.keyCode) > -1;

const isDeleteOrBackspace = event => [KEY_DELETE, KEY_BACKSPACE].indexOf(event.keyCode) > -1;

const TextEditor = React.forwardRef(({
  keyPress,
  charPress,
  value = '',
  allow,
  onDisallow,
  placeholder,
  append,
  textDropdownComponent : TextDropdownComponent,
  defaultValue = null
}, ref) => {
  const inputRef = useRef();

  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom' });

  const initialValue = useMemo(() => {
    let startValue = '';

    if (keyPress === KEY_BACKSPACE || keyPress === KEY_DELETE) {
      // if backspace or delete pressed, clear the cell
      startValue = '';
    } else if (charPress) {
      // if a letter was pressed, we start with the letter if it's allowed
      if (allow) {
        if (allow instanceof RegExp) {
          startValue = allow.test(charPress) ? charPress : (value || '');
        } else {
          startValue = allow(charPress) ? charPress : (value || '');
        }
      } else {
        startValue = charPress;
      }
    } else {
      // otherwise, we start with the current value
      startValue = value || '';
    }

    return startValue;
  }, []);

  const [currentValue, setCurrentValue] = useState(initialValue);

  const highlightAllOnFocus = useMemo(() => !charPress && keyPress !== KEY_F2, []);

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      inputRef.current.focus();
      if (highlightAllOnFocus) {
        inputRef.current.select();
      } else {
        // when we started editing, we want the carot at the end, not the start.
        // this comes into play in two scenarios: a) when user hits F2 and b)
        // when user hits a printable character, then on IE (and only IE) the carot
        // was placed after the first character, thus 'apply' would end up as 'pplea'
        const length = inputRef.current.value ? inputRef.current.value.length : 0;
        if (length > 0) {
          inputRef.current.setSelectionRange(length, length);
        }
      }
    },
    getValue: () => currentValue === '' || currentValue === undefined ? defaultValue : currentValue,
  }));

  const onChange = useCallback(
    e => {
      setCurrentValue(e.target.value);
    }, [setCurrentValue]
  );

  const onKeyDown = useCallback(
    e => {
      if (isLeftOrRight(e) || isDeleteOrBackspace(e)) {
        e.stopPropagation();
        return;
      }
    }
  );

  return (
    <Flex ref={reference} flexDirection='column' justifyContent='center' height='100%' cursor='pointer' pl={3} pr={3}>
      <BaseInput
        ref={inputRef}
        value={currentValue}
        allow={allow}
        onDisallow={onDisallow}
        onChange={onChange}
        onKeyDown={onKeyDown}
        append={append}
        placeholder={placeholder}
      />
      {
        TextDropdownComponent && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='fluid'>
            <TextDropdownComponent setCurrentValue={setCurrentValue} currentValue={currentValue} />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </Flex>
  );
});

export default TextEditor;
