import React, { useCallback, useRef } from 'react';

import Flex from '../../../components/common/Flex';
import Icon from '../../../components/common/Icon';
import Text from '../../../components/common/Text';

import BaseFormCell from '../../../components/form/BaseFormCell';

const RDXCheckboxInput = ({
  width,
  height = '100%',
  bg,
  label,
  input,
  defaultValue,
  toggleComponent: ToggleComponent,
  meta,
  disabled,
}) => {
  const ref = useRef();
  const onCellClick = useCallback(() => { ref.current.click(); }, []);
  const usableValue = ((input.value === '' || input.value === null) && defaultValue !== undefined) ? defaultValue : input.value;

  return (
    <BaseFormCell width={width} height={height} bg={bg} justifyContent='center' alignItems='flex-start' onClick={onCellClick} cursor='pointer'>
      <Flex flexDirection='row' alignItems='center' justifyContent='center'>
        <input
          ref={ref}
          type="checkbox"
          disabled={disabled}
          {...input}
          checked={usableValue}
          tabIndex="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
        {
          ToggleComponent ?
            <ToggleComponent checked={usableValue} /> :
            <Icon name={usableValue ? 'checkbox' : 'checkbox-outline'} color={usableValue ? 'primary.4' : 'gray.6'} fontSize={3} />
        }
        { label && <Text color='gray.7' fontWeight='400' ml={3}>{ label }</Text> }
      </Flex>
    </BaseFormCell>
  );
};

export default RDXCheckboxInput;
