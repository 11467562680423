import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import { PipingModulePipesBendSpringbacksEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchiveBendSpringbackFamiliesForPipeFamily,
  processFetchAllBendSpringbackFamiliesForPipeFamily,
  processUnarchiveBendSpringbackFamiliesForPipeFamily,
} from '../../entities/BendSpringbackFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import { selectCurrentFilteredPipesBendSpringbacks } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredPipesBendSpringbacks(),
  selectCurrentPipeId(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
    pipeId,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      pipeId,
    };
  },
);

function PipesBendSpringbacksGrid(ownProps) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs =[
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name // Instances',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModulePipesBendSpringbacksEditPath.generate({
            id: reduxProps.pipeId,
            familyId: data.id,
          })) ||
          undefined,
      }),
    },
    reduxProps.editable &&
      {
        type: ['dataActionIconCell'],
        cellRendererParams: ({ value }) => ({
          actions: (value &&
            List([
              {
                icon: 'list',
                onClick: (bendSpringback) => dispatch(push(PipingModulePipesBendSpringbacksEditPath.generate({id: ownProps.match.params.id,familyId: bendSpringback.id,}),),),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (bendSpringback) => dispatch(showModal('BEND_SPRINGBACK_MODAL', {pipeFamilyId: ownProps.match.params.id,bendSpringbackId: bendSpringback.id,copyEntity: true,}),),
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: (bendSpringback) => dispatch(processUnarchiveBendSpringbackFamiliesForPipeFamily(ownProps.match.params.id,[bendSpringback.id],),),
              },
            ])) ||
            List([
              {
                icon: 'edit',
                primary: true,
                onClick: (bendSpringback) => dispatch(showModal('BEND_SPRINGBACK_MODAL', {pipeFamilyId: ownProps.match.params.id,bendSpringbackId: bendSpringback.id,}),),
              },
              {
                icon: 'list',
                onClick: (bendSpringback) => dispatch(push(PipingModulePipesBendSpringbacksEditPath.generate({id: ownProps.match.params.id,familyId: bendSpringback.id,}),),),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (bendSpringback) => dispatch(showModal('BEND_SPRINGBACK_MODAL', {pipeFamilyId: ownProps.match.params.id,bendSpringbackId: bendSpringback.id,copyEntity: true,}),),
              },
              {
                icon: 'archive',
                error: true,
                onClick: (bendSpringback) => dispatch(processArchiveBendSpringbackFamiliesForPipeFamily(ownProps.match.params.id,[bendSpringback.id],),),
              },
            ]),
        }),
      }
  ];

  useEffect( () => {
    dispatch(processFetchAllBendSpringbackFamiliesForPipeFamily(ownProps.match.params.id));
  }, []);

  return (
    <Grid
      {...ownProps}
      {...reduxProps}

      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(PipesBendSpringbacksGrid);
