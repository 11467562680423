/// file for all (or at least most) indirect actions.
/// For Example, the actions for editing items in the expanded rows

import { processSaveEndTypes } from '../../../entities/EndTypes/actions';
import {
  processCreateEndTypeCompatibilities,
  processUpdateEndTypeCompatibility,
  processRemoveEndTypeCompatibility
} from '../../../entities/EndTypeCompatibilities/actions';


export function saveAllEndTypeChanges (dispatch, endTypeChanges, compatibilityChanges, resetRows) {
  let collectionsdata = [];

  endTypeChanges.created.length > 0 && endTypeChanges.created.forEach(item => {
    collectionsdata.push({ name: item.name, code: item.code });
  });
  endTypeChanges.edited.length > 0 && endTypeChanges.edited.forEach(item => collectionsdata.push({...item}));
  endTypeChanges.deleted.length > 0 && endTypeChanges.deleted.forEach(item => collectionsdata.push({ ...item, _destroy: '1' }));

  collectionsdata.length > 0 && dispatch(processSaveEndTypes(collectionsdata));

  collectionsdata = [];
  compatibilityChanges.created.forEach(item => {
    if (item.secondEndType) {
      collectionsdata.push({
        firstEndTypeId: item.firstEndTypeId,
        secondEndTypeId: item.secondEndTypeId,
        firstScheduleId: item.firstScheduleId,
        secondScheduleId: item.secondScheduleId
      });
    }
  });

  compatibilityChanges.created.length > 0 && dispatch(processCreateEndTypeCompatibilities(collectionsdata));
  compatibilityChanges.edited.length > 0 && compatibilityChanges.edited.forEach(item => dispatch(processUpdateEndTypeCompatibility(item.id, item)));
  compatibilityChanges.deleted.length > 0 && compatibilityChanges.deleted.forEach(item => dispatch(processRemoveEndTypeCompatibility(item.id)));

  // resets all of the changes to none, the rest is handled in redux
  resetRows();
}

export const removeItemFromCompatChanges = (originalItem, rows, setter) => {
  let _changes = { ...rows };
  //the change will only ever be in one of the 'edited', 'created', or 'deleted' arrays
  let editChangeItem = _changes.edited.findIndex(item => item.id == originalItem.id);
  if (editChangeItem != -1) {
    _changes.edited.splice(editChangeItem, 1);
    setter(_changes);
    return;
  }

  editChangeItem = _changes.created.findIndex(item => item.id == originalItem.id);
  if (editChangeItem != -1) {
    _changes.created.splice(editChangeItem, 1);
    setter(_changes);
    return;
  }

  editChangeItem = _changes.deleted.findIndex(item => item.id == originalItem.id);
  if (editChangeItem != -1) {
    _changes.deleted.splice(editChangeItem, 1);
    setter(_changes);
    return;
  }
};

// called when a value of an End Type Compatibility changes
export const handleCompatibilityChange = (
  e,
  originalRow,
  field,
  editedCompatRows,
  compatChanges,
  newCompatRows,
  setEditedCompatRows,
  setCompatChanges,
  canEdit,
  removeItemFromChanges,
) => {
  // create an updated version of the object
  let _editedRow = { ...originalRow };
  _editedRow[field] = e.value;

  if (canEdit) {
    // user does not have permission to edit ( 'user' level permissions or invalid license )
    // or row is marked for deletion
    return;
  }

  // editable copies of state variables that track changes
  let _rows = [...editedCompatRows];
  let _changes = { ...compatChanges };

  // if the item is the same as the original item, remove it from the rows
  if (JSON.stringify({ ..._editedRow, isEdited: false, isDeleted: false, isCreated: false }) === JSON.stringify(originalRow)) {
    // find the index of the row for the slice method
    let rowsItemIndex = _rows && _rows.findIndex(item => item.id == originalRow.id);
    if (rowsItemIndex != -1) {
      // if it is found, run the splices
      _rows.splice(rowsItemIndex, 1);
      removeItemFromChanges(originalRow);
      setEditedCompatRows(_rows);
      return;
    }
    // if it wasn't found, it is not in the changes, nothing needs to be done
    return;
  }

  // if it is a new item, add it to rows and changes
  if (originalRow.id.includes('NEW_ROW_')) {
    // try to find the correct row
    let prevRow = _rows.find(item => item.id == originalRow.id);
    let changeRow = _changes.created.find(item => item.id == originalRow.id);
    if (prevRow) { // if it was found, the row has already been created/edited
      prevRow[field] = _editedRow[field];
      changeRow[field] = _editedRow[field];
    } else { // If it was not found, the row needs to be added to the 'created rows' and edited rows lists
      _editedRow.isEdited = true;
      _editedRow.isCreated = true;
      _rows.push(_editedRow);
      _changes.created.push(_editedRow);
    }
    setEditedCompatRows(_rows);
    setCompatChanges(_changes);
    return;
  }

  // if it is an existing item, add it to rows and changes
  // search for the correct row in the edited rows
  let currentRowItem = _rows && _rows.find(item => item.id == originalRow.id);
  let changesItem = _changes.edited && _changes.edited.find(item => item.id == originalRow.id);
  if (currentRowItem) {
    //if the item is already in the edited rows and changes, edit it
    currentRowItem[field] = _editedRow[field];
    changesItem[field] = _editedRow[field];
  } else {
    //if it is not, then add it to them
    _editedRow.isEdited = true;
    _rows.push(_editedRow);
    _changes.edited.push(_editedRow);
  }
  setEditedCompatRows(_rows);
  setCompatChanges(_changes);
};