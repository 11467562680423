import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedModelTemplates = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get('modelTemplates')
  );
export const selectNormalizedModelTemplatesList = () =>
  createSelector(
    selectNormalizedModelTemplates(),
    (modelTemplates) => modelTemplates.toList()
  );
