import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import {
  ALLOWANCES,
  SECTION_METHOD_TYPE_OPTIONS,
  SECTION_METHOD_TYPES,
  SECTION_METHOD_TYPES_FORMATTED,
} from '../../entities/Sections/model';

import Grid from '../../containers/grid/Grid';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { createSectionDrafts, editSectionDrafts } from './actions';

import { selectCurrentFilteredNormalizedDraftSections } from './selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredNormalizedDraftSections(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.SECTIONS.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editSectionDrafts,
      createInstances: createSectionDrafts,
      copyInstances: createSectionDrafts,
    },
    ({ data, editable }, dispatchProps) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Name',
          field: 'name',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Name',
          },
          cellEditorParams: {
            allow: ALLOWANCES.NAME,
          },
        },
        {
          headerName: 'Section Method',
          field: 'sectionMethod',
          editable,
          type: ['basicCell', 'radioEditor'],
          valueFormatter: ({ value }) =>
            SECTION_METHOD_TYPES_FORMATTED[value] || value,
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setSectionMethod(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: 'Select Section Method',
            isCapitalized: true,
          },
          cellEditorParams: {
            isCapitalized: true,
            options: SECTION_METHOD_TYPE_OPTIONS,
          },
        },
        {
          headerName: 'Datum Feature Name',
          field: 'datumFeatureName',
          editable: ({ data }) =>
            editable && data.sectionMethod === SECTION_METHOD_TYPES.DATUM_PLANE,
          suppressNavigable: ({ data }) =>
            data.sectionMethod !== SECTION_METHOD_TYPES.DATUM_PLANE,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Datum Feature Name',
          },
          cellEditorParams: {
            allow: ALLOWANCES.DATUM_FEATURE_NAME,
          },
        },
        {
          headerName: 'Offset Distance',
          field: 'offsetDistance',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: '-',
          },
          cellEditorParams: {
            allow: ALLOWANCES.OFFSET_DISTANCE,
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Description',
          },
        },
      ],
      ...((editable && dispatchProps) || {}),
    }),
  ),
);

export default enhance(Grid);
