import React from 'react';

import Icon from '../../components/common/Icon';

import {
  DashboardPath,
  PipingModulePath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleEndTypesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleMaterialsPath,
  PipingModuleBoltNutsPath,
  PipingBoltNutsEditPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsEditPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  PipingModuleInsulationSpecsPath,
  SynchronizeModuleAppearancesPath,
  SynchronizeModuleConfigProfilesPath,
  StandardizeModulePath,
  StandardizeModuleNoteProfilesPath,
  NoteProfilesEditPath,
  StandardizeModuleNotesPath,
  StandardizeModuleParameterProfilesPath,
  ParameterProfilesEditPath,
  StandardizeModuleParametersPath,
  StandardizeModuleModelTemplatesPath,
  SynchronizeModulePath,
  FrameworkModulePath,
  FrameworkModuleProfileLibrariesPath,
  FrameworkModuleProfileLibrariesBasePath,
  FrameworkModuleProfilePath,
  FrameworkModuleTablePath,
  FrameworkModuleProfileTablesPath,
} from '../../paths';

import PipingModuleBreadcrumb from '../PipingModule/Breadcrumb';
import SchedulesBreadcrumb from '../Schedules/Breadcrumb';
import SizesBreadcrumb from '../Sizes/Breadcrumb';
import EndTypesBreadcrumb from '../Piping/EndTypes/Breadcrumb';
import FittingCategoriesBreadcrumb from '../FittingCategories/Breadcrumb';
import MaterialsBreadcrumb from '../Materials/Breadcrumb';
import BoltNutsBreadcrumb from '../BoltNuts/Breadcrumb';
import BoltNutsEditBreadcrumb from '../BoltNutsEdit/Breadcrumb';
import InsulationsBreadcrumb from '../Insulations/Breadcrumb';
import InsulationsEditBreadcrumb from '../InsulationsEdit/Breadcrumb';
import InsulationSpecsBreadcrumb from '../InsulationSpecs/Breadcrumb';
import FittingsBreadcrumb from '../Fittings/Breadcrumb';
import FittingsEditBreadcrumb from '../FittingsEdit/Breadcrumb';
import PipesBreadcrumb from '../Pipes/Breadcrumb';
import PipesEditBreadcrumb from '../PipesEdit/Breadcrumb';
import PipesBendsBreadcrumb from '../PipesBends/Breadcrumb';
import PipesBendsEditBreadcrumb from '../PipesBendsEdit/Breadcrumb';
import PipesBendMachinesBreadcrumb from '../PipesBendMachines/Breadcrumb';
import PipesBendMachinesEditBreadcrumb from '../PipesBendMachinesEdit/Breadcrumb';
import PipesBendSpringbacksBreadcrumb from '../PipesBendSpringbacks/Breadcrumb';
import PipesBendSpringbacksEditBreadcrumb from '../PipesBendSpringbacksEdit/Breadcrumb';
import PipesMitersBreadcrumb from '../PipesMiters/Breadcrumb';
import PipesMitersEditBreadcrumb from '../PipesMitersEdit/Breadcrumb';
import SpecsBreadcrumb from '../Specs/Breadcrumb';
import SpecsEditBreadcrumb from '../SpecsEditSwitch/Breadcrumb';
import SpecsFittingsEditBreadcrumb from '../SpecsFittingsEdit/Breadcrumb';
import SpecsFittingInstancesEditBreadcrumb from '../SpecsFittingInstancesEdit/Breadcrumb';
import SpecsPipesEditBreadcrumb from '../SpecsPipesEdit/Breadcrumb';
import SpecsPipeInstancesEditBreadcrumb from '../SpecsPipeInstancesEdit/Breadcrumb';
import ProjectsBreadcrumb from '../Projects/Breadcrumb';
import ProjectsEditBreadcrumb from '../ProjectsEdit/Breadcrumb';

import SynchronizeModuleBreadcrumb from '../SynchronizeModule/Breadcrumb';
import AppearancesBreadcrumb from '../Synchronize/Appearances/Breadcrumb';
import ConfigProfilesBreadcrumb from '../Synchronize/ConfigProfiles/Breadcrumb';

import StandardizeModuleBreadcrumb from '../StandardizeModule/Breadcrumb';
import NoteProfilesBreadcrumb from '../NoteProfiles/Breadcrumb';
import NoteProfilesEditBreadcrumb from '../NoteProfilesEdit/Breadcrumb';
import NotesBreadcrumb from '../Notes/Breadcrumb';
import ParameterProfilesBreadcrumb from '../ParameterProfiles/Breadcrumb';
import ParameterProfilesEditBreadcrumb from '../ParameterProfilesEdit/Breadcrumb';
import ParametersBreadcrumb from '../Parameters/Breadcrumb';
import ModelTemplatesBreadcrumb from '../ModelTemplates/Breadcrumb';

import FrameworkModuleBreadcrumb from '../Framework/Base/Breadcrumb';
import FrameworkProfileLibrariesBreadcrumb, { BaseLibraryBreadcrumb } from '../Framework/ProfileLibraries/Breadcrumb';
import FrameworkProfileBreadcrumb from '../Framework/Profile/Breadcrumb';
import FrameworkTablesBreadcrumb from '../Framework/ProfileTables/Breadcrumb';
import FrameworkTableBreadcrumb from '../Framework/Table/Breadcrumb';

export default [
  {
    path: DashboardPath.url,
    breadcrumb: () => <Icon name="home" />,
    routes: [
      {
        path: PipingModulePath.url,
        breadcrumb: PipingModuleBreadcrumb,
        routes: [
          {
            path: PipingModuleBoltNutsPath.url,
            breadcrumb: BoltNutsBreadcrumb,
            routes: [
              {
                path: PipingBoltNutsEditPath.url,
                breadcrumb: BoltNutsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModuleInsulationsPath.url,
            breadcrumb: InsulationsBreadcrumb,
            routes: [
              {
                path: PipingModuleInsulationsEditPath.url,
                breadcrumb: InsulationsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModuleFittingsPath.url,
            breadcrumb: FittingsBreadcrumb,
            routes: [
              {
                path: PipingModuleFittingsEditPath.url,
                breadcrumb: FittingsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModulePipesPath.url,
            breadcrumb: PipesBreadcrumb,
            routes: [
              {
                path: PipingModulePipesEditPath.url,
                breadcrumb: PipesEditBreadcrumb,
                routes: [
                  {
                    path: PipingModulePipesBendsPath.url,
                    breadcrumb: PipesBendsBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesBendsEditPath.url,
                        breadcrumb: PipesBendsEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModulePipesBendMachinesPath.url,
                    breadcrumb: PipesBendMachinesBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesBendMachinesEditPath.url,
                        breadcrumb: PipesBendMachinesEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModulePipesBendSpringbacksPath.url,
                    breadcrumb: PipesBendSpringbacksBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesBendSpringbacksEditPath.url,
                        breadcrumb: PipesBendSpringbacksEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModulePipesMitersPath.url,
                    breadcrumb: PipesMitersBreadcrumb,
                    routes: [
                      {
                        path: PipingModulePipesMitersEditPath.url,
                        breadcrumb: PipesMitersEditBreadcrumb,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PipingModuleSpecsPath.url,
            breadcrumb: SpecsBreadcrumb,
            routes: [
              {
                path: PipingModuleSpecsEditPath.url,
                breadcrumb: SpecsEditBreadcrumb,
                routes: [
                  {
                    path: PipingModuleSpecsFittingsEditPath.url,
                    breadcrumb: SpecsFittingsEditBreadcrumb,
                    routes: [
                      {
                        path:
                          PipingModuleSpecsFittingInstancesEditPath.url,
                        breadcrumb: SpecsFittingInstancesEditBreadcrumb,
                      },
                    ],
                  },
                  {
                    path: PipingModuleSpecsPipesEditPath.url,
                    breadcrumb: SpecsPipesEditBreadcrumb,
                    routes: [
                      {
                        path: PipingModuleSpecsPipeInstancesEditPath.url,
                        breadcrumb: SpecsPipeInstancesEditBreadcrumb,
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            path: PipingModuleProjectsPath.url,
            breadcrumb: ProjectsBreadcrumb,
            routes: [
              {
                path: PipingModuleProjectsEditPath.url,
                breadcrumb: ProjectsEditBreadcrumb,
              },
            ],
          },
          {
            path: PipingModuleSchedulesPath.url,
            breadcrumb: SchedulesBreadcrumb,
          },
          {
            path: PipingModuleSizesPath.url,
            breadcrumb: SizesBreadcrumb,
          },
          {
            path: PipingModuleEndTypesPath.url,
            breadcrumb: EndTypesBreadcrumb,
          },
          {
            path: PipingModuleFittingCategoriesPath.url,
            breadcrumb: FittingCategoriesBreadcrumb,
          },
          {
            path: PipingModuleMaterialsPath.url,
            breadcrumb: MaterialsBreadcrumb,
          },
          {
            path: PipingModuleInsulationSpecsPath.url,
            breadcrumb: InsulationSpecsBreadcrumb,
          },
        ],
      },
      {
        path: SynchronizeModulePath.url,
        breadcrumb: SynchronizeModuleBreadcrumb,
        routes: [
          {
            path: SynchronizeModuleAppearancesPath.url,
            breadcrumb: AppearancesBreadcrumb,
          },
          {
            path: SynchronizeModuleConfigProfilesPath.url,
            breadcrumb: ConfigProfilesBreadcrumb,
          },
        ],
      },
      {
        path: StandardizeModulePath.url,
        breadcrumb: StandardizeModuleBreadcrumb,
        routes: [
          {
            path: StandardizeModuleParameterProfilesPath.url,
            breadcrumb: ParameterProfilesBreadcrumb,
            routes: [
              {
                path: ParameterProfilesEditPath.url,
                breadcrumb: ParameterProfilesEditBreadcrumb,
              },
            ],
          },
          {
            path: StandardizeModuleNoteProfilesPath.url,
            breadcrumb: NoteProfilesBreadcrumb,
            routes: [
              {
                path: NoteProfilesEditPath.url,
                breadcrumb: NoteProfilesEditBreadcrumb,
              },
            ],
          },
          {
            path: StandardizeModuleNotesPath.url,
            breadcrumb: NotesBreadcrumb,
          },
          {
            path: StandardizeModuleParametersPath.url,
            breadcrumb: ParametersBreadcrumb,
          },
          {
            path: StandardizeModuleModelTemplatesPath.url,
            breadcrumb: ModelTemplatesBreadcrumb,
          },
        ],
      },
      {
        path: FrameworkModulePath.url,
        breadcrumb: FrameworkModuleBreadcrumb,
        routes: [
          {
            path: FrameworkModuleProfileLibrariesPath.url,
            breadcrumb: FrameworkProfileLibrariesBreadcrumb,
            routes: [
              {
                path: FrameworkModuleProfileLibrariesBasePath.url,
                breadcrumb: BaseLibraryBreadcrumb,
                routes: [
                  {
                    path: FrameworkModuleProfilePath.url,
                    breadcrumb: FrameworkProfileBreadcrumb,
                    routes: [
                      {
                        path: FrameworkModuleProfileTablesPath.url,
                        breadcrumb: FrameworkTablesBreadcrumb,
                        routes: [
                          {
                            path: FrameworkModuleTablePath.url,
                            breadcrumb: FrameworkTableBreadcrumb,
                          }
                        ]
                      }
                    ]
                  },
                ]
              }
            ]
          },
        ]
      }
    ],
  },
];
