import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentInsulation } from './selectors';

import { PipingModuleInsulationsEditPath } from '../../paths';

const mapStateToProps = createSelector(
  selectCurrentInsulation(),
  (insulation) => ({
    title:
      (insulation && insulation.name) ||
      PipingModuleInsulationsEditPath.defaultTitle,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
