import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveMiterFamiliesForPipeFamily,
  processUnarchiveMiterFamiliesForPipeFamily,
} from '../../entities/MiterFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import {
  selectCurrentDraftMiterInstancesPendingValidChanges,
  selectCurrentMiter,
} from './selectors';

import { updateMiterInstancesCollection } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentMiter(),
  selectCurrentPipeId(),
  selectCurrentDraftMiterInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    miter,
    pipeId,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    miterId: miter && miter.id || null,
    pipeId,
    isArchived: miter && miter.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  
  return (
    <Options 
      {...props}
      {...reduxProps}
      updateEntities = {() => dispatch(updateMiterInstancesCollection(reduxProps.miterId))}
      editEntity = {() => dispatch(showModal('MITER_MODAL', { pipeFamilyId: reduxProps.pipeId, miterId: reduxProps.miterId }))}
      archiveEntity = {() => dispatch(processArchiveMiterFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.miterId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveMiterFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.miterId]))}
    />
  );
}
