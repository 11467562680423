import React from 'react';
import pluralize from 'pluralize';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

const ListCreatorRenderer = ({ 
  value,
  placeholder,
}) => {
  const defaultOption = value.find(v => v.isSelected);

  return (
    <BaseCell flexDirection='column' justifyContent='center'>
      <BaseCellDataItem primary disabled={!defaultOption} pb={1}>
        { defaultOption && defaultOption.value || placeholder }
      </BaseCellDataItem>
      <BaseCellDataItem secondary disabled={!value || value.size === 0}>
        { value && value.size > 0 && `${value.size} ${pluralize('options', value.size)}` || 'No list options' }
      </BaseCellDataItem>
    </BaseCell>
  );
};

export default ListCreatorRenderer;