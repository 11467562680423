import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import PipeInstanceSchema from '../../entities/PipeInstances/schema';
import { 
  selectNormalizedPipeInstances,
  selectNormalizedDraftPipeInstances,
} from '../../entities/PipeInstances/selectors';

import { 
  selectCurrentPipeId,
  selectCurrentPipe,
} from '../PipesEditSwitch/selectors';

export const selectDraftDenormalizeSelector = () => createSelector(
  selectEntities(),
  entities => entities.delete(EK.PIPES.state)
);

export const selectOriginalNormalizedPipeInstancesForFamily = () => createSelector(
  selectNormalizedPipeInstances(),
  selectCurrentPipeId(),
  (instances, pipeFamilyId) => instances.filter(instance => instance.pipeFamilyId === pipeFamilyId)
);

export const selectOriginalDenormalizedPipeInstancesForFamily = () => createSelector(
  selectOriginalNormalizedPipeInstancesForFamily(),
  selectEntities(),
  (instances, entities) => denormalize(
    instances, 
    [PipeInstanceSchema], 
    entities.delete(EK.PIPES.state)
  )
);

const pipeInstanceFilterForm = formValueSelector(`filters.${EK.PIPE_INSTANCES.state}`);

export const selectCurrentDraftPipeInstances = () => createSelector(
  selectNormalizedDraftPipeInstances(),
  selectEntities(),
  (instances, entities) => denormalize(
    instances,
    [PipeInstanceSchema],
    entities.delete(EK.PIPES.state)
  )
);

export const selectCurrentFilteredDraftPipeInstances = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftPipeInstances(),
  selectEntities(),
  selectCurrentPipe(),
  state => pipeInstanceFilterForm(state, 'sizeProperty', 'schedule'),
  (isFiltering, instances, entities, pipe, query) => denormalize(
    isFiltering ? 
      instances.map(instance => instance.set('pipeShape', pipe && pipe.crossSectionShape)).filter(instance => instance.doesMatchQuery(query)) : 
      instances.map(instance => instance.set('pipeShape', pipe && pipe.crossSectionShape)),
    [PipeInstanceSchema],
    entities.delete(EK.PIPES.state)
  )
);

export const selectCurrentDraftPipeInstancesPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.PIPE_INSTANCES.state, 'saveable']).size > 0
);

export const selectCurrentDraftPipeInstanceIds = () => createSelector(
  selectNormalizedDraftPipeInstances(),
  drafts => drafts.reduce((ids, current) => ids.add(current.id), Set())
);

export const selectCurrentEditedDraftPipeInstancesCount = () => createSelector(
  selectNormalizedDraftPipeInstances(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);