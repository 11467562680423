import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentPipe } from '../SpecsEditSwitch/selectors';

import { PipingModuleSpecsPipeInstancesEditPath } from '../../paths';

const mapStateToProps = createSelector(selectCurrentPipe(), (pipe) => ({
  title:
    (pipe && pipe.name) ||
    PipingModuleSpecsPipeInstancesEditPath.defaultTitle,
}));

export default function () {
  const reduxProps = useSelector(mapStateToProps);

  return (<BreadcrumbTemplate title={reduxProps.title} />);
}