import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import MaterialModel from '../../entities/Materials/model';
import MaterialSchema from '../../entities/Materials/schema';
import { selectNormalizedMaterials, selectDraftMaterials } from '../../entities/Materials/selectors';

import {
  selectCurrentDraftMaterialIds,
} from './selectors';

import { processSaveMaterials } from '../../entities/Materials/actions';
import { saveMaterialsTypes } from '../../entities/Materials/constants';

import {
  CREATE_MATERIAL_DRAFTS,
  EDIT_MATERIAL_DRAFTS,
  UPDATE_MATERIALS_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.MATERIALS,
  schema: MaterialSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedMaterials,
});

const moveSaga = generateMoveSaga({
  keys: EK.MATERIALS,
  model: MaterialModel,
  originalNormalizedDataSelector: selectNormalizedMaterials, 
  currentIdsSetSelector: selectCurrentDraftMaterialIds, 
});

const createSaga = generateWatchCreateSaga({
  keys: EK.MATERIALS,
  model: MaterialModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftMaterialIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.MATERIALS, 
  model: MaterialModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectDraftMaterials,
  currentIdsSetSelector: selectCurrentDraftMaterialIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectDraftMaterials,
  processUpdateAction: processSaveMaterials,
  processUpdateTypes: saveMaterialsTypes,
  moveSaga,
});

export function* createDraftMaterialssSaga() {
  yield takeEvery(CREATE_MATERIAL_DRAFTS, createSaga);
}

export function* editDraftMaterialssSaga() {
  yield takeEvery(EDIT_MATERIAL_DRAFTS, editSaga);
}

export function* saveDraftMaterialssSaga() {
  yield takeLatest(UPDATE_MATERIALS_COLLECTION, updateSaga);
}

export function* watchMaterialssDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftMaterialssSaga),
      fork(editDraftMaterialssSaga),
      fork(saveDraftMaterialssSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.MATERIALS.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchMaterialssDraftsSaga);
}
