import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import ParameterProfileParameterSchema from './schema';

export const selectNormalizedParameterProfileParameters = () =>
  createSelector(
    selectEntities(),
    (entities) => entities.get('parameterProfileParameters'),
  );
export const selectNormalizedDraftParameterProfileParameters = () =>
  createSelector(
    selectDrafts(),
    (drafts) => {
      const state = drafts.get('parameterProfileParameters');
      const records = state.get('records');
      return state.get('ids').map((id) => records.get(id));
    },
  );

export const selectParameterProfileParameters = () =>
  createSelector(
    selectEntities(),
    (entities) =>
      denormalize(entities.get('parameterProfileParameters'), [
        ParameterProfileParameterSchema,
      ], entities.delete('parameterProfiles')),
  );
export const selectDraftParameterProfileParameters = () =>
  createSelector(
    selectEntities(),
    selectNormalizedDraftParameterProfileParameters(),
    (entities, drafts) =>
      denormalize(
        drafts,
        [ParameterProfileParameterSchema],
        entities.delete('parameterProfiles'),
      ),
  );
