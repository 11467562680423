import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import MiterInstanceModel from '../../entities/MiterInstances/model';
import MiterInstanceSchema from '../../entities/MiterInstances/schema';

import { selectCurrentPipeInstanceAndChildrenSerializationSelector } from '../PipesEditSwitch/selectors';

import {
  selectBaseParams,
  selectDraftDenormalizeSelector,
  selectOriginalNormalizedMiterInstancesForFamily,
  selectOriginalDenormalizedMiterInstancesForFamily,
  selectCurrentDraftMiterInstances,
  selectCurrentDraftMiterInstanceIds,
} from './selectors';

import { processFetchIndividualMiterFamilyForPipeFamily } from '../../entities/MiterFamilies/actions';
import { fetchIndividualMiterFamilyForPipeFamilyTypes } from '../../entities/MiterFamilies/constants';

import { 
  selectNormalizedDraftMiterInstances,
} from '../../entities/MiterInstances/selectors';
import { processSaveMiterInstances } from '../../entities/MiterInstances/actions';
import { saveMiterInstancesTypes } from '../../entities/MiterInstances/constants';

import {
  CREATE_MITER_INSTANCE_DRAFTS,
  EDIT_MITER_INSTANCE_DRAFTS,
  UPDATE_MITER_INSTANCES_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectBaseParams,
});

const reviewSaga = generateReviewSaga({
  keys: EK.MITER_INSTANCES,
  schema: MiterInstanceSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalDenormalizedMiterInstancesForFamily,
});

const generateCreationProperties = ({ familyId }) => ({
  miterFamilyId: familyId, 
  miterFamily: familyId
});

const moveSaga = generateMoveSaga({
  keys: EK.MITER_INSTANCES,
  model: MiterInstanceModel,
  baseParamsSelector: selectBaseParams, 
  originalNormalizedDataSelector: selectOriginalNormalizedMiterInstancesForFamily, 
  currentIdsSetSelector: selectCurrentDraftMiterInstanceIds, 
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.MITER_INSTANCES,
  model: MiterInstanceModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftMiterInstanceIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.MITER_INSTANCES, 
  model: MiterInstanceModel,
  reviewSaga,
  baseParamsSelector: selectBaseParams, 
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftMiterInstances,
  currentIdsSetSelector: selectCurrentDraftMiterInstanceIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectBaseParams,
  currentDraftDataToSerializeSelector: selectCurrentDraftMiterInstances,
  serializationDataSelector: selectCurrentPipeInstanceAndChildrenSerializationSelector,
  processUpdateAction: processSaveMiterInstances,
  processUpdateTypes: saveMiterInstancesTypes,
  moveSaga,
});

export function* createDraftMiterInstancesSaga() {
  yield takeEvery(CREATE_MITER_INSTANCE_DRAFTS, createSaga);
}

export function* editDraftMiterInstancesSaga() {
  yield takeEvery(EDIT_MITER_INSTANCE_DRAFTS, editSaga);
}

export function* saveDraftMiterInstancesSaga() {
  yield takeLatest(UPDATE_MITER_INSTANCES_COLLECTION, updateSaga);
}

export function* watchMiterInstancesDraftsSaga() {
  try {
    const baseParams = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualMiterFamilyForPipeFamily(baseParams));

    // wait for the individual request to be successful
    yield take(fetchIndividualMiterFamilyForPipeFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftMiterInstancesSaga),
      fork(editDraftMiterInstancesSaga),
      fork(saveDraftMiterInstancesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.MITER_INSTANCES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchMiterInstancesDraftsSaga);
}
