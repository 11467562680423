import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleFittingCategoriesPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentEditedDraftFittingCategoriesCount,
  selectCurrentFilteredNormalizedDraftFittingCategories,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentEditedDraftFittingCategoriesCount(),
  selectCurrentFilteredNormalizedDraftFittingCategories(),
  (isLoadingInitialData, isFetching, isFiltering, count, filtered) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: PipingModuleFittingCategoriesPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Fitting Category',
        count
      )}`
      : `Showing ${count} ${pluralize('Fitting Category', count)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
