import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  CREATE_BOLT_NUT_INSTANCE_DRAFTS,
  EDIT_BOLT_NUT_INSTANCE_DRAFTS,
  UPDATE_BOLT_NUT_INSTANCES_COLLECTION,
} from './constants';

export const createBoltNutInstanceDrafts = generateCreateDraftsAction(CREATE_BOLT_NUT_INSTANCE_DRAFTS);

export const editBoltNutInstanceDrafts = generateEditDraftsAction(EDIT_BOLT_NUT_INSTANCE_DRAFTS);

export const updateBoltNutInstancesCollection = createAction(UPDATE_BOLT_NUT_INSTANCES_COLLECTION, boltNutFamilyId => ({ boltNutFamilyId }));
