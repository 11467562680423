import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import ProjectSchema from './schema';

export const selectNormalizedProjects = () => createSelector(
  selectEntities(),
  entities => entities.get(EK.PROJECTS.state)
);

export const selectProjectsList = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.PROJECTS.state).toList(), [ProjectSchema], entities.delete('specs'))
);

export const selectProjectsCounts = () => createSelector(
  selectNormalizedProjects(),
  projects => projects.reduce(({ unarchived, total }, project) => ({
    unarchived: !project.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);
