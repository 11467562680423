import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  CREATE_MITER_INSTANCE_DRAFTS,
  EDIT_MITER_INSTANCE_DRAFTS,
  UPDATE_MITER_INSTANCES_COLLECTION,
} from './constants';

export const createMiterInstanceDrafts = generateCreateDraftsAction(CREATE_MITER_INSTANCE_DRAFTS);

export const editMiterInstanceDrafts = generateEditDraftsAction(EDIT_MITER_INSTANCE_DRAFTS);

export const updateMiterInstancesCollection = createAction(UPDATE_MITER_INSTANCES_COLLECTION, miterFamilyId => ({ miterFamilyId }));
