import { toast } from 'react-toastify';

import EK from '../keys';

import api from '../../modules/api/actions';
import { REQUEST_TYPES } from '../../modules/api/constants';

import { fetchAllParametersTypes, createParameterTypes, saveParametersTypes } from './constants';
import schema from './schema';

const client = api(schema, EK.PARAMETERS);

export const processFetchAllParameters = () => client(REQUEST_TYPES.GET, 'plural', fetchAllParametersTypes, '/standardize/parameters');

export const processCreateParameter = data => client(REQUEST_TYPES.POST, 'plural', createParameterTypes, '/standardize/parameters', {
  data: {
    parameters: [data],
  },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Parameter successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create parameter',
  },
});

export const processSaveParameters = (_, data) => client(REQUEST_TYPES.PATCH, 'plural', saveParametersTypes, '/standardize/parameters/collection?diff_result=true', {
  data: { parametersAttributes: data },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});
