import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModulePipesPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectPipeFamiliesCounts } from '../../entities/PipeFamilies/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredDraftPipes } from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectPipeFamiliesCounts(),
  selectCurrentFilteredDraftPipes(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    isShowingArchived,
    { unarchived, total },
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && total === 0),
    title: PipingModulePipesPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${
        isShowingArchived ? total : unarchived
      } ${pluralize(
        'filtered Pipe',
        isShowingArchived ? total : unarchived
      )} ${
        (isShowingArchived && '(including archived)') ||
          '(excluding archived)'
      }`
      : `Showing ${unarchived} ${pluralize('Pipe', unarchived)} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
