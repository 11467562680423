import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import { CREATE_FITTING_CATEGORY_DRAFTS, EDIT_FITTING_CATEGORY_DRAFTS, UPDATE_FITTING_CATEGORIES_COLLECTION } from './constants';

export const createFittingCategoryDrafts = generateCreateDraftsAction(CREATE_FITTING_CATEGORY_DRAFTS);

export const editFittingCategoryDrafts = generateEditDraftsAction(EDIT_FITTING_CATEGORY_DRAFTS);

export const updateFittingCategoriesCollection = createAction(UPDATE_FITTING_CATEGORIES_COLLECTION);
