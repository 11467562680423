import validateRules from '../../utils/validation/validateRules';

export const rules = {
  name: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: value => value && value.length >= 2,
    },
  },
};

export const validations = {
  name: validateRules(rules.name),
};
