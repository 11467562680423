import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentFitting } from './selectors';

import { PipingModuleFittingsEditPath } from '../../paths';

const mapStateToProps = createSelector(selectCurrentFitting(), (fitting) => ({
  title: (fitting && fitting.name) || PipingModuleFittingsEditPath.defaultTitle,
}));

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
