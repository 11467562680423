import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import useFloatingState from '../../../hooks/useFloatingState';
import useIsHovering from '../../../hooks/useIsHovering';

import Status from '../../common/Status';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';

import BaseCell from '../BaseCell';

import EntityStatusTooltip from '../../../containers/tooltip/templates/EntityStatusTooltip';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

const EntitySaveStatusCellRenderer = ({ value, data }) => {
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'left' });
  const isHovering = useIsHovering(reference, { delayEnter: 1000 });
  let statusProps;

  switch(value) {
  case ENTITY_STATUS.UNSELECTED: {
    // a newly-created draft that is currently unselected
    statusProps = {};
    break;
  }
  case ENTITY_STATUS.LOCAL_DRAFT:
  case ENTITY_STATUS.SAVED: {
    // a newly-created draft that is currently selected but unedited
    statusProps = {};
    break;
  }
  case ENTITY_STATUS.PENDING_VALID_SAVE:
  case ENTITY_STATUS.PENDING_VALID_CHANGES: {
    // a newly-created draft that is currently selected and valid
    statusProps = { primary: true };
    break;
  }
  case ENTITY_STATUS.PENDING_INVALID_SAVE:
  case ENTITY_STATUS.PENDING_INVALID_CHANGES: {
    // a newly-created draft that is currently selected but invalid
    statusProps = { error: true };
    break;
  }
  case ENTITY_STATUS.PENDING_DELETE: {
    // an existing record that is unselected, so it will be deleted
    statusProps = { primary: true };
    break;
  }
  }

  return (
    <BaseCell ref={reference} flexDirection='column' justifyContent='center' alignItems='center'>
      { statusProps && <Status { ...statusProps } /> || null }
      {
        statusProps && isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size='large'>
            <EntityStatusTooltip entity={data} />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseCell>
  );
};

export default EntitySaveStatusCellRenderer;