import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import Button from '../../components/common/Button';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveFittingFamilies,
  processUnarchiveFittingFamilies,
} from '../../entities/FittingFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';
import { toggleHiddenGridColumns } from '../Dashboard/actions';

import { showModal } from '../../modules/modal/actions';

import {
  selectCurrentFitting,
  selectCurrentDraftFittingInstancesPendingValidChanges,
} from './selectors';

import { updateFittingInstancesCollection } from './actions';

const ConstructedOptions = ({ toggleHiddenGridColumns }) => <Button secondary icon='list' onClick={toggleHiddenGridColumns} mr={2} />;

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentFitting(),
  selectCurrentDraftFittingInstancesPendingValidChanges(),
  ({ isLoadingInitialData, isFetching, ...rest }, isEditingGrid, fitting, pendingValidChanges) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    fittingId: fitting && fitting.id || null,
    isArchived: fitting && fitting.archivedFlag,
    pendingValidChanges,
    ConstructedOptions,
  })
);

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  return (
    <Options
      {...props}
      {...reduxProps}
      toggleHiddenGridColumns = {() => dispatch(toggleHiddenGridColumns())}
      updateEntities = {() => dispatch(updateFittingInstancesCollection(reduxProps.fittingId))}
      editEntity = {() => dispatch(showModal('FITTING_MODAL', {fittingId: props.match.params.id}))}
      archiveEntity = {() => dispatch(processArchiveFittingFamilies([reduxProps.fittingId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveFittingFamilies([reduxProps.fittingId]))}
    />
  );
}