import { List } from 'immutable';

import EntityRecord, {
  FILTERS,
  VALIDATORS,
} from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_]{0,31})$/,
  DATUM_FEATURE_NAME: /^([a-zA-Z0-9_]{0,31})$/,
  OFFSET_DISTANCE: /^(?=.{0,80}$)([0-9]*(\.)?[0-9]*)$/,
};

export const SECTION_METHOD_TYPES = {
  DATUM_PLANE: 'datum_plane',
  DEFAULT_X: 'default_x',
  DEFAULT_Y: 'default_y',
  DEFAULT_Z: 'default_z',
};

export const SECTION_METHOD_TYPES_FORMATTED = {
  [SECTION_METHOD_TYPES.DATUM_PLANE]: 'Datum Plane',
  [SECTION_METHOD_TYPES.DEFAULT_X]: 'Default X',
  [SECTION_METHOD_TYPES.DEFAULT_Y]: 'Default Y',
  [SECTION_METHOD_TYPES.DEFAULT_Z]: 'Default Z',
};

export const SECTION_METHOD_TYPE_OPTIONS = List([
  {
    id: SECTION_METHOD_TYPES.DATUM_PLANE,
    value: SECTION_METHOD_TYPES.DATUM_PLANE,
    label: 'Datum Plane',
    primary: 'Datum Plane',
  },
  {
    id: SECTION_METHOD_TYPES.DEFAULT_X,
    value: SECTION_METHOD_TYPES.DEFAULT_X,
    label: 'Default X',
    primary: 'Default X',
  },
  {
    id: SECTION_METHOD_TYPES.DEFAULT_Y,
    value: SECTION_METHOD_TYPES.DEFAULT_Y,
    label: 'Default Y',
    primary: 'Default Y',
  },
  {
    id: SECTION_METHOD_TYPES.DEFAULT_Z,
    value: SECTION_METHOD_TYPES.DEFAULT_Z,
    label: 'Default Z',
    primary: 'Default Z',
  },
]);

class Section extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
      ALLOWANCES.NAME.test(entity.name),
    validateError:
      'Name must be present, at most 31 characters in length, and can only contain letters, numbers, and \'_\'',
    filter: FILTERS.STRING,
  },
  sectionMethod: {
    value: SECTION_METHOD_TYPES.DATUM_PLANE,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      (entity.sectionMethod !== null || entity.sectionMethod !== undefined) &&
      SECTION_METHOD_TYPE_OPTIONS.find(
        (option) => option.value === entity.sectionMethod,
      ),
    validateError: 'Must have a valid section method',
    filter: FILTERS.EXACT_STRING,
  },
  datumFeatureName: {
    value: null,
    handleCopy: (entity) => `${entity.datumFeatureName} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      entity.sectionMethod === SECTION_METHOD_TYPES.DATUM_PLANE
        ? VALIDATORS.IS_REQUIRED_STRING(entity.datumFeatureName) &&
          ALLOWANCES.NAME.test(entity.datumFeatureName)
        : entity.datumFeatureName === null,
    validateError:
      'If Section Method is Datum Plane, Datum Feature Name must be present, at most 31 characters in length, and can only contain letters, numbers, and \'_\'',
    filter: FILTERS.STRING,
  },
  offsetDistance: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      !entity.offsetDistance ||
      (VALIDATORS.IS_NUMERIC(entity.offsetDistance) &&
        entity.offsetDistance.length <= 80),
    validateError:
      'Offset distance must be a valid number (decimal or integer) that is at most 80 characters in length',
  },
  description: {
    value: null,
    handleCopy: (entity) => `${entity.description} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  isLocked: {
    value: false,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.BOOLEAN,
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
  get tertiary() {
    return this.sectionMethod;
  }

  /**
   * Takes a section method and clears other properties in the section depending on method
   * @param {string} sectionMethod - One of the valid section methods for section
   * @return {Entity} Returns the current Entity with the new section method
   * @public
   */
  setSectionMethod(sectionMethod) {
    return this.set('sectionMethod', sectionMethod).set(
      'datumFeatureName',
      sectionMethod === SECTION_METHOD_TYPES.DATUM_PLANE
        ? this.get('datumFeatureName')
        : null,
    );
  }
}

export default Section;
