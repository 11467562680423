import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  waitWhileInitialFetchingSaga,
  waitWhilePerformingSaga,
} from '../../modules/drafts/sagas';

import {
  generateMoveMultiSaga,
  generateCreateOrEditSaga,
} from '../SpecsEditSwitch/sagaTemplates';

import {
  selectNormalizedUnarchivedPipeFamilies,
  selectCurrentNormalizedSpecPipeInstances
} from './selectors';

import {
  selectCurrentSpecId,
  selectCurrentDraftSpecPipeInstanceIds,
} from '../SpecsEditSwitch/selectors';

import SpecPipeInstanceModel from '../../entities/SpecPipeInstances/model';
import { selectNormalizedSpecPipeInstances, selectNormalizedDraftSpecPipeInstances } from '../../entities/SpecPipeInstances/selectors';
import { saveSpecPipeInstancesTypes } from '../../entities/SpecPipeInstances/constants';

import { selectNormalizedPipeFamilies } from '../../entities/PipeFamilies/selectors';
import { fetchAllPipeFamiliesTypes } from '../../entities/PipeFamilies/constants';
import { processFetchAllPipeFamilies } from '../../entities/PipeFamilies/actions';

import {
  TOGGLE_SPEC_PIPE_FAMILIES,
} from './constants';

const moveMultiSaga = generateMoveMultiSaga({
  originalNormalizedDataAndKeys: [
    { keys: EK.PIPES, selector: selectNormalizedUnarchivedPipeFamilies },
    { keys: EK.SPEC_PIPE_INSTANCES, selector: selectCurrentNormalizedSpecPipeInstances },
  ],
});

const toggleSpecPipeFamiliesSaga = generateCreateOrEditSaga({
  keys: EK.SPEC_PIPE_INSTANCES,
  childProperty: 'pipeInstances',
  model: SpecPipeInstanceModel,
  baseParamsSelector: selectCurrentSpecId,
  originalDraftDataSelector: selectNormalizedSpecPipeInstances,
  normalizedDraftDataSelector: selectNormalizedDraftSpecPipeInstances,
  normalizedOriginalParentDataSelector: selectNormalizedPipeFamilies,
  currentIdsSetSelector: selectCurrentDraftSpecPipeInstanceIds,
  findDraftFilterFunction: ({ baseParams, edit, currentDraftId }) => currentNormalizedDraft => (
    currentNormalizedDraft.spec === baseParams &&
    currentNormalizedDraft.pipeFamilyId === edit.id &&
    currentNormalizedDraft.pipeInstance === currentDraftId
  ),
  generateCreationProperties: ({ baseParams, edit, currentDraftId }) => ({
    spec: baseParams,
    pipeFamilyId: edit.id,
    pipeInstance: currentDraftId,
  })
});

export function* watchUpdateSpecSuccessForMoveSaga() {
  yield takeLatest(saveSpecPipeInstancesTypes.SUCCESS, function* () {
    yield call(waitWhilePerformingSaga);

    yield call(moveMultiSaga);
  });
}

export function* watchToggleSpecPipeFamiliesSaga() {
  yield takeEvery(TOGGLE_SPEC_PIPE_FAMILIES, toggleSpecPipeFamiliesSaga);
}

export function* watchSpecsPipesCollectionSaga() {
  try {
    yield put(processFetchAllPipeFamilies());

    yield take(fetchAllPipeFamiliesTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveMultiSaga);

    yield all([
      fork(watchToggleSpecPipeFamiliesSaga),
      fork(watchUpdateSpecSuccessForMoveSaga),
    ]);

    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.SPEC_PIPE_INSTANCES.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSpecsPipesCollectionSaga);
}
