import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
} from '../../../../paths';

import {
  createBendSpringbackFamilyForPipeFamilyTypes,
  copyBendSpringbackFamilyForPipeFamilyTypes,
  editBendSpringbackFamilyForPipeFamilyTypes,
  deleteBendSpringbackFamilyForPipeFamilyTypes,
} from '../../../../entities/BendSpringbackFamilies/constants';
import {
  selectNormalizedBendSpringbackFamilies
} from '../../../../entities/BendSpringbackFamilies/selectors';

import { PREPARE_BEND_SPRINGBACK_MODAL } from './constants';

const generateWatchCreateBendSpringbackSaga = (id) =>
  function* () {
    yield takeLatest([
      createBendSpringbackFamilyForPipeFamilyTypes.SUCCESS,
      copyBendSpringbackFamilyForPipeFamilyTypes.SUCCESS,
    ], function* ({ payload = {} }) {
      const familyId = `${get(payload, `${EK.BEND_SPRINGBACKS.plural}[0].id`)}`;
      if (!!familyId && !!id) {
        let bendSpringbacks = yield select(selectNormalizedBendSpringbackFamilies());
        let copiedBendSpringback = bendSpringbacks.get(familyId);

        while (!copiedBendSpringback) {
          yield take();
          bendSpringbacks = yield select(selectNormalizedBendSpringbackFamilies());
          copiedBendSpringback = bendSpringbacks.get(familyId);
        }

        yield put(
          push(
            PipingModulePipesBendSpringbacksEditPath.generate({
              id,
              familyId,
            })
          )
        );

        yield put(hideModal());
      }
    }
    );
  };

const generateWatchDeleteBendSpringbackSaga = (id) =>
  function* () {
    yield takeLatest(
      deleteBendSpringbackFamilyForPipeFamilyTypes.SUCCESS,
      function* () {
        yield put(push(PipingModulePipesBendSpringbacksPath.generate({ id })));
      }
    );
  };

export function* handleCloseBendSpringbackFamilyModal() {
  yield put(hideModal());
}

export function* watchCloseBendSpringbackModalSaga() {
  yield takeLatest(
    [
      createBendSpringbackFamilyForPipeFamilyTypes.SUCCESS,
      editBendSpringbackFamilyForPipeFamilyTypes.SUCCESS,
      deleteBendSpringbackFamilyForPipeFamilyTypes.SUCCESS,
    ],
    handleCloseBendSpringbackFamilyModal
  );
}

// final output saga
export default function* main() {
  const {
    payload: { id },
  } = yield take(PREPARE_BEND_SPRINGBACK_MODAL);

  const watchCreateBendSpringbackSaga = generateWatchCreateBendSpringbackSaga(
    id
  );
  const watchDeleteBendSpringbackSaga = generateWatchDeleteBendSpringbackSaga(
    id
  );

  yield all([
    fork(watchCreateBendSpringbackSaga),
    fork(watchDeleteBendSpringbackSaga),
    fork(watchCloseBendSpringbackModalSaga),
  ]);
}
