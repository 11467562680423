import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedBoltNutFamiliesList } from '../../entities/BoltNutFamilies/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const boltNutFilterForm = formValueSelector(`filters.${EK.BOLT_NUTS.state}`);

export const selectCurrentFilteredNormalizedDraftBoltNuts = () => createSelector(
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedBoltNutFamiliesList(),
  state => boltNutFilterForm(state, 'name', 'description'),
  (isFiltering, isShowingArchived, boltNuts, query) => isFiltering ? 
    boltNuts.filter(boltNut => (isShowingArchived || !boltNut.archivedFlag) && boltNut.doesMatchQuery(query)) : 
    boltNuts.filter(boltNut => !boltNut.archivedFlag)
);