import React from 'react';

import Icon from '../../Icon';

//handleRowAction is maintained in the body.js file of the page as it uses local state/
export default function EntityRowTools ({ rowdata, handleRowAction }) {
  let [iconName, iconColor] = rowdata.isDeleted || rowdata.isCreated || rowdata.isEdited ? ['undo', 'gray.6'] : ['delete', 'error.4'];
  let clickStyle = { cursor: 'pointer' };
  if (!rowdata.canSafelyDelete && !rowdata.isEdited) {
    [iconName, iconColor] = ['lock', 'gray.6'];
    clickStyle = {};
  }
  if (rowdata.id.includes('NEW_ROW_') && !rowdata.isCreated && !rowdata.isEdited) {
    iconColor = 'gray.2';
  }
  return (
    <div style={clickStyle} onClick={() => handleRowAction(rowdata, iconName)}>
      <Icon name={iconName} color={iconColor} />
    </div>
  );
}