import { createAction } from 'redux-actions';

import { generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  EDIT_PROJECT_SPECS,
  UPDATE_PROJECT_SPECS,
} from './constants';

export const editProjectSpecs = generateEditDraftsAction(EDIT_PROJECT_SPECS);

export const updateProjectSpecs = createAction(UPDATE_PROJECT_SPECS, projectId => ({ projectId }));
