import React from 'react';

import Text from '../../../common/Text';

import TooltipBody from '../../TooltipBody';
import TooltipSection from '../../TooltipSection';

const UnarchivedEntityTooltip = React.memo(() => (
  <TooltipBody>
    <TooltipSection main large>
      <Text fontSize={3} fontWeight={500}>Active</Text>
    </TooltipSection>
    <TooltipSection large>
      <Text fontSize={1}>
        This entity is currently <strong>active</strong>, so it can be edited and used in other entities.
      </Text>
    </TooltipSection>
  </TooltipBody>
));

export default UnarchivedEntityTooltip;