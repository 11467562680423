import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import PipeInstanceModel from '../../entities/PipeInstances/model';
import PipeInstanceSchema from '../../entities/PipeInstances/schema';
import { selectNormalizedDraftPipeInstances } from '../../entities/PipeInstances/selectors';

import { 
  selectCurrentPipeId,
  selectCurrentPipeInstanceAndChildrenSerializationSelector
} from '../PipesEditSwitch/selectors';

import {
  selectDraftDenormalizeSelector,
  selectOriginalNormalizedPipeInstancesForFamily,
  selectOriginalDenormalizedPipeInstancesForFamily,
  selectCurrentDraftPipeInstances,
  selectCurrentDraftPipeInstanceIds,
} from './selectors';

import { processFetchIndividualPipeFamily } from '../../entities/PipeFamilies/actions';
import { fetchIndividualPipeFamilyTypes } from '../../entities/PipeFamilies/constants';

import { processSavePipeInstances } from '../../entities/PipeInstances/actions';
import { savePipeInstancesTypes } from '../../entities/PipeInstances/constants';

import {
  CREATE_PIPE_INSTANCE_DRAFTS,
  EDIT_PIPE_INSTANCE_DRAFTS,
  UPDATE_PIPE_INSTANCES_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentPipeId,
});

const reviewSaga = generateReviewSaga({
  keys: EK.PIPE_INSTANCES,
  schema: PipeInstanceSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalDenormalizedPipeInstancesForFamily,
});

const generateCreationProperties = pipeId => ({
  pipeFamilyId: pipeId, 
  pipeFamily: pipeId
});

const moveSaga = generateMoveSaga({
  keys: EK.PIPE_INSTANCES,
  model: PipeInstanceModel,
  baseParamsSelector: selectCurrentPipeId, 
  originalNormalizedDataSelector: selectOriginalNormalizedPipeInstancesForFamily, 
  currentIdsSetSelector: selectCurrentDraftPipeInstanceIds, 
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.PIPE_INSTANCES,
  model: PipeInstanceModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftPipeInstanceIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.PIPE_INSTANCES, 
  model: PipeInstanceModel,
  reviewSaga,
  baseParamsSelector: selectCurrentPipeId, 
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftPipeInstances,
  currentIdsSetSelector: selectCurrentDraftPipeInstanceIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectCurrentPipeId,
  currentDraftDataToSerializeSelector: selectCurrentDraftPipeInstances,
  serializationDataSelector: selectCurrentPipeInstanceAndChildrenSerializationSelector,
  processUpdateAction: processSavePipeInstances,
  processUpdateTypes: savePipeInstancesTypes,
  moveSaga,
});

export function* createDraftPipeInstancesSaga() {
  yield takeEvery(CREATE_PIPE_INSTANCE_DRAFTS, createSaga);
}

export function* editDraftPipeInstancesSaga() {
  yield takeEvery(EDIT_PIPE_INSTANCE_DRAFTS, editSaga);
}

export function* saveDraftPipeInstancesSaga() {
  yield takeLatest(UPDATE_PIPE_INSTANCES_COLLECTION, updateSaga);
}

export function* watchPipeInstancesDraftsSaga() {
  try {
    const pipeId = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualPipeFamily(pipeId));

    // wait for the individual request to be successful
    yield take(fetchIndividualPipeFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftPipeInstancesSaga),
      fork(editDraftPipeInstancesSaga),
      fork(saveDraftPipeInstancesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.PIPE_INSTANCES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchPipeInstancesDraftsSaga);
}
