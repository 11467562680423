import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Map, List } from 'immutable';
import { push } from 'connected-react-router';
import ReactResizeDetector from 'react-resize-detector';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import styled, { createGlobalStyle } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import borders from '../../../assets/themes/base/borders';
import shadows from '../../../assets/themes/base/shadows';

import pluralize from 'pluralize';

import E_ALLOWANCES from '../../../entities/allowances';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import Box from '../../../components/common/Box';
import Flex from '../../../components/common/Flex';

import BaseCell from '../../../components/grid/BaseCell';

import BasicHeader from '../../../components/grid/BasicHeader';
import BasicGroupHeader from '../../../components/grid/BasicGroupHeader';

import EntitySelectionCellRenderer from '../../../components/grid/EntitySelectionCellRenderer';
import EntitySaveStatusCellRenderer from '../../../components/grid/EntitySaveStatusCellRenderer';

import TemporaryTextCellRenderer from '../../../components/grid/TemporaryTextCellRenderer';

import BasicCellRenderer from '../../../components/grid/BasicCellRenderer';
import MultiLineCellRenderer from '../../../components/grid/MultiLineCellRenderer';
import ComplexCellRenderer from '../../../components/grid/ComplexCellRenderer';
import EntityCellRenderer from '../../../components/grid/EntityCellRenderer';
import AppearanceCellRenderer from '../../../components/grid/AppearanceCellRenderer';
import ListCreatorRenderer from '../../../components/grid/ListCreatorRenderer';
import ListCreatorEditor from '../../../components/grid/ListCreatorEditor';
import ListCellRenderer from '../../../components/grid/ListCellRenderer';
import IconCellRenderer from '../../../components/grid/IconCellRenderer';
import ColorCellRenderer from '../../../components/grid/ColorCellRenderer';
import ToggleCellRenderer from '../../../components/grid/ToggleCellRenderer';
import OptionalUnitCellRenderer from '../../../components/grid/OptionalUnitCellRenderer';
import MatrixCellRenderer from '../../../components/grid/MatrixCellRenderer';

import ArchivedStatusCellRenderer from '../../../components/grid/ArchivedStatusCellRenderer';
import DataActionIconCellRenderer from '../../../components/grid/DataActionIconCellRenderer';
import DataActionDropdownCellRenderer from '../../../components/grid/DataActionDropdownCellRenderer';

import TextEditor from '../../../components/grid/TextEditor';
import MultiLineTextEditor from '../../../components/grid/MultiLineTextEditor';
import OptionalUnitEditor from '../../../components/grid/OptionalUnitEditor';
import RadioEditor from '../../../components/grid/RadioEditor';
import DropdownEditor from '../../../components/grid/DropdownEditor';
import MultiDropdownEditor from '../../../components/grid/MultiDropdownEditor';
import ColorDropdownEditor from '../../../components/grid/ColorDropdownEditor';

import SettingDetailsCellRenderer from '../../../components/grid/SettingDetailsCellRenderer';

import { ENTITY_STATUS, FIELD_STATUS } from '../../../modules/entities/constants';

import { startCellEditing, stopCellEditing } from '../../../modules/grid/actions';

const agGridThemeClass = 'ag-theme-material';
const agCadactiveUnselectableIconCell = 'cadactive-selection-cell';
const agCadactiveClickableCell = 'cadactive-clickable-cell';

const empty = {};

const rowClassRules = {
  [ENTITY_STATUS.LOCAL_DRAFT]: ({ data }) =>
    data.status === ENTITY_STATUS.LOCAL_DRAFT,
  [ENTITY_STATUS.SAVED]: ({ data }) => data.status === ENTITY_STATUS.SAVED,
  [ENTITY_STATUS.PENDING_VALID_SAVE]: ({ data }) =>
    data.status === ENTITY_STATUS.PENDING_VALID_SAVE,
  [ENTITY_STATUS.PENDING_VALID_CHANGES]: ({ data }) =>
    data.status === ENTITY_STATUS.PENDING_VALID_CHANGES,
  [ENTITY_STATUS.PENDING_INVALID_SAVE]: ({ data }) =>
    data.status === ENTITY_STATUS.PENDING_INVALID_SAVE,
  [ENTITY_STATUS.PENDING_INVALID_CHANGES]: ({ data }) =>
    data.status === ENTITY_STATUS.PENDING_INVALID_CHANGES,
  [ENTITY_STATUS.UNSELECTED]: ({ data }) =>
    data.status === ENTITY_STATUS.UNSELECTED,
  [ENTITY_STATUS.PENDING_DELETE]: ({ data }) =>
    data.status === ENTITY_STATUS.PENDING_DELETE,
};

const cellClassRules = {
  [FIELD_STATUS.UNEDITED]: ({ data, colDef }) =>
    colDef.fields
      ? data.getFieldStatus(colDef.fields) === FIELD_STATUS.UNEDITED
      : data.getFieldStatus(colDef.field) === FIELD_STATUS.UNEDITED,
  [FIELD_STATUS.VALID_UNEDITED]: ({ data, colDef }) =>
    colDef.fields
      ? data.getFieldStatus(colDef.fields) === FIELD_STATUS.VALID_UNEDITED
      : data.getFieldStatus(colDef.field) === FIELD_STATUS.VALID_UNEDITED,
  [FIELD_STATUS.INVALID_UNEDITED]: ({ data, colDef }) =>
    colDef.fields
      ? data.getFieldStatus(colDef.fields) === FIELD_STATUS.INVALID_UNEDITED
      : data.getFieldStatus(colDef.field) === FIELD_STATUS.INVALID_UNEDITED,
  [FIELD_STATUS.EDITED]: ({ data, colDef }) =>
    colDef.fields
      ? data.getFieldStatus(colDef.fields) === FIELD_STATUS.EDITED
      : data.getFieldStatus(colDef.field) === FIELD_STATUS.EDITED,
  [FIELD_STATUS.VALID_EDITED]: ({ data, colDef }) =>
    colDef.fields
      ? data.getFieldStatus(colDef.fields) === FIELD_STATUS.VALID_EDITED
      : data.getFieldStatus(colDef.field) === FIELD_STATUS.VALID_EDITED,
  [FIELD_STATUS.INVALID_EDITED]: ({ data, colDef }) =>
    colDef.fields
      ? data.getFieldStatus(colDef.fields) === FIELD_STATUS.INVALID_EDITED
      : data.getFieldStatus(colDef.field) === FIELD_STATUS.INVALID_EDITED,
};

const GridStyle = createGlobalStyle`
  .cadactive-theme-material.${agGridThemeClass} {
    overflow: hidden;
    border: ${borders[2]};
    border-radius: ${radii[2]};

    & .ag-root {
      visibility: hidden;
    }

    &.gridIsReady .ag-root {
      visibility: visible;
    }

    & .ag-watermark {
      visibility: hidden !important;
      opacity: 0 !important;
      display: none !important;
    }

    & .ag-row {
      border-color: ${colors.gray[2]};
    }

    & .ag-cell-no-focus,
    & .ag-cell-no-focus:focus,
    & .ag-cell-no-focus:active {
      outline: none;
    }

    .ag-cell-range-selected:not(.ag-cell-focus) {
      background-color: transparent !important;
    }

    & .ag-ltr .ag-cell-focus,
    & .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-focus.ag-cell-focus {
      border-color: ${colors.primary[4]};
    }

    & .ag-header-viewport {
      background-color: ${colors.gray[1]};
    }

    & .ag-header-row {
      border-top-left-radius: ${radii[2]};
      border-top-right-radius: ${radii[2]};
      border-bottom: none;

      & .ag-header-group-cell,
      & .ag-header-group-cell:hover,
      & .ag-header-group-cell:not(.ag-column-resizing) + .ag-header-group-cell:hover,
      & .ag-header-cell,
      & .ag-header-cell:hover,
      & .ag-header-cell:not(.ag-column-resizing) + .ag-header-cell:hover {
        font-family: 'proxima-soft', sans-serif;
        font-weight: 600;

        line-height: 1;
        padding-left: 0;
        padding-right: 0;

        &.ag-header-cell-sortable {
          cursor: pointer;
        }

        & .ag-react-container {
          height: 100%;
        }

        background-color: ${colors.gray[1]};
      }
    }

    & .ag-body-viewport {
      border-bottom-left-radius: ${radii[2]};
      border-bottom-right-radius: ${radii[2]};
    }

    & .ag-cell {
      font-family: 'proxima-soft', sans-serif;
      font-weight: 400;

      line-height: 1;
      padding-left: 0;
      padding-right: 0;

      &.${agCadactiveUnselectableIconCell} {
        padding-left: 0;
        padding-right: 0;

        &.ag-cell-focus {
          border-color: transparent !important;
        }

        &.${agCadactiveClickableCell} {
          cursor: pointer;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: center;

      &.ag-cell-inline-editing {
        height: 100%;
        padding-top: 0;
        padding-right: 0;
        padding-bottom: 0;
        padding-left: 0;
        box-shadow: ${shadows[2]};
      }

      & .ag-react-container {
        height: 100%;
      }
    }

    & .ag-row-drag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & .ag-icon {
        color: ${colors.gray[6]};
      }
    }

    & .ag-cell-data-changed {}

    & .ag-cell-data-changed-animation {}

    /* Component-specific styles */
    & .ag-row.${ENTITY_STATUS.UNSELECTED} {
      & ${BaseCell} {
        color: ${colors.gray[4]};
      }

      & .${FIELD_STATUS.VALID_EDITED} {
        ${BaseCell} {
          font-style: italic;
        }
      }

      & .${FIELD_STATUS.INVALID_EDITED} {
        ${BaseCell} {
          font-style: italic;
        }
      }
    }

    & .ag-row.${ENTITY_STATUS.LOCAL_DRAFT},
    & .ag-row.${ENTITY_STATUS.SAVED} {
      & ${BaseCell} {
        color: ${colors.gray[7]};
      }
    }

    & .ag-row.${ENTITY_STATUS.PENDING_VALID_CHANGES},
    & .ag-row.${ENTITY_STATUS.PENDING_VALID_SAVE} {
      background-color: ${colors.primary[1]};

      ${BaseCell} {
        color: ${colors.gray[7]};
      }

      & .${FIELD_STATUS.EDITED} {
        ${BaseCell} {
          color: ${colors.primary[4]};
          font-weight: 600;
        }
      }

      & .${FIELD_STATUS.VALID_EDITED} {
        ${BaseCell} {
          color: ${colors.primary[4]};
          font-weight: 600;
        }
      }
    }

    & .ag-row.${ENTITY_STATUS.PENDING_INVALID_CHANGES},
    & .ag-row.${ENTITY_STATUS.PENDING_INVALID_SAVE} {
      & .${FIELD_STATUS.EDITED} {
        ${BaseCell} {
          color: ${colors.primary[4]};
          font-weight: 600;
        }
      }
      & .${FIELD_STATUS.INVALID_EDITED} {
        ${BaseCell} {
          color: ${colors.error[4]};
          background-color: ${colors.error[2]};
          font-weight: 600;
        }
      }
      & .${FIELD_STATUS.VALID_EDITED} {
        ${BaseCell} {
          color: ${colors.primary[4]};
          font-weight: 600;
        }
      }

      & .${FIELD_STATUS.UNEDITED} {
        ${BaseCell} {
          color: ${colors.gray[4]};
          font-style: italic;
        }
      }
      & .${FIELD_STATUS.INVALID_UNEDITED} {
        ${BaseCell} {
          color: ${colors.error[3]};
          background-color: ${colors.error[2]};
          font-style: italic;
        }
      }
      & .${FIELD_STATUS.VALID_UNEDITED} {
        ${BaseCell} {
          color: ${colors.gray[7]};
        }
      }
    }

    & .ag-row.${ENTITY_STATUS.PENDING_DELETE} {
      background-color: ${colors.error[1]};

      ${BaseCell} {
        color: ${colors.error[3]};
      }
    }
  }
`;

const frameworkComponents = {
  basicHeaderRenderer: BasicHeader,
  basicGroupHeaderRenderer: BasicGroupHeader,

  archivedStatusCellRenderer: ArchivedStatusCellRenderer,
  dataActionIconCellRenderer: DataActionIconCellRenderer,
  dataActionDropdownCellRenderer: DataActionDropdownCellRenderer,

  temporaryTextCellRenderer: TemporaryTextCellRenderer,

  basicCellRenderer: BasicCellRenderer,
  basicCellEditor: TextEditor,
  multiLineCellRenderer: MultiLineCellRenderer,
  multiLineCellEditor: MultiLineTextEditor,
  optionalUnitEditor: OptionalUnitEditor,
  radioEditor: RadioEditor,
  dropdownEditor: DropdownEditor,
  iconCellRenderer: IconCellRenderer,
  colorCellRenderer: ColorCellRenderer,
  colorDropdownEditor: ColorDropdownEditor,
  toggleCellRenderer: ToggleCellRenderer,
  optionalUnitCellRenderer: OptionalUnitCellRenderer,

  complexCellRenderer: ComplexCellRenderer,

  entityCellRenderer: EntityCellRenderer,

  appearanceCellRenderer: AppearanceCellRenderer,

  entitySelectionCellRenderer: EntitySelectionCellRenderer,
  entitySaveStatusCellRenderer: EntitySaveStatusCellRenderer,

  listCreatorRenderer: ListCreatorRenderer,
  listCreatorEditor: ListCreatorEditor,
  listCellRenderer: ListCellRenderer,
  multiDropdownEditor: MultiDropdownEditor,

  settingDetailsRenderer: SettingDetailsCellRenderer,
  matrixCellRenderer: MatrixCellRenderer,
};

const defaultColDef = {
  suppressToolPanel: true,
  editable: false,
  sortable: false,
  resizable: true,
  enableValue: false,
  enableRowGroup: false,
  enablePivot: false,
  suppressMenu: true,
  headerComponent: 'basicHeaderRenderer',
};

const debounceCaptureEditsAsMap = (fn, wait = 10, options) => {
  let dbArgs = [];
  let dbFn = debounce(
    () => {
      fn.call(
        undefined,
        dbArgs.reduce((edited, edit) => edited.set(edit.id, edit), Map())
      );
      dbArgs = [];
    },
    wait,
    options
  );

  return (...args) => {
    dbArgs = [...dbArgs, ...args];
    dbFn();
  };
};

const Container = styled(Box)`
  position: relative;
  height: 100%;
  width: 100%;
`;

const MasterContainer = styled(Flex)`
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const getRowNodeId = ({ id }) => id;

const generateEmptyItems = (count) => Array(count).fill({});

const getContextMenuItems = ({
  api = {},
  node = {},
  context = {},
  columnApi,
  ...rest
}) => {
  const currentColumns = columnApi.getColumnState();
  const ranges = api.getCellRanges();
  const firstRange = ranges ? ranges[0] : { startRow: {}, endRow: {} };
  const isCopyableRange = firstRange.startRow.rowIndex !== firstRange.endRow.rowIndex;
  const canEdit = !!context.editInstances;
  const canCreate = !!context.createInstances;
  const canMarkForDeletion = find(
    currentColumns,
    (col) => col && col.colId === 'isSelected'
  );

  let highlightedInstanceIndexes = [];

  if (!ranges) {
    highlightedInstanceIndexes = node && [node.rowIndex];
  } else {
    highlightedInstanceIndexes = ranges
      .map(({ startRow, endRow }) => {
        if (startRow.rowIndex === endRow.rowIndex) {
          // only one instance selected in instance
          return [endRow.rowIndex];
        } else {
          let indexes = [];
          let currentIndex;
          for (
            currentIndex = startRow.rowIndex;
            currentIndex <= endRow.rowIndex;
            currentIndex++
          ) {
            indexes.push(currentIndex);
          }
          return indexes;
        }
      })
      .reduce(
        (instanceIndexes, rangeIndexes) => [
          ...instanceIndexes,
          ...rangeIndexes,
        ],
        []
      );
  }

  return [
    ...(canEdit && !!ranges && isCopyableRange
      ? [
        {
          name: 'Copy range down',
          disabled: ranges.length > 1,
          action() {
            api.copySelectedRangeDown();
          },
        },
        'separator',
      ]
      : []),
    ...(canEdit && canMarkForDeletion && highlightedInstanceIndexes.length > 0
      ? [
        {
          name: `Mark ${highlightedInstanceIndexes.length} ${pluralize(
            'row',
            highlightedInstanceIndexes.length
          )} for deletion`,
          disabled: !context.editInstances,
          action() {
            let edits = List();

            api.forEachNodeAfterFilterAndSort(({ data }, index) => {
              if (
                highlightedInstanceIndexes.indexOf(index) > -1 &&
                  data.canForcefullyDelete
              ) {
                edits = edits.push(data);
              }
            });

            edits.forEach((data) =>
              context.editInstances(data.set('isSelected', false))
            );
          },
        },
        'separator',
      ]
      : []),
    ...(canCreate && highlightedInstanceIndexes.length > 0
      ? [
        {
          name: `Duplicate ${highlightedInstanceIndexes.length} ${pluralize(
            'row',
            highlightedInstanceIndexes.length
          )}`,
          disabled: !context.copyInstances,
          action() {
            const lastIndex = highlightedInstanceIndexes.slice(-1)[0];

            let entityToInsertAt;
            let copies = List();

            api.forEachNodeAfterFilterAndSort(({ data }, index) => {
              if (highlightedInstanceIndexes.indexOf(index) > -1) {
                copies = copies.push(data.copy);
              }

              if (index === lastIndex) {
                entityToInsertAt = data.id;
              }
            });

            context.copyInstances(copies, { insertAt: entityToInsertAt });
          },
        },
        'separator',
      ]
      : []),
    ...(canCreate
      ? [
        {
          name: 'Add 1 new row',
          action() {
            context.createInstances &&
                context.createInstances(List(generateEmptyItems(1)), {
                  insertAt: node.data.id,
                  ignoreValidate: true,
                });
          },
        },
      ]
      : []),
    ...(canCreate
      ? [
        {
          name: 'Add 5 new row',
          action() {
            context.createInstances &&
                context.createInstances(List(generateEmptyItems(5)), {
                  insertAt: node.data.id,
                  ignoreValidate: true,
                });
          },
        },
      ]
      : []),
    ...(canCreate
      ? [
        {
          name: 'Add 20 new row',
          action() {
            context.createInstances &&
                context.createInstances(List(generateEmptyItems(20)), {
                  insertAt: node.data.id,
                  ignoreValidate: true,
                });
          },
        },
      ]
      : []),
    ...(canCreate ? ['separator'] : []),
    {
      name: 'How does this grid work?',
      action() {
        alert('This guide will be released in the near future');
      },
    },
    {
      name: 'Show all shortcuts',
      action() {
        alert('This guide will be released in the near future');
      },
    },
  ];
};

const ROW_HEIGHT = 56;
const LIST_ROW_HEIGHT = 64;
const SETTING_ROW_HEIGHT = 80;
const HEADER_HEIGHT = 56;
const GROUP_HEADER_HEIGHT = 38;

const defaultValueSetter = ({ data, colDef, oldValue, newValue, context }) => {
  oldValue !== newValue &&
    context.editInstances &&
    context.editInstances(
      isArray(colDef.field)
        ? data.setIn(colDef.field, newValue)
        : data.setIn(colDef.field.split('.'), newValue)
    );
  return false;
};

// we disable all table navigation if the column is being editing
const defaultSuppressKeyboardEvent = ({ event, editing }) =>
  editing &&
  !!event.keyCode &&
  [37, 38, 39, 40, 13].indexOf(event.keyCode) > -1;

export const CELL_RENDERER_UTILS = {
  GET_UNIT_APPEND: ({ colDef, context = {} }) =>
    ((context.defaultUnitSystem || empty)[colDef.unitType] || empty)
      .abbreviation,
};

const BaseGrid = ({
  data,
  editInstances,
  copyInstances,
  createInstances,
  defaultUnitSystem,
  customContext,
  isList,
  isSettings,
  onRowDragEnd,
  ...props
}) => {
  const apiRef = useRef();

  const [gridState, setGridState] = useState({
    gridIsReady: false,
    onResizeHandler: debounce(() => null, 25),
  });

  const dispatch = useDispatch();

  const onCellEditingStarted = useCallback(() => dispatch(startCellEditing()), [
    dispatch,
  ]);
  const onCellEditingStopped = useCallback(() => dispatch(stopCellEditing()), [
    dispatch,
  ]);

  const columnTypes = useMemo(
    () => ({
      entitySaveStatusCell: {
        headerName: '',
        field: 'status',
        width: 50,
        suppressSizeToFit: true,
        suppressMovable: true,
        resizable: false,
        suppressNavigable: true,
        cellRenderer: 'entitySaveStatusCellRenderer',
        cellClass: [agCadactiveUnselectableIconCell],
      },
      entitySelectionCell: {
        headerName: '',
        field: 'status',
        width: 50,
        suppressSizeToFit: true,
        suppressMovable: true,
        resizable: false,
        suppressNavigable: true,
        cellRenderer: 'entitySelectionCellRenderer',
        cellRendererParams: ({ data }) => ({
          canForcefullyDelete: data.canForcefullyDelete,
          canSafelyDelete: data.canSafelyDelete,
        }),
        cellClass: ({ data }) =>
          `${agCadactiveUnselectableIconCell} ${
            data.canForcefullyDelete ? agCadactiveClickableCell : ''
          }`,
        onCellClicked: ({ data, context, api, column }) => {
          const ranges = api.getCellRanges();
          const value = data.isSelected;

          if (ranges) {
            if (ranges.length === 1) {
              const firstRange = ranges[0] || {
                columns: [],
                startRow: {},
                endRow: {},
              };
              const isSingleCell =
                firstRange.startRow.rowIndex === firstRange.endRow.rowIndex;
              // we have exactly one range, which is what we want
              if (
                firstRange.columns.length === 1 &&
                firstRange.columns[0].colId === column.colId
              ) {
                if (isSingleCell) {
                  if (data.canForcefullyDelete) {
                    context.editInstances(data.set('isSelected', !value));
                  }
                } else {
                  let indexes = [];
                  let currentIndex;
                  if (
                    !isNil(firstRange.startRow.rowIndex) &&
                    !isNil(firstRange.endRow.rowIndex)
                  ) {
                    for (
                      currentIndex = firstRange.startRow.rowIndex;
                      currentIndex <= firstRange.endRow.rowIndex;
                      currentIndex++
                    ) {
                      indexes.push(currentIndex);
                    }
                    let edits = List();
                    let selectedValueToSet = false;

                    api.forEachNodeAfterFilterAndSort(
                      ({ data: data }, index) => {
                        if (index === firstRange.startRow.rowIndex) {
                          selectedValueToSet = data.isSelected;
                        }

                        if (
                          indexes.indexOf(index) > -1 &&
                          data.canForcefullyDelete
                        ) {
                          edits = edits.push(data);
                        }
                      }
                    );

                    edits.forEach((data) =>
                      context.editInstances(
                        data.set('isSelected', selectedValueToSet)
                      )
                    );
                  }

                  api.clearRangeSelection();
                }
              } else {
                // multiple columns in this singular range
                api.clearRangeSelection();
                ranges.forEach(({ columns, startRow, endRow }) => {
                  api.addCellRange({
                    rowStartIndex: startRow.rowIndex,
                    rowEndIndex: endRow.rowIndex,
                    // assuming that isSelected is always the first column in the table
                    columnStart: columns[1],
                    columnEnd: columns[columns.length - 1],
                  });
                });
              }
            } else {
              // multiple ranges, remove any isSelected column selections from the ranges
              api.clearRangeSelection();
              ranges.forEach(({ columns, startRow, endRow }) => {
                const incomingRange = {
                  rowStartIndex: startRow.rowIndex,
                  rowEndIndex: endRow.rowIndex,
                  columnEnd: columns[columns.length - 1].colId,
                };

                if (columns.length === 1) {
                  // assuming that isSelected is always the first column in the table
                  if (columns[0].colId !== column.colId) {
                    api.addCellRange({
                      ...incomingRange,
                      columnStart: columns[0].colId,
                    });
                  }
                } else {
                  // assuming that isSelected is always the first column in the table
                  if (columns[0].colId === column.colId) {
                    api.addCellRange({
                      ...incomingRange,
                      columnStart: columns[1].colId,
                    });
                  } else {
                    api.addCellRange({
                      ...incomingRange,
                      columnStart: columns[0].colId,
                    });
                  }
                }
              });
            }
          } else {
            if (data.canForcefullyDelete) {
              context.editInstances(data.set('isSelected', !value));
            }
          }
        },
      },
      iconCell: {
        width: 50,
        suppressSizeToFit: true,
        resizable: false,
        suppressNavigable: true,
        cellRenderer: 'iconCellRenderer',
        cellClass: [agCadactiveUnselectableIconCell],
      },
      toggleCell: {
        suppressSizeToFit: true,
        resizable: true,
        suppressNavigable: true,
        cellRenderer: 'iconCellRenderer',
        cellRendererParams: ({ value }) => ({
          name: (value && 'checkbox') || 'checkbox-outline',
          color: (value && 'primary.4') || 'gray.6',
        }),
        cellClass: [agCadactiveUnselectableIconCell],
        cellStyle: ({ colDef }) =>
          (colDef.editable && { cursor: 'pointer' }) || {},
        onCellClicked: ({ data, value, colDef, context }) => {
          if (colDef.editable) {
            context.editInstances(
              isArray(colDef.field)
                ? data.setIn(colDef.field, !value)
                : data.setIn(colDef.field.split('.'), !value)
            );
          }
        },
      },
      entitySelectionForIdCell: {
        headerName: '',
        field: 'isSelectedForId',
        width: 50,
        suppressSizeToFit: true,
        suppressMovable: true,
        resizable: false,
        suppressNavigable: true,
        cellRenderer: 'iconCellRenderer',
        cellClass: [agCadactiveUnselectableIconCell, agCadactiveClickableCell],
        cellRendererParams: ({ data }) => ({
          name: data.isSelectedForId ? 'checkbox' : 'checkbox-outline',
          color: data.isSelectedForId ? 'primary.4' : 'gray.6',
        }),
        onCellClicked: ({ data, context }) => {
          context.editInstances(data.set('isSelectedForId', !data.isSelectedForId));
        },
      },
      addCellClassRules: {
        cellClassRules,
      },
      basicCell: {
        cellRenderer: 'basicCellRenderer',
        cellClassRules,
      },
      basicEditor: {
        cellEditor: 'basicCellEditor',
        valueSetter: defaultValueSetter,
      },
      multiLineCell: {
        cellRenderer: 'multiLineCellRenderer',
        cellClassRules,
      },
      multiLineEditor: {
        cellEditor: 'multiLineCellEditor',
        valueSetter: defaultValueSetter,
        suppressKeyboardEvent: defaultSuppressKeyboardEvent,
      },
      optionalUnitCell: {
        cellRenderer: 'optionalUnitCellRenderer',
        cellClassRules,
      },
      optionalUnitEditor: {
        cellEditor: 'optionalUnitEditor',
      },
      temporaryTextCell: {
        cellRenderer: 'temporaryTextCellRenderer',
      },
      complexCell: {
        cellRenderer: 'complexCellRenderer',
      },
      entityCell: {
        cellRenderer: 'entityCellRenderer',
        cellClassRules,
      },
      colorCell: {
        cellRenderer: 'colorCellRenderer',
        width: 30,
        cellClassRules,
      },
      colorEditor: {
        cellEditor: 'colorDropdownEditor',
        valueSetter: defaultValueSetter,
      },
      appearanceCell: {
        cellRenderer: 'appearanceCellRenderer',
        cellClassRules,
      },
      unitCell: {
        width: 200,
        suppressSizeToFit: true,
        cellRenderer: 'basicCellRenderer',
        cellClassRules,
        cellEditor: 'basicCellEditor',
        valueGetter: ({ data, colDef, context = {} }) => {
          const field =
            (isArray(colDef.field) && colDef.field) || colDef.field.split('.');
          if (field.length > 1) {
            const parentField = field.slice(0, -1);
            const [unitField] = field.slice(-1);
            return (
              (data.getIn(parentField) &&
                data
                  .getIn(parentField)
                  .convertMeasurement(unitField, context.defaultUnitSystem)) ||
              null
            );
          } else {
            return data.convertMeasurement(field[0], context.defaultUnitSystem);
          }
        },
        valueSetter: ({
          data,
          colDef,
          oldValue,
          newValue,
          context = {},
          ...rest
        }) => {
          const unitId =
            ((context.defaultUnitSystem || empty)[colDef.unitType] || empty)
              .id || null;
          const field =
            (isArray(colDef.field) && colDef.field) || colDef.field.split('.');

          if (oldValue !== newValue && !!unitId) {
            if (data.getIn(field).size > 0) {
              const fieldList = data.getIn(field);

              context.editInstances(
                data.setIn(
                  field,
                  fieldList.update(
                    fieldList.findIndex((d) => `${d.id}` === `${unitId}`),
                    (listItem) => ({ ...listItem, value: newValue })
                  )
                )
              );
            } else {
              const fieldList = data.get(field) ? data.get(field) : List();
              context.editInstances(
                data.setIn(
                  field,
                  fieldList.push({ id: `${unitId}`, value: newValue })
                )
              );
            }
          }

          return false;
        },
        cellRendererParams: (params) => ({
          append: CELL_RENDERER_UTILS.GET_UNIT_APPEND(params),
        }),
        cellEditorParams: (params) => ({
          append: CELL_RENDERER_UTILS.GET_UNIT_APPEND(params),
          allow: E_ALLOWANCES.DOUBLE,
        }),
      },
      listCreatorRenderer: {
        cellRenderer: 'listCreatorRenderer',
        cellClassRules,
      },
      listCreatorEditor: {
        cellEditor: 'listCreatorEditor',
        // very similar to default, but this component doesn't allow up-down arrows
        suppressKeyboardEvent: ({ event, editing }) =>
          editing &&
          !!event.keyCode &&
          [37, 39, 13].indexOf(event.keyCode) > -1,
      },
      listCell: {
        cellRenderer: 'listCellRenderer',
        cellClassRules,
      },
      radioEditor: {
        cellEditor: 'radioEditor',
        valueSetter: defaultValueSetter,
        // very similar to default, but radio dropdown doesn't allow left-right arrows
        suppressKeyboardEvent: ({ event, editing }) =>
          editing &&
          !!event.keyCode &&
          [38, 40, 13].indexOf(event.keyCode) > -1,
      },
      dropdownEditor: {
        cellEditor: 'dropdownEditor',
        valueSetter: defaultValueSetter,
        suppressKeyboardEvent: defaultSuppressKeyboardEvent,
      },
      multiDropdownEditor: {
        cellEditor: 'multiDropdownEditor',
        valueSetter: defaultValueSetter,
        suppressKeyboardEvent: defaultSuppressKeyboardEvent,
      },
      linkCell: {
        cellStyle: { cursor: 'pointer' },
        onCellClicked: ({ value }) => {
          if (value) dispatch(push(value.to));
        },
      },
      archivedStatusCell: {
        headerName: '',
        field: 'archivedFlag',
        width: 50,
        suppressSizeToFit: true,
        cellRenderer: 'archivedStatusCellRenderer',
      },
      dataActionIconCell: {
        headerName: '',
        cellRenderer: 'dataActionIconCellRenderer',
        suppressSizeToFit: true,
        field: 'archivedFlag',
        width: 225,
      },
      dataActionDropdownCell: {
        headerName: '',
        cellRenderer: 'dataActionDropdownCellRenderer',
        suppressSizeToFit: true,
        field: 'archivedFlag',
        width: 50,
      },
      settingCell: {
        field: 'usableValue',
        cellStyle: ({ data }) =>
          (data.valueType === 'bool' && { cursor: 'pointer' }) || {},
        editable: ({ data }) => data.valueType !== 'bool',
        valueGetter: ({ data }) =>
          ((data.usableValue === null || data.usableValue === undefined) &&
            data.usableDefaultValue) ||
          data.usableValue,
        valueSetter: ({ data, newValue, context }) => {
          data.valueType !== 'bool' &&
            context.editInstances &&
            context.editInstances(data.setValue(newValue));
          return false;
        },
        cellRendererSelector: ({ data }) => {
          const settingType = data.valueType;
          switch (settingType) {
          case 'string': {
            if (data.multipleAllowed) {
              return {
                component: 'multipleAllowedStringCellRenderer',
                params: { placeholder: data.defaultValue },
              };
            } else {
              return {
                component: 'basicCellRenderer',
                params: { placeholder: data.defaultValue },
              };
            }
          }

          case 'list':
          case 'integer':
          case 'double': {
            return {
              component: 'basicCellRenderer',
              params: {
                placeholder: data.defaultValue,
                isDropdown: settingType === 'list',
              },
            };
          }

          case 'bool': {
            return {
              component: 'iconCellRenderer',
              params: {
                name: (data.usableValue && 'checkbox') || 'checkbox-outline',
                color: (data.usableValue && 'primary.4') || 'gray.6',
              },
            };
          }

          case 'color': {
            return { component: 'colorCellRenderer' };
          }

          default: {
            return { component: 'basicCellRenderer' };
          }
          }
        },
        cellEditorSelector: ({ data }) => {
          const settingType = data.valueType;
          switch (settingType) {
          case 'string': {
            if (data.multipleAllowed) {
              return { component: 'multipleALlowedStringCellEditor' };
            } else {
              return {
                component: 'basicCellEditor',
                params: { placeholder: data.usableDefaultValue },
              };
            }
          }

          case 'integer':
          case 'double': {
            return {
              component: 'basicCellEditor',
              params: { placeholder: data.usableDefaultValue },
            };
          }

          case 'list': {
            return {
              component: 'radioEditor',
              params: {
                placeholder: data.usableDefaultValue,
                options: data.listValues,
              },
            };
          }

          case 'color': {
            return { component: 'colorDropdownEditor' };
          }

          default: {
            return {
              component: 'basicCellEditor',
              params: { placeholder: data.usableDefaultValue },
            };
          }
          }
        },
        onCellClicked: ({ data, value, colDef, context }) => {
          if (colDef.editable && data.valueType === 'bool') {
            context.editInstances(data.setValue(!value));
          }
        },
        suppressKeyboardEvent: ({ data, event, editing }) =>
          data.valueType === 'list' &&
          defaultSuppressKeyboardEvent({ event, editing }),
      },
      settingDetailsCell: {
        cellRenderer: 'settingDetailsRenderer',
      },
      matrixCell: {
        cellRenderer: 'matrixCellRenderer',
      },
    }),
    [dispatch]
  );

  const debouncedEditInstances = useCallback(
    (editInstances && debounceCaptureEditsAsMap(editInstances)) || null,
    [editInstances]
  );

  const gridContext = useMemo(
    () => ({
      editInstances: debouncedEditInstances,
      copyInstances,
      createInstances,
      defaultUnitSystem,
      ...(customContext || {}),
    }),
    [
      editInstances,
      copyInstances,
      createInstances,
      defaultUnitSystem,
      customContext,
    ]
  );

  const onGridReady = useCallback(
    (params) => {
      // can access params.api + params.columnApi here if needed
      apiRef.current = params.api;

      // size columns to fit
      params.api.sizeColumnsToFit();

      // set onResizeHandler and grid state to ready
      setGridState((prev) => ({
        ...prev,
        gridIsReady: true,
        onResizeHandler: debounce(() => {
          params.api.sizeColumnsToFit();
          params.api.refreshHeader();
        }, 25),
      }));
    },
    [setGridState]
  );

  // used for toggling hidden columns - we want to size columns to fit
  // whenever this happens
  const onGridColumnsChanged = useCallback(() => {
    apiRef.current && apiRef.current.sizeColumnsToFit();
  }, [!apiRef.current]);

  return (
    <MasterContainer>
      {/* <LoadingGridContainer columnDefs={columnDefs} headerHeight={HEADER_HEIGHT} rowHeight={ROW_HEIGHT} /> */}
      <Container
        className={`${agGridThemeClass} cadactive-theme-material ${
          (gridState.gridIsReady && 'gridIsReady') || ''
        }`}
      >
        <GridStyle isList={isList} />
        <AgGridReact
          reactNext
          deltaRowDataMode
          rowData={data}
          getRowNodeId={getRowNodeId}
          rowClassRules={rowClassRules}
          rowSelection="multiple"
          headerHeight={HEADER_HEIGHT}
          groupHeaderHeight={GROUP_HEADER_HEIGHT}
          rowHeight={
            isSettings
              ? SETTING_ROW_HEIGHT
              : isList
                ? LIST_ROW_HEIGHT
                : ROW_HEIGHT
          }
          frameworkComponents={frameworkComponents}
          defaultColDef={defaultColDef}
          columnTypes={columnTypes}
          enableRangeSelection={!isList}
          suppresMultiRangeSelection
          sidebar={false}
          suppressRowClickSelection
          suppressRowDrag={!onRowDragEnd}
          onRowDragEnd={onRowDragEnd}
          suppressLoadingOverlay
          suppressCsvExport
          suppressExcelExport
          suppressDragLeaveHidesColumns
          suppressScrollOnNewData
          suppressMovableColumns={isList}
          suppressCellSelection={isList}
          suppressContextMenu={isList}
          getContextMenuItems={getContextMenuItems}
          context={gridContext}
          onGridReady={onGridReady}
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          onGridColumnsChanged={onGridColumnsChanged}
          {...props}
        />

        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={gridState.onResizeHandler}
        />
      </Container>
    </MasterContainer>
  );
};

export default BaseGrid;
