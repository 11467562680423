import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import BendMachineForm from '../../../form/templates/BendMachineForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import BendMachineModel from '../../../../entities/BendMachineFamilies/model';
import {
  processCreateBendMachineFamilyForPipeFamily,
  processCopyBendMachineFamilyForPipeFamily,
  processEditBendMachineFamilyForPipeFamily,
  processDeleteBendMachineFamilyForPipeFamily,
} from '../../../../entities/BendMachineFamilies/actions';

import { selectCurrentBendMachineFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

import { prepareBendMachineModal } from './actions';

const emptyBendMachine = new BendMachineModel();

const BendMachineModal = ({ pipeFamilyId, bendMachine, copyEntity, isPerforming, prepareModal, onCreate, onCopy, onUpdate, onDelete }) => {
  useEffect(() => prepareModal(pipeFamilyId), []);

  const onSave = useCallback(data => {
    if (bendMachine) {
      if (copyEntity) {
        onCopy(pipeFamilyId, { ...data, id: bendMachine.id });
      } else {
        onUpdate(pipeFamilyId, bendMachine.id, data);
      }
    } else {
      onCreate(pipeFamilyId, data);
    }
  }, [pipeFamilyId, bendMachine, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(pipeFamilyId, bendMachine.id), [pipeFamilyId, bendMachine, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={bendMachine && bendMachine.name} copy={copyEntity} create='Bend Machine' />

      <BendMachineForm
        initialValues={bendMachine || emptyBendMachine}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        bendMachine && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={bendMachine.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  bendMachine: selectCurrentBendMachineFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.BEND_SPRINGBACKS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      prepareModal: prepareBendMachineModal,
      onCreate: processCreateBendMachineFamilyForPipeFamily,
      onCopy: processCopyBendMachineFamilyForPipeFamily,
      onUpdate: processEditBendMachineFamilyForPipeFamily,
      onDelete: processDeleteBendMachineFamilyForPipeFamily,
    }
  ),
);

export default enhance(BendMachineModal);
