import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import {
  ALLOWANCES,
  PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS,
  PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS_FORMATTED,
  PARAMETER_VALUE_TYPE_OPTIONS,
  PARAMETER_VALUE_TYPES,
  PARAMETER_VALUE_TYPES_FORMATTED,
} from '../../entities/Parameters/model';

import Grid from '../../containers/grid/Grid';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { createParameterDrafts, editParameterDrafts } from './actions';

import { selectCurrentFilteredNormalizedDraftParameters } from './selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredNormalizedDraftParameters(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.PARAMETERS.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editParameterDrafts,
      createInstances: createParameterDrafts,
      copyInstances: createParameterDrafts,
    },
    ({ data, editable }, dispatchProps) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Name',
          field: 'name',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Name',
          },
          cellEditorParams: {
            allow: ALLOWANCES.NAME,
          },
        },
        {
          headerName: 'Value Type',
          field: 'valueType',
          editable,
          type: ['basicCell', 'radioEditor'],
          valueFormatter: ({ value }) =>
            PARAMETER_VALUE_TYPES_FORMATTED[value] || value,
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setEditableDefaultValueType(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: 'Select Value Type',
            isCapitalized: true,
          },
          cellEditorParams: {
            isCapitalized: true,
            isNullable: true,
            options: PARAMETER_VALUE_TYPE_OPTIONS,
          },
        },
        {
          headerName: 'Default Value',
          editable,
          type: ['addCellClassRules'],
          field: 'editableDefaultValue',
          fields: ['defaultValue', 'listValues', 'unit'],
          valueFormatter: ({ value, data }) =>
            (data.valueType === PARAMETER_VALUE_TYPES.BOOL &&
              PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS_FORMATTED[value]) ||
            value,
          valueSetter: ({ data, newValue, context }) => {
            context.editInstances &&
              context.editInstances(data.setEditableDefaultValue(newValue));
            return false;
          },
          cellRendererSelector: ({ data }) => {
            switch (data.valueType) {
            case PARAMETER_VALUE_TYPES.LIST: {
              return { component: 'listCreatorRenderer' };
            }
            case PARAMETER_VALUE_TYPES.DOUBLE: {
              return {
                component: 'optionalUnitCellRenderer',
                params: {
                  placeholder: 'No Default Value Set',
                  append: (data.unit && data.unit.abbreviation) || data.unit,
                },
              };
            }
            default: {
              return {
                component: 'basicCellRenderer',
                params: { placeholder: 'No Default Value Set' },
              };
            }
            }
          },
          cellEditorSelector: ({ data }) => {
            switch (data.valueType) {
            case PARAMETER_VALUE_TYPES.LIST: {
              return {
                component: 'listCreatorEditor',
                params: {
                  minListSize: 2,
                  maxListSize: 100,
                  allow: ALLOWANCES.DEFAULT_VALUE,
                },
              };
            }
            case PARAMETER_VALUE_TYPES.DOUBLE: {
              return {
                component: 'optionalUnitEditor',
                params: { unit: data.unit, allow: ALLOWANCES.DOUBLE },
              };
            }
            case PARAMETER_VALUE_TYPES.BOOL: {
              return {
                component: 'radioEditor',
                params: {
                  options: PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS,
                  isNullable: true,
                },
              };
            }
            case PARAMETER_VALUE_TYPES.INTEGER: {
              return {
                component: 'basicCellEditor',
                params: { allow: ALLOWANCES.INTEGER },
              };
            }
            default: {
              return {
                component: 'basicCellEditor',
                params: { allow: ALLOWANCES.DEFAULT_VALUE },
              };
            }
            }
          },
          suppressKeyboardEvent: ({ data, event, editing }) =>
            (data.valueType === PARAMETER_VALUE_TYPES.LIST ||
              data.valueType === PARAMETER_VALUE_TYPES.BOOL) &&
            editing &&
            event.keyCode === 13,
        },
        {
          headerName: 'Description',
          field: 'description',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Description',
          },
        },
      ],
      ...((editable && dispatchProps) || {}),
    }),
  ),
);

export default enhance(Grid);
