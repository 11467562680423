import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import { List } from 'immutable';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import borders from '../../../assets/themes/base/borders';
import transitions from '../../../assets/themes/base/transitions';
import space from '../../../assets/themes/base/space';

import times from 'lodash/times';
import trim from 'lodash/trim';

import { useFloating } from '@floating-ui/react';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import useStateWithCallback from '../../../hooks/useStateWithCallback';

import Box from '../../common/Box';
import Flex from '../../common/Flex';
import Icon from '../../common/Icon';
import Text from '../../common/Text';

import BaseInput from '../BaseInput';
import BaseAppend from '../BaseAppend';
import renderCreatedValueForList from '../BaseOption/utils/renderCreatedValueForList';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';

const Selectable = styled(Flex)`
  margin-bottom: ${space[2]};
  padding: ${space[2]};
  border: ${borders[1]};
  border-radius: ${radii[2]};

  transition: transform ${transitions.speed.fast} ${transitions.type.out};

  &:last-child {
    margin-bottom: 0;
  }

  ${({ $listValue }) =>
    !$listValue &&
    css`
      transform: scale(0.9);
      background-color: ${colors.gray[0]};
    `}

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      background-color: ${colors.white};
    `}

  ${({ $isSelected }) =>
    $isSelected &&
    css`
      border-color: ${colors.primary[4]};
      background-color: ${colors.primary[0]};
    `}
`;

const SECTION_LIMIT = 25;
const COLUMN_LIMIT = 5;
const calculateRequiredSectionCount = (listValuesSize) => {
  const requiredSections = Math.ceil(listValuesSize / SECTION_LIMIT) || 1;
  return requiredSections > SECTION_LIMIT ? SECTION_LIMIT : requiredSections;
};
const calculateRequiredColumnCount = (listValuesSize) => {
  if (listValuesSize < 25) {
    const requiredColumns = Math.ceil(listValuesSize / COLUMN_LIMIT) || 1;
    return requiredColumns > COLUMN_LIMIT ? COLUMN_LIMIT : requiredColumns;
  } else {
    return COLUMN_LIMIT;
  }
};
const noop = () => null;

const CreatedListValue = ({
  listValue,
  onDeleteListValue,
  onToggleDefaultListValue,
  itemHeight,
}) => {
  const onClick = useCallback(() => onToggleDefaultListValue(listValue.value), [
    listValue,
    onToggleDefaultListValue,
  ]);
  const onDelete = useCallback(() => onDeleteListValue(listValue.value), [
    listValue,
    onDeleteListValue,
  ]);

  return (
    <Selectable
      $listValue={listValue}
      onClick={(listValue && onClick) || undefined}
      $isSelected={listValue && listValue.isSelected}
      isSelected={listValue && listValue.isSelected}
      height={itemHeight}
      flexDirection="column"
      justifyContent="center"
    >
      {(listValue &&
        renderCreatedValueForList({
          item: listValue,
          isSelected: listValue.isSelected,
          onDelete,
        })) ||
        undefined}
    </Selectable>
  );
};

const CreateListInstructions = styled(Text)`
  flex-grow: 1;
  flex-shrink: 0;
`;

const CreateListCount = styled(Text)`
  flex-grow: 0;
  flex-shrink: 0;
`;

const CreatedListSections = styled(Flex)`
  max-height: calc(
    ${({ maxInnerHeight }) => maxInnerHeight}px + (2 * ${space[2]})
  );
  overflow-y: auto;
`;

const CreatedListColumn = styled(Flex)`
  flex-direction: column;

  ${({ isLastColumn }) =>
    (isLastColumn &&
      css`
        width: calc(
          ${({ minBaseWidth }) =>
        !isNaN(minBaseWidth - parseFloat(minBaseWidth))
          ? `${minBaseWidth}px`
          : minBaseWidth} - ${space[2]}
        );
      `) ||
    css`
      width: ${({ minBaseWidth }) =>
    !isNaN(minBaseWidth - parseFloat(minBaseWidth))
      ? `${minBaseWidth}px`
      : minBaseWidth};
      margin-right: ${space[2]};
    `}
`;

const CreatedListValues = ({
  listValues,
  onDeleteListValue,
  onToggleDefaultListValue,
  baseWidth,
  itemHeight,
  onOuterClick = noop,
  minListSize,
  maxListSize,
}) => {
  const containerRef = useRef();
  const scrollableRef = useRef();
  const sectionCount = useMemo(
    () => calculateRequiredSectionCount(listValues.size),
    [listValues.size]
  );
  const columnCount = useMemo(
    () => calculateRequiredColumnCount(listValues.size),
    [listValues.size]
  );
  const minBaseWidth = useMemo(
    () =>
      (baseWidth > 200 &&
        baseWidth / columnCount > 200 &&
        baseWidth / columnCount) ||
      200,
    [baseWidth, columnCount]
  );
  const isListSizeWarning =
    listValues.size >= minListSize && listValues.size < maxListSize;

  useEffect(() => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        behavior: 'smooth',
        top: scrollableRef.current.scrollHeight,
      });
    }
  }, [listValues.size]);

  useOnClickOutside(containerRef, onOuterClick);

  return (
    <Box
      ref={containerRef}
      width={minBaseWidth * columnCount}
      bg="white"
      border={1}
      borderRadius={2}
      boxShadow={2}
    >
      <Flex flexDirection="row" alignItems="center" p={2} borderBottom={1}>
        <CreateListInstructions color="gray.6" fontSize={0}>
          Press <strong>Enter</strong> to add options
        </CreateListInstructions>
        <CreateListCount color="gray..6" fontSize={0}>
          <Text
            as="span"
            color={isListSizeWarning ? 'gray.6' : 'error.4'}
            fontWeight={isListSizeWarning ? '500' : '600'}
          >
            {listValues.size}
          </Text>{' '}
          /{' '}
          <Text
            as="span"
            color={listValues.size < maxListSize ? 'gray.6' : 'error.4'}
            fontWeight={listValues.size < maxListSize ? '500' : '600'}
          >
            {maxListSize}
          </Text>
        </CreateListCount>
      </Flex>
      <CreatedListSections
        ref={scrollableRef}
        flexDirection="column"
        p={2}
        maxInnerHeight={itemHeight * COLUMN_LIMIT}
      >
        {times(sectionCount, (sidx) => (
          <Flex
            flexDirection="row"
            pb={sectionCount !== sidx + 1 ? 2 : undefined}
          >
            {times(columnCount, (idx) => (
              <CreatedListColumn
                key={sidx * SECTION_LIMIT + idx}
                minBaseWidth={minBaseWidth}
                height={itemHeight * COLUMN_LIMIT}
                isLastColumn={columnCount === idx + 1}
              >
                {times(COLUMN_LIMIT, (ridx) => {
                  const itemIndex =
                    sidx * SECTION_LIMIT + idx * COLUMN_LIMIT + ridx;
                  return (
                    <CreatedListValue
                      key={itemIndex}
                      listValue={listValues.get(itemIndex)}
                      onDeleteListValue={onDeleteListValue}
                      onToggleDefaultListValue={onToggleDefaultListValue}
                      itemHeight={itemHeight}
                    />
                  );
                })}
              </CreatedListColumn>
            ))}
          </Flex>
        ))}
      </CreatedListSections>
    </Box>
  );
};

const ForwardedBaseListCreator = ({
  forwardRef,
  type,
  value,
  onChange,
  placeholder,
  width = '100%',
  itemHeight,
  tabIndex,
  minListSize,
  maxListSize,
  allow,
  onDisallow,
  onOuterClick,
}) => {
  const inputRef = useRef(forwardRef);
  const { x, y, reference, floating, strategy } = useFloating({ placement: 'bottom' });

  const onListValuesChange = useCallback(
    ({ values }) => {
      onChange(values);
      inputRef.current && inputRef.current.focus();
    },
    [onChange, inputRef.current]
  );
  const [
    listValueCreatorState,
    setListValueCreatorState,
  ] = useStateWithCallback(
    { isOpen: false, currentValue: '', values: value || List() },
    onListValuesChange
  );

  const handleAppendClick = useCallback(() => {
    setListValueCreatorState(({ isOpen, ...rest }) => ({
      isOpen: !isOpen,
      ...rest
    }));
  }, []);

  const onDeleteListValue = useCallback(
    (removeValue) => {
      setListValueCreatorState(({ values, ...rest }) => ({
        ...rest,
        values: values.filter(({ value }) => value !== removeValue),
      }));
    },
    [setListValueCreatorState]
  );

  const onToggleDefaultListValue = useCallback(
    (toggleValue) => {
      setListValueCreatorState(({ values, ...rest }) => ({
        ...rest,
        values: values.map(({ value, isSelected, ...vrest }) => ({
          ...vrest,
          value,
          isSelected: value === toggleValue ? !isSelected : undefined,
        })),
      }));
    },
    [setListValueCreatorState]
  );

  const onListValueCreatorFocus = useCallback(
    (e) => {
      setListValueCreatorState(({ isOpen, ...rest }) => ({
        ...rest,
        isOpen: true,
      }));
    },
    [setListValueCreatorState]
  );

  const onCurrentValueChange = useCallback(
    (e) => {
      e.persist();
      setListValueCreatorState(({ currentValue, ...rest }) => ({
        ...rest,
        currentValue: e.target.value,
      }));
    },
    [setListValueCreatorState]
  );

  const onListValueSubmit = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        setListValueCreatorState(({ values, currentValue, ...rest }) => {
          if (trim(currentValue).length > 0) {
            const foundIndex = values.findIndex(
              ({ value }) => value === currentValue
            );
            return {
              ...rest,
              currentValue: '',
              values:
                foundIndex === -1 && values.size < maxListSize
                  ? values.push({
                    id: currentValue,
                    value: currentValue,
                    primary: currentValue,
                    isSelected: values.size === 0 || undefined,
                  })
                  : values,
            };
          } else {
            return { values, currentValue, ...rest };
          }
        });
      }
    },
    [setListValueCreatorState]
  );

  return (
    <Flex ref={reference} flexDirection="row" alignItems="center" height="100%">
      <BaseInput
        ref={inputRef}
        value={listValueCreatorState.currentValue}
        onChange={onCurrentValueChange}
        type={type}
        tabIndex={tabIndex}
        allow={allow}
        onDisallow={onDisallow}
        onKeyPress={onListValueSubmit}
        onFocus={onListValueCreatorFocus}
        placeholder={placeholder || 'Add list value'}
      />
      <BaseAppend onClick={handleAppendClick}>
        <Icon name="chevron-down" />
      </BaseAppend>
      {listValueCreatorState.isOpen &&
        ReactDOM.createPortal(
          <Tooltip ref={floating} style={{position: strategy, top : y?? 0, left: x??0}} size="fluid" priority={1}>
            <CreatedListValues
              listValues={listValueCreatorState.values}
              onDeleteListValue={onDeleteListValue}
              onToggleDefaultListValue={onToggleDefaultListValue}
              baseWidth={width}
              itemHeight={itemHeight}
              onOuterClick={onOuterClick}
              minListSize={minListSize}
              maxListSize={maxListSize}
            />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )}
    </Flex>
  );
};

const BaseListCreator = React.forwardRef((props, ref) => (
  <ForwardedBaseListCreator forwardRef={ref} {...props} />
));

export default BaseListCreator;
