import { List } from 'immutable';
import { compose } from 'redux';
import pluralize from 'pluralize';

import EntityRecord, { FILTERS } from '../../modules/entities/model';
import hasMeasurements from '../../modules/entities/decorators/hasMeasurements';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_\- ]{0,255})$/,
};

export const PIPE_SHAPES = {
  CIRCULAR: 'circular',
  RECTANGULAR: 'rectangular',
};

export const LINE_TYPES = {
  FLEXIBLE: 'flexible',
  RIGID: 'rigid',
};

export const CROSS_SECTION_SHAPE_OPTIONS = List([
  {
    id: PIPE_SHAPES.CIRCULAR,
    value: PIPE_SHAPES.CIRCULAR,
    label: 'Circular',
    primary: 'Circular',
  },
  {
    id: PIPE_SHAPES.RECTANGULAR,
    value: PIPE_SHAPES.RECTANGULAR,
    label: 'Rectangular',
    primary: 'Rectangular',
  },
]);

export const LINE_TYPE_OPTIONS = List([
  {
    id: LINE_TYPES.FLEXIBLE,
    value: LINE_TYPES.FLEXIBLE,
    label: 'Flexible',
    primary: 'Flexible',
  },
  {
    id: LINE_TYPES.RIGID,
    value: LINE_TYPES.RIGID,
    label: 'Rigid',
    primary: 'Rigid',
  },
]);

class PipeFamily extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  minLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
  },
  maxLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
  },
  maxOverallLength: {
    value: List(),
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    isUnit: true,
    unitType: 'unitLength',
  },
  minBends: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  maxBends: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  crossSectionShape: {
    value: PIPE_SHAPES.CIRCULAR,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  lineType: {
    value: LINE_TYPES.RIGID,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  unitSystem: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitSystemId',
  },
  material: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'materialId',
    filter: FILTERS.ENTITY,
  },
  pipeInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleSerializeCollection: true,
    handleDeepSerialize: true,
    serializeOutputField: 'pipeInstancesAttributes',
  },
  selectedPipeInstancesForSpec: {
    value: 0,
  },
  isSelectableForPriority: {
    value: false,
  },
  isSelectedForPriority: {
    value: false,
  },
  prioritizationState: {
    value: null,
  },
  sizes: {
    value: List(),
    isEntity: true,
    handleCopy: true,
  },
  schedules: {
    value: List(),
    isEntity: true,
    handleCopy: true,
  },
}) {
  get primary() {
    return this.code;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return `${this.pipeInstances.size} ${
      pluralize(
        'instance',
        this.pipeInstances.size,
      )
    }`;
  }
  get secondaryPlaceholder() {
    return '--';
  }

  get instancesCount() {
    const count = (this.pipeInstances && this.pipeInstances.size) || 0;
    return `${count} ${pluralize('instance', count)}`;
  }

  get summary() {
    return {
      primary: this.name,
      secondary: `${this.crossSectionShape} // ${this.lineType}`,
      tertiary: this.instancesCount,
    };
  }

  isCircular() {
    return this.crossSectionShape === PIPE_SHAPES.CIRCULAR;
  }
  isRectangular() {
    return this.crossSectionShape === PIPE_SHAPES.RECTANGULAR;
  }
}

const enhance = compose(hasMeasurements);

export default enhance(PipeFamily);
