import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectNormalizedSimplifiedRepsList } from '../../entities/SimplifiedReps/selectors';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectNormalizedSimplifiedRepsList(),
  ({ isLoadingInitialData, isFetching, ...rest }, data) => ({
    ...rest,
    isLoading: isLoadingInitialData ||
      (isFetching && (!data || data.size === 0)),
  }),
);

const mapDispatchToProps = (dispatch) => ({
  createEntity() {
    dispatch(showModal('SIMPLIFIED_REP_MODAL'));
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(Options);
