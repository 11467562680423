import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import BendInstanceSchema from './schema';

export const selectNormalizedBendInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('bendInstances')
);
export const selectNormalizedDraftBendInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('bendInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectBendInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('bendInstances'), [BendInstanceSchema], entities.delete('bendFamilies'))
);
export const selectDraftBendInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftBendInstances(),
  (entities, drafts) => denormalize(drafts, [BendInstanceSchema], entities.delete('bendFamilies'))
);