import React from 'react';
import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { PipingModulePipesPath } from '../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModulePipesPath.defaultTitle}></BreadcrumbTemplate>
  );
}
