import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  INITIAL_FITTING_FAMILY_LOAD,
  CREATE_FITTING_INSTANCE_DRAFTS,
  EDIT_FITTING_INSTANCE_DRAFTS,
  UPDATE_FITTING_INSTANCES_COLLECTION,
} from './constants';

export const initialFittingFamilyLoad = createAction(INITIAL_FITTING_FAMILY_LOAD, fittingFamilyId => ({ fittingFamilyId }));

export const createFittingInstanceDrafts = generateCreateDraftsAction(CREATE_FITTING_INSTANCE_DRAFTS);

export const editFittingInstanceDrafts = generateEditDraftsAction(EDIT_FITTING_INSTANCE_DRAFTS);

export const updateFittingInstancesCollection = createAction(UPDATE_FITTING_INSTANCES_COLLECTION, fittingFamilyId => ({ fittingFamilyId }));
