import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';

import Box from '../../../components/common/Box';
import Icon from '../../../components/common/Icon';

import { DEFAULT_SYMBOL_FILE_ICONS } from '../../../entities/FittingCategories/model';

const IconSelection = styled(Box)`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 4rem;
  background: white;
  cursor: pointer;

  border: 1px solid transparent;
  color: ${colors.gray[7]};

  ${
  ({ selected }) => selected && css`
      border: 1px solid ${colors.primary[4]};
      color: ${colors.primary[4]};
    `
}
`;

const SelectableIcon = ({ isSelected, onSelect, name }) => {
  const onSelectCategory = useCallback(
    _ => onSelect(name),
    [onSelect, name]
  );

  return (
    <IconSelection selected={isSelected} onClick={onSelectCategory}>
      <Icon name={name} fontSize={4} style='ptc' />
    </IconSelection>
  );
};

const PTCIconSelection = ({ setCurrentValue, currentValue }) => (
  <Box width='20rem' border={1}>
    { DEFAULT_SYMBOL_FILE_ICONS.map(icon => <SelectableIcon name={icon.name} onSelect={setCurrentValue} isSelected={icon.name === currentValue} key={icon.name} />) }
  </Box>
);

export default PTCIconSelection;