import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveProjects,
  processUnarchiveProjects,
} from '../../entities/Projects/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import {
  selectCurrentProject,
  selectDraftSpecsSelectedForIdsPendingValidChanges,
} from './selectors';

import { updateProjectSpecs } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentProject(),
  selectDraftSpecsSelectedForIdsPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    project,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    projectId: project && project.id || null,
    isArchived: project && project.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  
  return (
    <Options 
      {...props}
      {...reduxProps}
      updateEntities = {() => dispatch(updateProjectSpecs(reduxProps.projectId))}
      editEntity = {() => dispatch(showModal('PROJECT_MODAL', reduxProps.projectId))}
      archiveEntity = {() => dispatch(processArchiveProjects([reduxProps.projectId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveProjects([reduxProps.projectId]))}
    />
  );
}
