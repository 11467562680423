import { createSelector } from 'reselect';
import { List } from 'immutable';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import MaterialSchema from './schema';
import { MATERIAL_FILTERS } from './model';

export const selectNormalizedMaterials = () => createSelector(
  selectEntities(),
  entities => entities.get('materials')
);
export const selectPipeMaterialsOptions = () => createSelector(
  selectNormalizedMaterials(),
  materials => materials.reduce((output, material) => MATERIAL_FILTERS.PIPE(material) ? output.push(material.toOption()) : output, List())
);
export const selectFittingMaterialsOptions = () => createSelector(
  selectNormalizedMaterials(),
  materials => materials.reduce((output, material) => MATERIAL_FILTERS.FITTING(material) ? output.push(material.toOption()) : output, List())
);
export const selectInsulationMaterialsOptions = () => createSelector(
  selectNormalizedMaterials(),
  materials => materials.reduce((output, material) => MATERIAL_FILTERS.INSULATION(material) ? output.push(material.toOption()) : output, List())
);

export const selectNormalizedDraftMaterials = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('materials');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectMaterials = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('materials'), [MaterialSchema], entities)
);
export const selectDraftMaterials = () => createSelector(
  selectEntities(),
  selectNormalizedDraftMaterials(),
  (entities, drafts) => denormalize(drafts, [MaterialSchema], entities)
);
