import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import useFloatingState from '../../../hooks/useFloatingState';
import useIsHovering from '../../../hooks/useIsHovering';

import Flex from '../../common/Flex';
import Icon from '../../common/Icon';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';

import EntityTooltip from '../../../containers/tooltip/EntityTooltip';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

import BaseAppend from '../../form/BaseAppend';

const EntityCellRenderer = ({
  value,
  ek,
  placeholder,
  isCapitalized,
  isDropdown = true,
}) => {
  const isValuePresent = !!value;

  const [reference, tooltipFloating, tooltipFloatingStyle] = useFloatingState({ placement: 'right' });
  const isHoveringTooltip = useIsHovering(reference, { delayEnter: 1500 });

  return (
    <BaseCell
      ref={reference}
      flexDirection='row'
      alignContent='center'
    >
      <Flex flexDirection='column' justifyContent='center' minWidth={0} width='100%'>
        <BaseCellDataItem primary disabled={!isValuePresent} pb={(isValuePresent || (placeholder && placeholder.secondary)) && 1 || undefined}>
          { (isValuePresent && (value.primary || value.primaryPlaceholder)) || (placeholder && placeholder.primary) }
        </BaseCellDataItem>
        <BaseCellDataItem secondary disabled={!isValuePresent}>
          { (isValuePresent && (value.secondary || value.secondaryPlaceholder) || placeholder && placeholder.secondary) }
        </BaseCellDataItem>
      </Flex>
      {
        isDropdown && (
          <BaseAppend isCapitalized={isCapitalized}>
            <Icon name='chevron-down' />
          </BaseAppend>
        ) || null
      }

      {
        isValuePresent && isHoveringTooltip && ReactDOM.createPortal(
          <Tooltip ref={tooltipFloating} style={tooltipFloatingStyle} size='large'>
            <EntityTooltip ek={ek} id={value.id} />
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseCell>
  );
};

export default EntityCellRenderer;