import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import NoteModel from '../../entities/Notes/model';
import NoteSchema from '../../entities/Notes/schema';
import { selectNormalizedNotes, selectDraftNotes } from '../../entities/Notes/selectors';

import {
  selectCurrentDraftNoteIds,
} from './selectors';

import { processSaveNotes } from '../../entities/Notes/actions';
import { saveNotesTypes } from '../../entities/Notes/constants';

import {
  CREATE_NOTE_DRAFTS,
  EDIT_NOTE_DRAFTS,
  UPDATE_NOTES_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.NOTES,
  schema: NoteSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedNotes,
});

const moveSaga = generateMoveSaga({
  keys: EK.NOTES,
  model: NoteModel,
  originalNormalizedDataSelector: selectNormalizedNotes,
  currentIdsSetSelector: selectCurrentDraftNoteIds,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.NOTES,
  model: NoteModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftNoteIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.NOTES,
  model: NoteModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectDraftNotes,
  currentIdsSetSelector: selectCurrentDraftNoteIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectDraftNotes,
  processUpdateAction: processSaveNotes,
  processUpdateTypes: saveNotesTypes,
  moveSaga,
});

export function* createDraftNotessSaga() {
  yield takeEvery(CREATE_NOTE_DRAFTS, createSaga);
}

export function* editDraftNotessSaga() {
  yield takeEvery(EDIT_NOTE_DRAFTS, editSaga);
}

export function* saveDraftNotessSaga() {
  yield takeLatest(UPDATE_NOTES_COLLECTION, updateSaga);
}

export function* watchNotessDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftNotessSaga),
      fork(editDraftNotessSaga),
      fork(saveDraftNotessSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.NOTES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchNotessDraftsSaga);
}
