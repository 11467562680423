import React from 'react';
import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { PipingModuleSpecsPipesEditPath } from '../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModuleSpecsPipesEditPath.defaultTitle}></BreadcrumbTemplate>
  );
}
