import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModulePipesBendSpringbacksEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentBendSpringback,
  selectCurrentEditedDraftBendSpringbackInstancesCount,
  selectCurrentFilteredDraftBendSpringbackInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentBendSpringback(),
  selectCurrentEditedDraftBendSpringbackInstancesCount(),
  selectCurrentFilteredDraftBendSpringbackInstances(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    bendSpringback,
    count,
    filtered
  ) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title:
      (bendSpringback && bendSpringback.name) ||
      PipingModulePipesBendSpringbacksEditPath.defaultTitle,
    subtitle: isFiltering
      ? `Showing ${filtered.size}/${count} ${pluralize(
        'filtered Bend Springback Instance',
        count
      )}`
      : `Showing ${count} ${pluralize(
        'Bend Springback Instance',
        count
      )} Total`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
