import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import reduce from 'lodash/reduce';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import SpecFittingInstanceSchema from '../../entities/SpecFittingInstances/schema';
import {
  selectNormalizedSpecFittingInstances,
  selectNormalizedDraftSpecFittingInstances,
} from '../../entities/SpecFittingInstances/selectors';

import { selectNormalizedFittingCodes } from '../../entities/FittingCodes/selectors';
import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';

import { selectCurrentFittingId } from '../SpecsEditSwitch/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const specFittingInstanceFilterForm = formValueSelector(
  `filters.${EK.SPEC_FITTING_INSTANCES.state}`
);

export const selectCurrentFittingCode = () =>
  createSelector(
    selectCurrentFittingId(),
    selectNormalizedFittingFamilies(),
    selectNormalizedFittingCodes(),
    (fittingId, fittings, fittingCodes) =>
      fittingCodes.get(fittings.getIn([fittingId, 'fittingCode']))
  );

export const selectCurrentDefaultFittingSelectionName = () =>
  createSelector(
    selectCurrentFittingId(),
    selectNormalizedFittingFamilies(),
    (fittingId, fittings) => fittings.getIn([fittingId, 'selectionName'])
  );

const keys = [
  EK.SIZES.state,
  EK.SCHEDULES.state,
  EK.BOLT_NUTS.state,
  EK.FITTING_CODES.state,
  EK.FITTING_INSTANCES.state,
  EK.SPECS.state,
];
export const selectCurrentSpecFittingInstanceDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    Map(
      reduce(
        keys,
        (output, key) => ({ ...output, [key]: entities.get(key) }),
        {}
      )
    )
  );

export const selectOriginalSpecFittingInstancesForFamily = () =>
  createSelector(
    selectNormalizedSpecFittingInstances(),
    selectCurrentSpecFittingInstanceDraftDenormalizeSelector(),
    (instances, denormalizationData) =>
      denormalize(
        instances,
        [SpecFittingInstanceSchema],
        denormalizationData
      )
  );

export const selectCurrentSpecFittingInstancesForFamily = () =>
  createSelector(
    selectNormalizedDraftSpecFittingInstances(),
    selectCurrentSpecFittingInstanceDraftDenormalizeSelector(),
    (instances, denormalizationData) =>
      denormalize(
        instances,
        [SpecFittingInstanceSchema],
        denormalizationData
      )
  );

export const selectCurrentFilteredSpecFittingInstancesForFamily = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectCurrentSpecFittingInstancesForFamily(),
    (state) =>
      specFittingInstanceFilterForm(
        state,
        'schedule',
        'cadModelName',
        'port0Size',
        'port0EndType',
        'port1Size',
        'port1EndType',
        'port2Size',
        'port2EndType',
        'stockno',
        'mccsCode',
        'boltNutFamily'
      ),
    (isFiltering, instances, query) =>
      isFiltering
        ? instances.filter((instance) =>
          instance.doesMatchQuery(query, {
            normalized: false,
            searchAll: true,
          })
        )
        : instances
  );
