import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import SpecFittingInstanceSchema from './schema';

export const selectNormalizedSpecFittingInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('specFittingInstances')
);
export const selectNormalizedDraftSpecFittingInstancesRecords = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn(['specFittingInstances', 'records'])
);
export const selectNormalizedDraftSpecFittingInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('specFittingInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);
