import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentParameterProfile } from './selectors';

import {
  ParameterProfilesEditPath,
} from '../../paths';

const mapStateToProps = createSelector(
  selectCurrentParameterProfile(),
  parameterProfile => ({ title: parameterProfile && parameterProfile.name || ParameterProfilesEditPath.defaultTitle })
);

const enhance = compose(
  connect(mapStateToProps)
);

export default enhance(BreadcrumbTemplate);
