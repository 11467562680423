import React from 'react';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXSearchSelect from '../../RDXSearchSelect';

import renderAppearanceOptionForSelect from '../../../../components/form/BaseOption/utils/renderAppearanceOptionForSelect';

import { ALLOWANCES } from '../../../../entities/Specs/model';
import { validations } from '../../../../entities/Specs/formValidation';

const SpecForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="specName"
          label="Name"
          required
          allow={ALLOWANCES.SPEC_NAME}
          validate={validations['specName']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="mnemonic"
          label="Mnemonic"
          allow={ALLOWANCES.MNEMONIC}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="layerName"
          label="Layer Name"
          allow={ALLOWANCES.LAYER_NAME}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="appearance"
          label="Appearance"
          ek={EK.APPEARANCES}
          required
          validate={validations['appearance']}
          renderValue={renderAppearanceOptionForSelect}
          renderOption={renderAppearanceOptionForSelect}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="defaultInsulationSpec"
          label="Default Insulation Spec"
          ek={EK.INSULATION_SPECS}
          renderValue={renderAppearanceOptionForSelect}
          renderOption={renderAppearanceOptionForSelect}
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const enhance = compose(
  reduxForm({
    form: 'spec',
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(model.serialize());
    },
  })
);

export default enhance(SpecForm);
