import React from 'react';

import Flex from '../../common/Flex';
import Icon from '../../common/Icon';
import ColorSwatch from '../../common/ColorSwatch';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

import BaseAppend from '../../form/BaseAppend';

const AppearanceCellRenderer = ({ 
  value, 
  placeholder, 
  isDropdown = true,
}) => {
  const isValuePresent = !!value;
  
  return (
    <BaseCell 
      flexDirection='row' 
      alignContent='center'
    >
      { 
        isValuePresent && value.tertiary && (
          <Flex flexDirection='column' justifyContent='center' alignContent='center' mr={2}>
            <ColorSwatch color={value.tertiary} height='100%' width='100%' />
          </Flex>
        ) 
      }
      <Flex flexDirection='column' justifyContent='center' minWidth={0} width='100%'>
        <BaseCellDataItem primary disabled={!isValuePresent} pb={(isValuePresent || (placeholder && placeholder.secondary)) && 1 || undefined}>
          { (isValuePresent && (value.primary || value.primaryPlaceholder)) || (placeholder && placeholder.primary) }
        </BaseCellDataItem>
        <BaseCellDataItem secondary disabled={!isValuePresent}>
          { (isValuePresent && (value.secondary || value.secondaryPlaceholder) || placeholder && placeholder.secondary) }
        </BaseCellDataItem>
      </Flex>
      { 
        isDropdown && (
          <BaseAppend>
            <Icon name='chevron-down' />
          </BaseAppend>
        ) || null
      }
    </BaseCell>
  );
};

export default AppearanceCellRenderer;