import {
  take,
  takeLatest,
  takeEvery,
  all,
  call,
  put,
  fork,
  cancelled,
} from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateMoveSaga,
  // generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

// import OrientationModel from "../../entities/Orientations/model";
import OrientationSchema from '../../entities/Orientations/schema';
import {
  selectNormalizedOrientations,
  selectDraftOrientations,
} from '../../entities/Orientations/selectors';

// import { selectCurrentDraftOrientationIds } from "./selectors";

import { processSaveOrientations } from '../../entities/Orientations/actions';
import { saveOrientationsTypes } from '../../entities/Orientations/constants';

import {
  // CREATE_ORIENTATION_DRAFTS,
  EDIT_ORIENTATION_DRAFTS,
  UPDATE_ORIENTATIONS_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.ORIENTATIONS,
  schema: OrientationSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedOrientations,
});

const moveSaga = generateMoveSaga({
  keys: EK.ORIENTATIONS,
  // model: OrientationModel,
  originalNormalizedDataSelector: selectNormalizedOrientations,
  // currentIdsSetSelector: selectCurrentDraftOrientationIds,
});

// const createSaga = generateWatchCreateSaga({
//   keys: EK.ORIENTATIONS,
//   model: OrientationModel,
//   reviewSaga,
//   currentIdsSetSelector: selectCurrentDraftOrientationIds,
// });

const editSaga = generateWatchEditSaga({
  // keys: EK.ORIENTATIONS,
  // model: OrientationModel,
  reviewSaga,
  // draftStateToGetLastEditedIndexSelector: selectDraftOrientations,
  // currentIdsSetSelector: selectCurrentDraftOrientationIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectDraftOrientations,
  processUpdateAction: processSaveOrientations,
  processUpdateTypes: saveOrientationsTypes,
  moveSaga,
});

// export function* createDraftOrientationssSaga() {
//   yield takeEvery(CREATE_ORIENTATION_DRAFTS, createSaga);
// }

export function* editDraftOrientationssSaga() {
  yield takeEvery(EDIT_ORIENTATION_DRAFTS, editSaga);
}

export function* saveDraftOrientationssSaga() {
  yield takeLatest(UPDATE_ORIENTATIONS_COLLECTION, updateSaga);
}

export function* watchOrientationssDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      // fork(createDraftOrientationssSaga),
      fork(editDraftOrientationssSaga),
      fork(saveDraftOrientationssSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.ORIENTATIONS.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchOrientationssDraftsSaga);
}
