import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  INITIAL_INSULATION_FAMILY_LOAD,
  CREATE_INSULATION_INSTANCE_DRAFTS,
  EDIT_INSULATION_INSTANCE_DRAFTS,
  UPDATE_INSULATION_INSTANCES_COLLECTION,
} from './constants';

export const initialInsulationFamilyLoad = createAction(INITIAL_INSULATION_FAMILY_LOAD, insulationFamilyId => ({ insulationFamilyId }));

export const createInsulationInstanceDrafts = generateCreateDraftsAction(CREATE_INSULATION_INSTANCE_DRAFTS);

export const editInsulationInstanceDrafts = generateEditDraftsAction(EDIT_INSULATION_INSTANCE_DRAFTS);

export const updateInsulationInstancesCollection = createAction(UPDATE_INSULATION_INSTANCES_COLLECTION, insulationFamilyId => ({ insulationFamilyId }));
