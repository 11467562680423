import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import InsulationSpecModel from '../../entities/InsulationSpecs/model';
import InsulationSpecSchema from '../../entities/InsulationSpecs/schema';
import { 
  selectNormalizedInsulationSpecs, 
  selectInsulationSpecs,
  selectDraftInsulationSpecs, 
} from '../../entities/InsulationSpecs/selectors';

import {
  selectCurrentDraftInsulationSpecIds,
} from './selectors';

import { processFetchAllInsulationSpecs, processSaveInsulationSpecs } from '../../entities/InsulationSpecs/actions';
import { saveInsulationSpecsTypes, fetchAllInsulationSpecsTypes } from '../../entities/InsulationSpecs/constants';

import {
  CREATE_INSULATION_SPEC_DRAFTS,
  EDIT_INSULATION_SPEC_DRAFTS,
  UPDATE_INSULATION_SPECS_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.INSULATION_SPECS,
  schema: InsulationSpecSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectInsulationSpecs,
});

const moveSaga = generateMoveSaga({
  keys: EK.INSULATION_SPECS,
  model: InsulationSpecModel,
  originalNormalizedDataSelector: selectNormalizedInsulationSpecs, 
  currentIdsSetSelector: selectCurrentDraftInsulationSpecIds, 
});

const createSaga = generateWatchCreateSaga({
  keys: EK.INSULATION_SPECS,
  model: InsulationSpecModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftInsulationSpecIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.INSULATION_SPECS, 
  model: InsulationSpecModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectDraftInsulationSpecs,
  currentIdsSetSelector: selectCurrentDraftInsulationSpecIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectDraftInsulationSpecs,
  processUpdateAction: processSaveInsulationSpecs,
  processUpdateTypes: saveInsulationSpecsTypes,
  moveSaga,
});

export function* createDraftInsulationSpecssSaga() {
  yield takeEvery(CREATE_INSULATION_SPEC_DRAFTS, createSaga);
}

export function* editDraftInsulationSpecssSaga() {
  yield takeEvery(EDIT_INSULATION_SPEC_DRAFTS, editSaga);
}

export function* saveDraftInsulationSpecssSaga() {
  yield takeLatest(UPDATE_INSULATION_SPECS_COLLECTION, updateSaga);
}

export function* watchInsulationSpecssDraftsSaga() {
  try {
    yield put(processFetchAllInsulationSpecs());

    yield take(fetchAllInsulationSpecsTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftInsulationSpecssSaga),
      fork(editDraftInsulationSpecssSaga),
      fork(saveDraftInsulationSpecssSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.INSULATION_SPECS.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchInsulationSpecssDraftsSaga);
}
