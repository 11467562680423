import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import { updateParametersCollection } from './actions';

import { selectCurrentDraftParametersPendingValidChanges } from './selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentDraftParametersPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    pendingValidChanges,
  }),
);

const enhance = compose(
  connect(
    mapStateToProps,
    { updateEntities: updateParametersCollection },
  ),
);

export default enhance(Options);
