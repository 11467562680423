import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/Sizes/model';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { createSizeDrafts, editSizeDrafts } from './actions';

import { selectCurrentFilteredNormalizedDraftSizes } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentFilteredNormalizedDraftSizes(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.SIZES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editSizeDrafts,
      createInstances: createSizeDrafts,
      copyInstances: createSizeDrafts,
    },
    ({ data, editable }, dispatchProps) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Size',
          field: 'name',
          editable,
          type: ['basicCell', 'basicEditor'],
          width: 300,
          suppressSizeToFit: true,
          cellRendererParams: {
            placeholder: 'Size Name',
          },
          cellEditorParams: {
            allow: ALLOWANCES.NAME,
          },
        },
        {
          headerName: 'Code',
          field: 'code',
          editable,
          type: ['basicCell', 'basicEditor'],
          width: 300,
          suppressSizeToFit: true,
          cellRendererParams: {
            placeholder: 'Size Code',
          },
          cellEditorParams: {
            allow: ALLOWANCES.CODE,
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Description',
          },
        },
      ],
      ...((editable && dispatchProps) || {}),
    }),
  ),
);

export default enhance(Grid);
