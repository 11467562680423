import { List } from 'immutable';
import isArray from 'lodash/isArray';

import EntityRecord, {
  COMPARATORS,
  FILTERS,
  VALIDATORS,
} from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^([a-zA-Z0-9_]{0,31})$/,
  DEFAULT_VALUE: /^(.){0,80}$/,
  INTEGER: /^([0-9]{0,80})$/,
  DOUBLE: /^(?=.{0,80}$)([0-9]*(\.)?[0-9]*)$/,
};

export const PARAMETER_VALUE_TYPES = {
  STRING: 'string',
  LIST: 'list',
  INTEGER: 'integer',
  DOUBLE: 'double',
  BOOL: 'bool',
};

export const PARAMETER_VALUE_TYPE_OPTIONS = List([
  {
    id: PARAMETER_VALUE_TYPES.STRING,
    value: PARAMETER_VALUE_TYPES.STRING,
    label: 'String',
    primary: 'String',
  },
  {
    id: PARAMETER_VALUE_TYPES.LIST,
    value: PARAMETER_VALUE_TYPES.LIST,
    label: 'List',
    primary: 'List',
  },
  {
    id: PARAMETER_VALUE_TYPES.INTEGER,
    value: PARAMETER_VALUE_TYPES.INTEGER,
    label: 'Integer',
    primary: 'Integer',
  },
  {
    id: PARAMETER_VALUE_TYPES.DOUBLE,
    value: PARAMETER_VALUE_TYPES.DOUBLE,
    label: 'Double',
    primary: 'Double',
  },
  {
    id: PARAMETER_VALUE_TYPES.BOOL,
    value: PARAMETER_VALUE_TYPES.BOOL,
    label: 'Boolean',
    primary: 'Boolean',
  },
]);

export const PARAMETER_VALUE_TYPES_FORMATTED = {
  [PARAMETER_VALUE_TYPES.STRING]: 'string',
  [PARAMETER_VALUE_TYPES.LIST]: 'list',
  [PARAMETER_VALUE_TYPES.INTEGER]: 'integer',
  [PARAMETER_VALUE_TYPES.DOUBLE]: 'double',
  [PARAMETER_VALUE_TYPES.BOOL]: 'boolean',
};

const LOCAL_VALIDATORS = {
  CREATED_LIST: (listValues) =>
    listValues.size >= 2 &&
    listValues.size <= 100 &&
    listValues.every(({ value }) => value.length <= 80),
};

export const PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS = List([
  { id: null, value: null, label: 'No Default', primary: 'No Default' },
  { id: 'true', value: 'true', label: 'True', primary: 'True' },
  { id: 'false', value: 'false', label: 'False', primary: 'False' },
]);

export const PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS_FORMATTED = {
  true: 'true',
  false: 'false',
};

const RESERVED_PARAMS = List([
  { param_name: 'CREATED_BY_CADACTIVE', reserved_by: 'CadActive' },
  { param_name: 'LOCKED_BY_CADACTIVE', reserved_by: 'CadActive' },
  { param_name: 'CADACTIVE_CREATED_BY', reserved_by: 'CadActive' },
  { param_name: 'CADACTIVE_CREATED_AT', reserved_by: 'CadActive' },
  { param_name: 'CADACTIVE_UPDATED_BY', reserved_by: 'CadActive' },
  { param_name: 'CADACTIVE_UPDATED_AT', reserved_by: 'CadActive' },
  { param_name: 'CADACTIVE_PUBLISHED', reserved_by: 'CadActive' },
  { param_name: 'CADACTIVE_MODEL_TEMPLATE', reserved_by: 'CadActive' },

  { param_name: 'PTC_MODIFIED', reserved_by: 'Windchill' },
  { param_name: 'PTC_COMMON_NAME', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_REVISION', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_ITERATION', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_VERSION', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_LIFECYCLE_STATE', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_LIFECYCLE', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_LOCATION', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_CREATED_BY', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_CREATED_ON', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_MODIFIED_BY', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_MODIFIED_ON', reserved_by: 'Windchill' },
  { param_name: 'PTC_WM_TEAM', reserved_by: 'Windchill' },
  { param_name: 'PTC_ORGANIZATION_ID', reserved_by: 'Windchill' },

  { param_name: 'PROI_VERSION', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_REVISION', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_BRANCH', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_RELEASE', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_LOCATION', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_MODIFIED', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_CREATED_BY', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PROI_CREATED_ON', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PDMDB', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PDMRL', reserved_by: 'Pro/INTRALINK' },
  { param_name: 'PDMREV', reserved_by: 'Pro/INTRALINK' },

  { param_name: 'MODEL_CHECK', reserved_by: 'ModelCheck' },
  { param_name: 'MC_ERRORS', reserved_by: 'ModelCheck' },
  { param_name: 'MC_CONFIG', reserved_by: 'ModelCheck' },
  { param_name: 'MC_MODE', reserved_by: 'ModelCheck' },

  { param_name: 'PTC_MATERIAL_NAME', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_TYPE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_SUB_TYPE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_DESCRIPTION', reserved_by: 'Creo Materials' },
  {
    param_name: 'PTC_MTRL_THERMAL_SOFTENING_COEF',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_MTRL_HARDENING_DEF_BY_TESTS',
    reserved_by: 'Creo Materials',
  },
  { param_name: 'PTC_MATERIAL_MODEL', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_C10', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_C01', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_C11', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_C20', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_C02', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_C30', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_D', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_D1', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_D2', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_D3', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MATERIAL_MODEL_COEF_MU', reserved_by: 'Creo Materials' },
  {
    param_name: 'PTC_MATERIAL_MODEL_COEF_LAMBDA',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_MATERIAL_MODEL_DEF_BY_TESTS',
    reserved_by: 'Creo Materials',
  },
  { param_name: 'PTC_MTRL_HARDENING', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MTRL_HARDENING_LIMIT', reserved_by: 'Creo Materials' },
  {
    param_name: 'PTC_MTRL_EXPONENT_LAW_EXPONENT',
    reserved_by: 'Creo Materials',
  },
  { param_name: 'PTC_MTRL_POWER_LAW_EXPONENT', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MTRL_MODIFIED_MODULUS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MTRL_TANGENT_MODULUS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_MASS_DENSITY', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_POISSON_RATIO', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_YOUNG_MODULUS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_THERMAL_EXPANSION_COEF', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_TENSILE_YIELD_STRESS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_TENSILE_ULTIMATE_STRESS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_COMPR_ULTIMATE_STRESS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_SHEAR_ULTIMATE_STRESS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_SPECIFIC_HEAT', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_THERMAL_CONDUCTIVITY', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_FAILURE_CRITERION_TYPE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_FATIGUE_TYPE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_FATIGUE_MAT_TYPE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_FATIGUE_MAT_FINISH', reserved_by: 'Creo Materials' },
  {
    param_name: 'PTC_FATIGUE_STRNGTH_REDUCT_FCTR',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_TSAI_WU_INTERACTION_TERM_F12',
    reserved_by: 'Creo Materials',
  },
  { param_name: 'PTC_YOUNG_MODULUS_E1', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_YOUNG_MODULUS_E2', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_YOUNG_MODULUS_E3', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_POISSON_RATIO_NU21', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_POISSON_RATIO_NU31', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_POISSON_RATIO_NU32', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_SHEAR_MODULUS_G12', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_SHEAR_MODULUS_G13', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_SHEAR_MODULUS_G23', reserved_by: 'Creo Materials' },
  {
    param_name: 'PTC_THERMAL_EXPANSION_COEF_A1',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_THERMAL_EXPANSION_COEF_A2',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_THERMAL_EXPANSION_COEF_A3',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_TENSILE_ULTIMATE_STRESS_ST1',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_TENSILE_ULTIMATE_STRESS_ST2',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_COMPR_ULTIMATE_STRESS_SC1',
    reserved_by: 'Creo Materials',
  },
  {
    param_name: 'PTC_COMPR_ULTIMATE_STRESS_SC2',
    reserved_by: 'Creo Materials',
  },
  { param_name: 'PTC_THERMAL_CONDUCTIVITY_K1', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_THERMAL_CONDUCTIVITY_K2', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_THERMAL_CONDUCTIVITY_K3', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_INITIAL_BEND_Y_FACTOR', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_BEND_TABLE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_HARDNESS', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_HARDNESS_TYPE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_CONDITION', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_XHATCH_FILE', reserved_by: 'Creo Materials' },
  { param_name: 'PTC_TEMPERATURE', reserved_by: 'Creo Materials' },

  { param_name: 'FITTING_CODE', reserved_by: 'Creo Piping' },
]);

class Parameter extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => {
      if (
        VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
        ALLOWANCES.NAME.test(entity.name)
      ) {
        const uppercaseName = entity.name.toUpperCase();
        return RESERVED_PARAMS.every(
          ({ param_name }) => param_name !== uppercaseName,
        );
      } else {
        return false;
      }
    },
    validateError: (entity) => {
      if (
        VALIDATORS.IS_REQUIRED_STRING(entity.name) &&
        ALLOWANCES.NAME.test(entity.name)
      ) {
        const uppercaseName = entity.name.toUpperCase();
        return 'Name is already a reserved name in Creo';
        // TODO: need to figure out how to properly update the error handling reporting for cell
        // it is not updating correctly
        // const reserved = RESERVED_PARAMS.find(
        //   ({ param_name }) => param_name === uppercaseName
        // );
        // return `The parameter name ${uppercaseName} is reserved by ${reserved.reserved_by}`;
      } else {
        return 'Name must be present, at most 31 characters in length, and can only contain letters, numbers, and \'_\'';
      }
    },
    filter: FILTERS.STRING,
  },
  valueType: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      (entity.valueType !== null || entity.valueType !== undefined) &&
      PARAMETER_VALUE_TYPE_OPTIONS.find(
        (option) => option.value === entity.valueType,
      ),
    validateError: 'Must have a valid value type',
    filter: FILTERS.EXACT_STRING,
  },
  defaultValue: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => {
      const value = entity.defaultValue;

      // because all parameters are optional, null and undefined are acceptable values across the board
      // either of these values means that we will default to the setting's defaultValue
      if (value === null || value === undefined) {
        if (entity.valueType === PARAMETER_VALUE_TYPES.LIST) {
          return LOCAL_VALIDATORS.CREATED_LIST(entity.listValues);
        } else {
          return true;
        }
      }

      return entity.validateValueAgainstType(value);
    },
    validateError: (entity) => entity.validateErrorAgainstType,
  },
  // validation for this field is handled in the validation for defaultValue because the values are tied together obvi
  listValues: {
    value: List(),
    isList: true,
    handleCompare: (current, incoming) =>
      COMPARATORS.CREATED_LIST(current.listValues, incoming.listValues),
    handleSerialize: (entity) =>
      `[${
        entity.listValues.reduce(
          (serialized, listValue, index) =>
            `${serialized}"${listValue.value}"${
              (index !== entity.listValues.size - 1 && ',') || ''
            }`,
          '',
        )
      }]`,
    validate: (entity) =>
      entity.valueType !== PARAMETER_VALUE_TYPES.LIST ||
      LOCAL_VALIDATORS.CREATED_LIST(entity.listValues),
    validateError: (entity) => {
      if (entity.listValues.size < 2) {
        return 'There must be at least 2 list value options present';
      } else if (entity.listValues.size > 100) {
        return 'There cannot be more than 100 list value options present';
      } else if (entity.listValues.every((value) => value.length <= 80)) {
        return 'List value options cannot be longer than 80 characters';
      } else {
        return 'We ran into an internal issue here';
      }
    },
  },
  unit: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'unitId',
  },
  description: {
    value: null,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  constructor({ valueType, listValues, ...other } = {}) {
    let converted = List();
    if (valueType === PARAMETER_VALUE_TYPES.LIST) {
      try {
        converted = JSON.parse(listValues);
        if (isArray(converted)) {
          converted = List(
            converted.map((value) => ({ value, label: value, primary: value })),
          );
        } else {
          converted = List();
        }
      } catch (e) {
        converted = List();
      }
    }

    super({
      ...other,
      valueType,
      listValues: converted,
    });
  }

  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.description;
  }
  get secondaryPlaceholder() {
    return 'No Description';
  }
  get tertiary() {
    return this.valueType;
  }

  get editableDefaultValue() {
    if (this.defaultValue === undefined) {
      return undefined;
    }

    const dv = this.defaultValue;
    switch (this.valueType) {
    case 'bool': {
      return dv === null ? null : `${dv}`;
    }
    case 'list': {
      return dv !== null
        ? this.listValues.map(({ value, ...lv }) => ({
          ...lv,
          value,
          isSelected: dv === value || undefined,
        }))
        : this.listValues;
    }
    case 'double': {
      return { value: dv !== null ? dv : null, unit: this.unit };
    }
    case 'integer': {
      return dv !== null ? dv : null;
    }
    default: {
      // 'integer'
      return dv || null;
    }
    }
  }

  /**
   * Takes a valueType and clears all other properties before changing valueType
   * @param {string} valueType - One of the valid valueTypes for a parameter
   * @return {Entity} Returns the current Entity with the new valueType and emptied properties
   * @public
   */
  setEditableDefaultValueType(valueType) {
    return this.set('valueType', valueType)
      .set('defaultValue', null)
      .set('unit', null)
      .set('listValues', List());
  }

  /**
   * Takes a value, converts it based on the current setting's valueType, then saves it to defaultValue
   * @param {any} value - Any value that you want to set as the defaultValue for the current setting
   * @return {Entity} Returns the current Entity with the converted defaultValue set
   * @public
   */
  setEditableDefaultValue(value) {
    if (value === undefined) {
      return this.set('defaultValue', undefined);
    } else {
      switch (this.valueType) {
      case 'bool': {
        return this.set('defaultValue', value === null ? null : `${value}`);
      }
      case 'list': {
        let selectedValue = null;

        const listValues = value.map(({ isSelected, ...lv }) => {
          if (isSelected) {
            selectedValue = lv;
          }
          return lv;
        });

        return this.set(
          'defaultValue',
          selectedValue === null ? null : selectedValue.value,
        ).set('listValues', listValues);
      }
      case 'double': {
        if (value === null) {
          return this.set('defaultValue', null).set('unit', null);
        } else {
          return this.set('defaultValue', value.value).set(
            'unit',
            value.unit,
          );
        }
      }
      default:
        // 'string', 'integer'
        return this.set('defaultValue', value);
      }
    }
  }

  validateValueAgainstType(value) {
    switch (this.valueType) {
    case PARAMETER_VALUE_TYPES.STRING: {
      return VALIDATORS.IS_REQUIRED_STRING(value) && value.length <= 80;
    }
    case PARAMETER_VALUE_TYPES.LIST: {
      return (
        VALIDATORS.IS_REQUIRED_STRING(value) &&
          this.listValues.find((lv) => lv.value === value) &&
          value.length <= 80
      );
    }
    case PARAMETER_VALUE_TYPES.INTEGER: {
      return VALIDATORS.IS_POSITIVE_INTEGER(value) && value.length <= 80;
    }
    case PARAMETER_VALUE_TYPES.DOUBLE: {
      return VALIDATORS.IS_NUMERIC(value) && value.length <= 80;
    }
    case PARAMETER_VALUE_TYPES.BOOL: {
      return value === 'true' || value === 'false';
    }
    default: {
      return false;
    }
    }
  }

  get validateErrorAgainstType() {
    switch (this.valueType) {
    case PARAMETER_VALUE_TYPES.STRING: {
      return 'A string that is at most 80 characters in length must be present';
    }
    case PARAMETER_VALUE_TYPES.LIST: {
      return 'Only one of the options that is at most 80 characters in length from the list values is allowed';
    }
    case PARAMETER_VALUE_TYPES.INTEGER: {
      return 'A non-negative integer that is at most 80 digits long must be present';
    }
    case PARAMETER_VALUE_TYPES.DOUBLE: {
      return 'A valid number (decimal or integer) that is at most 80 characters in length must be present';
    }
    case PARAMETER_VALUE_TYPES.BOOL: {
      return 'This value may only be either true or false';
    }
    default: {
      return 'This is an invalid type of parameter';
    }
    }
  }
}

export default Parameter;
