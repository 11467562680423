import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import colors from '../../assets/themes/base/colors';
import space from '../../assets/themes/base/space';
import transitions from '../../assets/themes/base/transitions';

import Box from '../../components/common/Box';
import Flex from '../../components/common/Flex';
import Icon from '../../components/common/Icon';
import Text from '../../components/common/Text';

import FilterSidebar from '../templates/Structures/FilterSidebar';
import ShowArchivedWidget from '../templates/Structures/ShowArchivedWidget';

import { toggleSidebarFiltering } from './actions';

import {
  PipingModuleEndTypesPath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleBoltNutsPath,
  PipingBoltNutsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  SynchronizeModuleConfigProfilesPath,
  StandardizeModuleLayersPath,
  StandardizeModuleNotesPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleParametersPath,
  ParameterProfilesEditPath,
  StandardizeModuleSectionsPath,
} from '../../paths';

import SizeFilter from '../Sizes/Filter';
import ScheduleFilter from '../Schedules/Filter';
import EndTypeFilter from '../Piping/EndTypes/Filter';
import FittingCategoryFilter from '../FittingCategories/Filter';
import BoltNutFilter from '../BoltNuts/Filter';
import BoltNutEditFilter from '../BoltNutsEdit/Filter';
import FittingFilter from '../Fittings/Filter';
import FittingEditFilter from '../FittingsEdit/Filter';
import InsulationFilter from '../Insulations/Filter';
import InsulationEditFilter from '../InsulationsEdit/Filter';
import PipeFilter from '../Pipes/Filter';
import PipeEditFilter from '../PipesEdit/Filter';
import PipeBendFilter from '../PipesBends/Filter';
import PipeBendEditFilter from '../PipesBendsEdit/Filter';
import PipeBendMachineFilter from '../PipesBendMachines/Filter';
import PipeBendMachineEditFilter from '../PipesBendMachinesEdit/Filter';
import PipeBendSpringbackFilter from '../PipesBendSpringbacks/Filter';
import PipeBendSpringbackEditFilter from '../PipesBendSpringbacksEdit/Filter';
import PipeMiterFilter from '../PipesMiters/Filter';
import PipeMiterEditFilter from '../PipesMitersEdit/Filter';
import SpecFilter from '../Specs/Filter';
import SpecFittingFilter from '../SpecsFittingsEdit/Filter';
import SpecFittingInstanceFilter from '../SpecsFittingInstancesEdit/Filter';
import SpecPipeFilter from '../SpecsPipesEdit/Filter';
import SpecPipeInstanceFilter from '../SpecsPipeInstancesEdit/Filter';
import ProjectFilter from '../Projects/Filter';
import ProjectEditFilter from '../ProjectsEdit/Filter';

import ConfigProfileFilter from '../Synchronize/ConfigProfiles/Filter';

import LayerFilter from '../Layers/Filter';
import NoteFilter from '../Notes/Filter';
import OrientationFilter from '../Orientations/Filter';
import ParameterFilter from '../Parameters/Filter';
import ParameterProfilesEditFilter from '../ParameterProfilesEdit/Filter';
import SectionFilter from '../Sections/Filter';

const CloseTrigger = styled(Flex)`
  position: absolute;
  top: ${space[5]};
  right: ${space[3]};
  cursor: pointer;
  height: 2.25rem;
  transition: color ${transitions.speed.fast}
    ${transitions.type.inOut};

  &:hover {
    color: ${colors.gray[7]};
  }
`;

const DashboardFilterSidebar = ({ isMinimized, isFiltering }) => {
  const dispatch = useDispatch();
  const closeFilterSidebar = useCallback(
    () => dispatch(toggleSidebarFiltering()),
    [dispatch]
  );

  return (
    <FilterSidebar.Container isFiltering={isFiltering}>
      <FilterSidebar.Inner isMinimized={isMinimized}>
        <FilterSidebar.FormWrapper isMinimized={isMinimized}>
          <Text
            color="gray.7"
            fontSize={4}
            fontWeight="600"
            pt={5}
            pb={5}
            ml={3}
          >
            Filters
          </Text>

          <CloseTrigger
            onClick={closeFilterSidebar}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            p={3}
          >
            <Icon name="close" fontSize={3} />
          </CloseTrigger>

          <Route
            path={[
              PipingModuleBoltNutsPath.url,
              PipingModuleFittingsPath.url,
              PipingModuleInsulationsPath.url,
              PipingModulePipesPath.url,
              PipingModulePipesBendsPath.url,
              PipingModulePipesBendMachinesPath.url,
              PipingModulePipesBendSpringbacksPath.url,
              PipingModulePipesMitersPath.url,
              PipingModuleSpecsPath.url,
              PipingModuleProjectsPath.url,
              SynchronizeModuleConfigProfilesPath.url,
            ]}
            component={ShowArchivedWidget}
          />

          <Switch>
            <Route
              exact
              path={PipingModuleSizesPath.url}
              component={SizeFilter}
            />
            <Route
              exact
              path={PipingModuleSchedulesPath.url}
              component={ScheduleFilter}
            />
            <Route
              exact
              path={PipingModuleEndTypesPath.url}
              component={EndTypeFilter}
            />
            <Route
              exact
              path={PipingModuleFittingCategoriesPath.url}
              component={FittingCategoryFilter}
            />
            <Route
              exact
              path={PipingModuleBoltNutsPath.url}
              component={BoltNutFilter}
            />
            <Route
              exact
              path={PipingBoltNutsEditPath.url}
              component={BoltNutEditFilter}
            />
            <Route
              exact
              path={PipingModuleFittingsPath.url}
              component={FittingFilter}
            />
            <Route
              exact
              path={PipingModuleFittingsEditPath.url}
              component={FittingEditFilter}
            />
            <Route
              exact
              path={PipingModuleInsulationsPath.url}
              component={InsulationFilter}
            />
            <Route
              exact
              path={PipingModuleInsulationsEditPath.url}
              component={InsulationEditFilter}
            />
            <Route
              exact
              path={PipingModulePipesPath.url}
              component={PipeFilter}
            />
            <Route
              exact
              path={PipingModulePipesEditPath.url}
              component={PipeEditFilter}
            />
            <Route
              exact
              path={PipingModulePipesBendsPath.url}
              component={PipeBendFilter}
            />
            <Route
              exact
              path={PipingModulePipesBendsEditPath.url}
              component={PipeBendEditFilter}
            />
            <Route
              exact
              path={PipingModulePipesBendMachinesPath.url}
              component={PipeBendMachineFilter}
            />
            <Route
              exact
              path={PipingModulePipesBendMachinesEditPath.url}
              component={PipeBendMachineEditFilter}
            />
            <Route
              exact
              path={PipingModulePipesBendSpringbacksPath.url}
              component={PipeBendSpringbackFilter}
            />
            <Route
              exact
              path={PipingModulePipesBendSpringbacksEditPath.url}
              component={PipeBendSpringbackEditFilter}
            />
            <Route
              exact
              path={PipingModulePipesMitersPath.url}
              component={PipeMiterFilter}
            />
            <Route
              exact
              path={PipingModulePipesMitersEditPath.url}
              component={PipeMiterEditFilter}
            />
            <Route
              exact
              path={PipingModuleSpecsPath.url}
              component={SpecFilter}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecFittingFilter}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecFittingInstanceFilter}
            />
            <Route
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecPipeFilter}
            />
            <Route
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecPipeInstanceFilter}
            />
            <Route
              exact
              path={PipingModuleProjectsPath.url}
              component={ProjectFilter}
            />
            <Route
              exact
              path={PipingModuleProjectsEditPath.url}
              component={ProjectEditFilter}
            />

            <Route
              exact
              path={SynchronizeModuleConfigProfilesPath.url}
              component={ConfigProfileFilter}
            />

            <Route
              exact
              path={StandardizeModuleLayersPath.url}
              component={LayerFilter}
            />
            <Route
              exact
              path={StandardizeModuleNotesPath.url}
              component={NoteFilter}
            />
            <Route
              exact
              path={StandardizeModuleOrientationsPath.url}
              component={OrientationFilter}
            />
            <Route
              exact
              path={StandardizeModuleParametersPath.url}
              component={ParameterFilter}
            />
            <Route
              exact
              path={ParameterProfilesEditPath.url}
              component={ParameterProfilesEditFilter}
            />
            <Route
              exact
              path={StandardizeModuleSectionsPath.url}
              component={SectionFilter}
            />
          </Switch>
        </FilterSidebar.FormWrapper>
      </FilterSidebar.Inner>
    </FilterSidebar.Container>
  );
};

export default DashboardFilterSidebar;
