import { Map } from 'immutable';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { formValueSelector } from 'redux-form/immutable';
import reduce from 'lodash/reduce';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import SpecPipeInstanceSchema from '../../entities/SpecPipeInstances/schema';
import {
  selectNormalizedSpecPipeInstances,
  selectNormalizedDraftSpecPipeInstances,
} from '../../entities/SpecPipeInstances/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const specPipeInstanceFilterForm = formValueSelector(`filters.${EK.SPEC_PIPE_INSTANCES.state}`);

const keys = [EK.SIZES.state, EK.SCHEDULES.state, EK.END_TYPES.state, EK.PIPE_INSTANCES.state, EK.SPECS.state];
export const selectCurrentSpecPipeInstanceDraftDenormalizeSelector = () => createSelector(
  selectEntities(),
  entities => Map(reduce(keys, (output, key) => ({ ...output, [key]: entities.get(key) }), {}))
);

export const selectOriginalSpecPipeInstancesForFamily = () => createSelector(
  selectNormalizedSpecPipeInstances(),
  selectCurrentSpecPipeInstanceDraftDenormalizeSelector(),
  (instances, denormalizationData) => denormalize(instances, [SpecPipeInstanceSchema], denormalizationData)
);

export const selectCurrentSpecPipeInstancesForFamily = () => createSelector(
  selectNormalizedDraftSpecPipeInstances(),
  selectCurrentSpecPipeInstanceDraftDenormalizeSelector(),
  (instances, denormalizationData) => denormalize(instances, [SpecPipeInstanceSchema], denormalizationData)
);

export const selectCurrentFilteredSpecPipeInstancesForFamily = () => createSelector(
  selectSidebarIsFiltering(),
  selectCurrentSpecPipeInstancesForFamily(),
  state => specPipeInstanceFilterForm(state, 'sizeProperty', 'schedule', 'stockno', 'mccsCode',),
  (isFiltering, instances, query) => isFiltering ? instances.filter(instance => instance.doesMatchQuery(query, { normalized: false, searchAll: true })) : instances
);