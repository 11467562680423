import React from 'react';

import styled, { css } from 'styled-components';

import fontSizes from '../../../assets/themes/base/fontSizes';
import space from '../../../assets/themes/base/space';

import Flex from '../../common/Flex';

const StyledCell = styled(Flex)`
  min-height: 56px;
  height: 100%;
  font-size: ${fontSizes[2]};
  padding-left: ${space[3]};
  padding-right: ${space[3]};

  position: relative;

  flex-grow: 1;
  flex-shrink: 0;

  position: relative;

  ${
  ({ onClick }) => onClick && css`
      cursor: pointer;
      text-decoration: none;
    `
}
`;

const BaseCell = React.forwardRef((props, ref) => <StyledCell ref={ref} { ...props } />);

export default BaseCell;