import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';
import miterInstanceKeys from '../MiterInstances/keys';

import PipeFamilySchema from '../PipeFamilies/schema';
import SizeSchema from '../Sizes/schema';

const miterFamilySchema = baseSchema(keys.state, {
  pipeFamily: { schema: PipeFamilySchema, isId: true },
  miterInstances: { isId: true, idField: 'miterInstanceIds', isList: true },
});

export const miterInstanceSchema = baseSchema(miterInstanceKeys.state, {
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  radius: { isComplexUnit: true },
});

miterFamilySchema.define({ miterInstances: [ miterInstanceSchema ] });

export default miterFamilySchema;
