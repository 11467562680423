import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/MiterInstances/model';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { selectCurrentPipeUnitSystem } from '../PipesEditSwitch/selectors';

import { createMiterInstanceDrafts, editMiterInstanceDrafts } from './actions';

import {
  selectCurrentFilteredDraftMiterInstances,
  selectCurrentMiter,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentMiter(),
  selectCurrentFilteredDraftMiterInstances(),
  selectCurrentPipeUnitSystem(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    miter,
    data,
    defaultUnitSystem,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && miter &&
        !miter.archivedFlag,
      miterId: miter && miter.id || null,
      defaultUnitSystem,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.MITER_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editMiterInstanceDrafts,
      createInstances: createMiterInstanceDrafts,
      copyInstances: createMiterInstanceDrafts,
    },
    (
      { data, defaultUnitSystem, editable, miterId },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      defaultUnitSystem,
      columnDefs: [
        ...(editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ] || []),
        {
          headerName: 'Size',
          field: 'sizeProperty',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Size' },
            ek: EK.SIZES,
          },
          cellEditorParams: { ek: EK.SIZES },
        },
        {
          headerName: 'Radius',
          field: 'radius',
          editable,
          type: ['unitCell'],
          unitType: 'unitLength',
        },
        {
          headerName: 'Min Angle',
          field: 'minAngle',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Min Angle',
          },
        },
        {
          headerName: 'Max Angle',
          field: 'maxAngle',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Max Angle',
          },
        },
        {
          headerName: 'Cut Number',
          field: 'cutNumber',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Cut Number',
          },
          cellEditorParams: {
            allow: ALLOWANCES.CUT_NUMBER,
          },
        },
      ],
      ...(editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              miterFamily: miterId,
              miterFamilyId: miterId,
            },
          });
        },
      } || {}),
    }),
  ),
);

export default enhance(Grid);
