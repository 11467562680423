import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import { PipingBoltNutsEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchiveBoltNutFamilies,
  processUnarchiveBoltNutFamilies,
} from '../../entities/BoltNutFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredNormalizedDraftBoltNuts } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredNormalizedDraftBoltNuts(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const mapDispatchToProps = (dispatch) => ({
  editBoltNut(boltNut) {
    dispatch(showModal('BOLT_NUT_MODAL', { boltNutId: boltNut.id }));
  },
  copyBoltNut(boltNut) {
    dispatch(
      showModal('BOLT_NUT_MODAL', { boltNutId: boltNut.id, copyEntity: true }),
    );
  },
  manageBoltNutInstances(boltNut) {
    dispatch(push(PipingBoltNutsEditPath.generate({ id: boltNut.id })));
  },
  archiveBoltNut(boltNut) {
    dispatch(processArchiveBoltNutFamilies([boltNut.id]));
  },
  unarchiveBoltNut(boltNut) {
    dispatch(processUnarchiveBoltNutFamilies([boltNut.id]));
  },
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    ({ data, showArchived, editable, isList }, dispatchProps) => ({
      isList,
      data,
      columnDefs: [
        {
          type: ['archivedStatusCell'],
          hide: !showArchived,
        },
        {
          headerName: 'Name // Description',
          field: 'name',
          type: ['complexCell', 'linkCell'],
          valueGetter: ({ data }) => ({
            ...data.summary,
            to: (data.id && PipingBoltNutsEditPath.generate({ id: data.id })) ||
              undefined,
          }),
          cellRendererParams: {
            placeholder: {
              primary: 'No Name',
              secondary: 'No Description',
            },
          },
        },
        ...((editable && [
          {
            type: ['dataActionIconCell'],
            cellRendererParams: ({ value }) => ({
              actions: (value &&
                List([
                  {
                    icon: 'list',
                    onClick: dispatchProps.manageBoltNutInstances,
                  },
                  {
                    icon: 'copy',
                    primary: true,
                    onClick: dispatchProps.copyBoltNut,
                  },
                  {
                    icon: 'unarchive',
                    primary: true,
                    onClick: dispatchProps.unarchiveBoltNut,
                  },
                ])) ||
                List([
                  {
                    icon: 'edit',
                    primary: true,
                    onClick: dispatchProps.editBoltNut,
                  },
                  {
                    icon: 'list',
                    onClick: dispatchProps.manageBoltNutInstances,
                  },
                  {
                    icon: 'copy',
                    primary: true,
                    onClick: dispatchProps.copyBoltNut,
                  },
                  {
                    icon: 'archive',
                    error: true,
                    onClick: dispatchProps.archiveBoltNut,
                  },
                ]),
            }),
          },
        ]) ||
          []),
      ],
    }),
  ),
);

export default enhance(Grid);
