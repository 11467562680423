import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import { PipingModuleProjectsEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchiveProjects,
  processFetchAllProjects,
  processUnarchiveProjects,
} from '../../entities/Projects/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredProjects } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredProjects(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function ProjectsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name // Instances',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModuleProjectsEditPath.generate({ id: data.id })) ||
          undefined,
      }),
    },
    reduxProps.editable &&
      {
        type: ['dataActionIconCell'],
        cellRendererParams: ({ value }) => ({
          actions: (value &&
            List([
              {
                icon: 'list',
                onClick: (project) => dispatch(push(PipingModuleProjectsEditPath.generate({ id: project.id }))),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (project) => dispatch(showModal('PROJECT_MODAL', { projectId: project.id, copyEntity: true })),
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: (project) => dispatch(processUnarchiveProjects([project.id])),
              },
            ])) ||
            List([
              {
                icon: 'edit',
                primary: true,
                onClick: (project) => dispatch(showModal('PROJECT_MODAL', { projectId: project.id })),
              },
              {
                icon: 'list',
                onClick: (project) => dispatch(push(PipingModuleProjectsEditPath.generate({ id: project.id }))),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (project) => dispatch(showModal('PROJECT_MODAL', { projectId: project.id, copyEntity: true })),
              },
              {
                icon: 'archive',
                error: true,
                onClick: (project) => dispatch(processArchiveProjects([project.id])),
              },
            ]),
        }),
      }
  ];

  useEffect( () => {
    dispatch(processFetchAllProjects());
  }, []);

  return (
    <Grid
      {...props}
      {...reduxProps}

      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(ProjectsGrid);
