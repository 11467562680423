import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import { INITIAL_INSULATION_SPECS_LOAD, CREATE_INSULATION_SPEC_DRAFTS, EDIT_INSULATION_SPEC_DRAFTS, UPDATE_INSULATION_SPECS_COLLECTION } from './constants';

export const initialInsulationSpecsLoad = createAction(INITIAL_INSULATION_SPECS_LOAD);

export const createInsulationSpecDrafts = generateCreateDraftsAction(CREATE_INSULATION_SPEC_DRAFTS);

export const editInsulationSpecDrafts = generateEditDraftsAction(EDIT_INSULATION_SPEC_DRAFTS);

export const updateInsulationSpecsCollection = createAction(UPDATE_INSULATION_SPECS_COLLECTION);
