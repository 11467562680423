import React from 'react';

import Box from '../../../../components/common/Box';
import Flex from '../../../../components/common/Flex';
import Text from '../../../../components/common/Text';
import Button from '../../../../components/common/Button';

const FilterFormTemplate = ({ pristine, reset, children }) => (
  <Box pr={3} pl={3}>
    <Box as='form' border={1} borderRadius={1} mb={3}>
      { children }
    </Box>

    <Flex flexDirection='column' justifyContent='center' alignItems='center'>
      <Button tiny primary disabled={pristine} onClick={reset}>Clear</Button>
    </Flex>
  </Box>
);

export default FilterFormTemplate;