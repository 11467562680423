import React from 'react';
import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { PipingModuleSpecsFittingsEditPath } from '../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModuleSpecsFittingsEditPath.defaultTitle}></BreadcrumbTemplate>
  );
}
