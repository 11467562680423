import React from 'react';
import styled, { css } from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import space from '../../../assets/themes/base/space';
import transitions from '../../../assets/themes/base/transitions';

// TODO: update and check from react-toggled implementation
// all hail Kent C Dodds
import useToggle from '../../../hooks/useToggle';

import Box from '../../common/Box';

const ToggleInput = styled.input.attrs(_ => ({
  type: 'checkbox',
}))`
  display: none;

  &:selection,
  &::selection {
    background: none;
  }
`;

const ToggleSwitch = styled.label.attrs(({ name }) => ({
  for: name,
}))`
  outline: 0;
  display: block;
  width: ${space[7]};
  height: ${space[4]};
  position: relative;
  cursor: pointer;
  user-select: none;

  &:after,
  &::after,
  &:before,
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: ${space[4]};
    height: ${space[4]};
  }

  &:after,
  &::after {
    border-radius: 50%;
    top: 0;
    border: 1px solid ${colors.gray[4]};
    ${
  ({ disabled }) => disabled ? css`
        background: ${colors.gray[2]};
      ` : css`
        background: ${colors.gray[3]};
      `
}
    transition: background ${transitions.speed.fast} ${transitions.type.inOut},
      left ${transitions.speed.fast} ${transitions.type.inOut},
      right: ${transitions.speed.fast} ${transitions.type.inOut};
  }

  background: ${colors.gray[1]};
  border-radius: ${space[6]};
  padding: ${space[1]};

  ${
  ({ on }) => on ? css`
      &:after,
      &::after {
        right: 0;
      }
    ` : css`
      &:after,
      &::after {
        left: 0;
      }
    `
}

  ${
  ({ on, disabled }) => !!on && (
    disabled ? css`
        background: ${colors.primary[2]};
      ` : css`
        background: ${colors.primary[4]};
      `
  )
}

  &:before,
  &::before {
    display: none;
  }
`;

const BaseToggle = ({ name, disabled, onToggle, on : incomingOn, defaultOn = false, ...props }) => {
  const [on, toggle] = useToggle(incomingOn || defaultOn, onToggle);

  return (
    <Box { ...props }>
      <ToggleInput ariaExpanded={on} tabIndex={0} name={name} />
      <ToggleSwitch on={on} name={name} disabled={disabled} onClick={!disabled && toggle || undefined} />
    </Box>
  );
};

export default BaseToggle;
