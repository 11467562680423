import { createSelector } from 'reselect';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectDrafts from '../../modules/drafts/selectors';

import { selectNormalizedDraftSchedules } from '../../entities/Schedules/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

export const selectCurrentDraftSchedulesPendingValidChanges = () => createSelector(
  selectDrafts(),
  drafts => drafts.getIn([EK.SCHEDULES.state, 'saveable']).size > 0
);

export const selectCurrentDraftScheduleIds = () => createSelector(
  selectNormalizedDraftSchedules(),
  drafts => drafts.reduce((ids, draft) => ids.add(draft.id), Set())
);

export const selectCurrentEditedDraftSchedulesCount = () => createSelector(
  selectNormalizedDraftSchedules(),
  drafts => drafts.filter(draft => draft.status !== ENTITY_STATUS.LOCAL_DRAFT).size
);

const scheduleFilterForm = formValueSelector(`filters.${EK.SCHEDULES.state}`);

export const selectCurrentFilteredNormalizedDraftSchedules = () => createSelector(
  selectSidebarIsFiltering(),
  selectNormalizedDraftSchedules(),
  state => scheduleFilterForm(state, 'value', 'description'),
  (isFiltering, schedules, query) => isFiltering ? schedules.filter(schedule => schedule.doesMatchQuery(query)) : schedules
);