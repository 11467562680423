import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleSpecsPipesEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentSpec } from '../SpecsEditSwitch/selectors';

import {
  selectCurrentSelectedSpecPipeFamiliesIds,
  selectNormalizedUnarchivedPipeFamilies,
  selectOrderedFilteredUnarchivedPipeFamilies,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentSpec(),
  selectCurrentSelectedSpecPipeFamiliesIds(),
  selectNormalizedUnarchivedPipeFamilies(),
  selectOrderedFilteredUnarchivedPipeFamilies(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    spec,
    selectedIds,
    data,
    filtered
  ) => ({
    isLoading:
      isLoadingInitialData ||
      (isFetching && (!selectedIds || selectedIds.size === 0)),
    title:
      (spec &&
        spec.specName &&
        `${spec.specName} // ${PipingModuleSpecsPipesEditPath.defaultTitle}`) ||
      'Loading...',
    subtitle: `${selectedIds.size} ${pluralize(
      'Selected Pipe',
      selectedIds.size
    )} ${isFiltering
      ? ` // Showing ${filtered.size}/${data.size} ${pluralize(
        'filtered Pipe',
        data.size
      )}`
      : ''
    }`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
