import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import Header from '../../templates/Structures/Header';
import Flex from '../../../components/common/Flex/index';
import Options from '../../templates/Structures/Options';
import { ConstructedOptions } from './components';

import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import '../../../components/primeGrid/style.css';

import Button from '../../../components/common/Button/index';

import { createSelector } from 'reselect';

import { useLocation, matchPath } from 'react-router-dom';
import { FrameworkModuleProfilePath } from '../../../paths';

import {
  selectSidebarIsFiltering,
  selectFrameworkModulePermissionsAndState,
} from '../../Dashboard/selectors';
import { selectCurrentProfile } from '../../../entities/Profiles/selectors';
import { selectNormalizedProfileDimensionsList } from '../../../entities/ProfileDimensions/selectors';
import { selectNormalizedProfileParametersList } from '../../../entities/ProfileParameters/selectors';

import { selectIsShowingArchived } from '../../../modules/query/selectors';
import { useDispatch, useSelector } from 'react-redux';

// entity actions
import {
  processFetchParameters,
  processCreateParameter,
  processDeleteParameter,
  processEditParameter,
} from '../../../entities/ProfileParameters/actions';
import {
  processFetchDimensions,
  processCreateDimension,
  processDeleteDimension,
  processEditDimension,
} from '../../../entities/ProfileDimensions/actions';
import { processFetchProfiles } from '../../../entities/Profiles/actions';

import Text from '../../../components/common/Text';
import { VALUE_TYPES, VALUE_TYPE_DEFAULT } from '../../../entities/ProfileParameters/model';
import { areParamFieldsValid, areDimFieldsValid } from './actions';
import { has } from 'immutable';

const mapStateToProps = createSelector(
  selectFrameworkModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentProfile(),
  selectNormalizedProfileDimensionsList(),
  selectNormalizedProfileParametersList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    profile,
    dimensions,
    parameters,
  ) => {
    const dimArray = dimensions.toArray();
    const paramArray = parameters.toArray();
    const data = dimArray.concat(paramArray);

    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading: profile && isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      editable: canCollaborate && hasValidLicense,
      data: data || [],
      profile: profile,
      canCollaborate,
      hasValidLicense,
    };
  },
);

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);

  const dispatch = useDispatch();

  const blankParameter = {
    id: '',
    parameterName: '',
    valueType: '',
    defaultValue: '',
    isShownParameter: false,
    isInputParameter: false,
  };

  const blankDimension = {
    id: '',
    dimensionName: '',
    defaultValue: '',
    isShownDimension: false,
    isInputDimension: false,
  };

  // dialog states
  const [paramRow, setParamRow] = useState(blankParameter);
  const [dimRow, setDimRow] = useState(blankDimension);

  const [isParamDialogOpen, setIsParamDialogOpen] = useState(false);
  const [isDimDialogOpen, setIsDimDialogOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isDeleteParamDialogOpen, setIsDeleteParamDialogOpen] = useState(false);
  const [isDeleteDimDialogOpen, setIsDeleteDimDialogOpen] = useState(false);
  const [isChooseDialogOpen, setIsChooseDialogOpen] = useState(false);

  //validation states
  const [paramFieldValidation, setParamFieldValidation] = useState({
    areFieldsValid: true,
    parameterName: '',
    valueType: '',
  });
  const [dimFieldValidation, setDimFieldValidation] = useState({
    areFieldsValid: true,
    dimensionName: '',
  });

  const createDimension = () => {
    setIsChooseDialogOpen(false);
    setDimRow(blankDimension);
    setIsCreateDialogOpen(true);
    setIsDimDialogOpen(true);
  };

  const createParameter = () => {
    setIsChooseDialogOpen(false);
    setParamRow(blankParameter);
    setIsCreateDialogOpen(true);
    setIsParamDialogOpen(true);
  };

  const editDimension = (rowdata) => {
    let editItem = {
      id: rowdata.id,
      dimensionName: rowdata.dimensionName,
      defaultValue: rowdata.defaultValue,
      isShownDimension: rowdata.isShownDimension,
      isInputDimension: rowdata.isInputDimension,
    };
    setDimRow(editItem);
    setIsCreateDialogOpen(false);
    setIsDimDialogOpen(true);
  };

  const editParameter = (rowdata) => {
    let editItem = {
      id: rowdata.id,
      parameterName: rowdata.parameterName,
      valueType: rowdata.valueType,
      defaultValue: rowdata.defaultValue,
      isShownParameter: rowdata.isShownParameter,
      isInputParameter: rowdata.isInputParameter,
    };
    setParamRow(editItem);
    setIsCreateDialogOpen(false);
    setIsParamDialogOpen(true);
  };

  const cancelDialog = () => {
    isCreateDialogOpen && setIsCreateDialogOpen(false);
    isParamDialogOpen && setIsParamDialogOpen(false);
    isDimDialogOpen && setIsDimDialogOpen(false);
    isChooseDialogOpen && setIsChooseDialogOpen(false);
  };

  const deleteDimDialog = (rowdata) => {
    let deleteRow = { rowType: rowdata.rowType, id: rowdata.id, dimensionName: rowdata.dimensionName };
    setDimRow(deleteRow);
    setIsDeleteDimDialogOpen(true);
  };

  const deleteParamDialog = (rowdata) => {
    let deleteRow = { rowType: rowdata.rowType, id: rowdata.id, parameterName: rowdata.parameterName };
    setParamRow(deleteRow);
    setIsDeleteParamDialogOpen(true);
  };

  const cancelDeleteDialog = () => {
    isDeleteParamDialogOpen && setIsDeleteParamDialogOpen(false);
    isDeleteDimDialogOpen && setIsDeleteDimDialogOpen(false);
  };

  const onInputChange = (e, name) => {
    //copies the rowdata, then replaces just the correct field with the changed input value
    const val = (!!e.target && e.target.value) || e.value;
    let _row = !isParamDialogOpen ? { ...dimRow } : {...paramRow};
    _row[`${name}`] = val;
    !isParamDialogOpen ? setDimRow(_row) : setParamRow(_row);
  };

  const onValueTypeChange = (e) => {
    //copies the rowdata, then replaces just the correct field with the changed input value
    const val = (!!e.target && e.target.value) || e.value;
    setParamRow({ ...paramRow, valueType: val, defaultValue: VALUE_TYPE_DEFAULT[`${val}`]});
  };

  const deleteItem = (rowdata) => {
    rowdata.rowType == 'Dimension' ? dispatch(processDeleteDimension(profileId.params.id, dimRow.id)) : dispatch(processDeleteParameter(profileId.params.id, paramRow.id));
  };

  const deleteDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDeleteDialog} >Cancel</Button>
      <Button error={true} onClick={() => {deleteItem(isDeleteDimDialogOpen ? dimRow : paramRow); cancelDeleteDialog();}} >Delete</Button>
    </>
  );

  let saveParam = () => {
    const plchldr = areParamFieldsValid(paramRow);
    setParamFieldValidation(plchldr);
    if (plchldr.areFieldsValid) {
      isCreateDialogOpen ? dispatch(processCreateParameter(profileId.params.id, [{ ...paramRow, defaultValue: `${paramRow.defaultValue}` }])) : dispatch(processEditParameter(profileId.params.id, paramRow));
      cancelDialog();
    }
  };

  let saveDim = () => {
    const plchldr = areDimFieldsValid(dimRow);
    setDimFieldValidation(plchldr);
    if (plchldr.areFieldsValid) {
      isCreateDialogOpen ? dispatch(processCreateDimension(profileId.params.id, [dimRow])) : dispatch(processEditDimension(profileId.params.id, dimRow));
      cancelDialog();
    }
  };

  const paramDialogFooter = () => {
    return (
      <>
        <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
        <Button primary={true} onClick={saveParam} >Save</Button>
      </>
    );
  };

  const dimDialogFooter = () => (
    <>
      <Button secondary={true} onClick={cancelDialog} >Cancel</Button>
      <Button primary={true} onClick={saveDim} >Save</Button>
    </>
  );

  const chooseDialogFooter = () => (
    <>
      <Button secondary={true} onClick={() => {setIsChooseDialogOpen(false); setIsCreateDialogOpen(false); }} >Cancel</Button>
    </>
  );

  //actions for each row
  const actionBodyTemplate = (rowdata) => {
    return (
      <>
        <Button icon="edit" onClick={() => rowdata.rowType=='Parameter' ? editParameter(rowdata) : editDimension(rowdata)} />
        <Button icon="delete" onClick={() => rowdata.rowType == 'Dimension' ? deleteDimDialog(rowdata) : deleteParamDialog(rowdata)} />
      </>
    );
  };

  const nameBody = (rowData) => (
    <>
      <Text>{rowData.rowType == 'Parameter' ? rowData.parameterName : rowData.dimensionName}</Text>
    </>
  );

  const isInputBody = (rowdata) => (
    <>
      <InputSwitch checked={rowdata.rowType == 'Parameter' ? rowdata.isInputParameter : rowdata.isInputDimension} disabled ></InputSwitch>
    </>
  );

  const isShownBody = (rowdata) => (
    <>
      <InputSwitch checked={rowdata.rowType == 'Parameter' ? rowdata.isShownParameter : rowdata.isShownDimension} disabled ></InputSwitch>
    </>
  );

  const getdefaultValueField = () => {
    switch (paramRow.valueType) {
    case 'integer':
      return (
        <div className='field' >
          <label htmlFor="defaultValue" className="font-bold">Default Value(int)</label>
          <InputNumber id='defaultValue' value={paramRow.defaultValue} onChange={(e) => onInputChange(e, 'defaultValue')} maxFractionDigits={0} />
        </div>
      );
    case 'bool':
      return (
        <div className='field' style={{ paddingBottom: '3px', marginTop: '20px' }} >
          <label htmlFor="defaultValue" className="font-bold">Default Value(bool)</label>
          <InputSwitch id='defaultValue' checked={paramRow.defaultValue} onChange={(e) => onInputChange(e, 'defaultValue')} trueValue={'true'} falseValue={'false'} style={{ marginLeft: '20px' }} />
        </div>
      );
    case 'string':
      return (
        <div className='field' >
          <label htmlFor="defaultValue" className="font-bold">Default Value(string)</label>
          <InputText id='defaultValue' value={paramRow.defaultValue} onChange={(e) => onInputChange(e, 'defaultValue')} />
        </div>
      );
    case 'double':
      return (
        <div className='field' >
          <label htmlFor="defaultValue" className="font-bold">Default Value(double)</label>
          <InputNumber id='defaultValue' value={paramRow.defaultValue} onChange={(e) => onInputChange(e, 'defaultValue')} minFractionDigits={2} maxFractionDigits={5} />
        </div>
      );
    }
  };

  const location = useLocation();
  const profileId = matchPath(location.pathname, { path: FrameworkModuleProfilePath.url });

  useEffect( () => {
    if(!reduxProps.profile) {
      dispatch(processFetchProfiles(profileId.params.libraryId));
    }

    dispatch(processFetchParameters(profileId.params.id));
    dispatch(processFetchDimensions(profileId.params.id));
  }, []);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={`${reduxProps.profile && reduxProps.profile.name || 'Loading'} Parameters/Dimensions`}
          subtitle={`${reduxProps.data.length || 0} ${(!!reduxProps.data.length && reduxProps.data.length > 1) || !reduxProps.data.length ? 'Items' : 'Item'} Total`}
        />
        <Options
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}
          isLoading={reduxProps.isLoading}
          ConstructedOptions={() => ConstructedOptions(profileId.params.id, profileId.params.libraryId, dispatch)}
          createEntity={() => setIsChooseDialogOpen(true)}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={reduxProps.data}
          size='small'
          stripedRows
          scrollable
          scrollHeight="flex"
          tableStyle={{ minWidth: '50rem' }}
          removableSort
        >
          <Column header='Type' field='rowType' sortable ></Column>
          <Column header="Name" body={nameBody} ></Column>
          <Column header='Default Value' field='defaultValue' ></Column>
          <Column header='Input' style={{ width: '10%' }} body={isInputBody} ></Column>
          <Column header='Shown' style={{ width: '10%' }} body={isShownBody} ></Column>
          <Column body={actionBodyTemplate} style={{width: '10%', textAlign: 'right'}} ></Column>
        </DataTable>
      </Flex>
      <Dialog visible={reduxProps.editable && isChooseDialogOpen} style={{ width: '32rem' }} header='What item would you like to make?' headerStyle={{textAlign: 'center'}} footer={chooseDialogFooter} modal className='p-fluid' onHide={cancelDialog} closable={false} >
        <div style={{ textAlign: 'center', justifyContent: 'space-evenly'}}>
          <Button primary={true} onClick={createDimension} style={{ margin: '10px' }} >Dimension</Button>
          <Button primary={true} onClick={createParameter} style={{ margin: '10px' }} >Parameter</Button>
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isParamDialogOpen} style={{ width: '32rem' }} header={isCreateDialogOpen ? 'Create new Parameter' : `Edit ${paramRow.parameterName}`} footer={paramDialogFooter} modal className='p-fluid' onHide={cancelDialog} closable={false} >
        <div className="field">
          <label htmlFor="parameterName" className="font-bold">Name</label>
          <InputText id="parameterName" value={paramRow.parameterName} onChange={(e) => onInputChange(e, 'parameterName')} className={paramFieldValidation.parameterName} required autoFocus />
        </div>
        <div className='field'>
          <label htmlFor='valueType' >Value Type</label>
          <Dropdown value={paramRow.valueType} placeholder='Select a Value Type' options={VALUE_TYPES} onChange={(e) => onValueTypeChange(e)} className={paramFieldValidation.valueType} />
        </div>
        {getdefaultValueField()}
        <div className="field" style={{ marginTop: '20px' }} >
          <label htmlFor='isInputParameter' >Input</label>
          <InputSwitch id='isInputParameter' checked={paramRow.isInputParameter} onChange={(e) => onInputChange(e, 'isInputParameter')} style={{ marginLeft: '20px' }} />
        </div>
        <div className="field" style={{ marginTop: '20px' }} >
          <label>Shown</label>
          <InputSwitch id='isShownParameter' checked={paramRow.isShownParameter} onChange={(e) => onInputChange(e, 'isShownParameter')} style={{ marginLeft: '10px' }} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDimDialogOpen} style={{ width: '32rem' }} header={() => isCreateDialogOpen ? 'Create new Dimension' : `Edit ${dimRow.dimensionName}`} footer={dimDialogFooter} modal className='p-fluid' onHide={cancelDialog} closable={false} >
        <div className="field">
          <label htmlFor="dimensionName" className="font-bold">Name</label>
          <InputText id="dimensionName" value={dimRow.dimensionName} onChange={(e) => onInputChange(e, 'dimensionName')} className={dimFieldValidation.dimensionName} required autoFocus />
        </div>
        <div className='field' >
          <label htmlFor="defaultValue" className="font-bold">Default Value</label>
          <InputNumber id='defaultValue' value={dimRow.defaultValue} onChange={(e) => onInputChange(e, 'defaultValue')} minFractionDigits={2} maxFractionDigits={5} />
        </div>
        <div className="field" style={{marginTop: '20px'}} >
          <label htmlFor='isInputDimension' >Input</label>
          <InputSwitch id='isInputDimension' checked={dimRow.isInputDimension} onChange={(e) => onInputChange(e, 'isInputDimension')} style={{ marginLeft: '20px' }} />
        </div>
        <div className="field" style={{ marginTop: '20px' }} >
          <label htmlFor="isShownDimension" >Shown</label>
          <InputSwitch id='isShownDimension' checked={dimRow.isShownDimension} onChange={(e) => onInputChange(e, 'isShownDimension')} style={{ marginLeft: '10px' }} />
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteDimDialogOpen} style={{ width: '32rem' }} header={`Delete Dimension ${dimRow.dimensionName}`} footer={deleteDialogFooter} modal className='p-fluid' onHide={cancelDeleteDialog} closable={false} >
        <div>
          <Text>Are you sure you want to delete {dimRow.dimensionName}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
      <Dialog visible={reduxProps.editable && isDeleteParamDialogOpen} style={{ width: '32rem' }} header={`Delete Parameter ${paramRow.parameterName}`} footer={deleteDialogFooter} modal className='p-fluid' onHide={cancelDeleteDialog} closable={false} >
        <div>
          <Text>Are you sure you want to delete {paramRow.parameterName}?</Text>
          <Text style={{ color: 'red' }}>This action will be PERMANENT and CANNOT BE UNDONE.</Text>
        </div>
      </Dialog>
    </>
  );
}