import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveBendMachineFamiliesForPipeFamily,
  processUnarchiveBendMachineFamiliesForPipeFamily,
} from '../../entities/BendMachineFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import {
  selectCurrentBendMachine,
  selectCurrentDraftBendMachineInstancesPendingValidChanges,
} from './selectors';

import { updateBendMachineInstancesCollection } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentBendMachine(),
  selectCurrentPipeId(),
  selectCurrentDraftBendMachineInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    bendMachine,
    pipeId,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    bendMachineId: bendMachine && bendMachine.id || null,
    pipeId,
    isArchived: bendMachine && bendMachine.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  
  return (
    <Options 
      {...props}
      {...reduxProps}
      updateEntities = {() => dispatch(updateBendMachineInstancesCollection(reduxProps.bendMachineId))}
      editEntity = {() => dispatch(showModal('BEND_MACHINE_MODAL', { pipeFamilyId: reduxProps.pipeId, bendMachineId: reduxProps.bendMachineId }))}
      archiveEntity = {() => dispatch(processArchiveBendMachineFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.bendMachineId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveBendMachineFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.bendMachineId]))}
    />
  );
}
