import { List } from 'immutable';
import EntityRecord, { VALIDATORS, FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,1024})$/,
  MODEL_TEMPLATE_MODEL_NAME: /^(.{0,31})$/,
};

export const CAD_MODEL_TYPES = {
  PART: 'part',
  ASSEMBLY: 'assembly',
  DRAWING: 'drawing',
};

export const CAD_MODEL_PART_SUBTYPES = {
  SOLID: 'solid_part',
  SKELETON: 'skeleton_part',
  HARNESS: 'harness_part',
  PIPE_SOLID: 'pipe_solid',
  BULK: 'bulk_part',
  ECAD_BOARD: 'ecad_board_part',
};

export const CAD_MODEL_ASSEMBLY_SUBTYPES = {
  DESIGN: 'design_asm',
  SKELETON: 'skeleton_asm',
  HARNESS: 'harness_asm',
  PIPELINE: 'pipeline_asm',
  INTERCHANGE: 'interchange_asm',
  ECAD_BOARD: 'ecad_board_asm',
};

export const CAD_MODEL_DRAWING_SUBTYPES = {
  DESIGN: 'design_drawing',
};

export const CAD_MODEL_TYPE_OPTIONS = List([
  {
    id: CAD_MODEL_TYPES.PART,
    value: CAD_MODEL_TYPES.PART,
    primary: 'Part',
    label: 'Part',
  },
  {
    id: CAD_MODEL_TYPES.ASSEMBLY,
    value: CAD_MODEL_TYPES.ASSEMBLY,
    primary: 'Assembly',
    label: 'Assembly',
  },
  {
    id: CAD_MODEL_TYPES.DRAWING,
    value: CAD_MODEL_TYPES.DRAWING,
    primary: 'Drawing',
    label: 'Drawing',
  },
]);

export const CAD_MODEL_PART_SUBTYPE_OPTIONS = List([
  {
    id: CAD_MODEL_PART_SUBTYPES.SOLID,
    value: CAD_MODEL_PART_SUBTYPES.SOLID,
    primary: 'Solid',
    label: 'Solid',
  },
  {
    id: CAD_MODEL_PART_SUBTYPES.SKELETON,
    value: CAD_MODEL_PART_SUBTYPES.SKELETON,
    primary: 'Skeleton',
    label: 'Skeleton',
  },
  {
    id: CAD_MODEL_PART_SUBTYPES.HARNESS,
    value: CAD_MODEL_PART_SUBTYPES.HARNESS,
    primary: 'Harness',
    label: 'Harness',
  },
  {
    id: CAD_MODEL_PART_SUBTYPES.PIPE_SOLID,
    value: CAD_MODEL_PART_SUBTYPES.PIPE_SOLID,
    primary: 'Pipe Solid',
    label: 'Pipe Solid',
  },
  {
    id: CAD_MODEL_PART_SUBTYPES.BULK,
    value: CAD_MODEL_PART_SUBTYPES.BULK,
    primary: 'Bulk',
    label: 'Bulk',
  },
  {
    id: CAD_MODEL_PART_SUBTYPES.ECAD_BOARD,
    value: CAD_MODEL_PART_SUBTYPES. ECAD_BOARD,
    primary: 'ECAD Board',
    label: 'ECAD Board',
  },
]);

export const CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS = List([
  {
    id: CAD_MODEL_ASSEMBLY_SUBTYPES.DESIGN,
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.DESIGN,
    primary: 'Design',
    label: 'Design',
  },
  {
    id: CAD_MODEL_ASSEMBLY_SUBTYPES.SKELETON,
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.SKELETON,
    primary: 'Skeleton',
    label: 'Skeleton',
  },
  {
    id: CAD_MODEL_ASSEMBLY_SUBTYPES.HARNESS,
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.HARNESS,
    primary: 'Harness',
    label: 'Harness',
  },
  {
    id: CAD_MODEL_ASSEMBLY_SUBTYPES.PIPELINE,
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.PIPELINE,
    primary: 'Pipeline',
    label: 'Pipeline',
  },
  {
    id: CAD_MODEL_ASSEMBLY_SUBTYPES.INTERCHANGE,
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.INTERCHANGE,
    primary: 'Interchange',
    label: 'Interchange',
  },
  {
    id: CAD_MODEL_ASSEMBLY_SUBTYPES.ECAD_BOARD,
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.ECAD_BOARD,
    primary: 'ECAD Board',
    label: 'ECAD Board',
  },
]);

// prettier-ignore
export default class ModelTemplate extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) => VALIDATORS.IS_REQUIRED_STRING(entity.name) && ALLOWANCES.NAME.test(entity.name),
    validateError: 'A valid name no longer than 1024 characters must be present',
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  modelTemplateModelName: {
    value: null,
    handleCopy: (entity) => `${entity.modelTemplateModelName} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.modelTemplateModelName) && ALLOWANCES.MODEL_TEMPLATE_MODEL_NAME.test(entity.modelTemplateModelName),
    validateError: 'A valid model template model name no longer than 31 characters must be present',
    filter: FILTERS.STRING,
  },
  cadModelType: {
    value: CAD_MODEL_TYPES.PART,
    handleCopy: (entity) => `${entity.cadModelType} COPY`,
    handleCompare: true,
    handleSerialize: true,
    validate: (entity) =>
      VALIDATORS.IS_REQUIRED_STRING(entity.cadModelType) && !!CAD_MODEL_TYPE_OPTIONS.find(option => option.value === entity.cadModelType),
    validateError: 'A valid model type of either part, assembly, or drawing must be present',
    filter: FILTERS.STRING,
  },
  cadModelAssemblySubtype: {
    value: CAD_MODEL_ASSEMBLY_SUBTYPES.DESIGN,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: entity => entity.cadModelType === CAD_MODEL_TYPES.ASSEMBLY ? entity.cadModelAssemblySubtype : null,
    validate: (entity) =>
      entity.cadModelType === CAD_MODEL_TYPES.ASSEMBLY && VALIDATORS.IS_REQUIRED_STRING(entity.cadModelAssemblySubtype) && !!CAD_MODEL_ASSEMBLY_SUBTYPE_OPTIONS.find(option => option.value === entity.cadModelAssemblySubtype),
    validateError: 'A valid model subtype of design, skeleton, harness, pipeline, ecad board, or interchange must be present',
    filter: FILTERS.STRING,
  },
  cadModelPartSubtype: {
    value: CAD_MODEL_PART_SUBTYPES.SOLID,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: entity => entity.cadModelType === CAD_MODEL_TYPES.PART ? entity.cadModelPartSubtype : null,
    validate: (entity) =>
      entity.cadModelType === CAD_MODEL_TYPES.PART && VALIDATORS.IS_REQUIRED_STRING(entity.cadModelPartSubtype) && !!CAD_MODEL_PART_SUBTYPE_OPTIONS.find(option => option.value === entity.cadModelAssemblySubtype),
    validateError: 'A valid model subtype of solid, skeleton, harness, pipe solid, ecad board, or bulk must be present',
    filter: FILTERS.STRING,
  },
  cadModelDrawingSubtype: {
    value: CAD_MODEL_DRAWING_SUBTYPES.DESIGN,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: entity => entity.cadModelType === CAD_MODEL_TYPES.DRAWING ? entity.cadModelDrawingSubtype : null,
    validate: (entity) =>
      entity.cadModelType === CAD_MODEL_TYPES.DRAWING && VALIDATORS.IS_REQUIRED_STRING(entity.cadModelDrawingSubtype),
    validateError: 'A valid model subtype must be present',
    filter: FILTERS.STRING,
  },
  modelRelations: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  noteProfile: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'noteProfileId',
  },
  modelParameterProfile: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'modelParameterProfileId',
  },
  componentParameterProfile: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: entity => entity.cadModelType !== CAD_MODEL_TYPES.DRAWING ? entity.componentParameterProfile : null,
    serializeOutputField: 'componentParameterProfileId',
  },
  orientations: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'orientationIds',
  },
  layers: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'layerIds',
  },
  sections: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'sectionIds',
  },
  simplifiedReps: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'simplifiedRepIds',
  },
}) {
  constructor({ cadModelAssemblySubtype, cadModelPartSubtype, cadModelDrawingSubtype, ...other } = {}) {
    super({
      cadModelAssemblySubtype: cadModelAssemblySubtype || CAD_MODEL_ASSEMBLY_SUBTYPES.DESIGN,
      cadModelPartSubtype: cadModelPartSubtype || CAD_MODEL_PART_SUBTYPES.SOLID,
      cadModelDrawingSubtype: cadModelDrawingSubtype || CAD_MODEL_DRAWING_SUBTYPES.DESIGN,
      ...other,
    });
  }

  get summary() {
    return {
      primary: this.name,
      secondary: `${this.modelTemplateModelName} // ${this.cadModelType}`,
    };
  }
}
