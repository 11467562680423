import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import OrientationSchema from './schema';

export const selectNormalizedOrientations = () =>
  createSelector(selectEntities(), (entities) =>
    entities.get(EK.ORIENTATIONS.state)
  );
export const selectNormalizedOrientationsList = () =>
  createSelector(
    selectNormalizedOrientations(),
    (orientations) => orientations.toList()
  );

export const selectNormalizedDraftOrientations = () =>
  createSelector(selectDrafts(), (drafts) => {
    const state = drafts.get(EK.ORIENTATIONS.state);
    const records = state.get('records');
    return state.get('ids').map((id) => records.get(id));
  });

export const selectOrientations = () =>
  createSelector(selectEntities(), (entities) =>
    denormalize(
      entities.get(EK.ORIENTATIONS.state),
      [OrientationSchema],
      entities
    )
  );
export const selectDraftOrientations = () =>
  createSelector(
    selectEntities(),
    selectNormalizedDraftOrientations(),
    (entities, drafts) =>
      denormalize(drafts, [OrientationSchema], entities)
  );
