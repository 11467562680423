import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import EK from '../../entities/keys';

import {
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesEditPath,
  PipingModulePipesMitersPath,
} from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchivePipeFamilies,
  processFetchAllPipeFamilies,
  processUnarchivePipeFamilies,
} from '../../entities/PipeFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredDraftPipes } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredDraftPipes(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function PipesGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name // Cross Section Shape + Line Type',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModulePipesEditPath.generate({ id: data.id })) ||
          undefined,
      }),
    },
    {
      headerName: 'Material',
      field: 'material',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Material', secondary: '--' },
        ek: EK.MATERIALS,
      },
    },
    reduxProps.editable &&
      {
        type: ['dataActionIconCell'],
        cellRendererParams: ({ value }) => ({
          actions: (value &&
            List([
              {
                icon: 'list',
                onClick: (pipe) => dispatch(push(PipingModulePipesEditPath.generate({ id: pipe.id }))),
              },
              {
                icon: 'unarchive',
                primary: true,
                onClick: (pipe) => dispatch(processUnarchivePipeFamilies([pipe.id])),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (pipe) => dispatch(showModal('PIPE_MODAL', {pipeId: pipe.id, copyEntity: true})),
              },
            ])) ||
            List([
              {
                icon: 'edit',
                primary: true,
                onClick: (pipe) => dispatch(showModal('PIPE_MODAL', { pipeId: pipe.id })),
              },
              {
                icon: 'list',
                onClick: (pipe) => dispatch(push(PipingModulePipesEditPath.generate({ id: pipe.id }))),
              },
              {
                icon: 'archive',
                error: true,
                onClick: (pipe) => dispatch(processArchivePipeFamilies([pipe.id])),
              },
              {
                icon: 'copy',
                primary: true,
                onClick: (pipe) => dispatch(showModal('PIPE_MODAL', {pipeId: pipe.id, copyEntity: true})),
              },
            ]),
        }),
      },
    reduxProps.editable &&
      {
        type: ['dataActionDropdownCell'],
        cellRendererParams: {
          actions: List([
            {
              label: 'Manage Bends',
              icon: 'list',
              onClick: (pipe) => dispatch(push(PipingModulePipesBendsPath.generate({ id: pipe.id }))),
            },
            {
              label: 'Manage Bend Machines',
              icon: 'list',
              onClick: (pipe) => dispatch(push(PipingModulePipesBendMachinesPath.generate({ id: pipe.id }))),
            },
            {
              label: 'Manage Bend Springbacks',
              icon: 'list',
              onClick: (pipe) => dispatch(push(PipingModulePipesBendSpringbacksPath.generate({ id: pipe.id }))),
            },
            {
              label: 'Manage Miters',
              icon: 'list',
              onClick: (pipe) => dispatch(push(PipingModulePipesMitersPath.generate({ id: pipe.id }))),
            },
          ]),
        },
      },
  ];

  useEffect( () => {
    dispatch(processFetchAllPipeFamilies());
  }, []);

  return (
    <Grid
      {...reduxProps}
      {...props}

      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(PipesGrid);
