import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { Route, matchPath } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';
import { withRouter } from 'react-router-dom';

import Button from '../../components/common/Button';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import { convertListToString } from '../../entities/Specs/model';
import {
  processArchiveSpecs,
  processUnarchiveSpecs,
  processEditSpec,
} from '../../entities/Specs/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';
import { toggleHiddenGridColumns } from '../Dashboard/actions';

import {
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
} from '../../paths';

import { showModal } from '../../modules/modal/actions';

import {
  selectCurrentSpec,
  selectCurrentEditableSpecFamilySorting,
  selectSpecConditionalFittingInstancesChangesPending,
  selectSpecConditionalPipeInstancesChangesPending,
} from './selectors';

import {
  updateSpecFittingInstances,
  updateSpecPipeInstances,
  updateFamilySorting,
} from './actions';

const ConstructedOptions = ({
  isPerforming,
  managePipes,
  manageFittings,
  togglePipeFamilySorting,
  toggleFittingFamilySorting,
  currentEditableFamilySorting,
  toggleHiddenGridColumns,
}) => (
  <>
    <Route
      path={PipingModuleSpecsFittingsEditPath.url}
      render={() =>
        React.createElement(
          Button,
          {
            secondary: true,
            disabled: isPerforming,
            onClick: managePipes,
            mr: 2,
          },
          ['Manage Pipes']
        )
      }
    />
    <Route
      path={PipingModuleSpecsFittingsEditPath.url}
      exact
      render={() =>
        React.createElement(Button, {
          icon: 'star',
          secondary: !currentEditableFamilySorting,
          primary: currentEditableFamilySorting,
          disabled: isPerforming,
          onClick: toggleFittingFamilySorting,
          mr: 2,
        })
      }
    />
    <Route
      path={PipingModuleSpecsFittingInstancesEditPath.url}
      render={() =>
        React.createElement(Button, {
          secondary: true,
          icon: 'list',
          disabled: isPerforming,
          onClick: toggleHiddenGridColumns,
          mr: 2,
        })
      }
    />
    <Route
      path={PipingModuleSpecsPipesEditPath.url}
      render={() =>
        React.createElement(
          Button,
          {
            secondary: true,
            disabled: isPerforming,
            onClick: manageFittings,
            mr: 2,
          },
          ['Manage Fittings']
        )
      }
    />
    <Route
      path={PipingModuleSpecsPipesEditPath.url}
      exact
      render={() =>
        React.createElement(Button, {
          icon: 'star',
          secondary: !currentEditableFamilySorting,
          primary: currentEditableFamilySorting,
          disabled: isPerforming,
          onClick: togglePipeFamilySorting,
          mr: 2,
        })
      }
    />
  </>
);

const defaultEmptyList = List();

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentSpec(),
  selectCurrentEditableSpecFamilySorting(),
  selectSpecConditionalFittingInstancesChangesPending(),
  selectSpecConditionalPipeInstancesChangesPending(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    spec,
    currentEditableFamilySorting,
    fittingInstancesPendingValidChanges,
    pipeInstancesPendingValidChanges
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    currentEditableFamilySorting,
    prioritizedPipeFamilies:
      (spec && spec.prioritizedPipeFamilies) || defaultEmptyList,
    prioritizedFittingFamilies:
      (spec && spec.prioritizedFittingFamilies) || defaultEmptyList,
    specId: (spec && spec.id) || null,
    isArchived: spec && spec.archivedFlag,
    ...fittingInstancesPendingValidChanges,
    ...pipeInstancesPendingValidChanges,
    ConstructedOptions,
  })
);

function SpecsEditSwitchOptions(props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const pathname = props.location.pathname;

  const isSpecPipes = !!(matchPath(pathname, {path: PipingModuleSpecsPipesEditPath.url}));
  const isSpecFittings = !!(matchPath(pathname, {path: PipingModuleSpecsFittingsEditPath.url}));

  const updateEntitiesFamilySorting = (specId, sorting) => {
    isSpecFittings && dispatch(processEditSpec(specId, {prioritizedFittingFamilies: convertListToString(sorting)}));
    isSpecPipes && dispatch(processEditSpec(specId, {prioritizedPipeFamilies: convertListToString(sorting)}));
  };

  const updateEntities = (specId) => {
    isSpecFittings && dispatch(updateSpecFittingInstances(specId));
    isSpecPipes && dispatch(updateSpecPipeInstances(specId));
  };

  return (
    <Options
      {...reduxProps}

      toggleHiddenGridColumns = {() => dispatch(toggleHiddenGridColumns())}
      updateEntities = {() => reduxProps.currentEditableFamilySorting ? updateEntitiesFamilySorting(reduxProps.specId, reduxProps.currentEditableFamilySorting) : updateEntities(reduxProps.specId)}
      editEntity={() => dispatch(showModal('SPEC_MODAL', {spec: reduxProps.spec}))}
      archiveEntity = {() => dispatch(processArchiveSpecs([reduxProps.specId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveSpecs([reduxProps.specId]))}
      managePipes = {() => dispatch(push(PipingModuleSpecsPipesEditPath.generate({ id: reduxProps.specId })))}
      manageFittings = {() => dispatch(push(PipingModuleSpecsFittingsEditPath.generate({ id: reduxProps.specId })))}
      togglePipeFamilySorting = {() => dispatch(updateFamilySorting(!reduxProps.currentEditableFamilySorting ? reduxProps.prioritizedPipeFamilies : null))}
      toggleFittingFamilySorting = {() => dispatch(updateFamilySorting(!reduxProps.currentEditableFamilySorting ? reduxProps.prioritizedFittingFamilies : null))}
    />
  );
}

export default withRouter(SpecsEditSwitchOptions);