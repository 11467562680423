import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';

import BaseFormSection from '../../../../components/form/BaseFormSection';
import BaseFormRow from '../../../../components/form/BaseFormRow';

import RDXTextInput from '../../RDXTextInput';
import RDXSearchSelect from '../../RDXSearchSelect';

import UnitSystemSchema from '../../../../entities/UnitSystems/schema';
import { UNIT_SYSTEM_FILTERS } from '../../../../entities/UnitSystems/model';
import { selectMasterCatalogUnitSystemId } from '../../../../entities/PipingCatalogs/selectors';
import { selectUnitSystems } from '../../../../entities/UnitSystems/selectors';

import { ALLOWANCES } from '../../../../entities/InsulationFamilies/model';
import { validations } from '../../../../entities/InsulationFamilies/formValidation';

const InsulationForm = ({ handleSubmit, isPerforming, valid }) => (
  <Box as="form" onSubmit={handleSubmit}>
    <BaseFormSection>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="name"
          label="Name"
          required
          allow={ALLOWANCES.NAME}
          validate={validations['name']}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXSearchSelect}
          name="unitSystem"
          label="Unit System"
          ek={EK.UNIT_SYSTEMS}
          eFilter={UNIT_SYSTEM_FILTERS.DEFAULT}
          schema={UnitSystemSchema}
        />
      </BaseFormRow>
      <BaseFormRow>
        <Field
          component={RDXTextInput}
          name="description"
          label="Description"
        />
      </BaseFormRow>
    </BaseFormSection>

    <Button
      primary
      type="submit"
      large
      full
      disabled={isPerforming || !valid}
      isPerformingAction={isPerforming}
    >
      Save
    </Button>
  </Box>
);

const insulationFormSelector = formValueSelector(EK.INSULATIONS.state);

const mapStateToProps = createSelector(
  (state, props) => props.initialValues,
  (state) => insulationFormSelector(state, 'unitSystem'),
  selectMasterCatalogUnitSystemId(),
  selectUnitSystems(),
  (initialValues, unitSystemId, defaultUnitSystemId, unitSystems) => {
    const currentUnitSystem =
      unitSystems.get(unitSystemId) || unitSystems.get(defaultUnitSystemId);

    return {
      currentUnitSystem,
      initialValues: initialValues.set('unitSystem', currentUnitSystem.id),
    };
  }
);
const enhance = compose(
  connect(mapStateToProps),
  reduxForm({
    form: EK.INSULATIONS.state,
    onSubmit(model, dispatch, props) {
      props.onRDXSubmit(
        model.serialize({ unitSystem: props.currentUnitSystem })
      );
    },
  })
);

export default enhance(InsulationForm);
