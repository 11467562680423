import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Map, List } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import PipeFamilySchema from '../../entities/PipeFamilies/schema';
import { selectNormalizedPipeFamilies } from '../../entities/PipeFamilies/selectors';

import {
  selectNormalizedSpecPipeInstances,
  selectNormalizedDraftSpecPipeInstancesRecords,
} from '../../entities/SpecPipeInstances/selectors';

import {
  selectCurrentSpecId,
  selectCurrentSpec,
  selectCurrentEditableSpecFamilySorting,
} from '../SpecsEditSwitch/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

const pipeFilterForm = formValueSelector(`filters.${EK.PIPES.state}`);

export const selectNormalizedUnarchivedPipeFamilies = () =>
  createSelector(selectNormalizedPipeFamilies(), (pipes) =>
    pipes.filter((pipe) => !pipe.archivedFlag)
  );

export const selectOrderedFilteredUnarchivedPipeFamilies = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectEntities(),
    selectCurrentSpec(),
    selectCurrentEditableSpecFamilySorting(),
    selectNormalizedUnarchivedPipeFamilies(),
    (state) => pipeFilterForm(state, 'name', 'material'),
    (
      isFiltering,
      entities,
      spec,
      editableFamilySorting,
      pipes,
      query
    ) => {
      let ordered = pipes;

      const sortedList =
        editableFamilySorting ||
        (spec && spec.prioritizedPipeFamilies);

      if (sortedList) {
        const final = pipes.reduce(
          ({ prioritized, unprioritized }, pipe) => {
            if (sortedList.includes(pipe.id)) {
              return { prioritized: prioritized.push(pipe), unprioritized };
            } else {
              return { prioritized, unprioritized: unprioritized.push(pipe) };
            }
          },
          { prioritized: List(), unprioritized: List() }
        );
        ordered = final.prioritized
          .sort((a, b) => sortedList.indexOf(a.id) - sortedList.indexOf(b.id))
          .concat(final.unprioritized);
      }

      return denormalize(
        isFiltering
          ? ordered.filter((pipe) => pipe.doesMatchQuery(query))
          : ordered,
        [PipeFamilySchema],
        entities.delete(EK.PIPE_INSTANCES.state)
      );
    }
  );

export const selectCurrentNormalizedSpecPipeInstances = () =>
  createSelector(
    selectNormalizedSpecPipeInstances(),
    selectCurrentSpecId(),
    (specPipeInstances, specId) =>
      specPipeInstances.filter(
        (instance) => instance.spec === specId
      )
  );

export const selectCurrentSelectedSpecPipeFamiliesIds = () =>
  createSelector(
    selectNormalizedDraftSpecPipeInstancesRecords(),
    selectCurrentSpecId(),
    (instances, specId) =>
      Map().withMutations((count) => {
        instances.reduce((ids, instance) => {
          if (instance.isSelected && instance.spec === specId) {
            return ids.set(
              instance.pipeFamilyId,
              ids.get(instance.pipeFamilyId, 0) + 1
            );
          } else {
            return ids;
          }
        }, count);
      })
  );
