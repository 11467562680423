import {
  take,
  takeLatest,
  takeEvery,
  all,
  call,
  put,
  fork,
  cancelled,
} from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import SectionModel from '../../entities/Sections/model';
import SectionSchema from '../../entities/Sections/schema';
import {
  selectNormalizedSections,
  selectNormalizedDraftSections,
} from '../../entities/Sections/selectors';

import { selectCurrentDraftSectionIds } from './selectors';

import { processSaveSections } from '../../entities/Sections/actions';
import { saveSectionsTypes } from '../../entities/Sections/constants';

import {
  CREATE_SECTION_DRAFTS,
  EDIT_SECTION_DRAFTS,
  UPDATE_SECTIONS_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.SECTIONS,
  schema: SectionSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedSections,
});

const moveSaga = generateMoveSaga({
  keys: EK.SECTIONS,
  model: SectionModel,
  originalNormalizedDataSelector: selectNormalizedSections,
  currentIdsSetSelector: selectCurrentDraftSectionIds,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.SECTIONS,
  model: SectionModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftSectionIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.SECTIONS,
  model: SectionModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftSections,
  currentIdsSetSelector: selectCurrentDraftSectionIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectNormalizedDraftSections,
  processUpdateAction: processSaveSections,
  processUpdateTypes: saveSectionsTypes,
  moveSaga,
});

export function* createDraftSectionssSaga() {
  yield takeEvery(CREATE_SECTION_DRAFTS, createSaga);
}

export function* editDraftSectionssSaga() {
  yield takeEvery(EDIT_SECTION_DRAFTS, editSaga);
}

export function* saveDraftSectionssSaga() {
  yield takeLatest(UPDATE_SECTIONS_COLLECTION, updateSaga);
}

export function* watchSectionssDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftSectionssSaga),
      fork(editDraftSectionssSaga),
      fork(saveDraftSectionssSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.SECTIONS.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSectionssDraftsSaga);
}
