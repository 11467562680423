import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/Schedules/model';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { createScheduleDrafts, editScheduleDrafts } from './actions';

import { selectCurrentFilteredNormalizedDraftSchedules } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentFilteredNormalizedDraftSchedules(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.SCHEDULES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editScheduleDrafts,
      createInstances: createScheduleDrafts,
      copyInstances: createScheduleDrafts,
    },
    ({ data, editable }, dispatchProps) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Value',
          field: 'value',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Value',
          },
          cellEditorParams: {
            allow: ALLOWANCES.VALUE,
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Description',
          },
        },
      ],
      ...((editable && dispatchProps) || {}),
    }),
  ),
);

export default enhance(Grid);
