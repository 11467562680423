import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentBoltNut } from './selectors';

import { PipingBoltNutsEditPath } from '../../paths';

const mapStateToProps = createSelector(selectCurrentBoltNut(), (boltNut) => ({
  title: (boltNut && boltNut.name) || PipingBoltNutsEditPath.defaultTitle,
}));

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
