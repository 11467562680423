import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import { pluralize } from '../../../components/common/Header';

import { showModal } from '../../../modules/modal/actions';

import { selectIsShowingArchived } from '../../../modules/query/selectors';

import {
  processArchiveConfigProfiles,
  processFetchAllConfigProfiles,
  processUnarchiveConfigProfiles,
} from '../../../entities/ConfigProfiles/actions';

import {
  selectSidebarIsFiltering,
  selectSynchronizeModulePermissionsAndState,
} from '../../Dashboard/selectors';

import './styles.css';

import Header from '../../templates/Structures/Header';
import Options from '../../templates/Structures/Options';
import { SynchronizeModuleConfigProfilesPath } from '../../../paths';
import { FilterMatchMode, FilterOperator } from 'primereact/api';

import { selectCurrentFilteredConfigProfiles, selectCurrentConfigProfileFilterIsAdvanced } from './selectors';
import { selectConfigProfilesCounts } from '../../../entities/ConfigProfiles/selectors';
import Flex from '../../../components/common/Flex';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { EntityTools, nameCellBody, FilterHeader } from './components';
import Button from '../../../components/common/Button';

const mapStateToProps = createSelector(
  selectSynchronizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectCurrentConfigProfileFilterIsAdvanced(),
  selectIsShowingArchived(),
  selectConfigProfilesCounts(),
  selectCurrentFilteredConfigProfiles(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isAdvancedFiltering,
    isShowingArchived,
    {unarchived, total},
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
      canCollaborate,
      hasValidLicense,
      isFiltering,
      isShowingArchived,
      unarchived,
      total,
      isAdvancedFiltering: !!isAdvancedFiltering,
    };
  },
);

const baseFilterVal = {
  global: { value: '', matchMode: FilterMatchMode.CONTAINS },
  name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
};

function ConfigProfileGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  const [filters, setFilters] = useState(baseFilterVal);

  const copyAction = (configProfile) => dispatch(showModal('CONFIG_PROFILE_EDITOR_MODAL', { configProfileId: configProfile.id, copyEntity: true }));
  const archiveAction = (configProfile) => dispatch(processArchiveConfigProfiles([configProfile.id]));
  const unarchiveAction = (configProfile) => dispatch(processUnarchiveConfigProfiles([configProfile.id]));
  const changesAction = (configProfile) => dispatch(showModal('CONFIG_PROFILE_MAPKEYS_MODAL', { configProfileId: configProfile.id }));
  const editAction = (configProfile) => dispatch(showModal('CONFIG_PROFILE_EDITOR_MODAL', { configProfileId: configProfile.id }));


  useEffect(() => {
    dispatch(processFetchAllConfigProfiles());
  }, []);

  return (
    <>
      <Flex flexDirection="row" mb={4}>
        <Header
          isLoading={reduxProps.isLoading}
          title={SynchronizeModuleConfigProfilesPath.defaultTitle}
          subtitle={reduxProps.isFiltering
            ? `Showing ${reduxProps.data.length}/${reduxProps.isShowingArchived ? reduxProps.total : reduxProps.unarchived
            } ${pluralize(
              'filtered Config Profile',
              reduxProps.isShowingArchived ? reduxProps.total : reduxProps.unarchived
            )} ${(reduxProps.isShowingArchived && '(including archived)') ||
            '(excluding archived)'
            }`
            : `Showing ${reduxProps.unarchived} ${pluralize('Config Profile', reduxProps.unarchived)} Total`
          }
        />
        <Options
          isLoading={reduxProps.isLoading}
          createEntity= {() => dispatch(showModal('CONFIG_PROFILE_EDITOR_MODAL'))}
          shouldHaveLicense={!reduxProps.hasValidLicense}
          canCollaborate={reduxProps.canCollaborate}

          canFilter={true}
        />
      </Flex>
      <Flex style={{ border: '1px solid #DEE2E6', borderRadius: '5px', height: '86%' }} flexDirection='column' >
        <DataTable
          value={reduxProps.data}
          tableStyle={{ minWidth: '50rem' }}
          size='normal'

          scrollable
          scrollHeight='flex'

          filters={filters}
          globalFilterFields={['name']}
          filterDisplay='menu'
        >
          <Column field="name" header='Name // Instances' ></Column>
          <Column field='name' header='' align='right' filter={reduxProps.isAdvancedFiltering} body={(rowdata) => EntityTools({
            isArchived: rowdata.isArchived,
            changesAction: () => changesAction(rowdata),
            editAction: () => editAction(rowdata),
            copyAction: () => copyAction(rowdata),
            archiveAction: () => archiveAction(rowdata),
            unarchiveAction: () => unarchiveAction(rowdata) })}
          ></Column>
        </DataTable>
      </Flex>
    </>
  );
}

export default withRouter(ConfigProfileGrid);
