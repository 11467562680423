import React from 'react';

import Icon from '../../common/Icon';

import BaseCell from '../BaseCell';

const IconCellRenderer = ({ name, color }) => (
  <BaseCell flexDirection='column' justifyContent='center' alignItems='center'>
    { name && <Icon name={name} color={color} /> || null }
  </BaseCell>
);

export default IconCellRenderer;