import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import BendForm from '../../../form/templates/BendForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import BendModel from '../../../../entities/BendFamilies/model';
import {
  processCreateBendFamilyForPipeFamily,
  processCopyBendFamilyForPipeFamily,
  processEditBendFamilyForPipeFamily,
  processDeleteBendFamilyForPipeFamily,
} from '../../../../entities/BendFamilies/actions';

import { selectCurrentBendFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

import { prepareBendModal } from './actions';

const emptyBend = new BendModel();

const BendModal = ({ pipeFamilyId, bend, copyEntity, isPerforming, prepareModal, onCreate, onCopy, onUpdate, onDelete }) => {
  useEffect(() => prepareModal(pipeFamilyId), []);

  const onSave = useCallback(data => {
    if (bend) {
      if (copyEntity) {
        onCopy(pipeFamilyId, { ...data, id: bend.id });
      } else {
        onUpdate(pipeFamilyId, bend.id, data);
      }
    } else {
      onCreate(pipeFamilyId, data);
    }
  }, [pipeFamilyId, bend, onCreate, copyEntity, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(pipeFamilyId, bend.id), [pipeFamilyId, bend, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={bend && bend.name} copy={copyEntity} create='Bend' />

      <BendForm
        initialValues={bend || emptyBend}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        bend && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={bend.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  bend: selectCurrentBendFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.BENDS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      prepareModal: prepareBendModal,
      onCreate: processCreateBendFamilyForPipeFamily,
      onCopy: processCopyBendFamilyForPipeFamily,
      onUpdate: processEditBendFamilyForPipeFamily,
      onDelete: processDeleteBendFamilyForPipeFamily,
    }
  ),
);

export default enhance(BendModal);
