import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { push } from 'connected-react-router';
import { List } from 'immutable';

import EK from '../../entities/keys';

import { PipingModuleFittingsEditPath } from '../../paths';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import {
  processArchiveFittingFamilies,
  processFetchAllFittingFamilies,
  processUnarchiveFittingFamilies,
} from '../../entities/FittingFamilies/actions';

import {
  selectPipingModulePermissionsAndState,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import { selectCurrentFilteredDraftFittings } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectCurrentFilteredDraftFittings(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data && data.toList().toArray()) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

function FittingsGrid(props) {
  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  let columnDefs = [
    {
      type: ['archivedStatusCell'],
      hide: !reduxProps.showArchived,
    },
    {
      headerName: 'Name // Selection Name',
      field: 'name',
      type: ['complexCell', 'linkCell'],
      valueGetter: ({ data }) => ({
        ...data.summary,
        to: (data.id &&
          PipingModuleFittingsEditPath.generate({ id: data.id })) ||
          undefined,
      }),
      cellRendererParams: {
        placeholder: {
          primary: 'No Name',
          secondary: 'No Selection Name',
        },
      },
    },
    {
      headerName: 'Material',
      field: 'material',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Material', secondary: '--' },
        ek: EK.MATERIALS,
      },
    },
    {
      headerName: 'Fitting Code',
      field: 'fittingCode',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Fitting Code', secondary: '--' },
        ek: EK.FITTING_CODES,
      },
    },
    {
      headerName: 'Fitting Category',
      field: 'fittingCategory',
      type: ['entityCell'],
      cellRendererParams: {
        isDropdown: false,
        placeholder: { primary: 'No Fitting Category', secondary: '--' },
        ek: EK.FITTING_CATEGORIES,
      },
    },
    reduxProps.editable && {
      type: ['dataActionIconCell'],
      cellRendererParams: ({ value }) => ({
        actions: (value &&
          List([
            {
              icon: 'list',
              onClick: (fitting) => dispatch(push(PipingModuleFittingsEditPath.generate({ id: fitting.id }))),
            },
            {
              icon: 'copy',
              primary: true,
              onClick: (fitting) => dispatch(showModal('FITTING_MODAL', { fittingId: fitting.id, copyEntity: true })),
            },
            {
              icon: 'unarchive',
              primary: true,
              onClick: (fitting) => dispatch(processUnarchiveFittingFamilies([fitting.id])),
            },
          ])) ||
          List([
            {
              icon: 'edit',
              primary: true,
              onClick: (fitting) => dispatch(showModal('FITTING_MODAL', { fittingId: fitting.id })),
            },
            {
              icon: 'list',
              onClick: (fitting) => dispatch(push(PipingModuleFittingsEditPath.generate({ id: fitting.id }))),
            },
            {
              icon: 'copy',
              primary: true,
              onClick: (fitting) => dispatch(showModal('FITTING_MODAL', { fittingId: fitting.id, copyEntity: true })),
            },
            {
              icon: 'archive',
              error: true,
              onClick: (fitting) => dispatch(processArchiveFittingFamilies([fitting.id])),
            },
          ]),
      }),
    },
  ];

  useEffect( () => {
    dispatch(processFetchAllFittingFamilies());
  }, []);
  return (
    <Grid
      data={reduxProps.data}

      {...props}
      {...reduxProps}
      columnDefs = {columnDefs}
    />
  );
}

export default withRouter(FittingsGrid);
