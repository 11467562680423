import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  waitWhileInitialFetchingSaga,
  waitWhilePerformingSaga,
} from '../../modules/drafts/sagas';

import {
  generateMoveMultiSaga,
  generateCreateOrEditSaga,
} from '../SpecsEditSwitch/sagaTemplates';

import {
  selectNormalizedUnarchivedFittingFamilies,
  selectCurrentNormalizedSpecFittingInstances
} from './selectors';

import {
  selectCurrentSpecId,
  selectCurrentDraftSpecFittingInstanceIds,
} from '../SpecsEditSwitch/selectors';

import SpecFittingInstanceModel from '../../entities/SpecFittingInstances/model';
import { selectNormalizedSpecFittingInstances, selectNormalizedDraftSpecFittingInstances } from '../../entities/SpecFittingInstances/selectors';
import { saveSpecFittingInstancesTypes } from '../../entities/SpecFittingInstances/constants';

import { selectNormalizedFittingFamilies } from '../../entities/FittingFamilies/selectors';
import { fetchAllFittingFamiliesTypes } from '../../entities/FittingFamilies/constants';
import { processFetchAllFittingFamilies } from '../../entities/FittingFamilies/actions';

import {
  TOGGLE_SPEC_FITTING_FAMILIES,
} from './constants';

const moveMultiSaga = generateMoveMultiSaga({
  originalNormalizedDataAndKeys: [
    { keys: EK.FITTINGS, selector: selectNormalizedUnarchivedFittingFamilies },
    { keys: EK.SPEC_FITTING_INSTANCES, selector: selectCurrentNormalizedSpecFittingInstances },
  ],
});

const toggleSpecFittingFamiliesSaga = generateCreateOrEditSaga({
  keys: EK.SPEC_FITTING_INSTANCES,
  childProperty: 'fittingInstances',
  model: SpecFittingInstanceModel,
  baseParamsSelector: selectCurrentSpecId,
  originalDraftDataSelector: selectNormalizedSpecFittingInstances,
  normalizedDraftDataSelector: selectNormalizedDraftSpecFittingInstances,
  normalizedOriginalParentDataSelector: selectNormalizedFittingFamilies,
  currentIdsSetSelector: selectCurrentDraftSpecFittingInstanceIds,
  findDraftFilterFunction: ({ baseParams, edit, currentDraftId }) => currentNormalizedDraft => (
    currentNormalizedDraft.spec === baseParams &&
    currentNormalizedDraft.fittingFamilyId === edit.id &&
    currentNormalizedDraft.fittingInstance === currentDraftId
  ),
  generateCreationProperties: ({ baseParams, edit, currentDraftId }) => ({
    spec: baseParams,
    fittingFamilyId: edit.id,
    fittingInstance: currentDraftId,
  })
});

export function* watchUpdateSpecSuccessForMoveSaga() {
  yield takeLatest(saveSpecFittingInstancesTypes.SUCCESS, function* () {
    yield call(waitWhilePerformingSaga);

    yield call(moveMultiSaga);
  });
}

export function* watchToggleSpecFittingFamiliesSaga() {
  yield takeEvery(TOGGLE_SPEC_FITTING_FAMILIES, toggleSpecFittingFamiliesSaga);
}

export function* watchSpecsFittingsCollectionSaga() {
  try {
    yield put(processFetchAllFittingFamilies());

    yield take(fetchAllFittingFamiliesTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveMultiSaga);

    yield all([
      fork(watchToggleSpecFittingFamiliesSaga),
      fork(watchUpdateSpecSuccessForMoveSaga),
    ]);

    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.SPEC_FITTING_INSTANCES.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSpecsFittingsCollectionSaga);
}
