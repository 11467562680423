import isNil from 'lodash/isNil';
import get from 'lodash/get';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

class SpecFittingInstance extends EntityRecord({
  boltNutFamily: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: (entity) =>
      entity.boltNutFamily === null
        ? null
        : entity.boltNutFamily.id || entity.boltNutFamily,
    serializeOutputField: 'boltNutFamilyId',
    filter: FILTERS.STRING,
  },
  fittingInstance: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleSerialize: true,
    serializeOutputField: 'fittingInstanceId',
    filter: FILTERS.DEEP_ENTITY,
  },
  // this is not saved in the schema to just save us a ton of hassle
  fittingFamilyId: {
    value: null,
  },
  spec: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    serializeOutputField: 'specId',
  },
  stockno: {
    value: null,
    handleCopy: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  mccsCode: {
    value: null,
    handleCopy: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
  selectionName: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    filter: FILTERS.STRING,
  },
}) {
  constructor({ fittingFamilyId, ...other } = {}) {
    super({
      fittingFamilyId: !isNil(fittingFamilyId) ? `${fittingFamilyId}` : null,
      ...other,
    });
  }

  get summary() {
    return {
      primary: this.getIn(['fittingInstance', 'cadModelName'], 'No Name'),
      secondary: this.getIn(['fittingInstance', 'schedule', 'value'], 'No Schedule'),
    };
  }

  get editableStockno() {
    return (
      this.stockno ||
      (this.fittingInstance && this.fittingInstance.stockno) ||
      null
    );
  }

  setEditableStockno(stockno) {
    return this.set('stockno', stockno);
  }

  get editableMccsCode() {
    return (
      this.mccsCode ||
      (this.fittingInstance && this.fittingInstance.mccsCode) ||
      null
    );
  }

  setEditableMccsCode(mccsCode) {
    return this.set('mccsCode', mccsCode);
  }
}

export default SpecFittingInstance;
