import EntityRecord from '../../modules/entities/model';

export default class UnitSystem extends EntityRecord({
  name: {
    value: '',
    handleCopy: entity => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
  },
  unitLength: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  unitWeight: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
  unitDensity: {
    value: null,
    isEntity: true,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
  },
}) {
  get primary() { return this.name; }
  get primaryPlaceholder() { return 'No Name'; }
  get secondary() { return `${this.unitLength.name}, ${this.unitWeight.name}${this.unitDensity && !this.unitDensity.isBlank ? `, ${this.unitDensity.name}` : ''}`; }
  get secondaryPlaceholder() { return '--'; }

  toOption() {
    return {
      id: this.id,
      value: this.id,
      label: this.primary || this.primaryPlaceholder,
      primary: this.primary || this.primaryPlaceholder,
      secondary: this.secondary || this.secondaryPlaceholder,
      unitLength: this.unitLength,
      unitWeight: this.unitWeight,
      unitDensity: this.unitDensity,
    };
  }
}

export const UNIT_SYSTEM_FILTERS = {
  DEFAULT: unitSystem => unitSystem.name !== null && unitSystem.name !== undefined && unitSystem.name.toLowerCase() === 'mmks' || unitSystem.name.toLowerCase() === 'ips',
};
