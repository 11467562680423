import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentMiter } from './selectors';

import { PipingModulePipesMitersEditPath } from '../../paths';

const mapStateToProps = createSelector(selectCurrentMiter(), (miter) => ({
  title: (miter && miter.name) || PipingModulePipesMitersEditPath.defaultTitle,
}));

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
