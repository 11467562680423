import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { List } from 'immutable';

import EK from '../../../../entities/keys';

import { ENTITY_STATUS } from '../../../../modules/entities/constants';

import Grid from '../../../grid/Grid';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';

import ModalTitle from '../../../../components/modal/templates/ModalTitle';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import { 
  processCreateEndTypeCompatibilities, 
  processUpdateEndTypeCompatibility,
  processRemoveEndTypeCompatibility,
} from '../../../../entities/EndTypeCompatibilities/actions';

import { selectCurrentEndType, selectCurrentEndTypeCompatibilities } from './selectors';

import { initialEndTypeCompatibilityLoad, createEndTypeCompatibilityDrafts, editEndTypeCompatibilityDrafts } from './actions';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

class EndTypeCompatibilityModal extends React.Component {
  componentDidMount = () => {
    this.props.initialLoad();
  }
  
  render() {
    const { isPerforming, closeModal, endType, addRow, data, columnDefs, editInstances } = this.props;

    return (
      <Box>
        <ModalTitle><strong>Manage</strong> { endType.name } Compatibilities</ModalTitle>

        <Box mb={6}>
          <Button primary large full onClick={addRow} disabled={isPerforming}>Add New Compatibility</Button>
        </Box>

        <Flex height='30rem' mb={3}>
          <Grid 
            data={data} 
            columnDefs={columnDefs}
            editInstances={editInstances}
          />
        </Flex>

        <Box>
          <Button secondary large full onClick={closeModal} disabled={isPerforming}>Close</Button>
        </Box>

      </Box>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  endType: selectCurrentEndType(),
  data: selectCurrentEndTypeCompatibilities(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  initialLoad() { dispatch(initialEndTypeCompatibilityLoad(ownProps.endTypeId)); },
  editInstances(instances, opts) { dispatch(editEndTypeCompatibilityDrafts(instances, opts)); },
  addRow() { dispatch(createEndTypeCompatibilityDrafts(List([{}]), { creationProperties: { isSelected: true, firstEndType: ownProps.endTypeId } })); },

  createEndTypeCompatibility(endTypeCompatibility) { dispatch(processCreateEndTypeCompatibilities(endTypeCompatibility)); },
  updateEndTypeCompatibility(endTypeCompatibilityId, data) { dispatch(processUpdateEndTypeCompatibility(endTypeCompatibilityId, data)); },
  deleteEndTypeCompatibility(endTypeCompatibilityId) { dispatch(processRemoveEndTypeCompatibility(endTypeCompatibilityId)); },
});

const enhance = compose(
  injectSaga({ key: `${EK.END_TYPE_COMPATIBILITIES.state}Modal`, saga }),
  connect(
    mapStateToProps, 
    mapDispatchToProps,
    (
      { data, isPerforming, endType },
      dispatchProps
    ) => ({
      isPerforming,
      endType,
      data,
      initialLoad: dispatchProps.initialLoad,
      addRow: dispatchProps.addRow,
      editInstances(instances, opts) { dispatchProps.editInstances(instances, opts); },
      columnDefs: [
        {
          headerName: 'First End Type',
          field: 'firstEndType',
          editable: ({ data }) => data.secondEndType.id === endType.id,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select End Type' },
            ek: EK.END_TYPES,
          },
          cellEditorParams: { ek: EK.END_TYPES },
        },
        {
          headerName: 'First Schedule',
          field: 'firstSchedule',
          editable: true,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Schedule' },
            ek: EK.SCHEDULES,
          },
          cellEditorParams: { ek: EK.SCHEDULES },
        },
        {
          headerName: 'Second End Type',
          field: 'secondEndType',
          editable: ({ data }) => data.firstEndType.id === endType.id,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select End Type' },
            ek: EK.END_TYPES,
          },
          cellEditorParams: { ek: EK.END_TYPES },
        },
        {
          headerName: 'Second Schedule',
          field: 'secondSchedule',
          editable: true,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Schedule' },
            ek: EK.SCHEDULES,
          },
          cellEditorParams: { ek: EK.SCHEDULES },
        },
        {
          field: 'status',
          type: ['dataActionIconCell'],
          cellRendererParams: ({ value, data }) => ({
            actions: (
              value === ENTITY_STATUS.LOCAL_DRAFT ||
              value === ENTITY_STATUS.PENDING_VALID_SAVE ||
              value === ENTITY_STATUS.PENDING_INVALID_SAVE ||
              value === ENTITY_STATUS.UNSELECTED
            ) && List([
              {
                icon: 'add',
                primary: true,
                disabled: value !== ENTITY_STATUS.PENDING_VALID_SAVE || isPerforming,
                onClick: () => dispatchProps.createEndTypeCompatibility(data.serialize()),
              }
            ]) || List([
              {
                icon: 'edit',
                primary: true,
                disabled: value !== ENTITY_STATUS.PENDING_VALID_CHANGES || isPerforming,
                onClick: () => dispatchProps.updateEndTypeCompatibility(data.id, data.serialize()),
              },
              {
                icon: 'delete',
                error: true,
                disabled: isPerforming,
                onClick: () => dispatchProps.deleteEndTypeCompatibility(data.id),
              },
            ]),
          }),
        },
      ],
    })
  ),
);

export default enhance(EndTypeCompatibilityModal);
