import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';

import EK from '../../entities/keys';

import {
  ALLOWANCES,
  PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS,
  PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS_FORMATTED,
  PARAMETER_VALUE_TYPES,
} from '../../entities/Parameters/model';

import Grid from '../../containers/grid/Grid';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import {
  createParameterProfileParameterDrafts,
  editParameterProfileParameterDrafts,
} from './actions';

import {
  selectCurrentFilteredDraftParameterProfileParameters,
  selectCurrentParameterProfile,
} from './selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentParameterProfile(),
  selectCurrentFilteredDraftParameterProfileParameters(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    parameterProfile,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense && parameterProfile &&
        !parameterProfile.archivedFlag,
      parameterProfileId: parameterProfile && parameterProfile.id || null,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.PARAMETER_PROFILES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editParameterProfileParameterDrafts,
      createInstances: createParameterProfileParameterDrafts,
      copyInstances: createParameterProfileParameterDrafts,
    },
    (
      { data, editable, parameterProfileId },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      columnDefs: [
        ...(editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ] || []),
        {
          headerName: 'Parameter',
          field: 'parameter',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          valueSetter: ({ data, oldValue, newValue, context }) => {
            oldValue !== newValue &&
              context.editInstances &&
              context.editInstances(data.setParameter(newValue));
            return false;
          },
          cellRendererParams: {
            placeholder: { primary: 'Select Parameter' },
            ek: EK.PARAMETERS,
          },
          cellEditorParams: { ek: EK.PARAMETERS },
        },
        {
          headerName: 'Default Value',
          editable: ({ data }) => editable && data.parameter,
          type: ['basicCell'],
          field: 'editableDefaultValue',
          fields: ['defaultValue'],
          valueFormatter: ({ value, data }) =>
            data.valueType === PARAMETER_VALUE_TYPES.BOOL &&
              PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS_FORMATTED[value] || value,
          valueSetter: ({ data, newValue, context }) => {
            context.editInstances &&
              context.editInstances(data.setEditableDefaultValue(newValue));
            return false;
          },
          cellRendererParams: ({ data }) => ({
            placeholder: 'No Default Value Set',
            append:
              data.parameter &&
                data.parameter.valueType === PARAMETER_VALUE_TYPES.DOUBLE &&
                data.parameter.unit && data.parameter.unit.abbreviation || null,
          }),
          cellEditorSelector: ({ data }) => {
            switch (data.parameter.valueType) {
            case PARAMETER_VALUE_TYPES.LIST: {
              return {
                component: 'dropdownEditor',
                params: {
                  placeholder: 'No Default Value Set',
                  options: data.parameter && data.parameter.listValues ||
                      List(),
                  isNullable: true,
                },
              };
            }
            case PARAMETER_VALUE_TYPES.DOUBLE: {
              return {
                component: 'basicCellEditor',
                params: {
                  unit:
                      data.parameter && data.parameter.unit &&
                        data.parameter.unit.abbreviation || null,
                  allow: ALLOWANCES.DOUBLE,
                },
              };
            }
            case PARAMETER_VALUE_TYPES.BOOL: {
              return {
                component: 'radioEditor',
                params: {
                  options: PARAMETER_DEFAULT_VALUE_BOOLEAN_OPTIONS,
                  isNullable: true,
                },
              };
            }
            case PARAMETER_VALUE_TYPES.INTEGER: {
              return {
                component: 'basicCellEditor',
                params: { allow: ALLOWANCES.INTEGER },
              };
            }
            default: {
              return {
                component: 'basicCellEditor',
                params: { allow: ALLOWANCES.DEFAULT_VALUE },
              };
            }
            }
          },
          suppressKeyboardEvent: ({ data, event, editing }) =>
            (data.valueType === PARAMETER_VALUE_TYPES.LIST ||
              data.valueType === PARAMETER_VALUE_TYPES.BOOL) &&
            editing && event.keyCode === 13,
        },
        {
          headerName: 'Locked',
          field: 'paramIsLocked',
          editable,
          type: ['toggleCell'],
          width: 78,
        },
        {
          headerName: 'Designated',
          field: 'paramIsDesignated',
          editable,
          type: ['toggleCell'],
          width: 108,
        },
      ],
      ...(editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              parameterProfile: parameterProfileId,
              parameterProfileId: parameterProfileId,
            },
          });
        },
      } || {}),
    }),
  ),
);

export default enhance(Grid);
