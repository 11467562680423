import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import colors from '../../../../assets/themes/base/colors';
import radii from '../../../../assets/themes/base/radii';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';
import Button from '../../../../components/common/Button';
import Flex from '../../../../components/common/Flex';
import Icon from '../../../../components/common/Icon';
import Text from '../../../../components/common/Text';

import NoteBlockAddNoteForm from '../NoteBlockAddNoteForm';
import NoteBlockNote from '../NoteBlockNote';

import {
  processDeleteNoteBlock,
  processSortNoteBlockNote,
  processEditNoteBlock,
} from '../../../../entities/NoteBlocks/actions';

import { processCreateNoteBlockNote } from '../../../../entities/NoteBlockNotes/actions';

import { selectCurrentNoteBlockNotes } from '../../selectors';

import { showModal } from '../../../../modules/modal/actions';

const NoteBlockNoteList = styled(Box)`
  border: 1px solid ${colors.gray[4]};
  border-radius: ${radii[1]};
  width: 100%;
`;

const NoteBlock = ({
  profileId,
  noteBlock,
  noteBlockNotes,
  editable,
  onEdit,
  onToggleLock,
  onDelete,
  onCreateNoteBlockNote,
  onMoveNoteBlockNote,
}) => {
  const onMoveComplete = useCallback(
    (noteBlockNoteId, moveIndex) => {
      const existingIndex = noteBlockNotes.findIndex(
        (noteBlockNote) => noteBlockNote.id === noteBlockNoteId
      );
      if (existingIndex !== moveIndex) {
        onMoveNoteBlockNote(noteBlockNoteId, moveIndex + 1);
      }
    },
    [noteBlockNotes, onMoveNoteBlockNote]
  );

  const onHandleToggleLock = useCallback(
    () => onToggleLock(!noteBlock.isLocked),
    [noteBlock.isLocked]
  );

  return (
    <Box bg="white" border={1} borderRadius={1} mb={3} boxShadow={1}>
      <Flex flexDirection="row" alignItems="center" p={3} borderBottom={1}>
        <Flex
          flexDirection="column"
          justifyContent="center"
          mr={2}
          style={{ flexGrow: 1 }}
        >
          <Text fontSize={1} color="gray.7" mb={1}>
            {noteBlock.name}
          </Text>
          <Text fontSize={0} color="gray.6">
            {noteBlock.noteFeatureName}
          </Text>
        </Flex>
        {editable && (
          <Flex flexDirection="row" justifyContent="center" alignItems="center">
            <Button
              transparent
              subtle={!noteBlock.isLocked}
              error
              icon="lock"
              onClick={onHandleToggleLock}
              mr={2}
            />
            <Button
              transparent
              subtle
              primary
              icon="edit"
              onClick={onEdit}
              mr={2}
            />
            <Button transparent subtle error icon="delete" onClick={onDelete} />
          </Flex>
        )}
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent={
          noteBlockNotes && noteBlockNotes.size > 0 ? undefined : 'center'
        }
        alignItems="center"
        style={{ minHeight: '15rem' }}
        p={2}
        bg="gray.1"
      >
        {(noteBlockNotes && noteBlockNotes.size > 0 && (
          <NoteBlockNoteList>
            {noteBlockNotes.map((noteBlockNote, idx) => (
              <NoteBlockNote
                key={idx}
                index={idx}
                profileId={profileId}
                noteBlockId={noteBlock.id}
                noteBlockNote={noteBlockNote}
                editable={editable}
                isLocked={noteBlock.isLocked}
                onMoveComplete={onMoveComplete}
              />
            ))}
          </NoteBlockNoteList>
        )) || (
          <React.Fragment>
            <Text fontSize={8} fontWeight={400} color="gray.4">
              <Icon name="inbox" />
            </Text>
            <Text fontSize={4} fontWeight={400} color="gray.4" mb={1}>
              Add Notes Below
            </Text>
          </React.Fragment>
        )}
      </Flex>

      <Box p={3} borderTop={1}>
        <NoteBlockAddNoteForm
          form={`${EK.NOTE_BLOCK_NOTES.state}-${noteBlock.id}`}
          onRDXSubmit={onCreateNoteBlockNote}
          isLocked={noteBlock.isLocked || !editable}
          noteBlockNotes={noteBlockNotes}
        />
      </Box>
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  noteBlockNotes: selectCurrentNoteBlockNotes(),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onCreateNoteBlockNote(data) {
    dispatch(
      processCreateNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlock.id,
        data
      )
    );
  },
  onMoveNoteBlockNote(noteBlockNoteId, sortOrder) {
    dispatch(
      processSortNoteBlockNote(
        ownProps.profileId,
        ownProps.noteBlock.id,
        noteBlockNoteId,
        sortOrder
      )
    );
  },
  onEdit() {
    dispatch(
      showModal('NOTE_BLOCK_MODAL', {
        noteProfileId: ownProps.profileId,
        noteBlockId: ownProps.noteBlock.id,
      })
    );
  },
  onToggleLock(isLocked) {
    dispatch(
      processEditNoteBlock(
        ownProps.profileId,
        ownProps.noteBlock.id,
        { isLocked }
      )
    );
  },
  onDelete() {
    dispatch(
      processDeleteNoteBlock(
        ownProps.profileId,
        ownProps.noteBlock.id
      )
    );
  },
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhance(NoteBlock);
