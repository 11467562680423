import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BackTemplate from '../templates/Structures/Back';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData } from '../Dashboard/selectors';

import { PipingModuleProjectsPath } from '../../paths';

const mapStateToProps = createSelector(
  selectIsFetching(),
  selectIsLoadingInitialData(),
  (isFetching, isLoadingInitialData) => ({
    isLoading: isFetching || isLoadingInitialData,
    to: PipingModuleProjectsPath.url,
    label: PipingModuleProjectsPath.defaultTitle,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(BackTemplate);
