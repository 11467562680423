import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { PipingModuleProjectsEditPath } from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectNormalizedUnarchivedDraftSpecs } from '../../entities/Specs/selectors';

import {
  selectIsLoadingInitialData,
  selectSidebarIsFiltering,
} from '../Dashboard/selectors';

import {
  selectCurrentProject,
  selectDraftSpecsSelectedForIds,
  selectCurrentFilteredDraftSpecs,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentProject(),
  selectDraftSpecsSelectedForIds(),
  selectNormalizedUnarchivedDraftSpecs(),
  selectCurrentFilteredDraftSpecs(),
  (
    isLoadingInitialData,
    isFetching,
    isFiltering,
    project,
    selectedIds,
    data,
    filtered
  ) => ({
    isLoading:
      isLoadingInitialData ||
      (isFetching && (!selectedIds || selectedIds.size === 0)),
    title:
      (project && project.name) || PipingModuleProjectsEditPath.defaultTitle,
    subtitle: `${selectedIds.size} ${pluralize(
      'Selected Piping Spec',
      selectedIds.size
    )}${isFiltering
      ? ` // Showing ${filtered.size}/${data.size} ${pluralize(
        'filtered Piping Spec',
        data.size
      )}`
      : ''
    }`,
  })
);

const enhance = compose(connect(mapStateToProps));

export default enhance(Header);
