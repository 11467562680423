import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../entities/keys';

import BaseFormRow from '../../components/form/BaseFormRow';

import FilterFormTemplate from '../templates/Structures/FilterFormTemplate';

import RDXTextInput from '../../containers/form/RDXTextInput';

const FilterForm = props => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='value'
        label='Value'
        type='text'
      />
    </BaseFormRow>
    <BaseFormRow>
      <Field
        component={RDXTextInput}
        name='description'
        label='Description'
        type='text'
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.SCHEDULES.state}` })
);

export default enhance(FilterForm);
