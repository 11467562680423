import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import InsulationFamilySchema from './schema';

export const selectNormalizedInsulationFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('insulationFamilies')
);
export const selectNormalizedInsulationFamiliesList = () => createSelector(
  selectNormalizedInsulationFamilies(),
  insulationFamilies => insulationFamilies.toList()
);
export const selectInsulationFamiliesOptions = () => createSelector(
  selectNormalizedInsulationFamilies(),
  insulationFamilies => insulationFamilies.toList().map(family => family.toOption())
);
export const selectInsulationFamiliesCounts = () => createSelector(
  selectNormalizedInsulationFamilies(),
  insulations => insulations.reduce(({ unarchived, total }, insulation) => ({
    unarchived: !insulation.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);
