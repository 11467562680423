import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';
import InsulationForm from '../../../form/templates/InsulationForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import InsulationModel from '../../../../entities/InsulationFamilies/model';
import {
  processCreateInsulationFamily,
  processCopyInsulationFamily,
  processEditInsulationFamily,
  processDeleteInsulationFamily,
} from '../../../../entities/InsulationFamilies/actions';

import { selectCurrentInsulationFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyInsulation = new InsulationModel();

const InsulationModal = ({ insulation, isPerforming, copyEntity, onCreate, onCopy, onUpdate, onDelete }) => {
  const onSave = useCallback(data => {
    if (insulation) {
      if (copyEntity) {
        onCopy({ ...data, id: insulation.id });
      } else {
        onUpdate(insulation.id, data);
      }
    } else {
      onCreate(data);
    }
  }, [insulation, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(insulation.id), [insulation, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={insulation && insulation.name} copy={copyEntity} create='Insulation' />

      <InsulationForm
        initialValues={insulation || emptyInsulation}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        insulation && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={insulation.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  insulation: selectCurrentInsulationFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.INSULATIONS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreateInsulationFamily,
      onCopy: processCopyInsulationFamily,
      onUpdate: processEditInsulationFamily,
      onDelete: processDeleteInsulationFamily,
    }
  ),
);

export default enhance(InsulationModal);
