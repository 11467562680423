import { createSelector } from 'reselect';
import { List } from 'immutable';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedBoltNutFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('boltNutFamilies')
);
export const selectNormalizedBoltNutFamiliesList = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  boltNutFamilies => boltNutFamilies.toList()
);
export const selectAllBoltNutsOptions = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  boltNuts => boltNuts.reduce((output, boltNut) => !boltNut.archivedFlag ? output.push(boltNut.toOption()) : output, List())
);
export const selectBoltNutFamiliesCounts = () => createSelector(
  selectNormalizedBoltNutFamilies(),
  boltNuts => boltNuts.reduce(({ unarchived, total }, boltNut) => ({
    unarchived: !boltNut.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);
