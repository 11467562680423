import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectNormalizedBendMachineFamilies } from '../../entities/BendMachineFamilies/selectors';

import BendMachineInstanceSchema from '../../entities/BendMachineInstances/schema';
import {
  selectNormalizedBendMachineInstances,
  selectNormalizedDraftBendMachineInstances,
} from '../../entities/BendMachineInstances/selectors';

import { PipingModulePipesBendMachinesEditPath } from '../../paths';

export const selectBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModulePipesBendMachinesEditPath.url,
    });
    // returns { id, familyId }
    return (match && match.params) || null;
  });

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.BEND_MACHINES.state)
  );

export const selectCurrentBendMachineId = () =>
  createSelector(
    selectBaseParams(),
    (params) => (params && params.familyId) || null
  );

export const selectCurrentBendMachine = () =>
  createSelector(
    selectCurrentBendMachineId(),
    selectNormalizedBendMachineFamilies(),
    (bendMachineId, bendMachines) => bendMachines.get(bendMachineId)
  );

export const selectOriginalNormalizedBendMachineInstancesForFamily = () =>
  createSelector(
    selectNormalizedBendMachineInstances(),
    selectCurrentBendMachineId(),
    (instances, bendMachineFamilyId) =>
      instances.filter(
        (instance) => instance.bendMachineFamilyId === bendMachineFamilyId
      )
  );

export const selectOriginalDenormalizedBendMachineInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedBendMachineInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BendMachineInstanceSchema],
        entities.delete(EK.BEND_MACHINES.state)
      )
  );

const bendMachineInstanceFilterForm = formValueSelector(
  `filters.${EK.BEND_MACHINE_INSTANCES.state}`
);

export const selectCurrentDraftBendMachineInstances = () =>
  createSelector(
    selectNormalizedDraftBendMachineInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BendMachineInstanceSchema],
        entities.delete(EK.BEND_MACHINES.state)
      )
  );

export const selectCurrentFilteredDraftBendMachineInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftBendMachineInstances(),
    selectEntities(),
    (state) => bendMachineInstanceFilterForm(state, 'sizeProperty'),
    (isFiltering, instances, entities, sizeProperty) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) =>
            instance.doesMatchQuery({ sizeProperty })
          )
          : instances,
        [BendMachineInstanceSchema],
        entities.delete(EK.BEND_MACHINES.state)
      )
  );

export const selectCurrentDraftBendMachineInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) =>
      drafts.getIn([EK.BEND_MACHINE_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftBendMachineInstanceIds = () =>
  createSelector(selectNormalizedDraftBendMachineInstances(), (instances) =>
    instances.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftBendMachineInstancesCount = () =>
  createSelector(
    selectNormalizedDraftBendMachineInstances(),
    (instances) =>
      instances.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT)
        .size
  );
