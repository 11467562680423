import { List } from 'immutable';
import pluralize from 'pluralize';

import EntityRecord, { FILTERS } from '../../modules/entities/model';

export const ALLOWANCES = {
  NAME: /^(.{0,1024})$/,
  BOLT_NUT_CODE: /^(.{0,1024})$/,
};

export default class BoltNutFamily extends EntityRecord({
  name: {
    value: null,
    handleCopy: (entity) => `${entity.name} COPY`,
    handleCompare: true,
    handleSerialize: true,
    handleForm: true,
    filter: FILTERS.STRING,
  },
  description: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    handleForm: true,
    filter: FILTERS.STRING,
  },
  archivedFlag: {
    value: false,
  },
  boltNutCode: {
    value: null,
    handleCopy: true,
    handleCompare: true,
    handleSerialize: true,
    handleForm: true,
  },
  boltNutInstances: {
    value: List(),
    isEntity: true,
    handleCopy: true,
    handleDeepCopy: true,
    handleCompare: true,
    handleSerializeCollection: true,
    handleDeepSerialize: true,
    serializeOutputField: 'boltNutInstancesAttributes',
  },
}) {
  get primary() {
    return this.name;
  }
  get primaryPlaceholder() {
    return 'No Name';
  }
  get secondary() {
    return this.boltNutCode;
  }
  get secondaryPlaceholder() {
    return '--';
  }

  get instancesCount() {
    const count = (this.boltNutInstances && this.boltNutInstances.size) || 0;
    return `${count} ${pluralize('instance', count)}`;
  }

  get summary() {
    return {
      primary: `${this.name} // ${this.boltNutCode}`,
      secondary: this.description,
      tertiary: this.instancesCount,
    };
  }
}
