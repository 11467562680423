import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Set } from 'immutable';
import { formValueSelector } from 'redux-form/immutable';

import EK from '../../entities/keys';

import { ENTITY_STATUS } from '../../modules/entities/constants';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectNormalizedBendFamilies } from '../../entities/BendFamilies/selectors';

import BendInstanceSchema from '../../entities/BendInstances/schema';
import {
  selectNormalizedBendInstances,
  selectNormalizedDraftBendInstances,
} from '../../entities/BendInstances/selectors';

import { PipingModulePipesBendsEditPath } from '../../paths';

export const selectBaseParams = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, {
      path: PipingModulePipesBendsEditPath.url,
    });
    // returns { id, familyId }
    return (match && match.params) || null;
  });

export const selectDraftDenormalizeSelector = () =>
  createSelector(selectEntities(), (entities) =>
    entities.delete(EK.BENDS.state)
  );

export const selectCurrentBendId = () =>
  createSelector(
    selectBaseParams(),
    (params) => (params && params.familyId) || null
  );

export const selectCurrentBend = () =>
  createSelector(
    selectCurrentBendId(),
    selectNormalizedBendFamilies(),
    (bendId, bends) => bends.get(bendId)
  );

export const selectOriginalNormalizedBendInstancesForFamily = () =>
  createSelector(
    selectNormalizedBendInstances(),
    selectCurrentBendId(),
    (instances, bendFamilyId) =>
      instances.filter((instance) => instance.bendFamilyId === bendFamilyId)
  );

export const selectOriginalDenormalizedBendInstancesForFamily = () =>
  createSelector(
    selectOriginalNormalizedBendInstancesForFamily(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BendInstanceSchema],
        entities.delete(EK.BENDS.state)
      )
  );

const bendInstanceFilterForm = formValueSelector(
  `filters.${EK.BEND_INSTANCES.state}`
);

export const selectCurrentDraftBendInstances = () =>
  createSelector(
    selectNormalizedDraftBendInstances(),
    selectEntities(),
    (instances, entities) =>
      denormalize(
        instances,
        [BendInstanceSchema],
        entities.delete(EK.BENDS.state)
      )
  );

export const selectCurrentFilteredDraftBendInstances = () =>
  createSelector(
    selectSidebarIsFiltering(),
    selectNormalizedDraftBendInstances(),
    selectEntities(),
    (state) => bendInstanceFilterForm(state, 'sizeProperty'),
    (isFiltering, instances, entities, sizeProperty) =>
      denormalize(
        isFiltering
          ? instances.filter((instance) =>
            instance.doesMatchQuery({ sizeProperty })
          )
          : instances,
        [BendInstanceSchema],
        entities.delete(EK.BENDS.state)
      )
  );

export const selectCurrentDraftBendInstancesPendingValidChanges = () =>
  createSelector(
    selectDrafts(),
    (drafts) => drafts.getIn([EK.BEND_INSTANCES.state, 'saveable']).size > 0
  );

export const selectCurrentDraftBendInstanceIds = () =>
  createSelector(selectNormalizedDraftBendInstances(), (instances) =>
    instances.reduce((ids, current) => ids.add(current.id), Set())
  );

export const selectCurrentEditedDraftBendInstancesCount = () =>
  createSelector(
    selectNormalizedDraftBendInstances(),
    (instances) =>
      instances.filter((draft) => draft.status !== ENTITY_STATUS.LOCAL_DRAFT)
        .size
  );
