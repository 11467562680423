import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Switch } from 'react-router-dom';

import { processFetchIndividualPipeFamily } from '../../entities/PipeFamilies/actions';

import { selectCurrentPipeId } from './selectors';

const mapStateToProps = createStructuredSelector({
  pipeId: selectCurrentPipeId(),
});

export default function (props) {
  const reduxProps = useSelector(mapStateToProps);

  useEffect( () => {
    processFetchIndividualPipeFamily(reduxProps.pipeId);
  }, []);

  return <Switch {...props} {...reduxProps} />;
}
