import React from 'react';
import styled from 'styled-components';

import Flex from '../../../common/Flex';

import ColorSwatch from '../../../common/ColorSwatch';

import { DefaultOptionTemplate } from '../';

const AppearanceOption = styled(Flex).attrs(props => ({
  $ellipsis: true,
  flexDirection: props.flexDirection || 'column',
  justifyContent: props.justifyContent || 'center',
}))`
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
`;

const renderAppearanceOptionForSelect = ({ item, searchWords, isCapitalized }) => (
  <Flex flexDirection='row' justifyContent='center' alignItems='center'>
    { item.tertiary && <ColorSwatch color={item.tertiary} height='100%' width='100%' mr={3} /> }

    <AppearanceOption>
      <DefaultOptionTemplate searchWords={searchWords} isCapitalized={isCapitalized} item={item} />
    </AppearanceOption>
  </Flex>
);

export default renderAppearanceOptionForSelect;
