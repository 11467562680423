import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import { CREATE_NOTE_DRAFTS, EDIT_NOTE_DRAFTS, UPDATE_NOTES_COLLECTION } from './constants';

export const createNoteDrafts = generateCreateDraftsAction(CREATE_NOTE_DRAFTS);

export const editNoteDrafts = generateEditDraftsAction(EDIT_NOTE_DRAFTS);

export const updateNotesCollection = createAction(UPDATE_NOTES_COLLECTION);
