import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/FittingCategories/model';

import Grid from '../../containers/grid/Grid';

import PTCIconSelection from './components/PTCIconSelection';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import {
  createFittingCategoryDrafts,
  editFittingCategoryDrafts,
} from './actions';

import { selectCurrentFilteredNormalizedDraftFittingCategories } from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentFilteredNormalizedDraftFittingCategories(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.FITTING_CATEGORIES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editFittingCategoryDrafts,
      createInstances: createFittingCategoryDrafts,
      copyInstances: createFittingCategoryDrafts,
    },
    ({ data, editable }, dispatchProps) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Category Name',
          field: 'categoryName',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Category Name',
          },
          cellEditorParams: {
            allow: ALLOWANCES.CATEGORY_NAME,
          },
        },
        {
          headerName: 'Symbol File',
          field: 'symbolFile',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Symbol File',
          },
          cellEditorParams: {
            allow: ALLOWANCES.SYMBOL_FILE,
            textDropdownComponent: PTCIconSelection,
          },
        },
      ],
      ...((editable && dispatchProps) || {}),
    }),
  ),
);

export default enhance(Grid);
