import React, { useCallback } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import PipeForm from '../../../form/templates/PipeForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import PipeModel from '../../../../entities/PipeFamilies/model';
import {
  processCreatePipeFamily,
  processCopyPipeFamily,
  processEditPipeFamily,
  processDeletePipeFamily,
} from '../../../../entities/PipeFamilies/actions';

import { selectCurrentPipeFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyPipe = new PipeModel();

const PipeModal = ({ pipe, copyEntity, isPerforming, onCreate, onCopy, onUpdate, onDelete }) => {
  const onSave = useCallback(data => {
    if (pipe) {
      if (copyEntity) {
        onCopy({ ...data, id: pipe.id });
      } else {
        onUpdate(pipe.id, data);
      }
    } else {
      onCreate(data);
    }
  }, [pipe, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(pipe.id), [pipe, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={pipe && pipe.name} copy={copyEntity} create='Pipe' />

      <PipeForm
        initialValues={pipe || emptyPipe}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        pipe && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={pipe.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  pipe: selectCurrentPipeFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.PIPES.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      onCreate: processCreatePipeFamily,
      onCopy: processCopyPipeFamily,
      onUpdate: processEditPipeFamily,
      onDelete: processDeletePipeFamily,
    }
  ),
);

export default enhance(PipeModal);
