import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveBoltNutFamilies,
  processUnarchiveBoltNutFamilies,
} from '../../entities/BoltNutFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import {
  selectCurrentBoltNut,
  selectCurrentDraftBoltNutInstancesPendingValidChanges,
} from './selectors';

import { updateBoltNutInstancesCollection } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentBoltNut(),
  selectCurrentDraftBoltNutInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    boltNut,
    pendingValidChanges,
  ) => ({
    ...rest,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    boltNutId: boltNut && boltNut.id || null,
    isArchived: boltNut && boltNut.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const propsFromRedux = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  return (
    <Options 
      {...props}
      {...propsFromRedux}
      updateEntities={() => dispatch(updateBoltNutInstancesCollection(propsFromRedux.boltNutId))}
      editEntity={() => dispatch(showModal('BOLT_NUT_MODAL', propsFromRedux.boltNutId))}
      archiveEntity={() => dispatch(processArchiveBoltNutFamilies([propsFromRedux.boltNutId]))}
      unarchiveEntity={() => dispatch(processUnarchiveBoltNutFamilies([propsFromRedux.boltNutId]))}
    />
  );
}
