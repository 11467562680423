import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateMoveSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import {
  selectCurrentProjectId,
  selectCurrentProjectNormalizedUnarchivedSpecs,
  selectSerializedDraftSpecsSelectedForIds,
} from './selectors';

import { processFetchAllSpecs } from '../../entities/Specs/actions';

import { processFetchIndividualProject } from '../../entities/Projects/actions';

import { processSaveProjectSpecs } from '../../entities/Projects/actions';
import { saveIndividualProjectSpecsTypes } from '../../entities/Projects/constants';

import {
  EDIT_PROJECT_SPECS,
  UPDATE_PROJECT_SPECS,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentProjectId,
});

const moveSaga = generateMoveSaga({
  keys: EK.SPECS,
  originalNormalizedDataSelector: selectCurrentProjectNormalizedUnarchivedSpecs,
});

const editSaga = generateWatchEditSaga({
  keys: EK.SPECS,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectCurrentProjectId,
  currentDraftDataToSerializeSelector: selectSerializedDraftSpecsSelectedForIds,
  processUpdateAction: processSaveProjectSpecs,
  processUpdateTypes: saveIndividualProjectSpecsTypes,
  ignoreSerialization: true,
  moveSaga,
});

export function* editProjectSpecsSaga() {
  yield takeEvery(EDIT_PROJECT_SPECS, editSaga);
}

export function* saveProjectSpecsSaga() {
  yield takeLatest(UPDATE_PROJECT_SPECS, updateSaga);
}

export function* watchProjectSpecsSaga() {
  try {
    const projectId = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualProject(projectId));
    yield put(processFetchAllSpecs());

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    yield all([
      fork(editProjectSpecsSaga),
      fork(saveProjectSpecsSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.SPECS.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchProjectSpecsSaga);
}
