import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import Button from '../../components/common/Button';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveInsulationFamilies,
  processUnarchiveInsulationFamilies,
} from '../../entities/InsulationFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';
import { toggleHiddenGridColumns } from '../Dashboard/actions';

import { showModal } from '../../modules/modal/actions';

import {
  selectCurrentInsulation,
  selectCurrentDraftInsulationInstancesPendingValidChanges,
} from './selectors';

import { updateInsulationInstancesCollection } from './actions';

const ConstructedOptions = ({ toggleHiddenGridColumns }) => (
  <Button secondary icon="list" onClick={toggleHiddenGridColumns} mr={2} />
);

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentInsulation(),
  selectCurrentDraftInsulationInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    insulation,
    pendingValidChanges
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    insulationId: (insulation && insulation.id) || null,
    isArchived: insulation && insulation.archivedFlag,
    pendingValidChanges,
    ConstructedOptions,
  })
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  return (
    <Options 
      {...props}
      {...reduxProps}
      toggleHiddenGridColumns = {() => dispatch(toggleHiddenGridColumns())}
      updateEntities = {() => dispatch(updateInsulationInstancesCollection(reduxProps.insulationId))}
      editEntity = {() => dispatch(showModal('INSULATION_MODAL', reduxProps.insulationId))}
      archiveEntity = {() => dispatch(processArchiveInsulationFamilies([reduxProps.insulationId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveInsulationFamilies([reduxProps.insulationId]))}
    />
  );
}
