import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';
import bendInstanceKeys from '../BendInstances/keys';

import PipeFamilySchema from '../PipeFamilies/schema';
import SizeSchema from '../Sizes/schema';

const bendFamilySchema = baseSchema(keys.state, {
  pipeFamily: { schema: PipeFamilySchema, isId: true },
  bendInstances: { isId: true, idField: 'bendInstanceIds', isList: true },
});

export const bendInstanceSchema = baseSchema(bendInstanceKeys.state, {
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  radius: { isComplexUnit: true },
  minRadius: { isComplexUnit: true },
  maxRadius: { isComplexUnit: true },
  bendFamily: { schema: bendFamilySchema, isId: true },
});

bendFamilySchema.define({ bendInstances: [ bendInstanceSchema ] });

export default bendFamilySchema;
