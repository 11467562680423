import { matchPath } from 'react-router-dom';
import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { selectRouteLocationPathname } from '../../modules/router/selectors';

import { selectNormalizedPipeFamilies } from '../../entities/PipeFamilies/selectors';

import { selectMasterCatalogUnitSystem } from '../../entities/PipingCatalogs/selectors';

import UnitSystemSchema from '../../entities/UnitSystems/schema';

import { PipingModulePipesEditPath } from '../../paths';

export const selectCurrentPipeId = () =>
  createSelector(selectRouteLocationPathname(), (pathname) => {
    const match = matchPath(pathname, { path: PipingModulePipesEditPath.url });
    return (match && match.params.id) || null;
  });

export const selectCurrentPipe = () =>
  createSelector(
    selectCurrentPipeId(),
    selectNormalizedPipeFamilies(),
    (pipeId, pipes) => pipes.get(pipeId)
  );

export const selectCurrentPipeUnitSystem = () =>
  createSelector(
    selectCurrentPipe(),
    selectEntities(),
    selectMasterCatalogUnitSystem(),
    (pipe, entities, defaultMasterCatalogUnitSystem) =>
      pipe &&
        pipe.unitSystem &&
        entities.hasIn([EK.UNIT_SYSTEMS.state, pipe.unitSystem])
        ? denormalize(
          entities.getIn([EK.UNIT_SYSTEMS.state, pipe.unitSystem]),
          UnitSystemSchema,
          entities
        )
        : defaultMasterCatalogUnitSystem
  );

export const selectCurrentPipeInstanceAndChildrenSerializationSelector = () =>
  createSelector(selectCurrentPipeUnitSystem(), (unitSystem) => ({
    unitSystem,
  }));
