import React from 'react';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

const SettingDetailsCellRenderer = ({ 
  value, 
  placeholder,
}) => {
  const isValuePresent = !!value;
  
  return (
    <BaseCell flexDirection='column' justifyContent='center'>
      <BaseCellDataItem disabled={!isValuePresent || !value.primary} fontWeight={600}>
        { (isValuePresent && (value.primary || value.primaryPlaceholder)) || (placeholder && placeholder.primary) }
      </BaseCellDataItem>
      <BaseCellDataItem variable secondary disabled={!isValuePresent || !value.secondary} height='2rem'>
        { (isValuePresent && (value.secondary || value.secondaryPlaceholder) || placeholder && placeholder.secondary) }
      </BaseCellDataItem>
    </BaseCell>
  );
};

export default SettingDetailsCellRenderer;