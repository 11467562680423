import { toast } from 'react-toastify';

import { createAction } from 'redux-actions';

import { REQUEST_TYPES } from '../api/constants';
import { nonEntityApi } from '../api/actions';

import { client as currentUserClient } from '../../entities/CurrentUser/actions';

import userClient from '../../entities/Users/client';

import organizationUserInviteClient from '../../entities/OrganizationUserInvites/client';

import {
  processLogout,
  processLoginTypes,
  processRegisterTypes,
  processVerifyEmailTypes,
  processAcceptInviteTypes,
  processFetchCurrentUserTypes,
  processRequestPasswordResetTypes,
  processResetPasswordTypes,
  processForceResetPasswordTypes,
  samlLoginTypes,
  RESET_AUTH_STATE,
} from './constants';

export const handleLogout = createAction(processLogout.HANDLE);
export const logoutHandled = createAction(processLogout.HANDLED);

export const processLogin = (data, rememberMe = true) => currentUserClient(REQUEST_TYPES.POST, 'single', processLoginTypes, '/sessions', {
  data,
  disableErrorRedirect: true,
  mutateResponse: ({ user }, { entityKey }) => ({
    [entityKey]: user.emailVerified ? user : {},
    emailVerified: user.emailVerified,
    ...(user.emailVerified ? {
      authenticationToken: user.authenticationToken,
      passwordResetRequired: user.passwordResetRequired,
      ...(rememberMe ? { rememberMe } : {}),
    } : {}),
  }),
});

export const processSAMLLogin = (data) => {
  // TODO: Pass back an error state from the API if SAML login fails
  // if (data.error) {
  //   return {
  //     type: samlLoginTypes.FAILURE,
  //     payload: {
  //       error: data.error,
  //     },
  //   };
  // }

  return {
    type: samlLoginTypes.SUCCESS,
    payload: {
      authenticationToken: data,
    },
  };
};

export const processFetchCurrentUser = (disableErrorRedirect) =>
  currentUserClient(REQUEST_TYPES.GET, 'single', processFetchCurrentUserTypes, '/me', {
    disableErrorRedirect,
  });

export const processRegister = (data) =>
  userClient(REQUEST_TYPES.POST, 'single', processRegisterTypes, '/users', {
    data,
    disableErrorRedirect: true,
    mutateRequest: (data, { entityKey }) => ({ [entityKey]: data }),
  });

export const processVerifyEmail = (verifyToken) =>
  userClient(REQUEST_TYPES.PATCH, 'single', processVerifyEmailTypes, '/users/verify_email', {
    data: { verifyToken },
    mutateResponse: ({ user }, { entityKey }) => ({
      [entityKey]: user,
      authenticationToken: user.authenticationToken,
    }),
  });

export const processAcceptInvite = (data, token) =>
  organizationUserInviteClient(
    REQUEST_TYPES.PATCH,
    'single',
    processAcceptInviteTypes,
    `/organization_user_invites/${token}/accept`,
    {
      data,
      mutateResponse: ({ organizationUserInvite }, { entityKey }) => ({
        [entityKey]: organizationUserInvite,
        authenticationToken: organizationUserInvite.authenticationToken,
      }),
    }
  );

export const processRequestPasswordReset = (data) =>
  nonEntityApi(REQUEST_TYPES.POST, processRequestPasswordResetTypes, '/password_resets', { data });
export const processPasswordReset = (data) =>
  nonEntityApi(REQUEST_TYPES.PATCH, processResetPasswordTypes, '/password_resets', { data });
export const processForceResetPassword = (data) =>
  nonEntityApi(REQUEST_TYPES.POST, processForceResetPasswordTypes, '/password_resets', {
    data,
    successNotification: {
      type: toast.TYPE.SUCCESS,
      message: 'Password reset email sent',
    },
    errorNotification: {
      type: toast.TYPE.ERROR,
      message: 'Unable to reset password',
    },
  });
