import { toast } from 'react-toastify';

import EK from '../keys';

import bendClient from './client';

import { REQUEST_TYPES } from '../../modules/api/constants';

import bendFamilyKeys from './keys';
import bendInstanceKeys from '../BendInstances/keys';

import {
  fetchAllBendFamiliesForPipeFamilyTypes,
  fetchIndividualBendFamilyForPipeFamilyTypes,
  createBendFamilyForPipeFamilyTypes,
  copyBendFamilyForPipeFamilyTypes,
  editBendFamilyForPipeFamilyTypes,
  deleteBendFamilyForPipeFamilyTypes,
  archiveBendFamiliesForPipeFamilyTypes,
  unarchiveBendFamiliesForPipeFamilyTypes,
} from './constants';

export const processFetchAllBendFamiliesForPipeFamily = pipeId => bendClient(REQUEST_TYPES.GET, 'plural', fetchAllBendFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_families?all=true`, {
  mutateResponse: ({ bendFamilies = [] }) => ({
    bendFamilies: bendFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId })),
  }),
});

export const processFetchIndividualBendFamilyForPipeFamily = ({ id: pipeId, familyId }) => bendClient(REQUEST_TYPES.GET, 'single', fetchIndividualBendFamilyForPipeFamilyTypes, `/piping/bend_families/${familyId}?with_full_detail=true`, {
  mutateResponse: ({ bendFamily: { id, bendInstances = [], ...other } = {} }) => ({
    bendFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendInstances: bendInstances.map(instance => ({
        ...instance,
        bendFamily: `${id}`,
        bendFamilyId: `${id}`,
      })),
    },
  }),
});

export const processCreateBendFamilyForPipeFamily = (pipeId, data) => bendClient(REQUEST_TYPES.POST, 'plural', createBendFamilyForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_families`, {
  data: {
    bendFamilies: [data],
  },
  mutateResponse: ({ bendFamilies = [] }) => ({
    bendFamilies: bendFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend successfully created',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to create bend',
  },
});

export const processCopyBendFamilyForPipeFamily = (pipeId, data) =>
  bendClient(
    REQUEST_TYPES.POST,
    'plural',
    copyBendFamilyForPipeFamilyTypes,
    `/piping/pipe_families/${pipeId}/bend_families/copy`,
    {
      data: {
        [EK.BENDS.plural]: [data],
      },
      mutateResponse: ({ bendFamilies = [] }) => ({
        bendFamilies: bendFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
      }),
      successNotification: {
        type: toast.TYPE.SUCCESS,
        message: 'Bend successfully copied',
      },
      errorNotification: {
        type: toast.TYPE.ERROR,
        message: 'Unable to copy bend',
      },
    }
  );

export const processEditBendFamilyForPipeFamily = (pipeId, bendId, data) => bendClient(REQUEST_TYPES.PATCH, 'single', editBendFamilyForPipeFamilyTypes, `/piping/bend_families/${bendId}`, {
  data,
  mutateResponse: ({ bendFamily: { id, ...other } = {} }) => ({
    bendFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Changes saved',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to save changes',
  },
});

export const processDeleteBendFamilyForPipeFamily = (pipeId, bendId) => bendClient(REQUEST_TYPES.DELETE, 'single', deleteBendFamilyForPipeFamilyTypes, `/piping/bend_families/${bendId}?with_full_detail=true`, {
  deleteKey: bendFamilyKeys.state,
  dependentDeletionKeys: [bendInstanceKeys.state],
  mutateResponse: ({ bendFamily: { id, ...other } = {}, bendInstances = [] }) => ({
    bendFamily: {
      ...other,
      id,
      pipeFamily: pipeId,
      pipeFamilyId: pipeId,
      bendInstances: bendInstances.map(instance => ({
        ...instance,
        bendFamily: `${id}`,
        bendFamilyId: `${id}`,
      })),
    },
  }),
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend deleted',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to delete bend',
  },
});

export const processArchiveBendFamiliesForPipeFamily = (pipeId, bendIds) => bendClient(REQUEST_TYPES.ARCHIVE, 'plural', archiveBendFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_families/archive`, {
  mutateResponse: ({ bendFamilies = [] }) => ({
    bendFamilies: bendFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
  }),
  data: { ids: bendIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend archived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to archive bend',
  },
});

export const processUnarchiveBendFamiliesForPipeFamily = (pipeId, bendIds) => bendClient(REQUEST_TYPES.UNARCHIVE, 'plural', unarchiveBendFamiliesForPipeFamilyTypes, `/piping/pipe_families/${pipeId}/bend_families/unarchive`, {
  mutateResponse: ({ bendFamilies = [] }) => ({
    bendFamilies: bendFamilies.map(family => ({ ...family, pipeFamily: pipeId, pipeFamilyId: pipeId, })),
  }),
  data: { ids: bendIds },
  successNotification: {
    type: toast.TYPE.SUCCESS,
    message: 'Bend unarchived',
  },
  errorNotification: {
    type: toast.TYPE.ERROR,
    message: 'Unable to unarchive bend',
  },
});
