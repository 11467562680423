import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import useFloatingState from '../../../hooks/useFloatingState';
import useIsHovering from '../../../hooks/useIsHovering';

import Text from '../../common/Text';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';
import TooltipBody from '../../tooltip/TooltipBody';
import TooltipSection from '../../tooltip/TooltipSection';

import BaseCell from '../BaseCell';

const MatrixCellRenderer = () => {
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'bottom' });
  const isHovering = useIsHovering(reference, { delayEnter: 250 });

  return (
    <BaseCell
      ref={reference}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize={1} color="gray.6">
        Can only be managed in Creo
      </Text>
      {isHovering &&
        ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size="large">
            <TooltipBody>
              <TooltipSection small inverse>
                Due to the visual nature of orientations, orientation details
                and matrices can only be created and managed in Creo itself
              </TooltipSection>
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )}
    </BaseCell>
  );
};

export default MatrixCellRenderer;
