import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';
import E_ALLOWANCES from '../../entities/allowances';

import { ALLOWANCES } from '../../entities/BoltNutInstances/model';

import Grid from '../../containers/grid/Grid';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import {
  createBoltNutInstanceDrafts,
  editBoltNutInstanceDrafts,
} from './actions';

import {
  selectCurrentBoltNut,
  selectCurrentFilteredDraftBoltNutInstances,
} from './selectors';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentBoltNut(),
  selectCurrentFilteredDraftBoltNutInstances(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    boltNut,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense && boltNut &&
        !boltNut.archivedFlag,
      boltNutId: (boltNut && boltNut.id) || null,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.BOLT_NUT_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editBoltNutInstanceDrafts,
      createInstances: createBoltNutInstanceDrafts,
      copyInstances: createBoltNutInstanceDrafts,
    },
    ({ data, editable, boltNutId }, { createInstances, ...dispatchProps }) => ({
      data,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Size',
          field: 'sizeProperty',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Size' },
            ek: EK.SIZES,
          },
          cellEditorParams: { ek: EK.SIZES },
        },
        {
          headerName: 'Schedule',
          field: 'schedule',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Schedule' },
            ek: EK.SCHEDULES,
          },
          cellEditorParams: { ek: EK.SCHEDULES },
        },
        {
          headerName: 'MCCS Code',
          field: 'mccsCode',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'MCCS Code',
          },
          cellEditorParams: {
            allow: ALLOWANCES.MCCS_CODE,
          },
        },
        {
          headerName: 'Bulk Item Name',
          field: 'bulkItemName',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Bulk Item Name',
          },
        },
        {
          headerName: 'Quantity',
          field: 'quantity',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Quantity',
          },
          cellEditorParams: {
            allow: E_ALLOWANCES.INTEGER,
          },
        },
        {
          headerName: 'Bolt Type',
          field: 'boltType',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Bolt Type',
          },
        },
        {
          headerName: 'Material Description',
          field: 'materialDescription',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Material Description',
          },
        },
      ],
      ...((editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              boltNutFamily: boltNutId,
              boltNutFamilyId: boltNutId,
            },
          });
        },
      }) ||
        {}),
    }),
  ),
);

export default enhance(Grid);
