import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedNoteProfiles = () =>  createSelector(
  selectEntities(),
  entities => entities.get('noteProfiles')
);
export const selectNormalizedNoteProfilesList = () => createSelector(
  selectNormalizedNoteProfiles(),
  noteProfiles => noteProfiles.toList()
);