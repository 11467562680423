import {
  all,
  call,
  cancelled,
  fork,
  put,
  take,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateMoveSaga,
  generateReviewSaga,
  generateWaitWhileBaseParamsSaga,
  generateWatchCollectionUpdateSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import ParameterProfileParameterModel from '../../entities/ParameterProfileParameters/model';
import ParameterProfileParameterSchema from '../../entities/ParameterProfileParameters/schema';

import {
  selectCurrentDraftParameterProfileParameterIds,
  selectCurrentDraftParameterProfileParameters,
  selectCurrentParameterProfileId,
  selectDraftDenormalizeSelector,
  selectOriginalDenormalizedParameterProfileParameters,
  selectOriginalNormalizedParameterProfileParameters,
} from './selectors';

import { processFetchIndividualParameterProfile } from '../../entities/ParameterProfiles/actions';
import { fetchIndividualParameterProfileTypes } from '../../entities/ParameterProfiles/constants';

import {
  selectNormalizedDraftParameterProfileParameters,
} from '../../entities/ParameterProfileParameters/selectors';
import { processSaveParameterProfileParameters } from '../../entities/ParameterProfileParameters/actions';
import { saveParameterProfileParametersTypes } from '../../entities/ParameterProfileParameters/constants';

import {
  CREATE_PARAMETER_PROFILE_PARAMETER_DRAFTS,
  EDIT_PARAMETER_PROFILE_PARAMETER_DRAFTS,
  UPDATE_PARAMETER_PROFILE_PARAMETERS_COLLECTION,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentParameterProfileId,
});

const reviewSaga = generateReviewSaga({
  keys: EK.PARAMETER_PROFILE_PARAMETERS,
  schema: ParameterProfileParameterSchema,
  draftDenormalizeSelector: selectDraftDenormalizeSelector,
  originalDenormalizedDataSelector:
    selectOriginalDenormalizedParameterProfileParameters,
});

const generateCreationProperties = (parameterProfileId) => ({
  parameterProfileId: parameterProfileId,
  parameterProfile: parameterProfileId,
});

const moveSaga = generateMoveSaga({
  keys: EK.PARAMETER_PROFILE_PARAMETERS,
  model: ParameterProfileParameterModel,
  baseParamsSelector: selectCurrentParameterProfileId,
  originalNormalizedDataSelector:
    selectOriginalNormalizedParameterProfileParameters,
  currentIdsSetSelector: selectCurrentDraftParameterProfileParameterIds,
  generateCreationProperties,
});

const createSaga = generateWatchCreateSaga({
  keys: EK.PARAMETER_PROFILE_PARAMETERS,
  model: ParameterProfileParameterModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftParameterProfileParameterIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.PARAMETER_PROFILE_PARAMETERS,
  model: ParameterProfileParameterModel,
  reviewSaga,
  baseParamsSelector: selectCurrentParameterProfileId,
  draftStateToGetLastEditedIndexSelector:
    selectNormalizedDraftParameterProfileParameters,
  currentIdsSetSelector: selectCurrentDraftParameterProfileParameterIds,
  generateCreationProperties,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  baseParamsSelector: selectCurrentParameterProfileId,
  currentDraftDataToSerializeSelector:
    selectCurrentDraftParameterProfileParameters,
  processUpdateAction: processSaveParameterProfileParameters,
  processUpdateTypes: saveParameterProfileParametersTypes,
  moveSaga,
});

export function* createDraftParameterProfileParametersSaga() {
  yield takeEvery(CREATE_PARAMETER_PROFILE_PARAMETER_DRAFTS, createSaga);
}

export function* editDraftParameterProfileParametersSaga() {
  yield takeEvery(EDIT_PARAMETER_PROFILE_PARAMETER_DRAFTS, editSaga);
}

export function* saveDraftParameterProfileParametersSaga() {
  yield takeLatest(UPDATE_PARAMETER_PROFILE_PARAMETERS_COLLECTION, updateSaga);
}

export function* watchParameterProfileParametersDraftsSaga() {
  try {
    const parameterProfileId = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualParameterProfile(parameterProfileId));

    // wait for the individual request to be successful
    yield take(fetchIndividualParameterProfileTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftParameterProfileParametersSaga),
      fork(editDraftParameterProfileParametersSaga),
      fork(saveDraftParameterProfileParametersSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.PARAMETER_PROFILE_PARAMETERS.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchParameterProfileParametersDraftsSaga);
}
