import React, { useCallback } from 'react';
import { compose } from 'redux';
import { useDispatch, useSelector } from 'react-redux';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import ProjectForm from '../../../form/templates/ProjectForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import ProjectModel from '../../../../entities/Projects/model';
import {
  processCreateProject,
  processCopyProject,
  processEditProject,
  processDeleteProject
} from '../../../../entities/Projects/actions';

import { selectCurrentProject } from '../../../../routes/ProjectsEdit/selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

const emptyProject = new ProjectModel();

const ProjectModal = ({ copyEntity, projectId = null }) => {
  const isPerforming = useSelector(selectIsPerformingInModal());
  const project = useSelector(selectCurrentProject(projectId));
  const dispatch = useDispatch();

  const onSave = (data) => {
    if (project) {
      if (copyEntity) {
        dispatch(processCopyProject({ ...data, id: project.id }));
      } else {
        dispatch(processEditProject(project.id, data));
      }
    } else {
      dispatch(processCreateProject(data));
    }
  };

  const onConfirmDelete = () => dispatch(processDeleteProject(project.id));

  return (
    <Box>
      <CreateOrEditEntityTitle edit={project && project.name} copy={copyEntity} create='Project' />

      <ProjectForm
        initialValues={project || emptyProject}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        project && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={project.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const enhance = compose(
  injectSaga({ key: `${EK.PROJECTS.state}Modal`, saga }),
);

export default enhance(ProjectModal);
