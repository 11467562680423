import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';
import times from 'lodash/times';

import EK from '../../entities/keys';

import { ALLOWANCES } from '../../entities/FittingInstances/model';

import Grid from '../../containers/grid/Grid';

import {
  selectPipingModulePermissionsAndState,
  selectShowHiddenGridColumns,
} from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { selectBranchLetFittingCode } from '../../entities/FittingCodes/selectors';

import { selectPortNames } from '../../entities/FittingInstances/selectors';

import {
  createFittingInstanceDrafts,
  editFittingInstanceDrafts,
} from './actions';

import {
  selectCurrentFilteredDraftFittingInstances,
  selectCurrentFitting,
  selectCurrentFittingFamilyEndPortCountAndChangesSize,
  selectCurrentFittingUnitSystem,
} from './selectors';

// hardcoded WeldoletPortNames for BRANCH_LET
// putting this here for the time being because this is the only place it matters
const weldoletPortNames = List(['Line Size', 'Branch Size']);

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectCurrentFitting(),
  selectCurrentFilteredDraftFittingInstances(),
  selectBranchLetFittingCode(),
  selectPortNames(),
  selectCurrentFittingFamilyEndPortCountAndChangesSize(),
  selectCurrentFittingUnitSystem(),
  selectShowHiddenGridColumns(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    fitting,
    data,
    branchLetFittingCode,
    portNames,
    { endCount, changesSize },
    defaultUnitSystem,
    showHiddenGridColumns,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: (!isLoading && data) || [],
      defaultUnitSystem,
      editable: canCollaborate && hasValidLicense && fitting &&
        !fitting.archivedFlag,
      fittingId: (fitting && fitting.id) || null,
      fittingCodeId: fitting &&
        ((fitting.fittingCode &&
          (fitting.fittingCode.id || fitting.fittingCode)) ||
          null),
      portNames: endCount === 2 &&
          fitting &&
          branchLetFittingCode &&
          fitting.fittingCode === branchLetFittingCode.id
        ? weldoletPortNames
        : portNames,
      endPortCount: endCount,
      endPortChangesSize: changesSize,
      showHiddenGridColumns,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.FITTING_INSTANCES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editFittingInstanceDrafts,
      createInstances: createFittingInstanceDrafts,
      copyInstances: createFittingInstanceDrafts,
    },
    (
      {
        data,
        defaultUnitSystem,
        editable,
        fittingId,
        fittingCodeId,
        portNames,
        endPortCount,
        endPortChangesSize,
        showHiddenGridColumns,
      },
      { createInstances, ...dispatchProps },
    ) => ({
      data,
      defaultUnitSystem,
      columnDefs: [
        ...((editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ]) ||
          []),
        {
          headerName: 'Schedule',
          field: 'schedule',
          editable,
          type: ['entityCell', 'dropdownEditor'],
          cellRendererParams: {
            placeholder: { primary: 'Select Schedule' },
            ek: EK.SCHEDULES,
          },
          cellEditorParams: { ek: EK.SCHEDULES },
        },
        {
          headerName: 'CAD Model Name',
          field: 'cadModelName',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'CAD Model Name',
          },
        },
        ...times(endPortCount, (idx) => ({
          headerName: portNames.get(idx),
          headerGroupComponent: 'basicGroupHeaderRenderer',
          children: [
            {
              headerName: 'Size',
              field: `port${idx}Size`,
              editable: (editable && idx === 0) ||
                (endPortCount === 2 && endPortChangesSize) ||
                (endPortCount === 3 && idx === 2 && endPortChangesSize),
              suppressNavigable: !(
                idx === 0 ||
                (endPortCount === 2 && endPortChangesSize) ||
                (endPortCount === 3 && idx === 2 && endPortChangesSize)
              ),
              valueSetter: ({ data, colDef, oldValue, newValue, context }) => {
                oldValue !== newValue &&
                  context.editInstances &&
                  context.editInstances(
                    data.setEndPortSize(colDef.field, newValue),
                  );
                return false;
              },
              type: ['entityCell', 'dropdownEditor'],
              cellRendererParams: {
                placeholder: { primary: 'Select Size' },
                ek: EK.SIZES,
              },
              cellEditorParams: { ek: EK.SIZES },
            },
            {
              // TODO: Why are we ever checking for endport count less than 2??
              headerName: 'End Type',
              field: `port${idx}EndType`,
              editable,
              type: ['entityCell', 'dropdownEditor'],
              cellRendererParams: {
                placeholder: { primary: 'Select End Type' },
                ek: EK.END_TYPES,
              },
              cellEditorParams: { ek: EK.END_TYPES },
            },
          ],
        })),
        {
          headerName: 'Stock No',
          field: 'stockno',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Stockno',
          },
          cellEditorParams: {
            allow: ALLOWANCES.STOCKNO,
          },
        },
        {
          headerName: 'MCCS Code',
          field: 'mccsCode',
          hide: !showHiddenGridColumns,
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'MCCS Code',
          },
          cellEditorParams: {
            allow: ALLOWANCES.MCCS_CODE,
          },
        },
      ],
      ...((editable && {
        ...dispatchProps,
        createInstances(instances, opts) {
          createInstances(instances, {
            ...opts,
            creationProperties: {
              fittingFamily: fittingId,
              fittingFamilyId: fittingId,
              fittingCode: fittingCodeId,
              fittingCodeId,
            },
          });
        },
      }) ||
        {}),
    }),
  ),
);

export default enhance(Grid);
