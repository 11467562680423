import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import {
  ParameterProfilesEditPath,
} from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData, selectSidebarIsFiltering } from '../Dashboard/selectors';

import {
  selectCurrentParameterProfile,
  selectCurrentEditedDraftParameterProfileParametersCount,
  selectCurrentFilteredDraftParameterProfileParameters,
} from './selectors';


const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentParameterProfile(),
  selectCurrentEditedDraftParameterProfileParametersCount(),
  selectCurrentFilteredDraftParameterProfileParameters(),
  (isLoadingInitialData, isFetching, isFiltering, boltNut, count, filtered) => ({
    isLoading: isLoadingInitialData || (isFetching && (!count || count === 0)),
    title: boltNut && boltNut.name || ParameterProfilesEditPath.defaultTitle,
    subtitle: isFiltering ? `Showing ${filtered.size}/${count} ${pluralize('filtered Parameter', count)}` : `Showing ${count} ${pluralize('Parameter', count)} Total`,
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(Header);