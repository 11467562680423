import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';

import AppearanceSchema from '../Appearances/schema';
import InsulationFamilySchema from '../InsulationFamilies/schema';

const insulationSpecSchema = baseSchema(keys.state, {
  appearance: { schema: AppearanceSchema, isId: true },
  insulationFamily: { schema: InsulationFamilySchema, isId: true },
});

export default insulationSpecSchema;
