import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import isEmpty from 'lodash/isEmpty';

import selectEntities from '../../modules/entities/selectors';
import UnitSystemSchema from './schema';

// super monkey patching to limit options that user can select for unit systems here
export const selectUnitSystems = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('unitSystems'), [UnitSystemSchema], entities).filter(unitSystem => !isEmpty(unitSystem.name) && unitSystem.name.toLowerCase() === 'mmks' || unitSystem.name.toLowerCase() === 'ips')
);
export const selectUnitSystemsOptions = () => createSelector(
  selectUnitSystems(),
  unitSystems => unitSystems.toList().map(unitSystem => unitSystem.toOption())
);
