import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { List } from 'immutable';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import FittingFamilySchema from './schema';

export const selectNormalizedFittingFamilies = () => createSelector(
  selectEntities(),
  entities => entities.get('fittingFamilies')
);
export const selectFittingFamilies = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('fittingFamilies'), [FittingFamilySchema], entities.delete('fittingInstances'))
);
export const selectFittingFamiliesList = () => createSelector(
  selectFittingFamilies(),
  fittingFamilies => fittingFamilies.toList()
);

export const selectFittingFamiliesCounts = () => createSelector(
  selectNormalizedFittingFamilies(),
  fittings => fittings.reduce(({ unarchived, total }, fitting) => ({
    unarchived: !fitting.archivedFlag ? unarchived + 1 : unarchived,
    total: total + 1,
  }), {
    unarchived: 0,
    total: 0,
  })
);

export const selectNormalizedDraftFittingFamilies = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('fittingFamilies');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectNormalizedUnarchivedDraftFittingFamilies = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('fittingFamilies');
    const records = state.get('records');
    return state.get('ids').reduce((output, id) => !records.getIn([id, 'archivedFlag']) ? output.push(records.get(id)) : output, List());
  }
);