import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EK from '../../../../entities/keys';

import Box from '../../../../components/common/Box';

import CreateOrEditEntityTitle from '../../../../components/modal/templates/CreateOrEditEntityTitle';

import ConfirmDeleteForm from '../../../form/templates/ConfirmDeleteForm';

import MiterForm from '../../../form/templates/MiterForm';

import { selectIsPerformingInModal } from '../../../../modules/utility/selectors';

import MiterModel from '../../../../entities/MiterFamilies/model';
import {
  processCreateMiterFamilyForPipeFamily,
  processCopyMiterFamilyForPipeFamily,
  processEditMiterFamilyForPipeFamily,
  processDeleteMiterFamilyForPipeFamily,
} from '../../../../entities/MiterFamilies/actions';

import { selectCurrentMiterFamily } from './selectors';

import injectSaga from '../../../../utils/sagas/injectSaga';

import saga from './sagas';

import { prepareMiterModal } from './actions';

const emptyMiter = new MiterModel();

const MiterModal = ({ pipeFamilyId, miter, copyEntity, isPerforming, prepareModal, onCreate, onCopy, onUpdate, onDelete }) => {
  useEffect(() => prepareModal(pipeFamilyId), []);

  const onSave = useCallback(data => {
    if (miter) {
      if (copyEntity) {
        onCopy(pipeFamilyId, { ...data, id: miter.id });
      } else {
        onUpdate(pipeFamilyId, miter.id, data);
      }
    } else {
      onCreate(pipeFamilyId, data);
    }
  }, [pipeFamilyId, miter, copyEntity, onCreate, onCopy, onUpdate]);

  const onConfirmDelete = useCallback(() => onDelete(pipeFamilyId, miter.id), [pipeFamilyId, miter, onDelete]);

  return (
    <Box>
      <CreateOrEditEntityTitle edit={miter && miter.name} copy={copyEntity} create='Miter' />

      <MiterForm
        initialValues={miter || emptyMiter}
        isPerforming={isPerforming}
        enableReinitialize={copyEntity}
        isCopying={copyEntity}
        onRDXSubmit={onSave}
      />

      {
        miter && !copyEntity &&
        <React.Fragment>
          <Box pb={4} />
          <ConfirmDeleteForm deletePhrase={miter.name} isPerforming={isPerforming} onRDXSubmit={onConfirmDelete} />
        </React.Fragment>
      }
    </Box>
  );
};

const mapStateToProps = createStructuredSelector({
  isPerforming: selectIsPerformingInModal(),
  miter: selectCurrentMiterFamily(),
});

const enhance = compose(
  injectSaga({ key: `${EK.BENDS.state}Modal`, saga }),
  connect(
    mapStateToProps,
    {
      prepareModal: prepareMiterModal,
      onCreate: processCreateMiterFamilyForPipeFamily,
      onCopy: processCopyMiterFamilyForPipeFamily,
      onUpdate: processEditMiterFamilyForPipeFamily,
      onDelete: processDeleteMiterFamilyForPipeFamily,
    }
  ),
);

export default enhance(MiterModal);
