import { createAction } from 'redux-actions';

import {
  UPDATE_SPEC_FITTING_INSTANCES,
  UPDATE_SPEC_PIPE_INSTANCES,
  UPDATE_FAMILY_SORTING,
} from './constants';

export const updateSpecFittingInstances = createAction(
  UPDATE_SPEC_FITTING_INSTANCES,
  (specId) => ({ specId })
);

export const updateSpecPipeInstances = createAction(
  UPDATE_SPEC_PIPE_INSTANCES,
  (specId) => ({ specId })
);

export const updateFamilySorting = createAction(
  UPDATE_FAMILY_SORTING,
  (familySortingOrder) => ({ familySortingOrder })
);
