import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { selectCurrentProject } from './selectors';

import { PipingModuleProjectsEditPath } from '../../paths';

const mapStateToProps = createSelector(selectCurrentProject(), (project) => ({
  title: (project && project.name) || PipingModuleProjectsEditPath.defaultTitle,
}));

const enhance = compose(connect(mapStateToProps));

export default enhance(BreadcrumbTemplate);
