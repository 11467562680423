import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData, selectSidebarIsFiltering } from '../Dashboard/selectors';

import { selectCurrentSpec, selectCurrentFitting } from '../SpecsEditSwitch/selectors';

import {
  selectCurrentSpecFittingInstancesForFamily,
  selectCurrentFilteredSpecFittingInstancesForFamily,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectSidebarIsFiltering(),
  selectCurrentSpec(),
  selectCurrentFitting(),
  selectCurrentSpecFittingInstancesForFamily(),
  selectCurrentFilteredSpecFittingInstancesForFamily(),
  (isLoadingInitialData, isFetching, isFiltering, spec, fitting, data, filtered) => ({
    isLoading: isLoadingInitialData || (isFetching && (!data || data.size === 0)),
    title: spec && spec.specName && fitting && fitting.name && `${spec.specName} // ${fitting.name}` || 'Loading...',
    subtitle: isFiltering ? `Showing ${filtered.size}/${data.size} ${pluralize('filtered Fitting Instance', filtered.size)}` : `Showing ${data.size} ${pluralize('Fitting Instance', data.size)} Total`,
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(Header);