import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import colors from '../../../assets/themes/base/colors';
import shadows from '../../../assets/themes/base/shadows';
import { offset } from '@floating-ui/react';

import useFloatingState, {DEFAULT_MIDDLEWARE} from '../../../hooks/useFloatingState';

import Flex from '../../common/Flex';
import Box from '../../common/Box';
import BaseTextarea from '../../form/BaseTextarea';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';

// const KEY_ENTER = 13;
const KEY_BACKSPACE = 8;
const KEY_DELETE = 46;
const KEY_F2 = 113;

const NEW_MIDDLEWARE = [
  ...DEFAULT_MIDDLEWARE,
  offset(-1)
];

const TextareaTooltip = styled(Flex)`
  border: ${colors.primary[4]} 1px solid;
  box-shadow: ${shadows[2]};
`;

const MultiLineTextEditor = React.forwardRef(({
  keyPress,
  charPress,
  value = '',
  allow,
  onDisallow,
  placeholder,
  append,
  defaultValue = null,
  column,
}, ref) => {
  const inputRef = useRef();
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'top-start', middleware: NEW_MIDDLEWARE });

  const initialValue = useMemo(() => {
    let startValue = '';

    if (keyPress === KEY_BACKSPACE || keyPress === KEY_DELETE) {
      // if backspace or delete pressed, clear the cell
      startValue = '';
    } else if (charPress) {
      // if a letter was pressed, we start with the letter if it's allowed
      if (allow) {
        if (allow instanceof RegExp) {
          startValue = allow.test(charPress) ? charPress : (value || '');
        } else {
          startValue = allow(charPress) ? charPress : (value || '');
        }
      } else {
        startValue = charPress;
      }
    } else {
      // otherwise, we start with the current value
      startValue = value || '';
    }

    return startValue;
  }, []);

  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isReady, setIsReady] = useState(false);

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    getValue: () => currentValue === '' || currentValue === undefined ? defaultValue : currentValue,
  }));

  const onChange = useCallback(
    e => {
      setCurrentValue(e.target.value);
    }, [setCurrentValue]
  );

  // prevent initial render from ruining life
  useEffect(() => {
    if (!isReady) { setIsReady(true); }
  }, []);

  return (
    <Box ref={isReady ? reference : undefined}>
      {
        isReady && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} width={column.actualWidth}>
            <TextareaTooltip p={3} width={column.actualWidth} bg='white' borderRadii={1}>
              <BaseTextarea
                ref={inputRef}
                width='100%'
                value={currentValue}
                allow={allow}
                onDisallow={onDisallow}
                onChange={onChange}
                append={append}
                placeholder={placeholder}
                rows='5'
              />
            </TextareaTooltip>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </Box>
  );
});

export default MultiLineTextEditor;
