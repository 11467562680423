import React from 'react';

import BaseCell from '../BaseCell';

import ToggleInput from '../../form/BaseToggle';

const ToggleCellRenderer = ({ value }) => (
  <BaseCell flexDirection='column' justifyContent='center' alignItems='center'>
    <ToggleInput on={value} />
  </BaseCell>
);

export default ToggleCellRenderer;