import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedBendSpringbackFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('bendSpringbackFamilies')
);
export const selectNormalizedBendSpringbackFamiliesList = () => createSelector(
  selectNormalizedBendSpringbackFamilies(),
  bendSpringbackFamilies => bendSpringbackFamilies.toList()
);