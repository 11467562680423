import React, { useCallback } from 'react';
import styled from 'styled-components';

import colors from '../../../assets/themes/base/colors';
import fontSizes from '../../../assets/themes/base/fontSizes';

const StyledTextarea = styled.textarea`
  border: none;
  outline: none;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: ${colors.gray[7]};
  font-size: ${fontSizes[2]};
  font-family: 'proxima-soft', sans-serif;
  width: 100%;
  height: 100%;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-text-fill-color: ${colors.primary[4]};
    color: ${colors.primary[4]};
    background-color: ${colors.white};
  }

  &::placeholder {
    color: ${colors.gray[5]};
  }
`;

const BaseTextarea = React.forwardRef(({ allow, onDisallow, onChange, ...props }, ref) => {
  const onShouldChange = useCallback(e => {
    if (allow) {
      if (allow instanceof RegExp) {
        allow.test(e.target.value) ? (onChange && onChange(e)) : (onDisallow && onDisallow(e));
      } else {
        allow(e) ? (onChange && onChange(e)) : (onDisallow && onDisallow(e));
      }
    } else {
      onChange && onChange(e);
    }
  }, [allow, onChange]);

  return <StyledTextarea ref={ref} tabIndex='0' onChange={onShouldChange} { ...props } />;
});

export default BaseTextarea;
