import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';

import EK from '../../entities/keys';

import {
  ALLOWANCES,
  NOTE_TYPES_FORMATTED,
  NOTE_TYPES_OPTIONS,
} from '../../entities/Notes/model';

import Grid from '../../containers/grid/Grid';

import { selectStandardizeModulePermissionsAndState } from '../Dashboard/selectors';

import injectSaga from '../../utils/sagas/injectSaga';

import saga from './sagas';

import { createNoteDrafts, editNoteDrafts } from './actions';

import { selectCurrentFilteredNormalizedDraftNotes } from './selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectCurrentFilteredNormalizedDraftNotes(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      data: !isLoading && data || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const enhance = compose(
  withRouter,
  injectSaga({ key: `${EK.NOTES.state}Edit`, saga }),
  connect(
    mapStateToProps,
    {
      editInstances: editNoteDrafts,
      createInstances: createNoteDrafts,
      copyInstances: createNoteDrafts,
    },
    (
      { data, editable },
      dispatchProps,
    ) => ({
      data,
      columnDefs: [
        ...(editable && [
          { type: ['entitySelectionCell'] },
          { type: ['entitySaveStatusCell'] },
        ] || []),
        {
          headerName: 'Name',
          field: 'name',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Name',
          },
          cellEditorParams: {
            allow: ALLOWANCES.NAME,
          },
        },
        {
          headerName: 'Note Body',
          field: 'noteBody',
          editable,
          type: ['multiLineCell', 'multiLineEditor'],
          cellRendererParams: {
            placeholder: 'Note Body',
          },
        },
        {
          headerName: 'Note Type',
          field: 'noteType',
          width: 200,
          type: ['basicCell', 'radioEditor'],
          editable,
          valueFormatter: ({ value }) => NOTE_TYPES_FORMATTED[value] || value,
          cellRendererParams: {
            placeholder: 'Select a type',
          },
          cellEditorParams: {
            options: NOTE_TYPES_OPTIONS,
          },
        },
        {
          headerName: 'Description',
          field: 'description',
          editable,
          type: ['basicCell', 'basicEditor'],
          cellRendererParams: {
            placeholder: 'Description',
          },
        },
      ],
      ...(editable && dispatchProps || {}),
    }),
  ),
);

export default enhance(Grid);
