import validateRules from '../../utils/validation/validateRules';
import isString from 'lodash/isString';

import { ALLOWANCES } from './model';

export const rules = {
  name: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: (value) =>
        value && value.length >= 2 && ALLOWANCES.NAME.test(value),
    },
  },
  selectionName: {
    minLength: {
      message: 'Must be at least 2 characters long',
      validator: (value) =>
        value && value.length >= 2 && ALLOWANCES.SELECTION_NAME.test(value),
    },
  },
  fittingCode: {
    required: {
      message: 'Cannot be empty',
      validator: (value) => isString(value),
    },
  },
  fittingCategory: {
    required: {
      message: 'Cannot be empty',
      validator: (value) => isString(value),
    },
  },
};

export const validations = {
  name: validateRules(rules.name),
  selectionName: validateRules(rules.selectionName),
  fittingCode: validateRules(rules.fittingCode),
  fittingCategory: validateRules(rules.fittingCategory),
};
