import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';
import { MODAL_SIZES } from '../../containers/modal/Modal';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedSimplifiedRepsList } from '../../entities/SimplifiedReps/selectors';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../Dashboard/selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedSimplifiedRepsList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const mapDispatchToProps = (dispatch) => ({
  createEntity() {
    dispatch(showModal('SIMPLIFIED_REP_MODAL'));
  },

  editSimplifiedRep(simplifiedRep) {
    dispatch(
      showModal('SIMPLIFIED_REP_MODAL', {
        simplifiedRepId: simplifiedRep.id,
      }),
    );
  },
  copySimplifiedRep(simplifiedRep) {
    dispatch(
      showModal('SIMPLIFIED_REP_MODAL', {
        simplifiedRepId: simplifiedRep.id,
        copyEntity: true,
      }),
    );
  },
  manageSimplifiedRep(simplifiedRep) {
    dispatch(
      showModal('SIMPLIFIED_REP_RULESET_MODAL', {
        modalSize: MODAL_SIZES.FLUID,
        simplifiedRepId: simplifiedRep.id,
      }),
    );
  },
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    ({ data, editable, isList }, dispatchProps) => ({
      data,
      isList,
      columnDefs: [
        {
          headerName: 'Name // Description',
          field: 'name',
          type: ['complexCell'],
          valueGetter: ({ data }) => data.summary,
          onCellClicked: ({ data }) =>
            editable && dispatchProps.manageSimplifiedRep(data),
          cellStyle: () => (editable && { cursor: 'pointer' }) || {},
        },
        ...((editable && [
          {
            type: ['dataActionIconCell'],
            cellRendererParams: {
              actions: List([
                {
                  icon: 'edit',
                  primary: true,
                  onClick: dispatchProps.editSimplifiedRep,
                },
                {
                  icon: 'list',
                  onClick: dispatchProps.manageSimplifiedRep,
                },
                {
                  icon: 'copy',
                  primary: true,
                  onClick: dispatchProps.copySimplifiedRep,
                },
              ]),
            },
          },
        ]) ||
          []),
      ],
    }),
  ),
);

export default enhance(Grid);
