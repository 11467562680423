import { take, takeLatest, put, fork, all, select } from 'redux-saga/effects';
import get from 'lodash/get';

import { push } from 'connected-react-router';

import EK from '../../../../entities/keys';

import { hideModal } from '../../../../modules/modal/actions';

import {
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
} from '../../../../paths';

import {
  createInsulationFamilyTypes,
  copyInsulationFamilyTypes,
  editInsulationFamilyTypes,
  deleteInsulationFamilyTypes,
} from '../../../../entities/InsulationFamilies/constants';
import {
  selectNormalizedInsulationFamilies
} from '../../../../entities/InsulationFamilies/selectors';

export function* handleCreateInsulationFamilySuccess({ payload = {} }) {
  const id = `${get(payload, `${EK.INSULATIONS.plural}[0].id`)}`;
  if (id) {
    let insulations = yield select(selectNormalizedInsulationFamilies());
    let copiedInsulation = insulations.get(id);

    while (!copiedInsulation) {
      yield take();
      insulations = yield select(selectNormalizedInsulationFamilies());
      copiedInsulation = insulations.get(id);
    }

    yield put(push(PipingModuleInsulationsEditPath.generate({ id })));

    yield put(hideModal());
  }
}

export function* handleDeleteInsulationFamilySuccess() {
  yield put(push(PipingModuleInsulationsPath.url));
}

export function* watchCreateInsulationSaga() {
  yield takeLatest([
    createInsulationFamilyTypes.SUCCESS,
    copyInsulationFamilyTypes.SUCCESS,
  ], handleCreateInsulationFamilySuccess
  );
}

export function* watchDeleteInsulationSaga() {
  yield takeLatest(
    deleteInsulationFamilyTypes.SUCCESS,
    handleDeleteInsulationFamilySuccess
  );
}

export function* handleCloseInsulationFamilyModal() {
  yield put(hideModal());
}

export function* watchCloseInsulationModalSaga() {
  yield takeLatest(
    [
      createInsulationFamilyTypes.SUCCESS,
      editInsulationFamilyTypes.SUCCESS,
      deleteInsulationFamilyTypes.SUCCESS,
    ],
    handleCloseInsulationFamilyModal
  );
}

// final output saga
export default function* main() {
  yield all([
    fork(watchCreateInsulationSaga),
    fork(watchDeleteInsulationSaga),
    fork(watchCloseInsulationModalSaga),
  ]);
}
