import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import EK from '../keys';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

import NoteSchema from './schema';

export const selectNormalizedNotes = () =>  createSelector(
  selectEntities(),
  entities => entities.get(EK.NOTES.state)
);
export const selectNormalizedNotesList = () => createSelector(
  selectNormalizedNotes(),
  notes => notes.toList()
);

export const selectNormalizedDraftNotes = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get(EK.NOTES.state);
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectNotes = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get(EK.NOTES.state), [NoteSchema], entities)
);
export const selectDraftNotes = () => createSelector(
  selectEntities(),
  selectNormalizedDraftNotes(),
  (entities, drafts) => denormalize(drafts, [NoteSchema], entities)
);