/* eslint-disable */

import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import styled, { css } from "styled-components";
import transitions from "../../assets/themes/base/transitions";
import space from "../../assets/themes/base/space";

import Box from "../../components/common/Box";
import Flex from "../../components/common/Flex";

import Breadcrumbs from "../templates/Structures/Breadcrumbs";
import Headers from "../templates/Structures/Headers";

import GettingStarted from "../templates/Structures/GettingStarted";

import PageNotFoundRedirect from "../../containers/common/PageNotFoundRedirect";

import Sidebar from "./Sidebar";
import FilterSidebar from "./FilterSidebar";

import {
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsActive,
  UserHasNoRequiredPasswordResetPending,
} from "../../modules/auth/utils/routes";

import { processFetchAllUnits } from "../../entities/Units/actions";
import { processFetchAllUnitSystems } from "../../entities/UnitSystems/actions";
import { processFetchAllAppearances } from "../../entities/Appearances/actions";
import { processFetchAllSettings } from "../../entities/Settings/actions";

import { handleLogout } from "../../modules/auth/actions";

import { selectCurrentUser } from "../../entities/CurrentUser/selectors";

import { showModal } from "../../modules/modal/actions";
import { MODAL_SIZES } from "../../containers/modal/Modal";

import {
  DashboardPath,
  PipingModulePath,
  OrganizationPath,
  StandardizeModulePath,
  PipingModuleSchedulesPath,
  PipingModuleSizesPath,
  PipingModuleEndTypesPath,
  PipingModuleFittingCategoriesPath,
  PipingModuleBoltNutsPath,
  PipingBoltNutsEditPath,
  PipingModuleFittingsPath,
  PipingModuleFittingsEditPath,
  PipingModuleMaterialsPath,
  PipingModuleInsulationSpecsPath,
  PipingModuleInsulationsPath,
  PipingModuleInsulationsEditPath,
  PipingModulePipesPath,
  PipingModulePipesEditPath,
  PipingModulePipesBendsPath,
  PipingModulePipesBendsEditPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendMachinesEditPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesBendSpringbacksEditPath,
  PipingModulePipesMitersPath,
  PipingModulePipesMitersEditPath,
  PipingModuleSpecsPath,
  PipingModuleSpecsEditPath,
  PipingModuleSpecsFittingsEditPath,
  PipingModuleSpecsFittingInstancesEditPath,
  PipingModuleSpecsPipesEditPath,
  PipingModuleSpecsPipeInstancesEditPath,
  PipingModuleProjectsPath,
  PipingModuleProjectsEditPath,
  SynchronizeModuleAppearancesPath,
  StandardizeModuleLayersPath,
  StandardizeModuleNoteProfilesPath,
  NoteProfilesEditPath,
  StandardizeModuleNotesPath,
  StandardizeModuleOrientationsPath,
  StandardizeModuleParameterProfilesPath,
  ParameterProfilesEditPath,
  StandardizeModuleParametersPath,
  StandardizeModuleSectionsPath,
  StandardizeModuleSimplifiedRepsPath,
  StandardizeModuleModelTemplatesPath,
  SynchronizeModulePath,
  SynchronizeModuleConfigProfilesPath,
  SynchronizeModuleMapkeysPath,
  StandardizeModuleExplodeStatesPath,
  StandardizeModuleCombinedStatesPath,
  StandardizeModuleLayerStatesPath,
  StandardizeModuleAppearanceStatesPath,
  StandardizeModuleStyleStatesPath,
  FrameworkModulePath,
  FrameworkModuleProfileLibrariesPath,
  FrameworkModuleProfileLibrariesBasePath,
  FrameworkModuleProfilePath,
  FrameworkModuleProfileTablesPath,
  FrameworkModuleTablePath,
} from "../../paths";

import injectReducer from "../../utils/reducers/injectReducer";

import dashboardReducer from "./reducer";

import {
  selectIsLoadingInitialData,
  selectSidebarIsMinimized,
  selectSidebarIsFiltering,
} from "./selectors";

import breadcrumbs from "./breadcrumbs";

import PipingModuleBody from "../PipingModule/Body";

import SchedulesHeader from "../Schedules/Header";
import SchedulesOptions from "../Schedules/Options";
import SchedulesBody from "../Schedules/Body";

import SizesHeader from "../Sizes/Header";
import SizesOptions from "../Sizes/Options";
import SizesBody from "../Sizes/Body";

import EndTypesBody from "../Piping/EndTypes/Body";

import FittingCategoriesHeader from "../FittingCategories/Header";
import FittingCategoriesOptions from "../FittingCategories/Options";
import FittingCategoriesBody from "../FittingCategories/Body";

import MaterialsHeader from "../Materials/Header";
import MaterialsOptions from "../Materials/Options";
import MaterialsBody from "../Materials/Body";

import BoltNutsHeader from "../BoltNuts/Header";
import BoltNutsOptions from "../BoltNuts/Options";
import BoltNutsBody from "../BoltNuts/Body";

import BoltNutsEditBack from "../BoltNutsEdit/Back";
import BoltNutsEditHeader from "../BoltNutsEdit/Header";
import BoltNutsEditOptions from "../BoltNutsEdit/Options";
import BoltNutsEditBody from "../BoltNutsEdit/Body";

import InsulationsHeader from "../Insulations/Header";
import InsulationsOptions from "../Insulations/Options";
import InsulationsBody from "../Insulations/Body";
import InsulationsNotices from "../Insulations/Notices";

import InsulationsEditBack from "../InsulationsEdit/Back";
import InsulationsEditHeader from "../InsulationsEdit/Header";
import InsulationsEditOptions from "../InsulationsEdit/Options";
import InsulationsEditBody from "../InsulationsEdit/Body";

import FittingsHeader from "../Fittings/Header";
import FittingsOptions from "../Fittings/Options";
import FittingsBody from "../Fittings/Body";
import FittingsNotices from "../Fittings/Notices";

import FittingsEditBack from "../FittingsEdit/Back";
import FittingsEditHeader from "../FittingsEdit/Header";
import FittingsEditOptions from "../FittingsEdit/Options";
import FittingsEditBody from "../FittingsEdit/Body";

import PipesHeader from "../Pipes/Header";
import PipesOptions from "../Pipes/Options";
import PipesBody from "../Pipes/Body";
import PipesNotices from "../Pipes/Notices";

import PipesEditSwitch from "../PipesEditSwitch/Body";

import PipesEditBack from "../PipesEdit/Back";
import PipesEditHeader from "../PipesEdit/Header";
import PipesEditOptions from "../PipesEdit/Options";
import PipesEditBody from "../PipesEdit/Body";

import PipesBendsBack from "../PipesBends/Back";
import PipesBendsHeader from "../PipesBends/Header";
import PipesBendsOptions from "../PipesBends/Options";
import PipesBendsBody from "../PipesBends/Body";

import PipesBendsEditBack from "../PipesBendsEdit/Back";
import PipesBendsEditHeader from "../PipesBendsEdit/Header";
import PipesBendsEditOptions from "../PipesBendsEdit/Options";
import PipesBendsEditBody from "../PipesBendsEdit/Body";

import PipesBendMachinesBack from "../PipesBendMachines/Back";
import PipesBendMachinesHeader from "../PipesBendMachines/Header";
import PipesBendMachinesOptions from "../PipesBendMachines/Options";
import PipesBendMachinesBody from "../PipesBendMachines/Body";

import PipesBendMachinesEditBack from "../PipesBendMachinesEdit/Back";
import PipesBendMachinesEditHeader from "../PipesBendMachinesEdit/Header";
import PipesBendMachinesEditOptions from "../PipesBendMachinesEdit/Options";
import PipesBendMachinesEditBody from "../PipesBendMachinesEdit/Body";

import PipesBendSpringbacksBack from "../PipesBendSpringbacks/Back";
import PipesBendSpringbacksHeader from "../PipesBendSpringbacks/Header";
import PipesBendSpringbacksOptions from "../PipesBendSpringbacks/Options";
import PipesBendSpringbacksBody from "../PipesBendSpringbacks/Body";

import PipesBendSpringbacksEditBack from "../PipesBendSpringbacksEdit/Back";
import PipesBendSpringbacksEditHeader from "../PipesBendSpringbacksEdit/Header";
import PipesBendSpringbacksEditOptions from "../PipesBendSpringbacksEdit/Options";
import PipesBendSpringbacksEditBody from "../PipesBendSpringbacksEdit/Body";

import PipesMitersBack from "../PipesMiters/Back";
import PipesMitersHeader from "../PipesMiters/Header";
import PipesMitersOptions from "../PipesMiters/Options";
import PipesMitersBody from "../PipesMiters/Body";

import PipesMitersEditBack from "../PipesMitersEdit/Back";
import PipesMitersEditHeader from "../PipesMitersEdit/Header";
import PipesMitersEditOptions from "../PipesMitersEdit/Options";
import PipesMitersEditBody from "../PipesMitersEdit/Body";

import SpecsSwitch from "../SpecsSwitch/Body";
import SpecsHeader from "../Specs/Header";
import SpecsOptions from "../Specs/Options";
import SpecsBody from "../Specs/Body";
import SpecsNotices from "../Specs/Notices";

import SpecsEditSwitch from "../SpecsEditSwitch/Body";
import SpecsEditOptions from "../SpecsEditSwitch/Options";

import SpecsFittingsEditBack from "../SpecsFittingsEdit/Back";
import SpecsFittingsEditHeader from "../SpecsFittingsEdit/Header";
import SpecsFittingsEditBody from "../SpecsFittingsEdit/Body";
import SpecsFittingsEditNotices from "../SpecsFittingsEdit/Notices";

import SpecsFittingInstancesEditBack from "../SpecsFittingInstancesEdit/Back";
import SpecsFittingInstancesEditHeader from "../SpecsFittingInstancesEdit/Header";
import SpecsFittingInstancesEditBody from "../SpecsFittingInstancesEdit/Body";
import SpecsFittingInstancesEditNotices from "../SpecsFittingInstancesEdit/Notices";

import SpecsPipesEditBack from "../SpecsPipesEdit/Back";
import SpecsPipesEditHeader from "../SpecsPipesEdit/Header";
import SpecsPipesEditBody from "../SpecsPipesEdit/Body";
import SpecsPipesEditNotices from "../SpecsPipesEdit/Notices";

import SpecsPipeInstancesEditBack from "../SpecsPipeInstancesEdit/Back";
import SpecsPipeInstancesEditHeader from "../SpecsPipeInstancesEdit/Header";
import SpecsPipeInstancesEditBody from "../SpecsPipeInstancesEdit/Body";
import SpecsPipeInstancesEditNotices from "../SpecsPipeInstancesEdit/Notices";

import ProjectsHeader from "../Projects/Header";
import ProjectsOptions from "../Projects/Options";
import ProjectsBody from "../Projects/Body";
import ProjectsNotices from "../Projects/Notices";

import ProjectsEditBack from "../ProjectsEdit/Back";
import ProjectsEditHeader from "../ProjectsEdit/Header";
import ProjectsEditOptions from "../ProjectsEdit/Options";
import ProjectsEditBody from "../ProjectsEdit/Body";

import InsulationSpecsHeader from "../InsulationSpecs/Header";
import InsulationSpecsOptions from "../InsulationSpecs/Options";
import InsulationSpecsBody from "../InsulationSpecs/Body";

// Synchronize Components
import SynchronizeModuleBody from "../SynchronizeModule/Body";

import AppearancesBody from "../Synchronize/Appearances/Body";

import StandardizeModuleBody from "../StandardizeModule/Body";

import ExplodeStatesBody from '../ExplodeStates/Body';

import CombinedStateBody from '../CombinedStates/Body';

import LayerStateBody from '../LayerStates/Body';

import AppearanceStateBody from '../AppearanceStates/Body';

import StyleStatesBody from '../StyleStates/Body';

import LayersHeader from "../Layers/Header";
import LayersOptions from "../Layers/Options";
import LayersBody from "../Layers/Body";

import NoteProfilesHeader from "../NoteProfiles/Header";
import NoteProfilesOptions from "../NoteProfiles/Options";
import NoteProfilesBody from "../NoteProfiles/Body";
import NoteProfilesNotices from "../NoteProfiles/Notices";

import NoteProfilesEditBack from "../NoteProfilesEdit/Back";
import NoteProfilesEditHeader from "../NoteProfilesEdit/Header";
import NoteProfilesEditOptions from "../NoteProfilesEdit/Options";
import NoteProfilesEditBody from "../NoteProfilesEdit/Body";
import NoteProfilesEditNotices from "../NoteProfilesEdit/Notices";

import NotesHeader from "../Notes/Header";
import NotesOptions from "../Notes/Options";
import NotesBody from "../Notes/Body";

import OrientationsHeader from "../Orientations/Header";
import OrientationsOptions from "../Orientations/Options";
import OrientationsBody from "../Orientations/Body";

import ParameterProfilesHeader from "../ParameterProfiles/Header";
import ParameterProfilesOptions from "../ParameterProfiles/Options";
import ParameterProfilesBody from "../ParameterProfiles/Body";
import ParameterProfilesNotices from "../ParameterProfiles/Notices";

import ParameterProfilesEditBack from "../ParameterProfilesEdit/Back";
import ParameterProfilesEditHeader from "../ParameterProfilesEdit/Header";
import ParameterProfilesEditOptions from "../ParameterProfilesEdit/Options";
import ParameterProfilesEditBody from "../ParameterProfilesEdit/Body";

import ParametersHeader from "../Parameters/Header";
import ParametersOptions from "../Parameters/Options";
import ParametersBody from "../Parameters/Body";

import SectionsHeader from "../Sections/Header";
import SectionsOptions from "../Sections/Options";
import SectionsBody from "../Sections/Body";

import SimplifiedRepsHeader from "../SimplifiedReps/Header";
import SimplifiedRepsOptions from "../SimplifiedReps/Options";
import SimplifiedRepsBody from "../SimplifiedReps/Body";
import SimplifiedRepsNotices from "../SimplifiedReps/Notices";

import ModelTemplatesHeader from "../ModelTemplates/Header";
import ModelTemplatesOptions from "../ModelTemplates/Options";
import ModelTemplatesBody from "../ModelTemplates/Body";
import ModelTemplatesNotices from "../ModelTemplates/Notices";

import MapkeysBody from "../Synchronize/Mapkeys/Body";

import ConfigProfilesBody from "../Synchronize/ConfigProfiles/Body";

// framework components
import FrameworkModuleSwitch from '../Framework/Base/Switch';

import ProfileLibrariesBody from '../Framework/ProfileLibraries/Body';
import ProfileBody from '../Framework/Profile/Body';
import ProfileTablesBody from '../Framework/ProfileTables/Body';
import TableBody from '../Framework/Table/Body';

// <span className={cx('raptorInstructions')}>Up, Up, Down, Down, Left, Right, Left, Right, B, A</span>

const DashboardPage = styled(Box)`
  height: 100vh;
  position: absolute;

  transition: left ${transitions.speed.fast}
      ${transitions.type.inOut},
    right ${transitions.speed.fast}
      ${transitions.type.inOut};

  will-change: left, right;

  left: 20rem;
  right: 0;

  ${({ $isFullPage }) =>
    $isFullPage &&
    css`
      left: 2rem;
      right: 1rem;
    `}
`;

const DashboardSuperHeader = styled(Flex)`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-top: ${space[5]};
  padding-right: ${space[6]};
  padding-left: ${space[6]};
  margin-bottom: ${space[2]};
`;

const DashboardBody = styled(Flex)`
  height: calc(100% - 6rem);
`;

const Options = styled(Flex)`
  position: relative;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 0;
`;

const CadactiveBreadcrumbs = withBreadcrumbs(breadcrumbs, {
  excludePaths: ["/"],
})(Breadcrumbs);

const Dashboard = ({
  currentUser,
  isLoading,
  isMinimized,
  isFiltering,
  showCurrentUserModal,
  showPrivacyPolicyModal,
  logout,

  fetchUnits,
  fetchUnitSystems,
  fetchAppearances,
  fetchSettings,
  showLegalTermsUpdatedModal,
}) => {
  useEffect(() => {
    fetchUnits();
    fetchUnitSystems();
    fetchAppearances();
    fetchSettings();

    if (!currentUser.hasAcceptedEula) {
      showLegalTermsUpdatedModal();
    }
  }, []);

  return (
    <Flex height="100vh" width="100vw" flexDirection="row">
      <Sidebar
        currentUser={currentUser}
        isLoading={isLoading}
        isMinimized={isMinimized}
        isFiltering={isFiltering}
        showCurrentUserModal={showCurrentUserModal}
        showPrivacyPolicyModal={showPrivacyPolicyModal}
        logout={logout}
      />

      <FilterSidebar
        isLoading={isLoading}
        isMinimized={isMinimized}
        isFiltering={isFiltering}
      />

      <DashboardPage $isFullPage={isMinimized && !isFiltering}>
        <DashboardSuperHeader>
          <Switch>
            <Route
              exact
              path={PipingBoltNutsEditPath.url}
              component={BoltNutsEditBack}
            />
            <Route
              exact
              path={PipingModuleInsulationsEditPath.url}
              component={InsulationsEditBack}
            />
            <Route
              exact
              path={PipingModuleFittingsEditPath.url}
              component={FittingsEditBack}
            />
            <Route
              exact
              path={PipingModulePipesEditPath.url}
              component={PipesEditBack}
            />
            <Route
              exact
              path={PipingModulePipesBendsPath.url}
              component={PipesBendsBack}
            />
            <Route
              exact
              path={PipingModulePipesBendsEditPath.url}
              component={PipesBendsEditBack}
            />
            <Route
              exact
              path={PipingModulePipesBendMachinesPath.url}
              component={PipesBendMachinesBack}
            />
            <Route
              exact
              path={PipingModulePipesBendMachinesEditPath.url}
              component={PipesBendMachinesEditBack}
            />
            <Route
              exact
              path={PipingModulePipesBendSpringbacksPath.url}
              component={PipesBendSpringbacksBack}
            />
            <Route
              exact
              path={PipingModulePipesBendSpringbacksEditPath.url}
              component={PipesBendSpringbacksEditBack}
            />
            <Route
              exact
              path={PipingModulePipesMitersPath.url}
              component={PipesMitersBack}
            />
            <Route
              exact
              path={PipingModulePipesMitersEditPath.url}
              component={PipesMitersEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecsFittingsEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecsFittingInstancesEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecsPipesEditBack}
            />
            <Route
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecsPipeInstancesEditBack}
            />
            <Route
              exact
              path={PipingModuleProjectsEditPath.url}
              component={ProjectsEditBack}
            />
            <Route
              exact
              path={NoteProfilesEditPath.url}
              component={NoteProfilesEditBack}
            />
            <Route
              exact
              path={ParameterProfilesEditPath.url}
              component={ParameterProfilesEditBack}
            />
          </Switch>
          <CadactiveBreadcrumbs isLoading={isLoading} />
        </DashboardSuperHeader>

        <DashboardBody flexDirection="column" mr={6} ml={6}>
          <Flex flexDirection="row" mb={4}>
            <Headers>
              <Switch>
                <Route
                  exact
                  path={PipingModuleSchedulesPath.url}
                  component={SchedulesHeader}
                />
                <Route
                  exact
                  path={PipingModuleSizesPath.url}
                  component={SizesHeader}
                />
                <Route
                  exactA
                  path={PipingModuleFittingCategoriesPath.url}
                  component={FittingCategoriesHeader}
                />
                <Route
                  exact
                  path={PipingModuleMaterialsPath.url}
                  component={MaterialsHeader}
                />
                <Route
                  exact
                  path={PipingModuleBoltNutsPath.url}
                  component={BoltNutsHeader}
                />
                <Route
                  exact
                  path={PipingBoltNutsEditPath.url}
                  component={BoltNutsEditHeader}
                />
                <Route
                  exact
                  path={PipingModuleInsulationsPath.url}
                  component={InsulationsHeader}
                />
                <Route
                  exact
                  path={PipingModuleInsulationsEditPath.url}
                  component={InsulationsEditHeader}
                />
                <Route
                  exact
                  path={PipingModuleFittingsPath.url}
                  component={FittingsHeader}
                />
                <Route
                  exact
                  path={PipingModuleFittingsEditPath.url}
                  component={FittingsEditHeader}
                />

                <Route
                  exact
                  path={PipingModulePipesPath.url}
                  component={PipesHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesEditPath.url}
                  component={PipesEditHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesBendsPath.url}
                  component={PipesBendsHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesBendsEditPath.url}
                  component={PipesBendsEditHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesBendMachinesPath.url}
                  component={PipesBendMachinesHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesBendMachinesEditPath.url}
                  component={PipesBendMachinesEditHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesBendSpringbacksPath.url}
                  component={PipesBendSpringbacksHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesBendSpringbacksEditPath.url}
                  component={PipesBendSpringbacksEditHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesMitersPath.url}
                  component={PipesMitersHeader}
                />
                <Route
                  exact
                  path={PipingModulePipesMitersEditPath.url}
                  component={PipesMitersEditHeader}
                />

                <Route
                  exact
                  path={PipingModuleInsulationSpecsPath.url}
                  component={InsulationSpecsHeader}
                />
                <Route
                  exact
                  path={PipingModuleSpecsPath.url}
                  component={SpecsHeader}
                />
                <Route
                  exact
                  path={PipingModuleSpecsFittingsEditPath.url}
                  component={SpecsFittingsEditHeader}
                />
                <Route
                  exact
                  path={PipingModuleSpecsFittingInstancesEditPath.url}
                  component={SpecsFittingInstancesEditHeader}
                />
                <Route
                  exact
                  path={PipingModuleSpecsPipesEditPath.url}
                  component={SpecsPipesEditHeader}
                />
                <Route
                  exact
                  path={PipingModuleSpecsPipeInstancesEditPath.url}
                  component={SpecsPipeInstancesEditHeader}
                />
                <Route
                  exact
                  path={PipingModuleProjectsPath.url}
                  component={ProjectsHeader}
                />
                <Route
                  exact
                  path={PipingModuleProjectsEditPath.url}
                  component={ProjectsEditHeader}
                />

                <Route
                  exact
                  path={StandardizeModuleLayersPath.url}
                  component={LayersHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleNoteProfilesPath.url}
                  component={NoteProfilesHeader}
                />
                <Route
                  exact
                  path={NoteProfilesEditPath.url}
                  component={NoteProfilesEditHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleNotesPath.url}
                  component={NotesHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleOrientationsPath.url}
                  component={OrientationsHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleParameterProfilesPath.url}
                  component={ParameterProfilesHeader}
                />
                <Route
                  exact
                  path={ParameterProfilesEditPath.url}
                  component={ParameterProfilesEditHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleParametersPath.url}
                  component={ParametersHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleSectionsPath.url}
                  component={SectionsHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleSimplifiedRepsPath.url}
                  component={SimplifiedRepsHeader}
                />
                <Route
                  exact
                  path={StandardizeModuleModelTemplatesPath.url}
                  component={ModelTemplatesHeader}
                />
              </Switch>
            </Headers>

            <Options>
              <Switch>
                <Route
                  exact
                  path={PipingModuleSchedulesPath.url}
                  component={SchedulesOptions}
                />
                <Route
                  exact
                  path={PipingModuleSizesPath.url}
                  component={SizesOptions}
                />
                <Route
                  exact
                  path={PipingModuleFittingCategoriesPath.url}
                  component={FittingCategoriesOptions}
                />
                <Route
                  exact
                  path={PipingModuleMaterialsPath.url}
                  component={MaterialsOptions}
                />
                <Route
                  exact
                  path={PipingModuleBoltNutsPath.url}
                  component={BoltNutsOptions}
                />
                <Route
                  exact
                  path={PipingBoltNutsEditPath.url}
                  component={BoltNutsEditOptions}
                />
                <Route
                  exact
                  path={PipingModuleInsulationsPath.url}
                  component={InsulationsOptions}
                />
                <Route
                  exact
                  path={PipingModuleInsulationsEditPath.url}
                  component={InsulationsEditOptions}
                />
                <Route
                  exact
                  path={PipingModuleFittingsPath.url}
                  component={FittingsOptions}
                />
                <Route
                  exact
                  path={PipingModuleFittingsEditPath.url}
                  component={FittingsEditOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesPath.url}
                  component={PipesOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesEditPath.url}
                  component={PipesEditOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesBendsPath.url}
                  component={PipesBendsOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesBendsEditPath.url}
                  component={PipesBendsEditOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesBendMachinesPath.url}
                  component={PipesBendMachinesOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesBendMachinesEditPath.url}
                  component={PipesBendMachinesEditOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesBendSpringbacksPath.url}
                  component={PipesBendSpringbacksOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesBendSpringbacksEditPath.url}
                  component={PipesBendSpringbacksEditOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesMitersPath.url}
                  component={PipesMitersOptions}
                />
                <Route
                  exact
                  path={PipingModulePipesMitersEditPath.url}
                  component={PipesMitersEditOptions}
                />
                <Route
                  exact
                  path={PipingModuleInsulationSpecsPath.url}
                  component={InsulationSpecsOptions}
                />
                <Route
                  exact
                  path={PipingModuleSpecsPath.url}
                  component={SpecsOptions}
                />

                <Route
                  path={PipingModuleSpecsEditPath.url}
                  component={SpecsEditOptions}
                />

                <Route
                  exact
                  path={PipingModuleProjectsPath.url}
                  component={ProjectsOptions}
                />
                <Route
                  exact
                  path={PipingModuleProjectsEditPath.url}
                  component={ProjectsEditOptions}
                />

                <Route
                  exact
                  path={StandardizeModuleLayersPath.url}
                  component={LayersOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleNoteProfilesPath.url}
                  component={NoteProfilesOptions}
                />
                <Route
                  exact
                  path={NoteProfilesEditPath.url}
                  component={NoteProfilesEditOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleNotesPath.url}
                  component={NotesOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleOrientationsPath.url}
                  component={OrientationsOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleParameterProfilesPath.url}
                  component={ParameterProfilesOptions}
                />
                <Route
                  exact
                  path={ParameterProfilesEditPath.url}
                  component={ParameterProfilesEditOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleParametersPath.url}
                  component={ParametersOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleSectionsPath.url}
                  component={SectionsOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleSimplifiedRepsPath.url}
                  component={SimplifiedRepsOptions}
                />
                <Route
                  exact
                  path={StandardizeModuleModelTemplatesPath.url}
                  component={ModelTemplatesOptions}
                />
              </Switch>
            </Options>
          </Flex>

          <Switch>
            <Route exact path={DashboardPath.url} component={GettingStarted} />
            <Route
              path={PipingModulePath.url}
              render={() => (
                <PipingModuleBody>
                  <Route
                    exact
                    path={PipingModuleSchedulesPath.url}
                    component={SchedulesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleSizesPath.url}
                    component={SizesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleEndTypesPath.url}
                    component={EndTypesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleFittingCategoriesPath.url}
                    component={FittingCategoriesBody}
                  />
                  <Route
                    exact
                    path={PipingModuleMaterialsPath.url}
                    component={MaterialsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleBoltNutsPath.url}
                    component={BoltNutsBody}
                  />
                  <Route
                    exact
                    path={PipingBoltNutsEditPath.url}
                    component={BoltNutsEditBody}
                  />
                  <Route
                    exact
                    path={PipingModuleInsulationsPath.url}
                    component={InsulationsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleInsulationsEditPath.url}
                    component={InsulationsEditBody}
                  />
                  <Route
                    exact
                    path={PipingModuleFittingsPath.url}
                    component={FittingsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleFittingsEditPath.url}
                    component={FittingsEditBody}
                  />
                  <Route
                    exact
                    path={PipingModulePipesPath.url}
                    component={PipesBody}
                  />
                  <Route
                    path={PipingModulePipesEditPath.url}
                    render={() => (
                      <PipesEditSwitch>
                        <Route
                          exact
                          path={PipingModulePipesEditPath.url}
                          component={PipesEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendsPath.url}
                          component={PipesBendsBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendsEditPath.url}
                          component={PipesBendsEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendMachinesPath.url}
                          component={PipesBendMachinesBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendMachinesEditPath.url}
                          component={PipesBendMachinesEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendSpringbacksPath.url}
                          component={PipesBendSpringbacksBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesBendSpringbacksEditPath.url}
                          component={PipesBendSpringbacksEditBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesMitersPath.url}
                          component={PipesMitersBody}
                        />
                        <Route
                          exact
                          path={PipingModulePipesMitersEditPath.url}
                          component={PipesMitersEditBody}
                        />
                        <PageNotFoundRedirect />
                      </PipesEditSwitch>
                    )}
                  />
                  <Route
                    exact
                    path={PipingModuleInsulationSpecsPath.url}
                    component={InsulationSpecsBody}
                  />
                  <Route
                    path={PipingModuleSpecsPath.url}
                    render={() => (
                      <SpecsSwitch>
                        <Route
                          exact
                          path={PipingModuleSpecsPath.url}
                          component={SpecsBody}
                        />
                        <Route
                          path={PipingModuleSpecsEditPath.url}
                          render={() => (
                            <SpecsEditSwitch>
                              <Redirect
                                exact
                                from={PipingModuleSpecsEditPath.url}
                                to={PipingModuleSpecsPipesEditPath.url}
                              />
                              <Route
                                exact
                                path={
                                  PipingModuleSpecsFittingsEditPath.url
                                }
                                component={SpecsFittingsEditBody}
                              />
                              <Route
                                exact
                                path={
                                  PipingModuleSpecsFittingInstancesEditPath.url
                                }
                                component={SpecsFittingInstancesEditBody}
                              />
                              <Route
                                exact
                                path={PipingModuleSpecsPipesEditPath.url}
                                component={SpecsPipesEditBody}
                              />
                              <Route
                                exact
                                path={
                                  PipingModuleSpecsPipeInstancesEditPath.url
                                }
                                component={SpecsPipeInstancesEditBody}
                              />
                              <PageNotFoundRedirect />
                            </SpecsEditSwitch>
                          )}
                        />
                        <PageNotFoundRedirect />
                      </SpecsSwitch>
                    )}
                  />
                  <Route
                    exact
                    path={PipingModuleProjectsPath.url}
                    component={ProjectsBody}
                  />
                  <Route
                    exact
                    path={PipingModuleProjectsEditPath.url}
                    component={ProjectsEditBody}
                  />
                  <Redirect
                    exact
                    from={PipingModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </PipingModuleBody>
              )}
            />

            <Route path={SynchronizeModulePath.url}
              render={() => (
                <SynchronizeModuleBody>
                  <Route
                    exact
                    path={SynchronizeModuleAppearancesPath.url}
                    component={AppearancesBody}
                  />
                  <Route
                    exact
                    path={SynchronizeModuleConfigProfilesPath.url}
                    component={ConfigProfilesBody}
                  />
                  <Route
                    exact
                    path={SynchronizeModuleMapkeysPath.url}
                    component={MapkeysBody}
                  />
                  <Redirect
                    exact
                    from={SynchronizeModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </SynchronizeModuleBody>
              )}
            />

            <Route
              path={StandardizeModulePath.url}
              render={() => (
                <StandardizeModuleBody>
                  <Route
                    exact
                    path={StandardizeModuleLayersPath.url}
                    component={LayersBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleNoteProfilesPath.url}
                    component={NoteProfilesBody}
                  />
                  <Route
                    exact
                    path={NoteProfilesEditPath.url}
                    component={NoteProfilesEditBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleNotesPath.url}
                    component={NotesBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleOrientationsPath.url}
                    component={OrientationsBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleParameterProfilesPath.url}
                    component={ParameterProfilesBody}
                  />
                  <Route
                    exact
                    path={ParameterProfilesEditPath.url}
                    component={ParameterProfilesEditBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleParametersPath.url}
                    component={ParametersBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleSectionsPath.url}
                    component={SectionsBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleSimplifiedRepsPath.url}
                    component={SimplifiedRepsBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleModelTemplatesPath.url}
                    component={ModelTemplatesBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleExplodeStatesPath.url}
                    component={ExplodeStatesBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleCombinedStatesPath.url}
                    component={CombinedStateBody}
                  />
                  <Route
                  exact
                  path={StandardizeModuleLayerStatesPath.url}
                  component={LayerStateBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleAppearanceStatesPath.url}
                    component={AppearanceStateBody}
                  />
                  <Route
                    exact
                    path={StandardizeModuleStyleStatesPath.url}
                    component={StyleStatesBody}
                  />
                  <Redirect
                    exact
                    from={StandardizeModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </StandardizeModuleBody>
              )}
            />

            <Route
              path={FrameworkModulePath.url}
              render={ () => (
                <FrameworkModuleSwitch>
                  <Route
                    exact
                    path={FrameworkModuleProfileLibrariesPath.url}
                    component={ProfileLibrariesBody}
                  />
                  <Route
                    exact
                    path={FrameworkModuleProfilePath.url}
                    component={ProfileBody}
                  />
                  <Route
                    exact
                    path={FrameworkModuleProfileTablesPath.url}
                    component={ProfileTablesBody}
                  />
                  <Route
                    exact
                    path={FrameworkModuleTablePath.url}
                    component={TableBody}
                  />
                  <Redirect
                    exact
                    from={FrameworkModuleProfileLibrariesBasePath.url}
                    to={FrameworkModuleProfileLibrariesPath.url}
                  />
                  <Redirect
                    exact
                    from={FrameworkModulePath.url}
                    to={DashboardPath.url}
                  />
                  <PageNotFoundRedirect />
                </FrameworkModuleSwitch>
              )}
            />
            <PageNotFoundRedirect />
          </Switch>

          <Switch>
            <Route
              exact
              path={PipingModuleInsulationsPath.url}
              component={InsulationsNotices}
            />
            <Route
              exact
              path={PipingModuleFittingsPath.url}
              component={FittingsNotices}
            />
            <Route
              exact
              path={PipingModulePipesPath.url}
              component={PipesNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsPath.url}
              component={SpecsNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingsEditPath.url}
              component={SpecsFittingsEditNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsFittingInstancesEditPath.url}
              component={SpecsFittingInstancesEditNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsPipesEditPath.url}
              component={SpecsPipesEditNotices}
            />
            <Route
              exact
              path={PipingModuleSpecsPipeInstancesEditPath.url}
              component={SpecsPipeInstancesEditNotices}
            />
            <Route
              exact
              path={PipingModuleProjectsPath.url}
              component={ProjectsNotices}
            />
            <Route
              exact
              path={StandardizeModuleNoteProfilesPath.url}
              component={NoteProfilesNotices}
            />
            <Route
              exact
              path={NoteProfilesEditPath.url}
              component={NoteProfilesEditNotices}
            />
            <Route
              exact
              path={StandardizeModuleParameterProfilesPath.url}
              component={ParameterProfilesNotices}
            />
            <Route
              exact
              path={StandardizeModuleSimplifiedRepsPath.url}
              component={SimplifiedRepsNotices}
            />
          </Switch>
        </DashboardBody>
      </DashboardPage>
    </Flex>
  );
};

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser(),
  isLoading: selectIsLoadingInitialData(),
  isMinimized: selectSidebarIsMinimized(),
  isFiltering: selectSidebarIsFiltering(),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUnits() {
    dispatch(processFetchAllUnits());
  },
  fetchUnitSystems() {
    dispatch(processFetchAllUnitSystems());
  },
  fetchAppearances() {
    dispatch(processFetchAllAppearances());
  },
  fetchSettings() {
    dispatch(processFetchAllSettings());
  },
  showCurrentUserModal() {
    dispatch(showModal("CURRENT_USER_MODAL"));
  },
  showPrivacyPolicyModal() {
    dispatch(
      showModal("PRIVACY_POLICY_MODAL", { modalSize: MODAL_SIZES.XLARGE })
    );
  },
  showLegalTermsUpdatedModal() {
    dispatch(
      showModal("LEGAL_TERMS_UPDATED_MODAL", { modalSize: MODAL_SIZES.SMALL })
    );
  },
  logout() {
    dispatch(handleLogout());
  },
});

const enhance = compose(
  withRouter,
  injectReducer({ key: "dashboard", reducer: dashboardReducer }),
  connect(mapStateToProps, mapDispatchToProps),
  UserIsAuthenticated,
  UserEmailVerified,
  UserHasOrganization,
  UserIsActive,
  UserHasNoRequiredPasswordResetPending
);

export default enhance(Dashboard);
