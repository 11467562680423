import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchiveBendFamiliesForPipeFamily,
  processUnarchiveBendFamiliesForPipeFamily,
} from '../../entities/BendFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { showModal } from '../../modules/modal/actions';

import { selectCurrentPipeId } from '../PipesEditSwitch/selectors';

import {
  selectCurrentBend,
  selectCurrentDraftBendInstancesPendingValidChanges,
} from './selectors';

import { updateBendInstancesCollection } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentBend(),
  selectCurrentPipeId(),
  selectCurrentDraftBendInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    bend,
    pipeId,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    bendId: bend && bend.id || null,
    pipeId,
    isArchived: bend && bend.archivedFlag,
    pendingValidChanges,
  }),
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  
  return (
    <Options 
      {...props}
      {...reduxProps}
      updateEntities = {() => dispatch(updateBendInstancesCollection(reduxProps.bendId))}
      editEntity = {() => dispatch(showModal('BEND_MODAL', { pipeFamilyId: reduxProps.pipeId, bendId: reduxProps.bendId }))}
      archiveEntity = {() => dispatch(processArchiveBendFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.bendId]))}
      unarchiveEntity = {() => dispatch(processUnarchiveBendFamiliesForPipeFamily(reduxProps.pipeId, [reduxProps.bendId]))}
    />
  );
}
