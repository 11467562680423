import React, { useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { List } from 'immutable';

import Flex from '../../common/Flex';

import BaseListCreator from '../../form/BaseListCreator';

const ListCreatorEditor = React.forwardRef(({ 
  value, 
  placeholder, 
  stopEditing,
  column, 
  defaultValue = List(),
  minListSize,
  maxListSize,
  allow,
  onDisallow,
}, ref) => {
  const inputRef = useRef();

  useImperativeHandle(ref, () => ({
    isPopup: () => false,
    afterGuiAttached: () => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    },
    getValue: () => currentValue === null || currentValue === undefined ? defaultValue : currentValue,
  }));

  const [isReady, setIsReady] = useState(false);

  const [currentValue, setCurrentValue] = useState(value || defaultValue);

  // prevent initial render from ruining life
  useEffect(() => {
    if (!isReady) { setIsReady(true); }
  }, []);

  const onOuterClick = useCallback(_ => stopEditing(), [stopEditing]);

  return (
    <Flex flexDirection='column' justifyContent='center' height='100%' cursor='pointer' pl={3} pr={3}>
      <BaseListCreator
        ref={inputRef}
        placeholder={placeholder}
        value={currentValue}
        onChange={setCurrentValue}
        width={column.actualWidth}
        itemHeight={56}
        onOuterClick={onOuterClick}
        minListSize={minListSize}
        maxListSize={maxListSize}
        allow={allow} 
        onDisallow={onDisallow} 
      />
    </Flex>
  );
});

export default ListCreatorEditor;
