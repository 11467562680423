import { createSelector } from 'reselect';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';

export const selectNormalizedSchedules = () => createSelector(
  selectEntities(),
  entities => entities.get('schedules')
);

export const selectNormalizedSchedulesList = () => createSelector(
  selectNormalizedSchedules(),
  schedules => schedules.toList()
);

export const selectNormalizedDraftSchedules = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('schedules');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectAllSchedulesOptions = () => createSelector(
  selectNormalizedSchedules(),
  schedules => schedules.map(schedule => schedule.toOption()).toList()
);