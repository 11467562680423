import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { withRouter } from 'react-router-dom';
import { List } from 'immutable';

import Grid from '../../containers/grid/Grid';

import { showModal } from '../../modules/modal/actions';

import { selectIsShowingArchived } from '../../modules/query/selectors';

import { selectNormalizedModelTemplatesList } from '../../entities/ModelTemplates/selectors';
import {
  processArchiveModelTemplates,
  processUnarchiveModelTemplates,
} from '../../entities/ModelTemplates/actions';

import {
  selectSidebarIsFiltering,
  selectStandardizeModulePermissionsAndState,
} from '../Dashboard/selectors';

const mapStateToProps = createSelector(
  selectStandardizeModulePermissionsAndState(),
  selectSidebarIsFiltering(),
  selectIsShowingArchived(),
  selectNormalizedModelTemplatesList(),
  (
    {
      isLoadingInitialData,
      isFetching,
      canCollaborate,
      hasValidLicense,
      ...rest
    },
    isFiltering,
    isShowingArchived,
    data,
  ) => {
    const isLoading = isLoadingInitialData ||
      (isFetching && (!data || data.size === 0));
    return {
      ...rest,
      isLoading,
      isList: true,
      showArchived: isFiltering && isShowingArchived,
      data: (!isLoading && data) || [],
      editable: canCollaborate && hasValidLicense,
    };
  },
);

const mapDispatchToProps = (dispatch) => ({
  createEntity() {
    dispatch(showModal('MODEL_TEMPLATE_MODAL'));
  },

  editModelTemplate(modelTemplate) {
    dispatch(
      showModal('MODEL_TEMPLATE_MODAL', {
        modelTemplateId: modelTemplate.id,
      }),
    );
  },
  copyModelTemplate(modelTemplate) {
    dispatch(
      showModal('MODEL_TEMPLATE_MODAL', {
        modelTemplateId: modelTemplate.id,
        copyEntity: true,
      }),
    );
  },
  archiveModelTemplate(profile) {
    dispatch(processArchiveModelTemplates([profile.id]));
  },
  unarchiveModelTemplate(profile) {
    dispatch(processUnarchiveModelTemplates([profile.id]));
  },
});

const enhance = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps,
    ({ data, showArchived, editable, isList }, dispatchProps) => ({
      data,
      isList,
      columnDefs: [
        {
          type: ['archivedStatusCell'],
          hide: !showArchived,
        },
        {
          headerName: 'Name // Model Name + Model Type',
          field: 'name',
          type: ['complexCell'],
          valueGetter: ({ data }) => data.summary,
          onCellClicked: ({ data }) =>
            editable && dispatchProps.editModelTemplate(data),
          cellStyle: () => (editable && { cursor: 'pointer' }) || {},
        },
        ...((editable && [
          {
            type: ['dataActionIconCell'],
            cellRendererParams: ({ value }) => ({
              actions: (value &&
                List([
                  {
                    icon: 'unarchive',
                    primary: true,
                    onClick: dispatchProps.unarchiveModelTemplate,
                  },
                  {
                    icon: 'copy',
                    primary: true,
                    onClick: dispatchProps.copyModelTemplate,
                  },
                ])) ||
                List([
                  {
                    icon: 'edit',
                    primary: true,
                    onClick: dispatchProps.editModelTemplate,
                  },
                  {
                    icon: 'copy',
                    primary: true,
                    onClick: dispatchProps.copyModelTemplate,
                  },
                  {
                    icon: 'archive',
                    error: true,
                    onClick: dispatchProps.archiveModelTemplate,
                  },
                ]),
            }),
          },
        ]) ||
          []),
      ],
    }),
  ),
);

export default enhance(Grid);
