import React from 'react';

import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

import EK from '../../entities/keys';

import BaseFormRow from '../../components/form/BaseFormRow';

import FilterFormTemplate from '../templates/Structures/FilterFormTemplate';

import RDXSearchSelect from '../../containers/form/RDXSearchSelect';

const FilterForm = props => (
  <FilterFormTemplate { ...props }>
    <BaseFormRow>
      <Field
        component={RDXSearchSelect}
        name='sizeProperty'
        label='Size'
        ek={EK.SIZES}
        clearable
      />
    </BaseFormRow>
  </FilterFormTemplate>
);

const enhance = compose(
  reduxForm({ form: `filters.${EK.MITER_INSTANCES.state}` })
);

export default enhance(FilterForm);
