import { all, put, select } from 'redux-saga/effects';
import { List, Map } from 'immutable';
import reduce from 'lodash/reduce';

import { mergeDrafts, moveDrafts } from '../../modules/drafts/actions';

import { generateUniqueIdForCreations } from '../../modules/drafts/sagas';

export const generateMoveMultiSaga = ({
  originalNormalizedDataAndKeys,
}) => {
  return function* moveMulti() {
    const moveList = yield all(
      originalNormalizedDataAndKeys.map(function* ({ selector, keys }) {
        const data = yield select(selector());
        return { [keys.state]: data };
      }),
    );

    yield put(
      moveDrafts(
        reduce(moveList, (output, tuple) => ({ ...output, ...tuple }), {}),
      ),
    );
  };
};

export const generateMoveOrCreateSaga = ({
  keys,
  model,
  baseParamsSelector,
  originalNormalizedDataSelector,
  normalizedParentDataSelector,
  childProperty,
  findExistingRecordFilterFunction,
  currentIdsSetSelector,
  generateCreationProperties,
}) => {
  return function* moveOrCreate() {
    const baseParams = yield select(baseParamsSelector());
    const originalNormalizedData = yield select(
      originalNormalizedDataSelector(),
    );
    const normalizedParentData = yield select(normalizedParentDataSelector());
    const currentIdsSet = yield select(currentIdsSetSelector());

    if (
      !!baseParams.familyId && normalizedParentData.has(baseParams.familyId)
    ) {
      const instancesToCreateOrMove = Map().withMutations((collection) => {
        normalizedParentData.getIn([baseParams.familyId, childProperty])
          .forEach((originalRecordId) => {
            const foundInstance = originalNormalizedData.find(
              findExistingRecordFilterFunction({
                baseParams,
                originalRecordId,
              }),
            );

            if (foundInstance) {
              collection.set(
                foundInstance.id,
                foundInstance.set('isSelected', true),
              );
            } else {
              let drafts = generateUniqueIdForCreations(
                List([{}]),
                currentIdsSet,
                {
                  creationProperties: {
                    ...generateCreationProperties({
                      baseParams,
                      originalRecordId,
                    }),
                    isSelected: false,
                  },
                },
              );

              collection.set(drafts.first().id, new model(drafts.first()));
            }
          });
      });

      yield put(
        moveDrafts({
          [keys.state]: instancesToCreateOrMove,
        }),
      );
    }
  };
};

export const generateCreateOrEditSaga = ({
  keys,
  model,
  baseParamsSelector,
  originalDraftDataSelector,
  normalizedDraftDataSelector,
  normalizedOriginalParentDataSelector,
  currentIdsSetSelector,
  childProperty,
  findDraftFilterFunction,
  generateCreationProperties,
}) => {
  return function* createOrEdit({ payload: { edits } }) {
    const baseParams = yield select(baseParamsSelector());
    const originalDraftData = yield select(originalDraftDataSelector());
    const normalizedDraftData = yield select(normalizedDraftDataSelector());
    const currentIdsSet = yield select(currentIdsSetSelector());
    const normalizedOriginalParentData = yield select(
      normalizedOriginalParentDataSelector(),
    );

    const mergeOutput = edits.reduce((output, edit) => {
      if (!!edit.id && normalizedOriginalParentData.has(edit.id)) {
        return output.withMutations((collection) => {
          normalizedOriginalParentData.getIn([edit.id, childProperty]).forEach(
            (currentDraftId) => {
              const foundDraft = normalizedDraftData.find(
                findDraftFilterFunction({ baseParams, edit, currentDraftId }),
              );

              if (foundDraft) {
                collection.set(
                  foundDraft.id,
                  foundDraft
                    .set('isSelected', edit.isSelectedForId)
                    .forceSetIsEdited(
                      originalDraftData.has(foundDraft.id)
                        ? originalDraftData.getIn([
                          foundDraft.id,
                          'isSelected',
                        ]) !== edit.isSelectedForId
                        : edit.isSelectedForId,
                    )
                    .analyze,
                );
              } else {
                let drafts = generateUniqueIdForCreations(
                  List([{}]),
                  currentIdsSet,
                  {
                    creationProperties: {
                      ...generateCreationProperties({
                        baseParams,
                        edit,
                        currentDraftId,
                      }),
                      isSelected: edit.isSelectedForId,
                      isValid: true,
                    },
                  },
                );

                collection.set(
                  drafts.first().id,
                  new model(drafts.first()).forceSetIsEdited(true).analyze,
                );
              }
            },
          );
        });
      } else {
        return output;
      }
    }, Map());

    yield put(
      mergeDrafts({
        [keys.state]: mergeOutput,
      }),
    );
  };
};
