import React, { useRef } from 'react';
import ReactDOM from 'react-dom';

import useFloatingState from '../../../hooks/useFloatingState';
import useIsHovering from '../../../hooks/useIsHovering';

import Icon from '../../common/Icon';

import Tooltip, { CONTAINERS } from '../../tooltip/Tooltip';
import TooltipBody from '../../tooltip/TooltipBody';
import TooltipSection from '../../tooltip/TooltipSection';

import EntityTooltip from '../../tooltip/templates/EntityTooltip';

import BaseCell from '../BaseCell';

import { ENTITY_STATUS } from '../../../modules/entities/constants';

const EntitySelectionRenderer = ({ value, canForcefullyDelete, canSafelyDelete, data }) => {
  const [reference, floating, floatingStyle] = useFloatingState({ placement: 'top' });
  const isHovering = useIsHovering(reference, { delayEnter: 1000 });

  let iconProps;
  let tooltipSize = 'fluid';
  let tooltipProps = { inverse: true };
  let tooltipText;
  let restrict = [];
  let warn = [];

  switch(value) {
  case ENTITY_STATUS.UNSELECTED: {
    // a newly-created draft that is currently unselected
    iconProps = { name: 'add', color: 'primary.4' };
    tooltipText = 'Add record to be saved';
    break;
  }
  case ENTITY_STATUS.LOCAL_DRAFT:
  case ENTITY_STATUS.PENDING_VALID_SAVE:
  case ENTITY_STATUS.PENDING_INVALID_SAVE: {
    // a newly-created draft that is current selected
    iconProps = { name: 'close', color: 'error.4' };
    tooltipText = 'Remove record from edits to be saved';
    break;
  }
  case ENTITY_STATUS.PENDING_DELETE: {
    // an existing record that is unselected, so it will be deleted
    iconProps = { name: 'undo', color: 'primary.4' };
    tooltipText = 'Restore record';
    break;
  }
  case ENTITY_STATUS.SAVED:
  case ENTITY_STATUS.PENDING_VALID_CHANGES:
  case ENTITY_STATUS.PENDING_INVALID_CHANGES: {
    // an existing record that is selected, so saving changes
    if (canForcefullyDelete) {
      iconProps = { name: 'delete', color: 'error.4' };
      tooltipText = 'Delete record';
    } else if (canSafelyDelete) {
      tooltipSize = 'large';
      iconProps = { name: 'delete', color: 'warning.4' };
      tooltipText = 'Record can be deleted, but it is currently being used in other entities';
      tooltipProps = { main: true, large: true };
      warn = data.printableWarnDeleteCounts || [];
    } else {
      tooltipSize = 'large';
      iconProps = { name: 'lock', color: 'gray.6' };
      tooltipText = 'Record cannot be deleted because it is being used in other entities';
      tooltipProps = { main: true, large: true };
      restrict = data.printableRestrictDeleteCounts || [];
      warn = data.printableWarnDeleteCounts || [];
    }
    break;
  }
  }

  return (
    <BaseCell ref={reference} flexDirection='column' justifyContent='center' alignItems='center'>
      { iconProps && <Icon { ...iconProps } /> }
      {
        tooltipText && isHovering && ReactDOM.createPortal(
          <Tooltip ref={floating} style={floatingStyle} size={tooltipSize}>
            <TooltipBody>
              <TooltipSection small { ...tooltipProps }>{ tooltipText }</TooltipSection>

              {
                (warn.length > 0 || restrict.length > 0) && (
                  <TooltipSection large>
                    { restrict.length && <EntityTooltip.Counts counts={restrict} error /> || null }
                    { warn.length && <EntityTooltip.Counts counts={warn} /> || null }
                  </TooltipSection>
                ) || null
              }
            </TooltipBody>
          </Tooltip>,
          document.querySelector(CONTAINERS.TOOLTIP)
        )
      }
    </BaseCell>
  );
};

export default EntitySelectionRenderer;