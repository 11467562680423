import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import pluralize from 'pluralize';

import Header from '../templates/Structures/Header';

import {
  NoteProfilesEditPath,
} from '../../paths';

import { selectIsFetching } from '../../modules/utility/selectors';

import { selectIsLoadingInitialData } from '../Dashboard/selectors';

import {
  selectCurrentNoteProfile,
  selectCurrentNoteProfileNotesCount,
} from './selectors';

const mapStateToProps = createSelector(
  selectIsLoadingInitialData(),
  selectIsFetching(),
  selectCurrentNoteProfile(),
  selectCurrentNoteProfileNotesCount(),
  (isLoadingInitialData, isFetching, profile, notesCount) => ({
    isLoading: isLoadingInitialData || (isFetching && (!notesCount || notesCount === 0)),
    title: profile && profile.name || NoteProfilesEditPath.defaultTitle,
    subtitle: `${notesCount} ${pluralize('Notes', notesCount)}`,
  })
);

const enhance = compose(
  connect(mapStateToProps),
);

export default enhance(Header);