import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import selectEntities from '../../modules/entities/selectors';

import { clearDrafts } from '../../modules/drafts/actions';

import { 
  generateReviewSaga,
  generateMoveSaga,
  generateWatchCreateSaga,
  generateWatchEditSaga,
  generateWatchCollectionUpdateSaga,
  waitWhileInitialFetchingSaga,
} from '../../modules/drafts/sagas';

import ScheduleModel from '../../entities/Schedules/model';
import ScheduleSchema from '../../entities/Schedules/schema';
import { selectNormalizedSchedules, selectNormalizedDraftSchedules } from '../../entities/Schedules/selectors';

import {
  selectCurrentDraftScheduleIds,
} from './selectors';

import { processSaveSchedules } from '../../entities/Schedules/actions';
import { saveSchedulesTypes } from '../../entities/Schedules/constants';

import {
  CREATE_SCHEDULE_DRAFTS,
  EDIT_SCHEDULE_DRAFTS,
  UPDATE_SCHEDULES_COLLECTION,
} from './constants';

const reviewSaga = generateReviewSaga({
  keys: EK.SCHEDULES,
  schema: ScheduleSchema,
  draftDenormalizeSelector: selectEntities,
  originalDenormalizedDataSelector: selectNormalizedSchedules,
});

const moveSaga = generateMoveSaga({
  keys: EK.SCHEDULES,
  model: ScheduleModel,
  originalNormalizedDataSelector: selectNormalizedSchedules, 
  currentIdsSetSelector: selectCurrentDraftScheduleIds, 
});

const createSaga = generateWatchCreateSaga({
  keys: EK.SCHEDULES,
  model: ScheduleModel,
  reviewSaga,
  currentIdsSetSelector: selectCurrentDraftScheduleIds,
});

const editSaga = generateWatchEditSaga({
  keys: EK.SCHEDULES, 
  model: ScheduleModel,
  reviewSaga,
  draftStateToGetLastEditedIndexSelector: selectNormalizedDraftSchedules,
  currentIdsSetSelector: selectCurrentDraftScheduleIds,
});

const updateSaga = generateWatchCollectionUpdateSaga({
  currentDraftDataToSerializeSelector: selectNormalizedDraftSchedules,
  processUpdateAction: processSaveSchedules,
  processUpdateTypes: saveSchedulesTypes,
  moveSaga,
});

export function* createDraftSchedulesSaga() {
  yield takeEvery(CREATE_SCHEDULE_DRAFTS, createSaga);
}

export function* editDraftSchedulesSaga() {
  yield takeEvery(EDIT_SCHEDULE_DRAFTS, editSaga);
}

export function* saveDraftSchedulesSaga() {
  yield takeLatest(UPDATE_SCHEDULES_COLLECTION, updateSaga);
}

export function* watchSchedulesDraftsSaga() {
  try {
    yield call(waitWhileInitialFetchingSaga);

    yield call(moveSaga);

    // then here, we fork off tasks to watch for updates to the grid
    yield all([
      fork(createDraftSchedulesSaga),
      fork(editDraftSchedulesSaga),
      fork(saveDraftSchedulesSaga),
    ]);

    // and we leave this while-true loop here to make sure the saga doesnt end
    while(true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put( clearDrafts({ [EK.SCHEDULES.state]: [] }) );
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSchedulesDraftsSaga);
}
