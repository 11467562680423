import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';

export const selectNormalizedBendFamilies = () =>  createSelector(
  selectEntities(),
  entities => entities.get('bendFamilies')
);
export const selectNormalizedBendFamiliesList = () => createSelector(
  selectNormalizedBendFamilies(),
  bendFamilies => bendFamilies.toList()
);