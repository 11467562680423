import React from 'react';
import styled from 'styled-components';

import Flex from '../../common/Flex';
import Icon from '../../common/Icon';

import BaseCell from '../BaseCell';
import BaseCellDataItem from '../BaseCellDataItem';

import BaseAppend from '../../form/BaseAppend';
import BaseMultiOptionValue from '../../form/BaseMultiOptionValue';

const BaseMultiOptionValueWrapper = styled(Flex)`
  flex-grow: 1;
  flex-shrink: 1;
`;

const ListCellRenderer = ({ 
  value, 
  placeholder, 
  isCapitalized, 
  isDropdown, 
  append 
}) => {
  const isValueEmpty = value === null || value.size === 0;
  const isAppend = isDropdown || append;
  
  return (
    <BaseCell 
      flexDirection='row' 
      alignContent='center'
    >
      <BaseMultiOptionValueWrapper flexDirection='column' justifyContent='center'>
        <BaseCellDataItem primary={!isValueEmpty} disabled={isValueEmpty} isCapitalized={isCapitalized}>
          { isValueEmpty && placeholder || <BaseMultiOptionValue selectedItems={value} /> }
        </BaseCellDataItem>
      </BaseMultiOptionValueWrapper>
      {
        isAppend && (
          <BaseAppend isCapitalized={isCapitalized}>
            { isDropdown ? <Icon name='chevron-down' /> : append }
          </BaseAppend>
        )
      }
    </BaseCell>
  );
};

export default ListCellRenderer;
