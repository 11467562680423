import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { space, width } from 'styled-system';
import colors from '../../../assets/themes/base/colors';
import radii from '../../../assets/themes/base/radii';
import shadows from '../../../assets/themes/base/shadows';
import lineHeights from '../../../assets/themes/base/lineHeights';
import transitions from '../../../assets/themes/base/transitions';
import spaces from '../../../assets/themes/base/space';
import fontSizes from '../../../assets/themes/base/fontSizes';

import Icon from '../Icon';

const fluctuateLight = keyframes`
  0% {
    transform: scale(.5);
    background-color: ${({theme}) => theme.colors.white};
  }

  50% {
    background-color: ${({
    $primary,
    $secondary,
    $error,
    $success,
    theme
  }) =>
    ($primary && theme.colors.primary[1]) ||
      ($secondary && theme.colors.gray[1]) ||
      ($error && theme.colors.error[1]) ||
      ($success && theme.colors.success[1]) ||
      theme.colors.gray[1]
}
  }

  100% {
    transform: scale(1);
    background-color: ${({
    $primary,
    $secondary,
    $error,
    $success,
    theme
  }) =>
    ($primary && theme.colors.primary[2]) ||
      ($secondary && theme.colors.gray[2]) ||
      ($error && theme.colors.error[2]) ||
      ($success && theme.colors.success[2]) ||
      theme.colors.gray[2]
}
  }
`;

const fluctuateColor = keyframes`
  0% {
    transform: scale(.5);
    background-color: ${({
    $primary,
    $secondary,
    $error,
    $success,
    theme
  }) =>
    ($primary && theme.colors.primary[4]) ||
      ($secondary && theme.colors.gray[7]) ||
      ($error && theme.colors.error[5]) ||
      ($success && theme.colors.success[5]) ||
      theme.colors.gray[5]
}
  }

  50% {
    background-color: ${({
    $primary,
    $secondary,
    $error,
    $success,
    theme
  }) =>
    ($primary && theme.colors.primary[3]) ||
      ($secondary && theme.colors.gray[6]) ||
      ($error && theme.colors.error[4]) ||
      ($success && theme.colors.success[4]) ||
      theme.colors.gray[4]
}
  }

  100% {
    transform: scale(1);
    background-color: ${({
    $primary,
    $secondary,
    $error,
    $success,
    theme
  }) =>
    ($primary && theme.colors.primary[2]) ||
      ($secondary && theme.colors.gray[5]) ||
      ($error && theme.colors.error[3]) ||
      ($success && theme.colors.success[3]) ||
      theme.colors.gray[3]
}
  }
`;

const BaseButtonDot = styled.div`
  border-radius: 100%;
  display: block;
  height: ${spaces[2]};
  width: ${spaces[2]};
  margin: 0 ${spaces[0]};
`;

const BaseButtonLoader = styled.span`
  display: flex;
`;

const BaseButton = styled.button`
  line-height: ${lineHeights[0]};
  border-radius: ${radii[1]};
  display: inline-flex;
  border: none;
  outline: none;
  cursor: ${({ $disabled }) => $disabled ? 'not-allowed' : 'pointer'};
  justify-content: center;
  align-items: center;
  touch-action: manipulation;
  user-select: none;
  font-weight: 500;
  text-decoration: none;
  box-shadow: ${shadows[0]};

  transition: color ${transitions.speed.fast} ${transitions.type.inOut},
    background-color ${transitions.speed.fast} ${transitions.type.inOut};

  &:focus,
  &:active,
  &:hover {
    border: none;
    outline: none;
  }

  height: 2.5rem;
  width: ${({ $icon }) => $icon && '2.5rem' || '10rem' };
  font-size: ${fontSizes[2]};
  padding-left: ${spaces[3]};
  padding-right: ${spaces[3]};

  ${
  ({ $icon }) => $icon && css`
      font-weight: bold;
    `
}

  ${
  ({ $tiny }) => $tiny && css`
      height: 1.2rem;
      width: ${({ $icon }) => $icon && '1.2rem' || '4rem' };
      font-size: ${fontSizes[0]};
      padding-left: ${spaces[1]};
      padding-right: ${spaces[1]};
    `
}

  ${
  ({ $small }) => $small && css`
      height: 2rem;
      width: ${({ $icon }) => $icon && '2rem' || '8rem' };
      font-size: ${fontSizes[1]};
      padding-left: ${spaces[2]};
      padding-right: ${spaces[2]};
    `
}

  ${
  ({ $large }) => $large && css`
      height: 3rem;
      width: ${({ $icon }) => $icon && '3rem' || '12rem' };
      font-size: ${fontSizes[3]};
      padding-left: ${spaces[4]};
      padding-right: ${spaces[4]};
    `
}

  ${
  ({ $fluid }) => $fluid && css`
      width: auto;
    `
}

  ${
  ({ $full }) => $full && css`
      width: 100%;
    `
}

  ${
  ({ $isPerformingAction }) => $isPerformingAction && css`
      cursor: wait;
      font-size: 0;
    `
}

  ${
  ({ $transparent }) => $transparent && css`
      /* if transparent prop is present */
      background-color: transparent;

      ${
  ({
    $primary,
    $secondary,
    $error,
    $success,
    $subtle,
  }) => $subtle && css`
          color: ${({ $disabled }) => $disabled ? colors.gray[3] : colors.gray[4]};
        ` ||
        $primary && css`
          color: ${({ $disabled }) => $disabled ? colors.primary[3] : colors.primary[4]};
        ` ||
        $secondary && css`
          color: ${({ $disabled }) => $disabled ? colors.gray[1] : colors.gray[2]};
        ` ||
        $error && css`
          color: ${({ $disabled }) => $disabled ? colors.error[3] : colors.error[5]};
        ` ||
        $success && css`
          color: ${({ $disabled }) => $disabled ? colors.success[3] : colors.success[5]};
        `
}

      &:focus,
      &:active,
      &:hover {
        background-color: transparent;

        ${
  ({
    $primary,
    $secondary,
    $error,
    $success,
    $subtle,
  }) => $subtle && (!$primary && !$secondary && !$error && !$success) && css`
            color: ${({ $disabled }) => !$disabled && colors.gray[6]};
          ` ||
          $primary && css`
            color: ${({ $disabled }) => !$disabled && colors.primary[5]};
          ` ||
          $secondary && css`
            color: ${({ $disabled }) => !$disabled && colors.gray[3]};
          ` ||
          $error && css`
            color: ${({ $disabled }) => !$disabled && colors.error[6]};
          ` ||
          $success && css`
            color: ${({ $disabled }) => !$disabled && colors.success[6]};
          `
}
      }
    ` ||
    css`
      /* if transparent prop is NOT present */
      ${
  ({
    $primary,
    $secondary,
    $error,
    $success,
  }) => $primary && css`
          color: ${colors.gray[0]};
          background-color: ${({ $disabled }) => $disabled ? colors.primary[3] : colors.primary[4]};
        ` ||
        $secondary && css`
          color: ${colors.gray[7]};
          background-color: ${({ $disabled }) => $disabled ? colors.gray[1] : colors.gray[2]};
        ` ||
        $error && css`
          color: ${colors.gray[0]};
          background-color: ${({ $disabled }) => $disabled ? colors.error[3] : colors.error[5]};
        ` ||
        $success && css`
          color: ${colors.gray[0]};
          background-color: ${({ $disabled }) => $disabled ? colors.success[3] : colors.success[5]};
        `
}

      &:focus,
      &:active,
      &:hover {
        ${
  ({
    $primary,
    $secondary,
    $error,
    $success,
  }) => $primary && css`
            background-color: ${({ $disabled }) => !$disabled && colors.primary[5]};
          ` ||
          $secondary && css`
            background-color: ${({ $disabled }) => !$disabled && colors.gray[3]};
          ` ||
          $error && css`
            background-color: ${({ $disabled }) => !$disabled && colors.error[6]};
          ` ||
          $success && css`
            background-color: ${({ $disabled }) => !$disabled && colors.success[6]};
          `
}
      }
    `
}

  ${BaseButtonLoader} {
    ${BaseButtonDot} {
      ${
  ({ $transparent }) => $transparent && css`
          animation-name: ${fluctuateColor};
        ` ||
        css`
          animation-name: ${fluctuateLight};
        `
}

      animation-direction: alternate;
      animation-duration: .5s;
      animation-fill-mode: none;
      animation-iteration-count: infinite;
      animation-play-state: running;
      animation-timing-function: ease-out;

      animation-delay: .1s;
      margin: 0 ${spaces[1]};

      &:last-child {
        animation-delay: .2s;
        margin: 0;
      }

      &:first-child {
        animation-delay: 0s;
        margin: 0;
      }
    }
  }

  ${width}
  ${space}
`;

const Button = React.memo(React.forwardRef(
  (
    {
      type,
      tabIndex,
      primary,
      secondary,
      error,
      success,
      transparent,
      disabled,
      icon,
      iconStyle,
      isLoading,
      isPerformingAction,
      as,
      children,
      value,
      full,
      large,
      small,
      tiny,
      fluid,
      ...props
    },
    ref
  ) => {
    return (
      <BaseButton
        ref={ref}
        as={as}
        type={type}
        tabIndex={tabIndex || '0'}
        $primary={primary}
        $secondary={secondary}
        $error={error}
        $success={success}
        $transparent={transparent}
        $disabled={disabled}
        $icon={icon}
        $isLoading={isLoading}
        $isPerformingAction={isPerformingAction}
        $full={full}
        $large={large}
        $small={small}
        $tiny={tiny}
        $fluid={fluid}
        { ...props }
      >
        {
          (!icon && children) ||
          <Icon name={icon} style={iconStyle} value={value} />
        }
        {
          isPerformingAction && (
            <BaseButtonLoader>
              { !icon && <BaseButtonDot /> }
              <BaseButtonDot />
              { !icon && <BaseButtonDot /> }
            </BaseButtonLoader>
          )
        }
      </BaseButton>
    );
  }
));

export default Button;
