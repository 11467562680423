import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { push } from 'connected-react-router';

import Button from '../../components/common/Button';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import {
  processArchivePipeFamilies,
  processUnarchivePipeFamilies,
} from '../../entities/PipeFamilies/actions';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';
import { toggleHiddenGridColumns } from '../Dashboard/actions';

import { showModal } from '../../modules/modal/actions';

import {
  PipingModulePipesBendsPath,
  PipingModulePipesBendMachinesPath,
  PipingModulePipesBendSpringbacksPath,
  PipingModulePipesMitersPath,
} from '../../paths';

import { selectCurrentPipe } from '../PipesEditSwitch/selectors';

import { selectCurrentDraftPipeInstancesPendingValidChanges } from './selectors';

import { updatePipeInstancesCollection } from './actions';

const ConstructedOptions = ({
  isPerforming,
  manageBends,
  manageBendMachines,
  manageBendSpringbacks,
  manageMiters,
  toggleHiddenGridColumns,
}) => (
  <React.Fragment>
    <Button secondary disabled={isPerforming} onClick={manageBends} mr={2}>
      Bends
    </Button>
    <Button
      secondary
      disabled={isPerforming}
      onClick={manageBendMachines}
      mr={2}
    >
      Bend Machines
    </Button>
    <Button
      secondary
      disabled={isPerforming}
      onClick={manageBendSpringbacks}
      mr={2}
    >
      Bend Springbacks
    </Button>
    <Button secondary disabled={isPerforming} onClick={manageMiters} mr={2}>
      Miters
    </Button>
    <Button secondary icon="list" onClick={toggleHiddenGridColumns} mr={2} />
  </React.Fragment>
);

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentPipe(),
  selectCurrentDraftPipeInstancesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    pipe,
    pendingValidChanges
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    pipeId: (pipe && pipe.id) || null,
    isArchived: pipe && pipe.archivedFlag,
    pendingValidChanges,
    ConstructedOptions,
  })
);

export default function (props) {

  const reduxProps = useSelector(mapStateToProps);
  const dispatch = useDispatch();

  return (
    <Options
      {...props}
      {...reduxProps}
      toggleHiddenGridColumns = {() => dispatch(toggleHiddenGridColumns())}
      updateEntities = {() => dispatch(updatePipeInstancesCollection(reduxProps.pipeId))}
      editEntity = {() => dispatch(showModal('PIPE_MODAL', {pipeId: reduxProps.pipeId}))}
      archiveEntity = {() => dispatch(processArchivePipeFamilies([reduxProps.pipeId]))}
      unarchiveEntity = {() => dispatch(processUnarchivePipeFamilies([reduxProps.pipeId]))}
      manageBends = {() => dispatch(push(PipingModulePipesBendsPath.generate({ id: reduxProps.pipeId })))}
      manageBendMachines = {() => dispatch(push(PipingModulePipesBendMachinesPath.generate({ id: reduxProps.pipeId })))}
      manageBendSpringbacks = {() => dispatch(push(PipingModulePipesBendSpringbacksPath.generate({ id: reduxProps.pipeId })))}
      manageMiters = {() => dispatch(push(PipingModulePipesMitersPath.generate({ id: reduxProps.pipeId })))}
    />
  );
}
