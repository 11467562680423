import { take, takeLatest, takeEvery, all, call, put, fork, cancelled } from 'redux-saga/effects';

import EK from '../../entities/keys';

import { clearDrafts } from '../../modules/drafts/actions';

import {
  generateReviewSaga,
  generateWatchEditSaga,
  waitWhileInitialFetchingSaga,
  waitWhilePerformingSaga,
  generateWaitWhileBaseParamsSaga,
} from '../../modules/drafts/sagas';

import {
  generateMoveOrCreateSaga,
} from '../SpecsEditSwitch/sagaTemplates';

import {
  selectCurrentPipeInstancesBaseParams,
  selectCurrentDraftSpecPipeInstanceIds,
} from '../SpecsEditSwitch/selectors';

import {
  selectCurrentSpecPipeInstanceDraftDenormalizeSelector,
  selectOriginalSpecPipeInstancesForFamily,
} from './selectors';

import SpecPipeInstanceModel from '../../entities/SpecPipeInstances/model';
import SpecPipeInstanceSchema from '../../entities/SpecPipeInstances/schema';

import { selectNormalizedSpecPipeInstances } from '../../entities/SpecPipeInstances/selectors';
import { saveSpecPipeInstancesTypes } from '../../entities/SpecPipeInstances/constants';

import { selectNormalizedPipeFamilies } from '../../entities/PipeFamilies/selectors';
import { fetchIndividualPipeFamilyTypes } from '../../entities/PipeFamilies/constants';
import { processFetchIndividualPipeFamily } from '../../entities/PipeFamilies/actions';

import {
  EDIT_SPEC_PIPE_INSTANCE_DRAFTS,
} from './constants';

const waitForBaseParamsSaga = generateWaitWhileBaseParamsSaga({
  baseParamsSelector: selectCurrentPipeInstancesBaseParams,
});

const moveOrCreateSaga = generateMoveOrCreateSaga({
  keys: EK.SPEC_PIPE_INSTANCES,
  model: SpecPipeInstanceModel,
  baseParamsSelector: selectCurrentPipeInstancesBaseParams,
  originalNormalizedDataSelector: selectNormalizedSpecPipeInstances,
  normalizedParentDataSelector: selectNormalizedPipeFamilies,
  childProperty: 'pipeInstances',
  currentIdsSetSelector: selectCurrentDraftSpecPipeInstanceIds,
  findExistingRecordFilterFunction: ({ baseParams, originalRecordId }) => originalRecord => (
    originalRecord.spec === baseParams.id &&
    originalRecord.pipeFamilyId === baseParams.familyId &&
    originalRecord.pipeInstance === originalRecordId
  ),
  generateCreationProperties: ({ baseParams, originalRecordId }) => ({
    spec: baseParams.id,
    pipeFamilyId: baseParams.familyId,
    pipeInstance: originalRecordId,
  }),
});

const reviewSaga = generateReviewSaga({
  keys: EK.SPEC_PIPE_INSTANCES,
  schema: SpecPipeInstanceSchema,
  draftDenormalizeSelector: selectCurrentSpecPipeInstanceDraftDenormalizeSelector,
  originalDenormalizedDataSelector: selectOriginalSpecPipeInstancesForFamily,
  postValidateProcessing: entity => entity.isSelected && entity.forceSetIsEdited(true).analyze || null,
});

const editSaga = generateWatchEditSaga({
  reviewSaga,
});

export function* updateSpecSuccessForMoveSaga() {
  yield takeLatest(saveSpecPipeInstancesTypes.SUCCESS, function* () {
    yield call(waitWhilePerformingSaga);

    yield call(moveOrCreateSaga);
  });
}

export function* editSpecPipeInstancesSaga() {
  yield takeEvery(EDIT_SPEC_PIPE_INSTANCE_DRAFTS, editSaga);
}

export function* watchSpecsPipesCollectionSaga() {
  try {
    const baseParams = yield call(waitForBaseParamsSaga);

    yield put(processFetchIndividualPipeFamily(baseParams.familyId));

    yield take(fetchIndividualPipeFamilyTypes.SUCCESS);

    yield call(waitWhileInitialFetchingSaga);

    yield call(moveOrCreateSaga);

    yield all([
      fork(editSpecPipeInstancesSaga),
      fork(updateSpecSuccessForMoveSaga),
    ]);

    while (true) {
      yield take();
    }
  } finally {
    if (yield cancelled()) {
      yield put(clearDrafts({ [EK.SPEC_PIPE_INSTANCES.state]: [] }));
    }
  }
}

// final output saga
export default function* main() {
  yield fork(watchSpecsPipesCollectionSaga);
}
