import React from 'react';

import Text from '../../common/Text';

import BaseCell from '../BaseCell';

const TemporaryTextCellRenderer = ({ value, textProps = {} }) => (
  <BaseCell flexDirection='column' justifyContent='center'>
    { value && <Text { ...textProps }>{ value }</Text> }
  </BaseCell>
);

export default TemporaryTextCellRenderer;