import React from 'react';
import BreadcrumbTemplate from '../templates/Structures/Breadcrumb';

import { PipingModulePipesBendsPath } from '../../paths';

export default function WPropsBreadcrumbTemplate() {
  return (
    <BreadcrumbTemplate title={PipingModulePipesBendsPath.defaultTitle}></BreadcrumbTemplate>
  );
}
