import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';

import selectEntities from '../../modules/entities/selectors';
import selectDrafts from '../../modules/drafts/selectors';
import BendMachineInstanceSchema from './schema';

export const selectNormalizedBendMachineInstances = () => createSelector(
  selectEntities(),
  entities => entities.get('bendMachineInstances')
);
export const selectNormalizedDraftBendMachineInstances = () => createSelector(
  selectDrafts(),
  drafts => {
    const state = drafts.get('bendMachineInstances');
    const records = state.get('records');
    return state.get('ids').map(id => records.get(id));
  }
);

export const selectBendMachineInstances = () => createSelector(
  selectEntities(),
  entities => denormalize(entities.get('bendMachineInstances'), [BendMachineInstanceSchema], entities.delete('bendMachineFamilies'))
);
export const selectDraftBendMachineInstances = () => createSelector(
  selectEntities(),
  selectNormalizedDraftBendMachineInstances(),
  (entities, drafts) => denormalize(drafts, [BendMachineInstanceSchema], entities.delete('bendMachineFamilies'))
);