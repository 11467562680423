import baseSchema from '../../modules/entities/utils/schema';

import keys from './keys';
import boltNutInstanceKeys from '../BoltNutInstances/keys';

import ScheduleSchema from '../Schedules/schema';
import SizeSchema from '../Sizes/schema';

const boltNutFamilySchema = baseSchema(keys.state, {
  boltNutInstances: { isId: true, idField: 'boltNutInstanceIds', isList: true },
});

export const boltNutInstanceSchema = baseSchema(boltNutInstanceKeys.state, {
  schedule: { schema: ScheduleSchema, isId: true },
  sizeProperty: { schema: SizeSchema, isId: true, originalField: 'size' },
  boltNutFamily: { schema: boltNutFamilySchema, isId: true },
});

boltNutFamilySchema.define({ boltNutInstances: [ boltNutInstanceSchema ] });

export default boltNutFamilySchema;
