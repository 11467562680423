import { createAction } from 'redux-actions';

import { generateCreateDraftsAction, generateEditDraftsAction } from '../../modules/drafts/actions';

import {
  CREATE_BEND_INSTANCE_DRAFTS,
  EDIT_BEND_INSTANCE_DRAFTS,
  UPDATE_BEND_INSTANCES_COLLECTION,
} from './constants';

export const createBendInstanceDrafts = generateCreateDraftsAction(CREATE_BEND_INSTANCE_DRAFTS);

export const editBendInstanceDrafts = generateEditDraftsAction(EDIT_BEND_INSTANCE_DRAFTS);

export const updateBendInstancesCollection = createAction(UPDATE_BEND_INSTANCES_COLLECTION, bendFamilyId => ({ bendFamilyId }));
