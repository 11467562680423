import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import { Map } from 'immutable';

import { selectNormalizedEndTypes } from '../../../../entities/EndTypes/selectors';

import { selectNormalizedSchedules } from '../../../../entities/Schedules/selectors';

import EndTypeCompatabilitySchema from '../../../../entities/EndTypeCompatibilities/schema';
import { selectNormalizedDraftEndTypeCompatibilities } from '../../../../entities/EndTypeCompatibilities/selectors';

export const selectCurrentEndType = () => createSelector(
  selectNormalizedEndTypes(),
  (state, ownProps) => ownProps.endTypeId,
  (endTypes, id) => endTypes.get(id)
);

export const selectCurrentEndTypeCompatibilities = () => createSelector(
  selectNormalizedDraftEndTypeCompatibilities(),
  selectNormalizedEndTypes(),
  selectNormalizedSchedules(),
  (state, ownProps) => ownProps.endTypeId,
  (endTypeCompatibilities, endTypes, schedules, endTypeId) => denormalize(
    endTypeCompatibilities.filter(comp => comp.firstEndType === endTypeId || comp.secondEndType === endTypeId),
    [EndTypeCompatabilitySchema],
    Map({ endTypes, schedules })
  )
);