import React from 'react';
import styled from 'styled-components';
import space from '../../../../assets/themes/base/space';
import pluralize from 'pluralize';

import Box from '../../../common/Box';
import Text from '../../../common/Text';

const Header = ({ children, ...props }) => <Text color='gray.8' fontSize={5} fontWeight='500' { ...props }>{ children }</Text>;

const Subheader = ({ children, ...props }) => <Text color='gray.6' fontSize={2} mb={2} { ...props }>{ children }</Text>;

const Description = ({ children, ...props }) => <Text color='gray.7' fontSize={2} {...props}>{ children }</Text>;

const CountsBox = styled(Box)`
  margin-bottom: ${space[3]};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Counts = ({ error, counts }) => (
  <CountsBox>
    <Text color='gray.7' fontSize={1} mb={1}>
      {
        error &&
          'The following items are using this size and are blocking it from being deleted' ||
          'The following items are using this entity and will be set to empty if this entity is deleted:'
      }
    </Text>
    <Box as='ul'>
      {
        counts.map((tuple, idx) => (
          <Text key={idx} as='li' color='gray.6' fontSize={1}>
            <Text as='span' fontWeight='500' color={tuple[0] && (error && 'error.4' || 'warning.4') || 'gray.6'}>{ tuple[0] }</Text> { pluralize(tuple[1], tuple[0]) }
          </Text>
        ))
      }
    </Box>
  </CountsBox>
);

export default {
  Header,
  Subheader,
  Description,
  Counts,
};