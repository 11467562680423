import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import Options from '../templates/Structures/Options';

import { selectIsEditingGrid } from '../../modules/grid/selectors';

import { selectPipingModulePermissionsAndState } from '../Dashboard/selectors';

import { selectCurrentDraftSizesPendingValidChanges } from './selectors';

import { updateSizesCollection } from './actions';

const mapStateToProps = createSelector(
  selectPipingModulePermissionsAndState(),
  selectIsEditingGrid(),
  selectCurrentDraftSizesPendingValidChanges(),
  (
    { isLoadingInitialData, isFetching, ...rest },
    isEditingGrid,
    pendingValidChanges,
  ) => ({
    ...rest,
    canFilter: true,
    isLoading: isLoadingInitialData || isFetching,
    isEditingGrid,
    pendingValidChanges,
  }),
);

const enhance = compose(
  connect(
    mapStateToProps,
    { updateEntities: updateSizesCollection },
  ),
);

export default enhance(Options);
